// Customizable Area Start
import React from "react";
import SellerPendingRequestController from "./SellerPendingRequestController";
import { 
  Container, withStyles, 
  Grid, styled, 
  Tab, Tabs, 
  InputLabel, Box, 
  InputBase, ClickAwayListener,
  TableCell, TableRow,
  Chip, Table,
  Tooltip, FormControl, 
  TableBody, TableContainer, 
  TableHead, Dialog, 
  Checkbox, Button, 
  Collapse, FormControlLabel, 
  TextField, CircularProgress 
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Navbar from "../../../components/src/NavBar.web";
import LandingPageTextField, { AutocompleteStyle, OptionForSelect, ProjectTextField } from "../../../components/src/ProjectTextField";
import ProjectText from "../../../components/src/ProjectText";
import { DateTimeIcon,close, resultNotFound1, image_dropdown, image_success, } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import { ContainedButton } from "../../../components/src/GlobalButton";
import Select from "react-select";
import moment from "moment";
import Spinner from "../../../components/src/ProjectLoader";
import AddListing from "../../../components/src/AddListing";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import CancelIcon from '@material-ui/icons/Cancel';
import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete";
import { SectionWrapperLeft } from "./SellerListing.web"
import { toast } from "react-toastify";

const StyledTabs = withStyles((theme) => ({
  root: {
    fontFamily: "Asap, sans-serif",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",color: " #363636",
    fontSize: "20px",fontStyle: "normal",
    fontWeight: 500,lineHeight: "normal",
    background: "#DADADA",
  }
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    borderTopLeftRadius: "5px",
    fontWeight: "bold",
    overflow: "visible",
    color: "#1E1E1E",
    fontFamily: "Asap",
    borderTopRightRadius: "5px",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "normal",
    textTransform: "uppercase",
    width: "451.5px",height: "72px",
  },
  selected: { background: "#ffffff" },
}))(Tab);

export const BorderedStyledTab = withStyles((theme) => ({
  root: {
    borderTopLeftRadius: "5px",
    fontWeight: "bold",
    overflow: "visible",
    color: "#1E1E1E",
    fontFamily: "Asap",
    borderTopRightRadius: "5px",
    fontSize: "14px", 
    fontStyle: "normal",
    lineHeight: "normal",
    textTransform: "uppercase",
    width: "451.5px",
    height: "72px",
  },
  selected: { background: "#ffffff" },
  wrapper: {
    padding: '14px 0px',borderRight: '1px solid rgba(172, 172, 172, 1)'
  }
}))(Tab);

const StyledInputLable = withStyles((theme) => ({
  root: { 
    fontWeight: 700, color: "#363636", 
    textTransform: "uppercase",fontSize: "18px", 
    fontFamily: "Asap", 
    },
}))(InputLabel);

const BootstrapInput = withStyles((theme) => ({
  root: { "label + &": { marginTop: theme.spacing(3) } },
  input: {
    borderRadius: 5, 
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",fontSize: "16px",
    padding: "10px 12px", 
    transition: theme.transitions.create(["border-color", "box-shadow"]), 
    color: "#363636", fontFamily: "Asap",
    position: "relative", "&:focus": { 
      borderColor: "##DCDCD", 
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", 
    }
  },
}))(InputBase);

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: "14px", 
    color: "#1E1E1E", 
    verticalAlign: "baseline",
    fontFamily: "Asap", 
    fontStyle: "normal",
     lineHeight: "24px", 
    fontWeight: 600,
    background: "#E1E5EE",
     borderBottom: "2px solid #8A99BB",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 767px)": { 
      "&:nth-child(5) > ul": { 
        width: "500px" 
      } 
    },
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1199px)": { 
      "&:nth-child(5) > ul": { 
        width: "500px" 
      } 
    },
  },
  body: { fontSize: 14 },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    fontSize: "14px", borderRadius: "10px", 
    color: "#363636", fontWeight: 400, 
    lineHeight: "16px",fontFamily: "Asap", 
     background: "#fff",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)":
     { "&>:nth-child(5)": 
     {
       width: "400px", 
    } 
  },
    "@media only screen and (min-device-width: 769px) and (max-device-width: 1199px)": {
      "&>:nth-child(5)>div": 
      { 
        width: "500px"
       },
    },
  },
}))(TableRow);

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" id={`simple-tabpanel-${index}`} hidden={value !== index} aria-labelledby={`simple-tab-${index}`} {...other} >
      {value === index && children}
    </div>
  );
};

const StyledTable = withStyles((theme) => ({
  root: { 
    rowGap: "10px", border: "none", 
  borderCollapse: "separate",borderSpacing: "0 3px", 
  background: "transparent", 
}
}))(Table);

const StyledTableHeaderRow = withStyles((theme) => ({
  root: {
    "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)": {
      "&>:nth-child(5)": 
      {
         width: "400px" 
        },
    },
    "@media only screen and (min-device-width: 769px) and (max-device-width: 1199px)": {
      "&>:nth-child(5)>div": 
      { 
        width: "500px" 
      },
    },
  },
}))(TableRow);

const columns = [
  { id: "request", label: ["REQUEST ID "], minWidth: "auto" }, 
  { id: "date", label: ["DATE RANGE"], minWidth: "auto" }, 
  { id: "pol", label: ["POL"], minWidth: "auto" },
  { id: "type_", label: ["POD"], minWidth: "auto" }, 
  // { id: "quantity", label: ["TYPE", "CODES", "QUANTITY", "REQUESTED RATE (USD)"], minWidth: "auto" },
  {
    minWidth: "auto",label: ["STATUS"],id: "type_",
  },
  {
    label: ["TYPES"],id: "code_",minWidth: "auto",},{minWidth: "auto",id: "quantity",label: ["QUANTITY"],
  },
  {
    minWidth: "auto",id: "requestedRate",label: ["BUYER PRICE (USD)"],
  },
  { id: "terms", label: ["TERMS"], minWidth: "auto" },
   { id: "total", label: ["TOTAL (USD)"], minWidth: "auto",justifyContent:"start" },
   {
    label: [""], minWidth: "auto", id: "offer",
  },];
const StyledBox = withStyles((theme) => ({
  root: {
    position: "relative", 
    padding: "20px 110px", 
    backgroundColor: theme.palette.common.white, 
    border: "1px solid #ced4da",
    fontSize: 16, 
    borderRadius: 8, 
    transition: theme.transitions.create(["border-color", "box-shadow"]), 
    cursor: "pointer", "&:focus": { borderColor: "#000" }
  },
}))(Box);

const StyledChip = withStyles((theme) => ({
  root: {
    fontFamily: "Asap, sans-serif",
    background: "blue",
    color: "white",
    fontSize: ".875rem",
    flexDirection: "row-reverse",
    "& .MuiChip-deleteIcon": {
      color: "rgba(255, 255, 255, 1)",
    },
    "& .MuiChip-label": {
      paddingRight: "0rem"
    },
    "& .MuiChip-icon": {
      color: "rgba(255, 255, 255, 1)",
      margin: "0rem"
    }
  },
}))(Chip);

const StyledSwitch = styled("div")({
  position: "relative", display: "inline-block", 
  height: "2.125rem", marginRight: "15px", 
  width: "75px",
  "& input": { 
    opacity: 0,
     width: 0, 
     height: 0 
    },
    
  "& .slider": {
    position: "absolute",bottom: 0, 
    top: 0, cursor: "pointer", 
    right: 0, left: 0, 
    backgroundColor: "#ccc", transition: "background-color 0.4s",
    "& .text": { 
      position: "absolute", top: "4.8px",
      left: "10px", 
    color: "white", fontSize: "16px", 
    fontWeight: "bolder" 
  },
    "&:before": {
      position: "absolute", content: '""', 
      backgroundColor: "white", height: "1.625rem", 
       width: "1.625rem", left: "0.56rem", 
       bottom: ".25rem",transition: "transform 0.4s"
    }
  },
  "& .noData": {
    display: "flex",fontFamily: "Asap",
    justifyContent: "center",alignItems: "center",
    flexDirection: "column",paddingTop: "96px",
  },
  "& .noDataImg": {
    height: "80px",
  },
  "& .head": {
    margin: "8px",fontFamily: "Asap",
    fontSize: "32px",fontWeight: "bold",
  },
  "& .msg": {
    margin: "0px",fontFamily: "Asap",
    fontSize: "16px",
  },
  "& input:checked + .slider": {
     backgroundColor: "#1653DD" 
    },
  "& input:focus + .slider": { 
    boxShadow: "0 0 .0625rem #2196F3"
   },
  "& input:checked + .slider:before": { 
    transform: "translateX(37.4px)" 
  },
  "& .slider.round": { 
    borderRadius: "2.125rem",
     "&:before": { 
      borderRadius: "50%"
     } }
});

const useStyles = {
  reactSelectWrapper: {
    fontFamily: "Asap",
    "& .css-1p3m7a8-multiValue": { 
      padding: "3px 9px", 
      borderRadius: "28px"
     },
    "& .css-wsp0cs-MultiValueGeneric": { 
      fontWeight: 600, 
      fontSize: "14px", 
      fontFamily: "Asap"
     },
    "& .css-12a83d4-MultiValueRemove": {
       borderRadius: "100%", 
       background: "#000", 
       height: "20px", 
       width: "20px", 
       position: "relative",
        top: "2px" 
      },
    "& .css-tj5bde-Svg": { fill: "#fff" },
    "& .css-13cymwt-control": { borderRadius: "12px", 
    height: "43px !important" },
    "& .css-1hb7zxy-IndicatorsContainer": { display: "none" },
    "& .css-yk16xz-control": { background: "#ECECEC !important", 
    borderRadius: "8px", 
    padding: "6.5px 0" },
    "& .css-1fhf3k1-control": { minHeight: "46px", 
    borderRadius: "8px" }
  },
  leftBar: {
    display: "flex", alignItems: "center",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)":
      { flexWrap: "wrap" },
  },
  cellAlignmentPadding: {
    padding: "0px"
  },
  selectSlot: {
    width: "24.3125rem",
    height: "3rem",
    background: "rgba(255, 255, 255, 1)",
    border: ".0625rem solid rgba(205, 205, 205, 1)",
    marginLeft: ".9375rem",
    padding: '1rem .75rem',
    borderRadius: ".5rem",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    //   "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)":
    //     {
    //       width: "346px",
    //       margin: "10px 0 15px 0",
    //     },
  },
  btnDiv: {
    display: "flex", alignItems: "center", justifyContent: "center",
  },
  addListing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ".875rem",
    background: "#1653DD", 
    border: "none",
    borderRadius: "2rem",
    padding: ".5625rem 1.125rem",
    color: "#FFFFFF", 
    marginRight: "1.875rem",
    fontWeight: 600,
    height: "3rem",
    width: "16rem",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
  },
  monthlyPricing: {
    display: "flex", 
    alignItems: "center", 
    color: "#1E1E1E", 
    justifyContent: "center", 
    fontSize: "14px", 
    background: "transparent",
    border: "1px solid #1E1E1E",
     borderRadius: "32px", 
     marginRight: "30px", 
     fontWeight: 600, width: "256px", 
     textTransform: "uppercase" as "uppercase",
    height: "48px", 
    cursor: "pointer", 
    fontFamily: "Asap",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)": {
      width: "179px", 
      height: "43px", 
      marginRight: "7px",
    },
  },
  textEnd: {
    display: "flex", justifyContent: "end",
  },
  btnImgDiv: {
     marginRight: "8px" 
    },
  root: { 
    background: "#eee",
     padding: "30px 0" ,
     minHeight : "100vh"
    },
  slotsSection: { 
    marginBottom: "50px", 
    justifyContent: "center" 
  },
  slotsFor: { margin: 0, 
    color: "#1E1E1E", 
    fontFamily: `"Asap", "sans-serif"`, 
    fontWeight: 800, 
    fontSize: "24px" },
  tableContent: { color: "#363636", 
  fontSize: "15px", 
  fontFamily: "Asap", 
  textTransform: "uppercase" as "uppercase", 
  fontWeight: 500 
},
  org: { 
    background: "#B8D5F9",
     color: "#1E1E1E", 
     fontSize: "16px", 
     padding: "6px 12px 6px 10px", 
     fontWeight: 500, 
     fontFamily: "Asap Roboto",
      borderRadius: "4px" ,
    },
  pipe: { 
    position: "absolute" as "absolute", 
    top: 0, 
    width: "1px", 
    background: "#6495D2",
     height: "32px", 
     left: "78px" },
  typeDiv: {
    display: "flex", 
    justifyContent: "space-between", 
    background: "#B8D5F9", 
    fontSize: "15px", 
    fontWeight: 500, 
    fontFamily: "Asap", 
    padding: "6px 25px",
    border: "1px solid #6495D2", 
    color: "#1E1E1E", 
    borderRadius: "4px",
     marginBottom: "3px"
  },
  available: {
    color: "#3C7C09", 
    fontWeight: 500, 
    fontSize: "15px", 
    fontFamily: "Asap",
  },
  quantityDiv: {
    display: "flex", 
    border: "1px solid #DADADA", 
    borderRadius: "8px", 
    padding: "10px 20px", 
    alignItems: "center", 
    marginRight: "15px",
  },
  availableQuantity: { 
    color: "#3C7C09", 
    fontSize: "18px", 
    fontFamily: "Asap", 
    fontWeight: 500, 
    margin: 0 },
  total: {
    color: "#1E1E1E",
    fontSize: "15px",
    fontFamily: "Asap",
    background: "#EAEAEA",
    padding: "5px 10px",
    fontWeight: 500,
    borderRadius: "4px",
  },
  bookedAmount: {
    color: "#DB2525",
    fontSize: "15px",
    textAlign: "center" as "center",
    fontWeight: 500,
    fontFamily: "Asap",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    marginTop: "10px",
  },
  mr15: {
    marginRight: "15px",
  },
  tableLeftBorder: {
    borderRadius: "6px",
    borderLeft: "3px solid rgb(184, 213, 249)",
    textAlign: "center" as "center"
  },
  cellAlignment: {
    textAlign: "center" as "center",
    whiteSpace: "nowrap" as "nowrap"
  },
  dFlex: {
    display: "flex",
    justifyContent: "space-between",
    listStyle: "none",
    padding: 0,
    alignItems: "center",
    margin: 0
  },
  textCenter: {
    textAlign: "center" as "center",
  },

  ellipsis: {
    cursor: "pointer",
  },
  addListingModal: {
    padding: "20px 10px",
  },
  p_10_b_30: {
    padding: "10px 30px",
  },
  title: {
    fontSize: "28px",
    color: "#1E1E1E",
    fontFamily: "Asap, sans-serif",
    margin: "0 0 15px 0",
    textAlign: "center" as "center",
  },
  close: {
    textAlign: "end" as "end",
    cursor: "pointer",
  },
  principalLabel: {
    color: "#363636",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Asap, sans-serif",
  },
  organisation: {
    margin: "0 20px 20px 20px",
  },
  vesselHeading: {
    color: "#1E1E1E",
    fontSize: "18px",
    textAlign: "center" as "center",
    margin: "0 0 25px 0",
    fontFamily: "asap, sans-serif",
  },
  vesselContainer: {
    borderRadius: "18px",
    background: "#E3E6ED",
    padding: "25px 10px",
    marginBottom: "15px",
  },
  inputText: {
    height: "44px",
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #CBD5E1",
    padding: "0 8px",
  },
  validtyInputText: {
    border: "none",
    width: "40px",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    height: "44px",
    padding: "0 8px",
  },
  validityBox: {
    display: "flex",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    alignItems: "center",
  },
  etbDiv: {
    marginLeft: "10px",
  },
  expiryDate: {
    color: "#1E1E1E",
    fontFamily: "Asap, sans-serif",
    fontSize: "14px",
  },
  beforeETB: {
    color: "#797979",
    fontFamily: "Asap, sans-serif",
    fontSize: "14px",
  },
  expiryDateDiv: {
    display: "flex",
    height: "40px",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    paddingLeft: "10px",
  },
  rateBox: {
    alignItems: "center",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    background: "#fff",
  },
  bBottom: {
    margin: "0 2px 20px 2px",
    border: "1px solid #CBD5E1",
  },
  usd: {
    margin: "0 10px",
  },
  borderRight: {
    borderRight: "1px solid #CDCDCD",
    height: "41px",
  },
  rateDiv: {
    color: "#1E1E1E",
    fontSize: "14px",
    fontFamily: "Asap, sans-serif",
  },
  slash: {
    color: "gray",
    position: "relative" as "relative",
    left: "12px",
  },
  inputRateText: {
    border: "none",
    width: "40px",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    height: "44px",
    padding: "0 8px",
  },
  ml5: {
    marginLeft: "5px",
  },
  btnTheme: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    background: "#1653DD",
    border: "none",
    borderRadius: "2rem",
    padding: ".5625rem 1.125rem",
    color: "#FFFFFF",
    fontWeight: 600,
    height: "2.8125rem",
    width: "16rem",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
  },
  dFlexJCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listingSubmittedBox: {
    padding: "10px 20px",
  },
  mb20: {
    marginBottom: "20px",
  },
  listingIdPara: {
    color: "#363636",
    fontSize: "16px",
    fontFamily: "Asap, sans-serif",
    margin: "0 0 5px 0",
  },
  listingHeading: {
    color: "#1E1E1E",
    fontSize: "28px",
    fontFamily: "Asap, sans-serif",
    fontWeight: 700,
  },
  pTB50: {
    padding: "50px 0",
  },
  // Listing submitted Css

  // Matching Requests Available for Your Listing! btn css
  viewOfferBtn: {
    fontSize: "16px",
    background: "#1653DD",
    border: "none",
    borderRadius: "32px",
    padding: "9px 18px",
    color: "#FFFFFF",
    fontWeight: 600,
    height: "45px",
    width: "212px",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
    marginTop: "25px",
  },
  // Matching Requests Available for Your Listing! btn css
  // Delete modal css
  deleteBtn: {
    fontSize: "16px",
    border: "none",
    borderRadius: "32px",
    padding: "9px 18px",
    color: "#FFFFFF",
    fontWeight: 600,
    background: "#DB2525",
    height: "45px",
    width: "212px",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
    marginRight: "15px",
  },
  cancelBtn: {
    fontSize: "16px",
    background: "#FFFFFF",
    borderRadius: "32px",
    padding: "9px 18px",
    color: "#000000",
    fontWeight: 600,
    border: "1px solid #000000",
    height: "45px",
    width: "212px",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
  },
  // Delete modal css

  // User Guide videos modal css
  videoContainer: {
    background: "#E3E6ED",
    borderRadius: "22px",
    paddingBottom: "15px",
  },
  videoText: {
    color: "#1E1E1E",
    fontSize: "15px",
    margin: "15px 12px 5px 12px",
    fontFamily: "Asap, sans-serif",
    fontWeight: 600,
  },
  timeStamp: {
    color: "#797979",
    fontSize: "15px",
    fontFamily: "Asap, sans-serif",
    margin: "0 12px",
  },
  // User Guide videos modal css

  offerBtn: {
    borderRadius: "32px",
    background: "#1653DD",
    border: "none",
    padding: "9px 18px",
    color: "#FFFFFF",
    marginRight: "15px",
    fontWeight: 600,
    height: "43px",
    fontSize: "14px",
    width: "115px",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
    fontFamily: "Asap",
  },
  totalUSD: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Asap",
    marginInline: "20px",
  },
  verticalLine: {
    height: "20px",
    position: "absolute" as "absolute",
    top: "-2px",
    width: "1.5px",
    left: "68px",
    background: "#6AA2C6",
  },
  positionRelative: {
    position: "relative" as "relative",
  },
  ml15: {
    marginLeft: "3px",
  },
  daysBeforeETB: {
    background: "#E9E9E9", 
    display: "inline-block",
    position: "absolute" as "absolute",
    top: "-39px",
    right: "1px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "10px",
    fontSize: "14px",
    padding: "9px 22px",
    fontFamily: "Asap",
    color: "#797979",
    "@media (max-width: 1200px)": {
      top: "-29px",
      padding: "9px 4px",
    },
  },
  teu: {
    display: "inline-block",
    fontSize: "14px",
    fontFamily: "Asap",
    color: "#797979",
    position: "absolute" as "absolute",
    top: "-29px",
    right: "10px",
    "@media (max-width: 960px)": {
      top: "-26px",
      padding: "9px 4px",
    },
  },
  slashTerms: {
    position: "absolute" as "absolute",
    left: "220px",
    top: "-29px",
    fontWeight: 600,
    "@media (max-width: 560px)": {
      top: "-23px",
      left: "240px",

    },
  },
  usdDiv: {
    background: "#ECECEC",
    border: "1px solid #ACACAC",
    padding: "8.5px 17px",
    fontSize: "15px",
    fontFamily: "Asap",
    height: "40px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    fontWeight: 600,
  },
  usdDivTransparent: {
    fontSize: "15px",
    background: "#fff",
    border: "1px solid #ACACAC",
    fontFamily: "Asap",
    height: "40px",
    padding: "8.5px 17px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    fontWeight: 600,
  },
  offerBox: {
    display: "flex",
    alignItems: "center",
  },
  multiSelectBox: {
    "& .MuiTypography-body1, .MuiChip-label": {
      fontFamily: "Asap",
      fontSize: "14px",
      textTransform: "capitalize",
    },
    "& .MuiBox-root-60": {
      width: "100% !important",
    },
  },
  selectSlotItem: {
    display: 'flex',
    width: '100%',
    height: '2.25rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1rem'
  },
  
  DropDownDiv: {
    display: 'flex', 
    flexShrink: 0, 
    textTransform: 'uppercase' as "uppercase", 
    gap: '.625rem', 
    alignItems: 'center', 
    justifyContent: 'center', 
    paddingRight: '.625rem', 
    paddingLeft: '.625rem'
  },
  boxtextp:{
    width: "fit-content", 
    marginLeft:"4px",
    height: "fit-content",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 900,
    fontFamily: "Asap",
    padding: "4px 16px 4px",
    background: "rgb(184, 213, 249)",
    whiteSpace: "nowrap" as "nowrap",
    borderRadius:"4px"
  },
  selectSlotDropdown: {
    position: 'absolute' as 'absolute',
    width: '24.125rem',
    background: 'white',
    borderRadius: '.5rem',
    boxShadow: "rgba(100, 100, 111, 0.2) 0rem .4375rem 1.8125rem 0rem",
    padding: '.25rem .375rem',
    display: 'flex',
    flexDirection: "column" as "column",
    gap: '.125rem',
    left: '.9375rem',
    marginTop: '.25rem',
    zIndex: 2,
  },
};
const buyerStyle = {
  org: {
    color: "#1E1E1E", 
    background: "#B8D5F9",
    fontSize: "15px",
    fontFamily: 'Asap',
    padding: "6px 12px",
    borderRadius: "4px",
    fontWeight: 500,
  },
  noData:{
    display: "flex", 
    fontFamily: "Asap",
   justifyContent: "center",
    alignItems: "center",
    paddingTop: "96px",
  },
   noDataImg: {
    height: "80px",
  },
   head : {
    margin: "8px",
    fontFamily: "Asap",
    fontSize: "32px",
  },
   msg: {
    margin: "0px",
    fontFamily: "Asap",
    fontSize: "16px",
  },
  tableContent: {
    color: "#363636",
    fontSize: "15px",
    fontFamily: 'Asap',
    fontWeight: 500,
  },
  highlightedTableContent: {
    padding: '8px 0px',
    textAlign: 'center' as 'center',
    width: '100%'
  },
  mr15: {
    marginRight: "15px"
  },
  tableLeftBorder: {
    borderRadius: "6px",
    borderLeft: "3px solid #B8D5F9",
    textAlign: "center" as "center"
  },

  dFlex: {
    listStyle: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dFlexs: {
    listStyle: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dFlexStart: {
    listStyle: "none",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  textCenter: {
    textAlign: "center" as "center"
  },

}

class SellerPendingRequest extends SellerPendingRequestController {
  [x: string]: any;
  NewListingSchema = () => {
    const firstContainerActive = this.state.offeredQuantity != "" || this.state.offeredRate != "" || this.state.offeredTermsOne != "" || this.state.offeredTermsTwo != ""
    const secondContainerActive = this.state.secondOfferedQuantity != "" || this.state.secondOfferedrate != "" || this.state.secondOfferedTermsOne != "" || this.state.secondOfferedTermsTwo != ""
    return Yup.object().shape({
      PRINCIPALORGANISATION: Yup.string().required("Principal Organisation Name Required"), VOYAGE: Yup.string().required("Voyage Required"),
      VESSEL: Yup.string().required("Vessel Required"), ETBATPOL: Yup.string().required("ETB at POL Required"), ETAATPOD: Yup.string().required("ETA at POD Required"),
      VALIDITYOFLISTING: Yup.number().required("Validity of Offer Required"), 
      ...( (this.state.selectedPendingReq.container_details?.length == 1 || firstContainerActive || !secondContainerActive) && {
        TOTALQUANTITY: Yup.number().min(1, "Please add value more than 0").max(this.state.selectedPendingReq.container_details[0]?.quantity, "Offered Quantity can't be more than Requested Quantity").required("Offered Quantity Required"),
        OFFEREDRATE: Yup.number().min(1, "Please add value more than 0").required("Seller Price Required"),
        OFFEREDTERM: Yup.string().required("Terms Required"), 
        OFFEREDTERMTWO: Yup.string().required("Terms Required"),
      }),
      ...(this.state.prinicpalOrgList?.length !== 0 && {
        PRINCIPALORGANISATION: Yup.string().required("Principal Organisation Name Required"),
      }),
      ...(this.state.selectedPendingReq.container_details?.length > 1 && secondContainerActive && {
        TOTALQUANTITYTWO: Yup.number().min(1, "Please add value more than 0").max(this.state.selectedPendingReq?.container_details[1]?.quantity, "Offered Quantity can't be more than Requested Quantity").required("Offered Quantity Required"),
        OFFEREDRATETWO: Yup.number().min(1, "Please add value more than 0").required("Seller Price Required"), 
        OFFEREDTERMTHREE: Yup.string().required("Terms Required"),
        OFFEREDTERMFOUR: Yup.string().required("Terms Required"),
      }),
    });
  };

  ErrorRender = (ErrorMessage: string | undefined, TextShow: boolean) => {
    return (
      ErrorMessage !== undefined && TextShow &&
      <Box className="ErrorTextContainer">
          <ProjectText className={`TextError ${TextShow ? "" : "TextHidden"}`}>
              {typeof ErrorMessage === "string" ? ErrorMessage : ""}
          </ProjectText>
      </Box>
  );
  };

   handleKeyPress(e: any) {
    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    }
  }
   renderCircularProgress(open: boolean, dataLength: number) {
    return open && dataLength === 0 ? <CircularProgress color="inherit" size={20} /> : null;
}

  renderVasselBlock = (
    errors: {
      VESSEL?: string; POL?: string; VOYAGE?: string; POLTERMINAL?: string; ETBATPOL?: string; ETAATPOD?: string; POD?: string; VALIDITYOFLISTING?: string;
      PODTERMINAL?: string; EXPIRYDATE?: string; AVERAGETONSBOX?: string;
    },
    touched: {
      VESSEL?: string | boolean; POL?: string | boolean; VOYAGE?: string | boolean; POLTERMINAL?: string | boolean; ETBATPOL?: string | boolean;
      ETAATPOD?: string | boolean; POD?: string | boolean; VALIDITYOFLISTING?: string | boolean; PODTERMINAL?: string | boolean; EXPIRYDATE?: string | boolean;
      AVERAGETONSBOX?: string | boolean;
    },
    values: {
      VESSEL: string; POL: string; VOYAGE: string; POLTERMINAL: string; ETBATPOL: string; ETAATPOD?: string; POD: string; PODTERMINAL: string;
      VALIDITYOFLISTING: string; EXPIRYDATE?: string; AVERAGETONSBOX?: string;
    },
    setFieldValue: (field: string, value: string, shouldValidate?: boolean
    ) => void, classes: { positionRelative: any; daysBeforeETB: any }
  ) => {
    
    return (
      <section>
        <Grid container spacing={2} style={{ marginBottom: "15px" }}>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor="VESSEL">
              <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">VESSEL</ProjectText>
            </InputLabel>
            <AutocompleteStyle
              id="VESSELAutoSelectId" options={this.state.VesselDropDownData}
              onChange={(event: React.ChangeEvent<{}>, value) => {
                this.AutocompleteOnChange(setFieldValue, value, "VESSEL")
              }}
              onOpen={this.OnOpenDropDown}
              getOptionSelected={(option: unknown, value) => (option as { name: string }).name === values.VESSEL}
              size="small"
              FormError={
                errors.VESSEL !== undefined &&
                touched.VESSEL !== undefined && values.VESSEL === ""
              }
              getOptionLabel={(option: unknown) => (option as { name: string }).name ?? ""}
              inputValue={this.state.VesselDropDownString?.toUpperCase()}
              onInputChange={(event, newInputValue) => {
                if (event !== null) {
                  setFieldValue("VESSEL", newInputValue.toUpperCase())
                  this.setState({
                    VesselDropDownString: newInputValue.toUpperCase()
                  })
                }
              }}
              data-test-id="VESSEL"
              closeIcon={false}
              renderInput={(params) => {
                return (<TextField {...params} variant="outlined" data-test-id="VESSELDrop" onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  this.AutocompleteTextfieldChange(event)
                }}
                  value={values.VESSEL?.toUpperCase()}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Box className="AutoComplateTextField">
                        {this.renderCircularProgress(this.state.VesselDropDownOpen, this.state.VesselDropDownData.length)}

                        <ProjectText variant='h6' TextSize='16px'>
                          {params.InputProps.endAdornment}
                        </ProjectText>
                      </Box>
                    ),
                  }}
                />)
              }}
              onClose={this.OnOpenDropDown}
              open={this.state.VesselDropDownOpen}
              clearOnEscape={false}
              clearOnBlur={false}
              renderOption={(option: unknown, state: AutocompleteRenderOptionState) => {
                return (
                  <ProjectText
                    variant="h6"
                    TextSize="14px"
                    fontweight="500"
                    data-test-id={(option as { name: string }).name}
                  >
                    {(option as { name: string }).name ?? ""}
                  </ProjectText>
                )
              }}
            />
            {this.ErrorRender(errors.VESSEL, errors.VESSEL !== undefined && touched.VESSEL !== undefined && values.VESSEL == "")}
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor="VESSEL">
              <ProjectText variant="subtitle1" fontweight="900" TextSize="14px" textcolor="black" Upercase>VOYAGE</ProjectText>
            </InputLabel>
            <AutocompleteStyle
              id="VOyogesdata"
              options={this.state.VoyageDropDownData}
              onChange={(event: React.ChangeEvent<{}>, value) => {
                this.AutocompleteOnChange(setFieldValue, value, "VOYAGE")
                this.setState({voyageValue: value})
              }}
              onOpen={this.OnOpenDropDownVoyage}
              getOptionSelected={(option: unknown, value) => (option as { inVoyN: string }).inVoyN + (option as { outVoyN: string }).outVoyN === this.state.InVoyogeAndOutVoyoge}
              size="small"
              FormError={
                errors.VOYAGE !== undefined &&
                touched.VOYAGE !== undefined && values.VOYAGE === ""
              }
              getOptionLabel={(option: unknown) => `In ${(option as { inVoyN: string }).inVoyN} Out ${(option as { outVoyN: string }).outVoyN}` ?? ""}
              closeIcon={false}
              inputValue={this.state.InVoyogeAndOutVoyoge?.toUpperCase()}
              data-test-id="VOYAGE"
              renderInput={(params) => {
                return (<TextField style={{textTransform: 'uppercase'}} {...params} variant="outlined" data-test-id="VOYAGEDrop" />)
              }}
              onClose={this.OnOpenDropDownVoyage}
              onInputChange={(event, newInputValue) => {
                if (event !== null) {
                  setFieldValue("VOYAGE", newInputValue)
                  this.setState({
                    InVoyogeAndOutVoyoge: newInputValue
                  })
                }
              }}
              open={this.state.VoyageDropDownOpen}
              clearOnEscape={false}
              clearOnBlur={false}
              renderOption={(option: unknown, state: AutocompleteRenderOptionState) => {
                return (
                  <Box data-test-id={(option as { name: string }).name ?? ""}>
                    <ProjectText variant="h6" TextSize=".875rem" fontweight="500">
                      <ProjectText TextSize=".875rem"
                        fontweight="500" variant="caption" textcolor="grey">IN </ProjectText>{(option as { inVoyN: string }).inVoyN ?? ""} &nbsp; &nbsp; <ProjectText variant="caption" TextSize=".875rem"
                          fontweight="500" textcolor="grey"> OUT</ProjectText>{" "}
                      {(option as { outVoyN: string }).outVoyN ?? ""}
                    </ProjectText>
                  </Box>
                )
              }}
            />
            {this.ErrorRender(errors.VOYAGE, errors.VOYAGE !== undefined && touched.VOYAGE !== undefined && values.VOYAGE == "")}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: "15px" }}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="POL">
                  <ProjectText variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black" Upercase>
                    POL
                  </ProjectText>
                </InputLabel>
                <ProjectTextField
                  fullWidth
                  variant="outlined"
                  BgColor="#ECECEC"
                  disabled
                  id="POL"
                  name="POL"
                  value={this.state.selectedPendingReq?.request?.data?.attributes?.pol}
                  inputProps={{
                    "data-test-id": "POL",
                  }}
                >
                  <OptionForSelect
                    value={this.state.selectedPendingReq?.request?.data?.attributes?.pol}
                  >
                    <ProjectText
                      variant="h6"
                      TextSize="14px"
                      fontweight="500"
                    >
                      {this.state.selectedPendingReq?.request?.data?.attributes?.pol}
                    </ProjectText>
                  </OptionForSelect>

                </ProjectTextField>
                {this.ErrorRender(errors.POL, errors.POL !== undefined && touched.POL !== undefined)}
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="POLTERMINAL">
                  <ProjectText variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black"> POL TERMINAL </ProjectText>
                </InputLabel>
                <ProjectTextField
                  BgColor="#ECECEC"
                  disabled
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    this.AddListingFormTextFieldChange(
                      e,
                      setFieldValue,
                      "POLTERMINAL"
                    )
                  }
                  value={this.state.selectedPendingReq?.request?.data?.attributes?.pol_terminal}
                  id="POLTERMINAL"
                  name="POLTERMINAL"
                  inputProps={{
                    "data-test-id": "POLTERMINAL",
                  }}
                >
                  <OptionForSelect
                    value={this.state.selectedPendingReq?.request?.data?.attributes?.pol_terminal}
                  >
                    <ProjectText
                      variant="h6"
                      TextSize="14px"
                      fontweight="500"
                    >
                      {this.state.selectedPendingReq?.request?.data?.attributes?.pol_terminal}
                    </ProjectText>
                  </OptionForSelect>
                </ProjectTextField>
                {this.ErrorRender(
                  errors.POLTERMINAL,
                  errors.POLTERMINAL !== undefined && touched.POLTERMINAL !== undefined
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="POD">
                  <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">POD </ProjectText>
                </InputLabel>
                <ProjectTextField
                  BgColor="#ECECEC"
                  disabled
                  variant="outlined"
                  fullWidth
                  value={this.state.selectedPendingReq?.request?.data?.attributes?.pod}
                  id="POD"
                  name="POD"
                  inputProps={{
                    "data-test-id": "POD",
                  }}
                >
                  <OptionForSelect
                    value={this.state.selectedPendingReq?.request?.data?.attributes?.pod}
                  >
                    <ProjectText
                      variant="h6"
                      TextSize="14px"
                      fontweight="500"
                    >
                      {this.state.selectedPendingReq?.request?.data?.attributes?.pod}
                    </ProjectText>
                  </OptionForSelect>

                </ProjectTextField>
                {this.ErrorRender(errors.POD, errors.POD !== undefined && touched.POD !== undefined)}
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="PODTERMINAL">
                  <ProjectText variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black"> POD TERMINAL</ProjectText>
                </InputLabel>
                <ProjectTextField
                  variant="outlined"
                  fullWidth
                  value={this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal}
                  id="PODTERMINAL"
                  name="PODTERMINAL"
                  inputProps={{
                    "data-test-id": "PODTERMINAL",
                  }}
                  BgColor="#ECECEC"
                  disabled
                >
                  <OptionForSelect
                    value={this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal}
                  >
                    <ProjectText
                      variant="h6"
                      TextSize="14px"
                      fontweight="500"
                    >
                      {this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal}
                    </ProjectText>
                  </OptionForSelect>
                </ProjectTextField>
                {this.ErrorRender(
                  errors.PODTERMINAL,
                  errors.PODTERMINAL !== undefined && touched.PODTERMINAL !== undefined
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: "15px" }}>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor="ETBATPOL">
              <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">ETB AT POL</ProjectText>
            </InputLabel>
            <LandingPageTextField
              BoderRadius="10px"
              inputProps={{
                "data-test-id": "ETBATPOL",
                min: moment.parseZone(this.state.selectedPendingReq?.request?.data?.attributes?.container_loading_range_start).format("YYYY-MM-DD"),
                max: moment.parseZone(this.state.selectedPendingReq?.request?.data?.attributes?.container_loading_range_end).format("YYYY-MM-DD")
              }}
              variant="outlined"
              size="small"
              FormError={
                errors.ETBATPOL !== undefined && touched.ETBATPOL !== undefined
              }
              type="Date"
              value={values.ETBATPOL}
              id="ETBATPOL"
              name="ETBATPOL"
              fullWidth
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                this.setState({ etbDate: e.target.value });
                const dateFrom = moment(e.target.value)
                  .subtract(values.VALIDITYOFLISTING, "d")
                  .parseZone()
                  .format("YYYY-MM-DD");
                this.setState({ expiry_date: dateFrom });
                this.AddListingFormTextFieldChange(
                  e,
                  setFieldValue,
                  "ETBATPOL"
                );
              }}
            />
            {this.ErrorRender(
              errors.ETBATPOL,
              errors.ETBATPOL !== undefined && touched.ETBATPOL !== undefined
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor="ETAATPOD">
              <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">ETA AT POD</ProjectText>
            </InputLabel>
            <LandingPageTextField
              BoderRadius="10px"
              inputProps={{
                "data-test-id": "ETAATPOD",
                min: moment.parseZone(values.ETBATPOL).add(+values.VALIDITYOFLISTING + 1, 'd').format("YYYY-MM-DD"),
              }}
              variant="outlined"
              size="small"
              FormError={
                errors.ETAATPOD !== undefined && touched.ETAATPOD !== undefined
              }
              type="Date"
              value={values.ETAATPOD}
              id="ETAATPOD"
              name="ETAATPOD"
              fullWidth
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                this.setState({ etaDate: e.target.value });
                this.AddListingFormTextFieldChange(
                  e,
                  setFieldValue,
                  "ETAATPOD"
                );
              }}
            />
            {this.ErrorRender(
              errors.ETAATPOD,
              errors.ETAATPOD !== undefined && touched.ETAATPOD !== undefined
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor="CONTAINERSIZE">
              <ProjectText
                Upercase
                variant="subtitle1"
                TextSize="14px"
                fontweight="900"
                textcolor="black"
              >
                Validity of Offer
              </ProjectText>
            </InputLabel>
            <LandingPageTextField
              BoderRadius="10px"
              inputProps={{
                "data-test-id": "VALIDITYOFLISTING",
                min: 1,
                max: moment(values.ETBATPOL).diff(moment(), 'days'),
              }}
              variant="outlined"
              size="small"
              FormError={
                errors.VALIDITYOFLISTING !== undefined &&
                touched.VALIDITYOFLISTING !== undefined
              }
              onKeyPress={this.handleKeyPress}
              type="text"
              value={this.state.minimumValidity}
              id="VALIDITYOFLISTING"
              name="VALIDITYOFLISTING"
              fullWidth
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                const dateFrom = moment(values.ETBATPOL)
                  .subtract(e.target.value, "d")
                  .parseZone()
                  .format("YYYY-MM-DD");
                this.setState({ expiry_date: dateFrom, minimumValidity: e.target.value });

                this.AddListingFormTextFieldChange(
                  e,
                  setFieldValue,
                  "VALIDITYOFLISTING"
                );
              }}
            />
            <div className={classes.positionRelative}>
              <div className={classes.daysBeforeETB}>DAYS BEFORE ETB</div>
            </div>
            {this.ErrorRender(
              errors.VALIDITYOFLISTING,
              errors.VALIDITYOFLISTING !== undefined && touched.VALIDITYOFLISTING !== undefined
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor="CONTAINERSIZE">
              <ProjectText
                Upercase
                variant="subtitle1"
                TextSize="14px"
                fontweight="900"
                textcolor="black"
              >
                Expiry Date
              </ProjectText>
            </InputLabel>
            <LandingPageTextField
              BoderRadius="10px"
              inputProps={{
                "data-test-id": "EXPIRYDATE",
              }}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                this.AddListingFormTextFieldChange(e, setFieldValue, "EXPIRYDATE");
              }}
              size="small"
              FormError={
                errors.EXPIRYDATE !== undefined &&
                touched.EXPIRYDATE !== undefined
              }
              type="date"
              disabled
              value={moment.parseZone(this.state.expiry_date).format("YYYY-MM-DD")}
              id="EXPIRYDATE"
              name="EXPIRYDATE"
              fullWidth
            />
          </Grid>
        </Grid>
      </section>
    );
  };
  renderTerms(val: any, index: number) {
    if (val.quantity !== 0) {
        return (
            <span key={index} style={{ maxWidth: "10rem", textAlign: "center" }}>{index==1 ? ' | ' :''}{val.terms}</span>
        );
    }
    return null; // Return null if quantity is 0
}

  renderTable = (classes: any) => {
    const { anchorElDropdown } = this.state;
    const open = Boolean(anchorElDropdown);
    return (
      <TabPanel
        value={this.state.currentTab}
        index={0}
        data-test-id="SellerTable"
      > 
        {/* / {Array.isArray(this.state.sellerPendingReq)
        //this.state.sellerPendingReq?.length !== 0 && ( */}
          <TableContainer> <StyledTable aria-label="sticky table">
            <TableHead>
              <StyledTableHeaderRow data-test-id="sellerRequests">
                {columns.map((column: any, index: number) => (
                  <StyledTableCell
                    key={column.id}
                    style={{ padding: '25px 16px', minWidth: column.minWidth, borderBottomLeftRadius: index === 0 ? '8px' : '0px', borderBottomRightRadius: index === columns.length - 1 ? '8px' : '0px' }}
                  >{index <= 9 ? 
                    <div style={{ ...buyerStyle.dFlexs}}>{column.label.map((elem: string, indexing: number) => <span key={indexing}>{elem}</span>)}</div>
                    :
                    <div style={buyerStyle.dFlex}>{column.label.map((elem: any, i: number) => <span key={i}>{elem}</span>)}</div>
                  }
                  </StyledTableCell>
                ))}
              </StyledTableHeaderRow>
            </TableHead>  <TableBody>
              {Array.isArray(this.state.sellerPendingReq) &&
                this.state.sellerPendingReq?.map(
                  (list: any, index: number) => {
                    return (<StyledTableRow key={index}>
                      <TableCell className={classes.tableLeftBorder}> <span className={classes.tableContent}> {list?.request?.data?.attributes?.request_uid}
                      </span>
                      </TableCell> <TableCell className={classes.cellAlignment}> <span className={classes.tableContent}>
                        {moment(
                          list?.request?.data.attributes
                            .container_loading_range_start
                        ).format("DD-MMM-YYYY")}{" "}
                        -{" "}
                        {moment(
                          list?.request?.data.attributes
                            .container_loading_range_end
                        ).format("DD-MMM-YYYY")}
                      </span>
                      </TableCell> <TableCell className={classes.cellAlignment}>
                        <span className={classes.tableContent}>
                          {list?.request?.data?.attributes?.pol}/
                          <br />
                          {list?.request?.data?.attributes?.pol_terminal}
                        </span>
                      </TableCell>
                      <TableCell className={classes.cellAlignment}>
                        <span className={classes.tableContent}> 
                        {list?.request?.data?.attributes?.pod}/
                        <br />
                        {list?.request?.data?.attributes?.pod_terminal} </span>
                      </TableCell>
                      
                      <TableCell className={classes.cellAlignment} style={useStyles.cellAlignmentPadding}>
                        <div style={{ ...buyerStyle.tableContent, ...buyerStyle.highlightedTableContent, borderRadius: '4px 0px 0px 4px', paddingLeft: '4px', backgroundColor:'#B8D5F9' }}>
                          {list?.container?.container_type}
                        </div></TableCell>
                      <TableCell className={classes.cellAlignment} style={useStyles.cellAlignmentPadding}>
                        <div style={{ ...buyerStyle.tableContent, ...buyerStyle.highlightedTableContent, backgroundColor: '#B8D5F9' }}>
                          {
                            <div>
                              {list?.container?.codes?.map((code: string, index: number, array: string[]) => (
                                <span key={code}>{code}{index < array.length - 1 && ','}</span>
                              ))}
                            </div>
                          }
                        </div>
                      </TableCell>
                      <TableCell className={classes.cellAlignment} style={useStyles.cellAlignmentPadding}>
                        <div style={{ ...buyerStyle.tableContent, ...buyerStyle.highlightedTableContent, backgroundColor: '#B8D5F9' }}>
                          {list?.container_details?.map((container: {
                            quantity?: number ;
                            container_size?: string;
                          }, index: number
                          ) => (
                            <React.Fragment key={index}>
                    {(container.quantity ?? 0) > 0 && (
                        <>
                            <b>{container.quantity}X{container.container_size} </b>
                            {(index !== list.container_details.length - 1 && list?.container_details[1]?.quantity && list?.container_details[1]?.container_size && list?.container_details[0]?.quantity && list?.container_details[0]?.container_size) ? ' | ' : " "}
                        </>
                    )}
                </React.Fragment>
                          ))}
                        </div>
                      </TableCell>

                      <TableCell className={classes.cellAlignment} style={useStyles.cellAlignmentPadding}>
                        <div style={{ ...buyerStyle.tableContent, ...buyerStyle.highlightedTableContent, backgroundColor: '#B8D5F9', borderRadius: '0px 4px 4px 0px' }}>
                          {list?.container_details?.map((
                    container : { desired_rates?: number;
                      container_size?: string;
                    }, index : number) => (
                            <React.Fragment key={index}>
                              {container?.desired_rates !== 0 &&
                                <>
                                  <b>
                                  {container?.desired_rates
                                    ? `$${container.desired_rates.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}/`
                                    : ""}
                                  </b>
                                  {container.container_size}
                                  {(index !== list.container_details.length - 1 && list?.container_details[1]?.desired_rates && list?.container_details[1]?.container_size && list?.container_details[0]?.desired_rates && list?.container_details[0]?.container_size) ? ' | ' : " "}
                                </>
                              }
                            </React.Fragment>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell className={classes.cellAlignment}>
                        <span className={classes.tableContent}>
                        {list.container_details?.map((val: any, index: number) => this.renderTerms(val ,index))}
                        </span>
                      </TableCell>
                      <TableCell className={classes.cellAlignment}>
                        <section className={classes.btnDiv}>
                          <div>
                            <div className={classes.totalUSD}>
                              <b>
                                {list?.container_details?.[1]?.desired_rates ? `$${(list?.container_details?.[0]?.desired_rates * list.container_details?.[0]?.quantity + list?.container_details?.[1]?.desired_rates * list.container_details?.[1]?.quantity).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : `$${(list?.container_details?.[0]?.desired_rates * list.container_details?.[0]?.quantity).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}
                              </b>
                            </div>
                          </div>
                        </section>
                      </TableCell>
                      <TableCell> <div className={classes.leftBar}>
                            <button className={classes.offerBtn} onClick={() => this.handleOfferClick(list)} data-test-id={`OfferText${index}`}>
                              Offer
                            </button>
                          </div></TableCell>
                    </StyledTableRow>
                    );
                  }
                )}
            </TableBody>
          </StyledTable>
          </TableContainer>
          {!Array.isArray(this.state.sellerPendingReq)&& <div  style={{...buyerStyle.noData, flexDirection: "column"}}>
              <img
                src={resultNotFound1}
                alt="resultNotFound1"
                style={buyerStyle.noDataImg}
              />
              <p style={{...buyerStyle.head,fontWeight: "bold"}}>No Results Found!</p>
              <p  style={{...buyerStyle.msg}}>Change or Remove Filters and Try Again.</p>
            </div>
            }
        {/* } */}
      </TabPanel>
    );
  };

  offerSubmitted = (classes: any) => {
    return (
      <>
        {this.state.offerAdded && (
          <Box className={classes.listingSubmittedBox}>
            <div className={classes.textEnd}> <img src={close} alt="" onClick={() => this.handleClose()} /></div>
            <div className={`${classes.textCenter} ${classes.pTB50}`}>
              <div className={classes.mb20}><img src={image_success} alt="" /></div>
              <h1 className={classes.listingHeading}>Offer Submitted!</h1>
              <p className={classes.listingIdPara}>Your offer (ID: {this.state.offerUID}) has been submitted successfully and shared with the buyer.</p>
              <p className={classes.listingIdPara}>You'll be notified when the buyer responds to your offer.</p>
            </div>
          </Box>
        )}
      </>
    );
  };

  renderOrganizationItems = () => {
    return (
      <Box>                  
      {Array.isArray(this.state.prinicpalOrgList) ? (
      <div 
      data-test-id="organizationFilterDropdown" className="selectSlotDropdown"
       style={useStyles.selectSlotDropdown}
       >
       {this.state.prinicpalOrgList?.map((val: any) => {
        return (
          <div key={val.id}
            style={{ borderLeft: `.25rem solid ${val.attributes.color_name}`, ...useStyles.selectSlotItem }}>
            <h4 
            style={{ textTransform: 'uppercase' }}>
              {val.attributes.name}
            </h4>
            <Checkbox color="primary" checked={this.state.activeSlotFilter.includes(val)}
              data-test-id="organizationFilterDropdownCheck"
              onChange={(e) => this.handleSlotFilter(val, e.target.checked)}
            />
          </div>
       )})}
         </div>)
      : this.state.prinicpalOrgList.type !== "organization" && (
        <div data-test-id="organizationFilterDropdown"  className="selectSlotDropdown"
       style={useStyles.selectSlotDropdown}>
      <div  key={this.state.prinicpalOrgList.id}
            style={{ borderLeft: `.25rem solid ${this.state.prinicpalOrgList.attributes.color_name}`, 
            ...useStyles.selectSlotItem }}
            >
            <h4 
            style={{ textTransform: 'uppercase' }}
            > {this.state.prinicpalOrgList.attributes.name} </h4>
            <Checkbox color="primary" 
            checked=
            {this.state.activeSlotFilter.includes(this.state.prinicpalOrgList)}
            onChange={(e) => this.handleSlotFilter(this.state.prinicpalOrgList, e.target.checked)}/>
          </div>
          </div>
      )               
      }
      </Box>
    )
  }

  renderSection=()=>{
    return (
      <Grid item lg={6} md={6}>
              <SectionWrapperLeft style={useStyles.leftBar}>
                <StyledSwitch>
                  <div
                    className="switch"
                    onClick={() => this.SwitchChnages()}
                    data-test-id="switch-changes"
                  >
                    <input
                      className="Inputswitch"
                      type="checkbox"
                      checked={this.state.switch}
                    />
                    <span className="slider round">
                      <ProjectText TextSize="1rem" fontweight="700" className="text">
                        {this.state.switch ? "SELL" : ""}
                      </ProjectText>
                    </span>
                  </div>
                </StyledSwitch>
                <h1 style={useStyles.slotsFor}>Slots for</h1>
                <div style={{ position: 'relative', color: 'rgba(30, 30, 30, 1)', fontFamily: 'Asap', fontSize: '.875rem', fontWeight: 500 }}>
                  <div className="selectSlotInput" data-test-id="organizationFilter" onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                    style={useStyles.selectSlot}>
                    <div style={{ fontWeight: 500, display: 'flex', overflow: 'auto',alignItems:"center" }}>
                      {Array.isArray(this.state.activeSlotFilter) ?
                        this.MapData() : this.MapDataElse()}
                    </div>
                    <img src={image_dropdown} style={{width: '10px', height: '10px', objectFit: 'contain'}} alt="dropdown" />
                  </div>
                  {this.state.isSlotFilterOpen && (
                    <ClickAwayListener onClickAway={() => this.setState({ isSlotFilterOpen: false })}>

                      {this.renderOrganizationItems()}
                    </ClickAwayListener>
                  )}
                </div>
              </SectionWrapperLeft>
            </Grid>
    )
  }

  getPrincipalOrg(principalOrgList: any): string | JSX.Element {
    if (Array.isArray(principalOrgList)) {
        return "ALL";
    } else if (principalOrgList?.type === "organization") {
        return (
            <>
                <span>{principalOrgList.attributes?.name}</span>
                <p  style={useStyles.boxtextp}>
                    {principalOrgList.attributes?.code}
                </p>
            </>
        );
    }
    return "ALL";
}

   MapData = () => {
     // Extracting state variables
     let text = "";
     const activeSlotFilter = this.state.activeSlotFilter;
    let backgroundColor = activeSlotFilter[0]?.attributes?.color_name;
  
    // Building the text and backgroundColor strings
    activeSlotFilter.forEach((slot: any) => {
      text += `${slot.attributes?.name?.trim().split(" ").join("/")} ${slot.attributes?.code} `;
      if (slot.attributes?.color_name)  {
        backgroundColor += ` ${slot.attributes?.color_name} `
      }
    }
    );    
    // Handling empty state case
    if (activeSlotFilter.length === 0) {
      return this.getPrincipalOrg(this.state.prinicpalOrgList);
    }
  
    // Truncating text if necessary
    const truncatedText = text.length > 28 
    ? text.slice(0, 28) + " ..."  : text;
  
    return (
      <div className="DropDownDiv" 
      title={text?.split("/").join(" ")}>
        {truncatedText.split(" ").filter(txt => txt !== "").map((txt, index) => {
          if (index % 2 === 0) {
            return (
              <h3 className="DropDownDivh3" key={index}
              >
                {txt?.split("/").join(" ")}
                 </h3> );
          } else 
          {
            return (
              <p  className="DropDownDivP"
               key={index}
                style={{padding: ".25rem .625rem", borderRadius: ".25rem",
                  backgroundColor: backgroundColor?.split(" ").filter((txt:any) => txt !== "")[index],}}
                  >
                {txt}
              </p> 
               );    }
        }
        )}
      </div>
    );
  };
   MapDataElse = () => {
    return (
      <div className="DropDownDiv">
        <h3 className="DropDownDivh3">{this.state.activeSlotFilter?.attributes?.name}</h3>
        <p className="DropDownDivP" style={{ padding: '.25rem .625rem', borderRadius: '.25rem', backgroundColor: this.state.activeSlotFilter?.attributes?.color_name, margin: 0 }}>{this.state.activeSlotFilter?.attributes?.code}</p>
      </div>
    )
  }

  renderRequestFilters = () => {
    return(
      <>
      <FormControl style={{ width: "100%", marginRight: "15px" }}>

<StyledInputLable shrink>REQUEST ID</StyledInputLable>
<Box style={{ marginTop: "24px" }}>
  <StyledBox
    data-test-id="listingfilter"
    onClick={() => this.handleOfferExpanded("listingID")}
  >
    <DropdownArrowBox> 
      <img style={this.state.offerExpanded ? {transform: "scaleY(-1)"}: {}} src={image_dropdown}  alt="" onClick={() => this.handleOfferExpanded("listingID")} />
    </DropdownArrowBox>
  </StyledBox>
  {this.state.offerExpanded && (
    <ClickAwayListener
      onClickAway={() => this.setState({ offerExpanded: false })}
    >
      <Collapse
        in={this.state.offerExpanded}
        timeout="auto"
        unmountOnExit
        disableStrictModeCompat={false}
        style={{
          position: "absolute",
          width: "100%",
          background: "#fff",
          zIndex: 1,
          display: "flex",
          fontFamily: "Asap",
          flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
        }}
      >
        {this.state.filter?.listingID?.map(
          (item: { label: string; isChecked: boolean }, index: number) => {
            return (
              <CustomFormControlLabel
                key={item.label}
                control={
                  <Checkbox
                    color="primary"
                    checked={item.isChecked}
                    data-test-id={`listingcheck${index}`}
                    onClick={(e) =>
                      this.handleOfferChange(
                        e,
                        this.state.filter.listingID,
                        "listingID"
                      )
                    }
                    style={{ paddingRight: "30px" }}
                    name={item.label}
                  />
                }
                labelPlacement="start"
                label={item.label}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                  fontFamily: "Asap",
                  fontSize: "14px",
                  fontWeight: 900,
                }}
              />
            );
          }
        )}
      </Collapse>
    </ClickAwayListener>
  )}
</Box>
<Box
  style={{
    padding: "2px",
    width: "220px",
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    gap: "5px",
    marginTop: "3px",
    listStyle: "none",
  }}
>
  {this.state.filter.listingID.map(
    (option: { label: string; isChecked: boolean }, index: number) => {
      if (option.isChecked) {
        return (
          <li
            key={option.label}
            style={{ fontFamily: "Asap", fontSize: "14px" }}
          >
            <StyledChip
              label={option.label}
              icon={<CancelIcon data-test-id={`CancelIconlistingID${index}`} onClick={() => {
                this.handleOfferDelete(
                  option,
                  this.state.filter.listingID,
                  "listingID"
                )
              }} />}
              data-test-id="listingIdChip"
            />
          </li>
        );
      }
    }
  )}
</Box>
</FormControl>
<FormControl style={{ width: "100%", marginRight: "15px" }}>
<StyledInputLable shrink>POL</StyledInputLable>{" "}
<Box style={{ marginTop: "24px" }}>
  <StyledBox data-test-id="polfilter" onClick={() => this.handleOfferExpanded("pol")}>
  <DropdownArrowBox> 
      <img src={image_dropdown} style={this.state.offerExpandedPol ? {
        transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("pol")} />
    </DropdownArrowBox>
  </StyledBox>
  {this.state.offerExpandedPol && (
    <ClickAwayListener
      onClickAway={() => this.setState({ offerExpandedPol: false })}
    >
      <Collapse
        in={this.state.offerExpandedPol}
        timeout="auto"
        unmountOnExit
        disableStrictModeCompat={false}
        style={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          background: "#fff",
          display: "flex",
          flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
        }}
      >
        {this.state.filter?.pol?.map(
          (item: { label: string; isChecked: boolean }, index: number) => {
            return (
              <CustomFormControlLabel
                key={item.label}
                control={
                  <Checkbox
                    color="primary"
                    data-test-id={`polcheck${index}`}
                    checked={item.isChecked}
                    onClick={(e) =>
                      this.handleOfferChange(
                        e,
                        this.state.filter.pol,
                        "pol"
                      )
                    }
                    style={{ paddingRight: "30px" }}
                    name={item.label}
                  />
                }
                label={item.label}
                labelPlacement="start"
                style={{
                  fontFamily: "Asap",
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}
              />
            );
          }
        )}
      </Collapse>
    </ClickAwayListener>
  )}
</Box>
<Box
  style={{
    marginTop: "3px",
    width: "220px",
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    gap: "5px",
    listStyle: "none",
    padding: "2px",
  }}
>
  {this.state.filter.pol.map(
    (option: { label: string; isChecked: boolean }, index: number) => {
      if (option.isChecked) {
        return (
          <li
            key={option.label}
            style={{ fontFamily: "Asap", fontSize: "14px" }}
          >
            <StyledChip
              label={option.label}
              icon={<CancelIcon data-test-id={`CancelIconpol${index}`} onClick={() => {
                this.handleOfferDelete(
                  option,
                  this.state.filter.pol,
                  "pol"
                )
              }} />}
            />
          </li>
        );
      }
    }
  )}
</Box>
</FormControl>
      </>
    )
  }

  renderHeader = (classes: any) => {
   this.MapData()

    
    return (
      <section className={classes.root} data-test-id="Sller">
        <Container maxWidth="xl">
          <Grid container alignItems="center" style={useStyles.slotsSection}>
            {this.renderSection()}
            <Grid item xs={12} lg={6} md={6} style={useStyles.textEnd}>
              <AddListing />
            </Grid>
          </Grid>

          <StyledTabs
            value={this.state.currentTab}
            onChange={this.handleTabChange}
            TabIndicatorProps={{ style: { display: "none" } }}
            variant="fullWidth"
            aria-label="full width tabs example"
            data-test-id="tab"
          >
            <StyledTab
              disableTouchRipple
              label={`BUYER REQUESTS (${this.state.DynamicCount.requst})`}
              onClick={() =>
                this.props.navigation.navigate("SellerPendingRequest")
              }
            />
            <BorderedStyledTab
              disableTouchRipple
              label={`LISTINGS (${this.state.DynamicCount.listing})`}
              onClick={() => this.props.navigation.navigate("SellerListing")}
            />{" "}
            <BorderedStyledTab
              disableTouchRipple
              label={`OFFERS (${this.state.DynamicCount.offers})`}
              onClick={() =>
                this.props.navigation.navigate("SellerPendingOffers")
              }
            />{" "}
            <StyledTab
              disableTouchRipple
              label={`BOOKINGS (${this.state.DynamicCount.booking})`}
              onClick={() => this.props.navigation.navigate("SellerBookings")}
            />
          </StyledTabs>

          <CustomBox
          >
            {this.renderRequestFilters()}
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
              <StyledInputLable shrink>POD</StyledInputLable>{" "}
              <Box style={{ marginTop: "24px" }}>
                <StyledBox data-test-id="podfilter" onClick={() => this.handleOfferExpanded("pod")}>
                <DropdownArrowBox> 
                    <img src={image_dropdown} style={
                      this.state.offerExpandedPod ? {transform: "scaleY(-1)"}: {}} 
                      alt="" onClick={() => this.handleOfferExpanded("pod")} />
                  </DropdownArrowBox>
                </StyledBox>
                {this.state.offerExpandedPod && (
                  <ClickAwayListener
                    onClickAway={() => this.setState({ offerExpandedPod: false })}
                  >
                    <Collapse
                      in={this.state.offerExpandedPod}
                      timeout="auto"
                      unmountOnExit
                      disableStrictModeCompat={false}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        width: "100%",
                        background: "#fff",
                        display: "flex",
                        flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                      }}
                    >
                      {this.state.filter?.pod?.map(
                        (item: { label: string; isChecked: boolean }, index: number) => {
                          return (
                            <CustomFormControlLabel
                              key={item.label}
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={item.isChecked}
                                  data-test-id={`podcheck${index}`}
                                  onClick={(e) =>
                                    this.handleOfferChange(
                                      e,
                                      this.state.filter.pod,
                                      "pod"
                                    )
                                  }
                                  name={item.label}
                                  style={{ paddingRight: "30px" }}
                                />
                              }
                              label={item.label}
                              labelPlacement="start"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontFamily: "Asap",
                                fontSize: "14px",
                                flexDirection: "row-reverse",
                              }}
                            />
                          );
                        }
                      )}
                    </Collapse>
                  </ClickAwayListener>
                )}
              </Box>
              <Box
                style={{
                  width: "220px",
                  padding: "2px",
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  gap: "5px",
                  listStyle: "none",
                  marginTop: "3px",
                }}
              >
                {this.state.filter.pod.map(
                  (option: { label: string; isChecked: boolean }, index: number) => {
                    if (option.isChecked) {
                      return (
                        <li
                          key={option.label}
                          style={{ fontFamily: "Asap", fontSize: "14px" }}
                        >
                          <StyledChip
                            label={option.label}
                            icon={<CancelIcon data-test-id={`CancelIconpod${index}`} onClick={() => {
                              this.handleOfferDelete(
                                option,
                                this.state.filter.pod,
                                "pod"
                              )
                            }} />}
                          />
                        </li>
                      );
                    }
                  }
                )}
              </Box>
            </FormControl>
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
              <StyledInputLable shrink>STATUS</StyledInputLable>{" "}
              <Box style={{ marginTop: "24px" }}>
                <StyledBox data-test-id="typefilter" onClick={() => this.handleOfferExpanded("type")}>
                <DropdownArrowBox> 
                    <img src={image_dropdown
                    } style={this.state.offerExpandedtype ? {transform: "scaleY(-1)"}: {}} 
                    alt="" onClick={() => this.handleOfferExpanded("type")} 
                    />
                  </DropdownArrowBox>
                </StyledBox>
                {this.state.offerExpandedtype && (
                  <ClickAwayListener
                    onClickAway={() => this.setState({ offerExpandedtype: false })}
                  >
                    <Collapse
                      in={this.state.offerExpandedtype}
                      timeout="auto"
                      unmountOnExit
                      disableStrictModeCompat={false}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        width: "100%",
                        background: "#fff",
                        display: "flex",
                        flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                      }}
                    >
                      {this.state.filter?.type?.map(
                        (item: { label: string; isChecked: boolean }, index: number) => {
                          return (
                            <CustomFormControlLabel
                              key={item.label}
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={item.isChecked}
                                  data-test-id={`typecheck${index}`}
                                  onClick={(e) =>
                                    this.handleOfferChange(
                                      e,
                                      this.state.filter.type,
                                      "type"
                                    )
                                  }
                                  name={item.label}
                                  style={{ paddingRight: "30px" }}
                                />
                              }
                              label={item.label}
                              labelPlacement="start"
                              style={{
                                fontSize: "14px",
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                                fontFamily: "Asap",
                              }}
                            />
                          );
                        }
                      )}
                    </Collapse>
                  </ClickAwayListener>
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  marginTop: "3px",
                  width: "220px",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  gap: "5px",
                  listStyle: "none",
                  padding: "2px",
                }}
              >
                {this.state.filter.type.map(
                  (option: { label: string; isChecked: boolean }, index: number) => {
                    if (option.isChecked) {
                      return (
                        <li
                          key={option.label}
                          style={{ fontFamily: "Asap", fontSize: "14px" }}
                        >
                          <StyledChip
                            label={option.label}
                            icon={<CancelIcon data-test-id={`CancelIconpodtype${index}`} onClick={() => {
                              this.handleOfferDelete(
                                option,
                                this.state.filter.type,
                                "type"
                              )
                            }} />}
                          />
                        </li>
                      );
                    }
                  }
                )}
              </Box>
            </FormControl>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <StyledInputLable
                style={{ fontWeight: 700 }}
                htmlFor="ETB DATE RANGE"
                shrink
              >
                LOADING DATE RANGE
              </StyledInputLable>
              <BootstrapInput
                defaultValue=""
                fullWidth
                id="ETB DATE RANGE"
                data-test-id="dateopne"
                onClick={this.TogolFilter}
                value={this.state.StartDate !== "" && this.state.EndDate !== "" ? `${this.state.RenderStart} - ${this.state.RenderEndDate}` : ""}
              />
              <CalendarImgBox> <img src={DateTimeIcon} alt="" onClick={this.TogolFilter} />
              </CalendarImgBox>
              <Box style={{
                position: "absolute",
                top: "70px",
                right: "0px"
              }}>
                {
                  this.state.open &&
                  <DateContainer style={{ position: "relative" }} data-test-id="DateFilterMainDiv">
                    <DatePickerStyle
                      open={this.state.open}
                      toggle={this.TogolFilter}
                      wrapperClassName="DateMainClassName"
                      className="DateMainClassName"
                      onChange={(range: DateRange) => {
                        this.SetDateFilterData(range);
                      }}
                      initialDateRange={{
                        startDate: this.state.DateRangeStartDate,
                        endDate: this.state.DateRangeEndDate
                      }}
                    />
                    <Box className="ButtonContaienr">
                      <Button style={{ marginRight: "10px" }}
                        data-test-id="ClearFilter" variant="text"
                        onClick={() => { this.ClearFilterData() }}
                      >
                        <ProjectText variant="h6"
                          TextSize="16px" textcolor="primary">
                          clear
                        </ProjectText>
                      </Button>
                      <Button variant="contained"
                        data-test-id="SelectFilter"
                        onClick={this.FilterDateSelect} color="primary" className="SelectButton">
                        <ProjectText variant="h6"
                          TextSize="16px" textcolor="white">
                          Select
                        </ProjectText>
                      </Button> </Box>
                  </DateContainer>

                }
              </Box>
            </FormControl>   </CustomBox>
          {this.renderTable(classes)}
        </Container>
      </section>
    );
  };

  Loader = () => {
    if (this.state.loading) {
      return <Spinner spinnerModal={this.state.loading} />
    }
  }

  PrincipalOrgnaisationNameGridRender = (errors: { PRINCIPALORGANISATION?: string }, touched: { PRINCIPALORGANISATION?: boolean }, values: { PRINCIPALORGANISATION: string }, setFieldValue: (field: string, value: string, shouldValidate?: boolean
  ) => void) => {
    return (
      <ProjectTextField
        variant="outlined"
        fullWidth
        FormError={
          errors.PRINCIPALORGANISATION !== undefined &&
          touched.PRINCIPALORGANISATION !== undefined &&
          values.PRINCIPALORGANISATION === ""
        }
        value={values.PRINCIPALORGANISATION}
        inputProps={{
          "data-test-id": "PRINCIPALORGANISATION",
        }}
        id="PRINCIPALORGANISATION"
        name="PRINCIPALORGANISATION"
        onChange={(e) => {
          this.AddListingFormTextFieldChange(
            e,
            setFieldValue,
            "PRINCIPALORGANISATION"
          )
          this.setState({ PrincipalOrganizationSelectedName: e.target.value })
        }
        }
      >
        {!Array.isArray(this.state.prinicpalOrgList) ? (
          <OptionForSelect
            value={
              this.state.prinicpalOrgList?.attributes?.name
            }
            key={this.state.prinicpalOrgList?.id}
            data-test-id={`option1`}
          >
            <ProjectText
              variant="h6"
              TextSize="14px"
              fontweight="500"
            >
              {
                this.state.prinicpalOrgList?.attributes
                  ?.name
              }
            </ProjectText>
          </OptionForSelect>
        ) : (
          Array.isArray(this.state.prinicpalOrgList) &&
          this.state.prinicpalOrgList?.map(
            (val: any, index: number) => {
              return (
                <OptionForSelect
                  value={val.attributes.name}
                  key={val.id}
                  data-test-id={`option${index}`}
                >
                  <ProjectText
                    variant="h6"
                    TextSize="14px"
                    fontweight="500"
                  >
                    {val.attributes.name}
                  </ProjectText>
                </OptionForSelect>
              );
            }
          )
        )}
      </ProjectTextField>
    )
  }

  ContainerSizeGridRender = (errors: { TOTALQUANTITY?: string }, touched: { TOTALQUANTITY?: boolean }, values: { TOTALQUANTITY: string }, setFieldValue: (field: string, value: string, shouldValidate?: boolean
  ) => void, validateFirstContainer: any) => {
    return (
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "15px" }}
      >
        <Grid item xs={12} lg={4}>
          <InputLabel htmlFor="CONTAINERSIZE">
            <ProjectText
              Upercase
              variant="subtitle1"
              TextSize="14px"
              fontweight="900"
              textcolor="black"
            >
              CONTAINER SIZE 
            </ProjectText>
          </InputLabel>
          <ProjectTextField
            variant="outlined"
            BgColor="#ECECEC"
            fullWidth
            type="number"
            inputProps={{
              "data-test-id": "CONTAINERSIZE",
            }}
            value={
              this.state.selectedPendingReq
                .container_details[0]?.container_size
            }
            id="CONTAINERSIZE"
            name="CONTAINERSIZE"
            disabled
          >
            <OptionForSelect
              value={
                this.state.selectedPendingReq
                  .container_details[0]?.container_size
              }
              key={
                this.state.selectedPendingReq
                  .container_details[0]?.container_size
              }
            >
              <ProjectText
                variant="h6"
                TextSize="14px"
                fontweight="500"
              >
                {
                  this.state.selectedPendingReq
                    .container_details[0]?.container_size
                }
              </ProjectText>
            </OptionForSelect>
          </ProjectTextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <InputLabel htmlFor="TOTALQUANTITY">
            <ProjectText
              Upercase
              variant="subtitle1"
              TextSize="14px"
              fontweight="900"
              textcolor="black"
            >
              Offered Quantity
            </ProjectText>
          </InputLabel>
          <LandingPageTextField
          placeholder=""
            BoderRadius="10px"
            inputProps={{
              "data-test-id": "TOTALQUANTITY",
              min: 1
            }}
            variant="outlined"
            size="small"
            FormError={
              errors.TOTALQUANTITY !== undefined && validateFirstContainer && touched.TOTALQUANTITY
            }
            type="text"
            onKeyPress={this.handleKeyPress}
            value={values.TOTALQUANTITY }
            id="TOTALQUANTITY"
            name="TOTALQUANTITY"
            fullWidth
            onChange={(
              e: React.ChangeEvent<{ value: unknown }>
            ) => {
              this.AddListingFormTextFieldChange(
                e,
                setFieldValue,
                "TOTALQUANTITY"
              );
              this.setState({offeredQuantity: e.target.value})
              if(e.target.value != ""){
                this.setState({firstContainerActive: true})
              }
            }}
          />
          {this.ErrorRender(
            errors.TOTALQUANTITY,
            (errors.TOTALQUANTITY !== undefined && touched.TOTALQUANTITY !== undefined && validateFirstContainer) ||  (touched.TOTALQUANTITY !== undefined && this.state.offeredQuantity === "" && validateFirstContainer)
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <InputLabel htmlFor="AVERAGETONSBOX">
            <ProjectText
              Upercase
              variant="subtitle1"
              TextSize="14px"
              fontweight="900"
              textcolor="black"
            >
              AVG. TONS/CONTAINER
            </ProjectText>
          </InputLabel>
          <LandingPageTextField
            BoderRadius="10px"
            BgColor="#ECECEC"
            inputProps={{
              "data-test-id": "AVERAGETONSBOX",
            }}
            variant="outlined"
            size="small"
            type="text"
            value={
              this.state.selectedPendingReq
                .container_details[0]?.tons_per_teu_extra
            }
            id="AVERAGETONSBOX"
            name="AVERAGETONSBOX"
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }

  ConatinerTypeGridRender = () => {
    return (<Grid item xs={12} lg={6}>
      <InputLabel htmlFor="CONTAINERTYPE">
        <ProjectText
          Upercase
          variant="subtitle1"
          TextSize="14px"
          fontweight="900"
          textcolor="black"
        >
          CONTAINER STATUS
        </ProjectText>
      </InputLabel>
      <ProjectTextField
        variant="outlined"
        fullWidth
        BgColor="#ECECEC"
        inputProps={{ "data-test-id": "CONTAINERTYPE" }}
        value={
          this.state.selectedPendingReq.container
            ?.container_type
        }
        id="CONTAINERTYPE"
        name="CONTAINERTYPE"
        disabled
      >
        <OptionForSelect
          value={
            this.state.selectedPendingReq.container
              .container_type
          }
        >
          <ProjectText
            variant="h6"
            TextSize="14px"
            fontweight="500"
          >
            {
              this.state.selectedPendingReq.container
                .container_type
            }
          </ProjectText>
        </OptionForSelect>
      </ProjectTextField>
    </Grid>)
  }

  EmailAddresGridRender = (classes: { reactSelectWrapper: string }) => {
    return (
      <Grid item xs={12} lg={6}>
        <InputLabel htmlFor="EMAILADDRESS">
          <ProjectText
            Upercase
            variant="subtitle1"
            TextSize="14px"
            fontweight="900"
            textcolor="black"
          >
            Container Type
          </ProjectText>
        </InputLabel>
        <div className={classes.reactSelectWrapper}>
          <Select
            options={this.state.selectedPendingReq.container.codes.map(
              (val: string) => {
                return {
                  value: val,
                  label: val,
                };
              }
            )}
            isMulti={true}
            value={this.state.selectedPendingReq.container.codes.map(
              (val: string) => {
                return {
                  value: val,
                  label: val,
                };
              }
            )}
            hideSelectedOptions={true}
            placeholder=""
            isDisabled
          />
        </div>
      </Grid>
    )
  }

  ContainerFirstGridMapRender = (errors: { TOTALQUANTITYTWO?: string }, touched: { TOTALQUANTITYTWO?: boolean }, values: { TOTALQUANTITYTWO: string }, setFieldValue: (field: string, value: string, shouldValidate?: boolean
  ) => void, validateSeconsContainer: any) => {
    console.log(errors,touched,validateSeconsContainer)
    return (
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "15px" }}
      >
        <Grid item lg={4}>
          <InputLabel htmlFor="CONTAINERSIZE">
            <ProjectText
              Upercase
              variant="subtitle1"
              TextSize="14px"
              fontweight="900"
              textcolor="black"
            >
              CONTAINER SIZE 
            </ProjectText>
          </InputLabel>
          <ProjectTextField
            variant="outlined"
            BgColor="#ECECEC"
            fullWidth
            type="number"
            inputProps={{
              "data-test-id": "CONTAINERSIZE",
            }}
            value={
              this.state.selectedPendingReq
                .container_details[1]?.container_size
            }
            id="CONTAINERSIZE"
            name="CONTAINERSIZE"
            disabled
          >
            <OptionForSelect
              value={
                this.state.selectedPendingReq
                  .container_details[1]?.container_size
              }
              key={
                this.state.selectedPendingReq
                  .container_details[1]?.container_size
              }
            >
              <ProjectText
                variant="h6"
                TextSize="14px"
                fontweight="500"
              >
                {
                  this.state.selectedPendingReq
                    .container_details[1]?.container_size
                }
              </ProjectText>
            </OptionForSelect>
          </ProjectTextField>
        </Grid>
        <Grid item lg={4}>
          <InputLabel htmlFor="TOTALQUANTITYTWO">
            <ProjectText
              Upercase
              variant="subtitle1"
              TextSize="14px"
              fontweight="900"
              textcolor="black"
            >
              Offered Quantity
            </ProjectText>
          </InputLabel>
          <LandingPageTextField
          placeholder=""
            BoderRadius="10px"
            inputProps={{
              "data-test-id": "TOTALQUANTITYTWO",
              min: 1
            }}
            variant="outlined"
            onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
            size="small"
            FormError={
              errors.TOTALQUANTITYTWO !== undefined && validateSeconsContainer && touched.TOTALQUANTITYTWO
            }

            value={values.TOTALQUANTITYTWO}
            id="TOTALQUANTITYTWO"
            name="TOTALQUANTITYTWO"
            fullWidth
            onChange={(
              e: React.ChangeEvent<{ value: unknown }>
            ) => {
              this.AddListingFormTextFieldChange(
                e,
                setFieldValue,
                "TOTALQUANTITYTWO"
              );
              this.setState({secondOfferedQuantity: e.target.value})
              if(e.target.value != ""){
                this.setState({secondContainerActive: true})
              }
            }}
          />
          {this.ErrorRender(
            errors.TOTALQUANTITYTWO,
            (errors.TOTALQUANTITYTWO !== undefined && touched.TOTALQUANTITYTWO !== undefined && validateSeconsContainer) ||  (touched.TOTALQUANTITYTWO !== undefined && this.state.secondOfferedQuantity === "" && validateSeconsContainer)
          )}
        </Grid>
        <Grid item lg={4}>
          <InputLabel htmlFor="AVERAGETONSBOX">
            <ProjectText
              Upercase
              variant="subtitle1"
              TextSize="14px"
              fontweight="900"
              textcolor="black"
            >
              AVG. TONS/CONTAINER
            </ProjectText>
          </InputLabel>
          <LandingPageTextField
            BoderRadius="10px"
            BgColor="#ECECEC"
            inputProps={{
              "data-test-id": "AVERAGETONSBOX",
            }}
            variant="outlined"
            size="small"
            type="text"
            value={
              this.state.selectedPendingReq
                .container_details[1]?.tons_per_teu_extra
            }
            id="AVERAGETONSBOX"
            name="AVERAGETONSBOX"
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }

  onOfferFormSubmit = (values: any) => {
    const voyageDropdownArray = this.state.VoyageDropDownData?.map((item) => {
      return `In ${item.inVoyN} Out ${item.outVoyN}`
  })
    if (voyageDropdownArray?.includes(this.state.InVoyogeAndOutVoyoge)){
      this.onSubmitHandler(values);
    }
    if(!voyageDropdownArray?.includes(this.state.InVoyogeAndOutVoyoge)){
      toast.warning("Please select Voyage value")
    }
  }

  renderFirstContainerOfferedRate = (classes: any, errors: any, touched:any, values: any,setFieldValue: any, validateFirstContainer: boolean) => {
    return (
      <>
      <section className={classes.leftBar}>
                                <div className={classes.usdDivTransparent}>
                                  USD
                                </div>
                                <LandingPageTextField
                                  BoderRadius="10px"
                                  BorderTopLeftRadius="0px"
                                  BorderBottomLeftRadius="0px"
                                  inputProps={{
                                    "data-test-id": "OFFEREDRATE",
                                  }}
                                  variant="outlined"
                                  size="small"
                                  FormError={
                                    errors.OFFEREDRATE !== undefined &&
                                    touched.OFFEREDRATE !== undefined && validateFirstContainer ||  (touched.OFFEREDRATE !== undefined && this.state.offeredRate === "" && validateFirstContainer)
                                  }
                                  type="text"
                                  onKeyPress={this.handleKeyPress}
                                  value={values.OFFEREDRATE}
                                  id="OFFEREDRATE"
                                  name="OFFEREDRATE"
                                  fullWidth
                                  onChange={(
                                    e: React.ChangeEvent<
                                      HTMLInputElement | HTMLTextAreaElement
                                    >
                                  ) => {
                                    this.AddListingFormTextFieldChange(
                                      e,
                                      setFieldValue,
                                      "OFFEREDRATE"
                                    );
                                    this.setState({offeredRate: e.target.value})
                                    if(e.target.value != ""){
                                      this.setState({firstContainerActive: true})
                                    }
                                  }}
                                  
                                />
                              </section>
                              <div className={classes.positionRelative}>
                                <div className={classes.teu}>
                                  /
                                  {
                                    this.state.selectedPendingReq
                                      .container_details[0]?.container_size
                                  }
                                </div>
                              </div>
                              {this.ErrorRender(
                                errors.OFFEREDRATE || "Seller Price Required",
                                (errors.OFFEREDRATE !== undefined && touched.OFFEREDRATE !== undefined && validateFirstContainer) ||  (touched.OFFEREDRATE !== undefined && this.state.offeredRate === "" && validateFirstContainer)
                              )}
      </>
    )
  }
  renderFirstContainerOfferedTermsOne = (classes: any, errors: any, touched:any, values: any,setFieldValue: any, validateFirstContainer: boolean) => {
    return (
      <>
      <LandingPageTextField
                                    BoderRadius="10px"
                                    inputProps={{
                                      "data-test-id": "OFFEREDTERM",
                                    }}
                                    variant="outlined"
                                    size="small"
                                    FormError={
                                      errors.OFFEREDTERM !== undefined &&
                                      touched.OFFEREDTERM !== undefined && validateFirstContainer ||  (touched.OFFEREDTERM !== undefined && this.state.offeredTermsOne === "" && validateFirstContainer)
                                    }
                                    type="text"
                                    value={values.OFFEREDTERM}
                                    id="OFFEREDTERM"
                                    name="OFFEREDTERM"
                                    fullWidth
                                    onChange={(
                                      e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >
                                    ) => {
                                      this.AddListingFormTextFieldChange(
                                        e,
                                        setFieldValue,
                                        "OFFEREDTERM"
                                      );
                                      this.setState({offeredTermsOne: e.target.value})
                                      if(e.target.value != ""){
                                        this.setState({firstContainerActive: true})
                                      }
                                    }}
                                  />
                                  <div className={classes.positionRelative}>
                                    <span className={classes.slashTerms}>
                                      /
                                    </span>
                                  </div>
                                  {this.ErrorRender(
                                    errors.OFFEREDTERM || "Terms Required",
                                    (errors.OFFEREDTERM !== undefined && touched.OFFEREDTERM !== undefined && validateFirstContainer) ||  (touched.OFFEREDTERM !== undefined && this.state.offeredTermsOne === "" && validateFirstContainer)
                                  )}
      </>
    )
  }
  renderFirstContainerOfferedTermsTwo = (classes: any, errors: any, touched:any, values: any,setFieldValue: any, validateFirstContainer: boolean) => {
    return (
      <>
      <LandingPageTextField
                                    BoderRadius="10px"
                                    inputProps={{
                                      "data-test-id": "OFFEREDTERMTWO",
                                    }}
                                    variant="outlined"
                                    size="small"
                                    FormError={
                                      errors.OFFEREDTERMTWO !== undefined &&
                                      touched.OFFEREDTERMTWO !== undefined && validateFirstContainer ||  (touched.OFFEREDTERMTWO !== undefined && this.state.offeredTermsTwo === "" && validateFirstContainer)
                                    }
                                    type="text"
                                    value={values.OFFEREDTERMTWO}
                                    onChange={(
                                      e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >
                                    ) => {
                                      this.AddListingFormTextFieldChange(
                                        e,
                                        setFieldValue,
                                        "OFFEREDTERMTWO"
                                      );
                                      this.setState({offeredTermsTwo: e.target.value})
                                      if(e.target.value != ""){
                                        this.setState({firstContainerActive: true})
                                      }
                                    }}
                                    id="OFFEREDTERMTWO"
                                    name="OFFEREDTERMTWO"
                                    fullWidth
                                  />
                                  {this.ErrorRender(
                                    errors.OFFEREDTERMTWO || "Terms Required",
                                    (errors.OFFEREDTERMTWO !== undefined && touched.OFFEREDTERMTWO !== undefined && validateFirstContainer) ||  (touched.OFFEREDTERMTWO !== undefined && this.state.offeredTermsTwo === "" && validateFirstContainer)
                                  )}
      </>
    )
  }

  renderSecondContainerOfferedRate = (classes: any, errors: any, touched:any, values: any,setFieldValue: any, validateSeconsContainer: boolean) => {
    return (
      <>
      <section className={classes.leftBar}>
                                    <div className={classes.usdDivTransparent}>
                                      USD
                                    </div>
                                    <LandingPageTextField
                                      BoderRadius="10px"
                                      BorderTopLeftRadius="0px"
                                      BorderBottomLeftRadius="0px"
                                      inputProps={{
                                        "data-test-id": "OFFEREDRATETWO",
                                      }}
                                      variant="outlined"
                                      size="small"
                                      onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
                                      FormError={
                                        errors.OFFEREDRATETWO !== undefined &&
                                        touched.OFFEREDRATETWO !== undefined && validateSeconsContainer ||  (touched.OFFEREDRATETWO !== undefined && this.state.secondOfferedrate === "" && validateSeconsContainer)
                                      }
                                      type="text"
                                      value={values.OFFEREDRATETWO}
                                      id="OFFEREDRATETWO"
                                      name="OFFEREDRATETWO"
                                      fullWidth
                                      onChange={(
                                        e: React.ChangeEvent<
                                          HTMLInputElement | HTMLTextAreaElement
                                        >
                                      ) => {
                                        this.AddListingFormTextFieldChange(
                                          e,
                                          setFieldValue,
                                          "OFFEREDRATETWO"
                                        );
                                        this.setState({secondOfferedrate: e.target.value})
                                        if(e.target.value != ""){
                                          this.setState({secondContainerActive: true})
                                        }
                                      }}
                                    />
                                  </section>
                                  <div className={classes.positionRelative}>
                                    <div className={classes.teu}>
                                      /
                                      {
                                        this.state.selectedPendingReq
                                          .container_details[1]?.container_size
                                      }
                                    </div>
                                  </div>
                                  {this.ErrorRender(
                                    errors.OFFEREDRATETWO || "Seller Price Required",
                                    (errors.OFFEREDRATETWO !== undefined && touched.OFFEREDRATETWO !== undefined && validateSeconsContainer) ||  (touched.OFFEREDRATETWO !== undefined && this.state.secondOfferedrate === "" && validateSeconsContainer)
                                  )}
      </>
    )
  }
  renderSecondContainerOfferedTermsOne = (classes: any, errors: any, touched:any, values: any,setFieldValue: any, validateSeconsContainer: boolean) => {
    return (
      <>
      <LandingPageTextField
                                        BoderRadius="10px"
                                        inputProps={{
                                          "data-test-id": "OFFEREDTERMTHREE",
                                        }}
                                        variant="outlined"
                                        size="small"
                                        FormError={
                                          errors.OFFEREDTERMTHREE !==
                                          undefined &&
                                          touched.OFFEREDTERMTHREE !== undefined && validateSeconsContainer ||  (touched.OFFEREDTERMTHREE !== undefined && this.state.secondOfferedTermsOne === "" && validateSeconsContainer)
                                        }
                                        type="text"
                                        value={values.OFFEREDTERMTHREE}
                                        id="OFFEREDTERMTHREE"
                                        name="OFFEREDTERMTHREE"
                                        fullWidth
                                        onChange={(
                                          e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                          >
                                        ) => {
                                          this.AddListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "OFFEREDTERMTHREE"
                                          );
                                          this.setState({secondOfferedTermsOne: e.target.value})
                                          if(e.target.value != ""){
                                            this.setState({secondContainerActive: true})
                                          }
                                        }}
                                      />
                                      <div className={classes.positionRelative}>
                                        <span className={classes.slashTerms}>
                                          /
                                        </span>
                                      </div>
                                      {this.ErrorRender(
                                        errors.OFFEREDTERMTHREE || "Terms Required",
                                        (errors.OFFEREDTERMTHREE !== undefined && touched.OFFEREDTERMTHREE !== undefined && validateSeconsContainer) ||  (touched.OFFEREDTERMTHREE !== undefined && this.state.secondOfferedTermsOne === "" && validateSeconsContainer)
                                      )}
      </>
    )
  }
  renderSecondContainerOfferedTermsTwo = (classes: any, errors: any, touched:any, values: any,setFieldValue: any, validateSeconsContainer: boolean) => {
    return (
      <>
      <LandingPageTextField
                                        BoderRadius="10px"
                                        inputProps={{
                                          "data-test-id": "OFFEREDTERMFOUR",
                                        }}
                                        variant="outlined"
                                        size="small"
                                        FormError={
                                          errors.OFFEREDTERMFOUR !==
                                          undefined &&
                                          touched.OFFEREDTERMFOUR !== undefined && validateSeconsContainer ||  (touched.OFFEREDTERMFOUR !== undefined && this.state.secondOfferedTermsTwo === "" && validateSeconsContainer)
                                        }
                                        type="text"
                                        value={values.OFFEREDTERMFOUR}
                                        id="OFFEREDTERMFOUR"
                                        name="OFFEREDTERMFOUR"
                                        fullWidth
                                        onChange={(
                                          e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                          >
                                        ) => {
                                          this.AddListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "OFFEREDTERMFOUR"
                                          );
                                          this.setState({secondOfferedTermsTwo: e.target.value})
                                          if(e.target.value != ""){
                                            this.setState({secondContainerActive: true})
                                          }
                                        }}
                                      />
                                      {this.ErrorRender(
                                        errors.OFFEREDTERMFOUR || "Terms Required",
                                        (errors.OFFEREDTERMFOUR !== undefined && touched.OFFEREDTERMFOUR !== undefined && validateSeconsContainer) ||  (touched.OFFEREDTERMFOUR !== undefined && this.state.secondOfferedTermsTwo === "" && validateSeconsContainer)
                                      )}
      </>
    )
  }


  render() {
    const { classes }: any = this.props;
    const firstContainerActive = this.state.offeredQuantity != "" || this.state.offeredRate != "" || this.state.offeredTermsOne != "" || this.state.offeredTermsTwo != ""
    const secondContainerActive = this.state.secondOfferedQuantity != "" || this.state.secondOfferedrate != "" || this.state.secondOfferedTermsOne != "" || this.state.secondOfferedTermsTwo != ""
    const validateFirstContainer = this.state.selectedPendingReq.container_details?.length == 1 || firstContainerActive || !secondContainerActive
    const validateSeconsContainer = this.state.selectedPendingReq.container_details?.length > 1 && secondContainerActive
    
    return (
      <div data-test-id="sellerpendingrequest">
        <Navbar SellerPendingScreen AppBarTransparent={false} TabDataCount={this.DynamicDataSet} />
        {this.Loader()}
        {this.renderHeader(classes)}

        <Dialog
          open={this.state.offerModal}
          maxWidth="md"
          fullWidth
          style={{ borderRadius: "18px !important" }}
        >
          {/* Make an offer Modal Start */}
          {this.state.addOffer && (
            <StyleModel>
              <Formik
                validationSchema={() => this.NewListingSchema()}
                validateOnChange={true}
                initialValues={{
                  PRINCIPALORGANISATION: "",
                  VESSEL: "",
                  VOYAGE: "",
                  POD: `${this.state.selectedPendingReq?.request?.data?.attributes?.pod}` || "",
                  PODTERMINAL: `${this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal}` || "",
                  POL: `${this.state.selectedPendingReq?.request?.data?.attributes?.pol}` || "",
                  POLTERMINAL: `${this.state.selectedPendingReq?.request?.data?.attributes?.pol_terminal}` || "",
                  ETBATPOL: "",
                  ETAATPOD: "",
                  VALIDITYOFLISTING: this.state.minimumValidity || "",
                  EXPIRYDATE: "",
                  TOTALQUANTITY: `${this.state.selectedPendingReq.container_details[0]?.quantity}`,
                  TOTALQUANTITYTWO: `${this.state.selectedPendingReq.container_details[1]?.quantity}`,
                  OFFEREDRATE: "",
                  OFFEREDRATETWO: "",
                  OFFEREDTERM: "",
                  OFFEREDTERMTWO: "",
                  OFFEREDTERMTHREE: "",
                  OFFEREDTERMFOUR: "",
                }}
                onSubmit={(values) => {
                  this.onOfferFormSubmit(values);
                }}
                validateOnBlur={true}
              >
                {({
                  errors,
                  touched,
                  setFieldValue,
                  handleSubmit,
                  isValid,
                  values,
                }) => {
                  this.setValuesRef = setFieldValue;
                  return (
                    <form
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="FormOrganisationDetails"
                    >
                      <Box className={classes.addListingModal}>
                        <Box
                          className={classes.close}
                          onClick={this.handleClose}
                          data-test-id="close-btn"
                        >
                          <img src={close} alt="" />
                        </Box>
                        <h1 className={classes.title}>Make an Offer</h1>
                        <section className={classes.organisation}>
                          <ProjectText
                            fontweight="800"
                            TextSize="15px"
                            textcolor="#000"
                          >
                            REQUEST ID
                          </ProjectText>
                          <ProjectText TextSize="14px" textcolor="#000">
                            {this.state.selectedPendingReq.request.data.attributes.request_uid}
                          </ProjectText>
                          <InputLabel
                            htmlFor="PRINCIPALORGANISATION"
                            style={{ marginTop: "20px" }}
                          >
                            <ProjectText
                              Upercase
                              variant="subtitle1"
                              TextSize="14px"
                              fontweight="900"
                              textcolor="black"
                            >
                              PRINCIPAL ORGANISATION NAME
                            </ProjectText>
                          </InputLabel>
                          {this.PrincipalOrgnaisationNameGridRender(errors, touched, values, setFieldValue)}
                          {this.ErrorRender(
                            errors.PRINCIPALORGANISATION,
                            errors.PRINCIPALORGANISATION !== undefined && touched.PRINCIPALORGANISATION !== undefined && values.PRINCIPALORGANISATION === ""
                          )}
                        </section>
                        <section className={classes.vesselContainer}>
                          <h3 className={classes.vesselHeading}>
                            Port & Vessel Information
                          </h3>
                          {this.renderVasselBlock(
                            errors,
                            touched,
                            values,
                            setFieldValue,
                            classes
                          )}
                        </section>

                        <section className={classes.vesselContainer}>
                          <h3 className={classes.vesselHeading}>
                            Container & Pricing Details
                          </h3>
                          {this.state.selectedPendingReq?.container_details[0]?.quantity !== null &&
                          this.state.selectedPendingReq?.container_details[0]?.quantity !== 0 && <>
                            <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: "15px" }}
                          >
                            {this.ConatinerTypeGridRender()}
                            {this.EmailAddresGridRender(classes)}
                          </Grid>
                          {this.ContainerSizeGridRender(errors, touched, values, setFieldValue, validateFirstContainer)}
                          <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: "15px" }}
                          >
                            <Grid item lg={6}>
                              <InputLabel htmlFor="RATEREQUESTEDBYBUYER">
                                <ProjectText
                                  Upercase
                                  variant="subtitle1"
                                  TextSize="14px"
                                  fontweight="900"
                                  textcolor="black"
                                >
                                  BUYER PRICE
                                </ProjectText>
                              </InputLabel>
                              <section className={classes.leftBar}>
                                <div className={classes.usdDiv}>USD</div>
                                <LandingPageTextField
                                  BgColor="#ECECEC"
                                  BoderRadius="10px"
                                  BorderTopLeftRadius="0px"
                                  BorderBottomLeftRadius="0px"
                                  inputProps={{
                                    "data-test-id": "RATEREQUESTEDBYBUYER",
                                  }}
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  value={this.state.selectedPendingReq.container_details[0]?.desired_rates && `$${parseInt(
                                    this.state.selectedPendingReq.container_details[0]?.desired_rates).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}
                                  id="RATEREQUESTEDBYBUYER"
                                  name="RATEREQUESTEDBYBUYER"
                                  fullWidth
                                />
                              </section>
                              <div className={classes.positionRelative}>
                                <div className={classes.teu}>
                                  /
                                  {
                                    this.state.selectedPendingReq
                                      .container_details[0]?.container_size
                                  }
                                </div>
                              </div>
                            </Grid>
                            <Grid item lg={6}>
                              <Box className={classes.leftBar}>
                                <InputLabel htmlFor="TERMS">
                                  <ProjectText
                                    Upercase
                                    variant="subtitle1"
                                    TextSize="14px"
                                    fontweight="900"
                                    textcolor="black"
                                  >
                                    Terms
                                  </ProjectText>
                                </InputLabel>
                                {/* <LightTooltip
                                  title="Full legal name of your organisation as registered with the local authority."
                                  placement="right"
                                  className="tooltip"
                                >
                                  <InfoOutlinedIcon style={{ marginLeft: "6px", marginBottom: "2px", fontSize: "1.3rem" }} />
                                </LightTooltip> */}
                              </Box>
                              <Grid container spacing={2}>
                                <Grid item lg={6}>
                                  <LandingPageTextField
                                    BgColor="#ECECEC"
                                    BoderRadius="10px"
                                    inputProps={{
                                      "data-test-id": "TERMS",
                                    }}
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    value={
                                      this.state.selectedPendingReq.container_details[0]?.terms.split(
                                        "/"
                                      )[0]
                                    }
                                    id="TERMS"
                                    name="TERMS"
                                    fullWidth
                                  />
                                  <div className={classes.positionRelative}>
                                    <span className={classes.slashTerms}>
                                      /
                                    </span>
                                  </div>
                                </Grid>
                                <Grid item lg={6}>
                                  <LandingPageTextField
                                    BgColor="#ECECEC"
                                    BoderRadius="10px"
                                    inputProps={{
                                      "data-test-id": "TERMS29",
                                    }}
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    value={
                                      this.state.selectedPendingReq.container_details[0]?.terms.split(
                                        "/"
                                      )[1]
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: "15px" }}
                          >
                            <Grid item lg={6}>
                              <InputLabel htmlFor="OFFEREDRATE">
                                <ProjectText
                                  Upercase
                                  variant="subtitle1"
                                  TextSize="14px"
                                  fontweight="900"
                                  textcolor="black"
                                >
                                  Seller Price
                                </ProjectText>
                              </InputLabel>
                              {this.renderFirstContainerOfferedRate(classes, errors, touched, values,setFieldValue, validateFirstContainer)}
                            </Grid>
                            <Grid item lg={6}>
                              <Box className={classes.leftBar}>
                                <InputLabel htmlFor="TERMSTHREE">
                                  <ProjectText
                                    Upercase
                                    variant="subtitle1"
                                    TextSize="14px"
                                    fontweight="900"
                                    textcolor="black"
                                  >
                                    Terms
                                  </ProjectText>
                                </InputLabel>
                                {/* <LightTooltip
                                  className="tooltip"
                                  title="Full legal name of your organisation as registered with the local authority."
                                  placement="right"
                                >
                                  <InfoOutlinedIcon style={{ marginLeft: "6px", marginBottom: "2px", fontSize: "1.3rem" }} />
                                </LightTooltip> */}
                              </Box>
                              <Grid container spacing={2}>
                                <Grid item lg={6}>
                                  {this.renderFirstContainerOfferedTermsOne(classes, errors, touched, values,setFieldValue, validateFirstContainer)}
                                </Grid>
                                <Grid item lg={6}>
                                  {this.renderFirstContainerOfferedTermsTwo(classes, errors, touched, values,setFieldValue, validateFirstContainer)}
                                  
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          </>}
                          {(this.state.selectedPendingReq?.container_details[1]?.quantity !== null &&
                          this.state.selectedPendingReq?.container_details[1]?.quantity !==0
                           && this.state.selectedPendingReq?.container_details?.length > 1 ) &&(
                            <>
                              {this.ContainerFirstGridMapRender(errors, touched, values, setFieldValue, validateSeconsContainer)}
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "15px" }}
                              >
                                <Grid item lg={6}>
                                  <InputLabel htmlFor="RATEREQUESTEDBYBUYER">
                                    <ProjectText
                                      Upercase
                                      variant="subtitle1"
                                      TextSize="14px"
                                      fontweight="900"
                                      textcolor="black"
                                    >
                                      BUYER PRICE
                                    </ProjectText>
                                  </InputLabel>
                                  <section className={classes.leftBar}>
                                    <div className={classes.usdDiv}>USD</div>
                                    <LandingPageTextField
                                      BgColor="#ECECEC"
                                      BoderRadius="10px"
                                      BorderTopLeftRadius="0px"
                                      BorderBottomLeftRadius="0px"
                                      inputProps={{
                                        "data-test-id": "RATEREQUESTEDBYBUYER",
                                      }}
                                      variant="outlined"
                                      size="small"
                                      type="text"
                                      value={
                                        this.state.selectedPendingReq
                                          .container_details[1]?.desired_rates
                                      }
                                      id="RATEREQUESTEDBYBUYER"
                                      name="RATEREQUESTEDBYBUYER"
                                      fullWidth
                                    />
                                  </section>
                                  <div className={classes.positionRelative}>
                                    <div className={classes.teu}>
                                      /
                                      {
                                        this.state.selectedPendingReq
                                          .container_details[1]?.container_size
                                      }
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item lg={6}>
                                  <Box className={classes.leftBar}>
                                    <InputLabel htmlFor="TERMS">
                                      <ProjectText
                                        Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                      >
                                        Terms
                                      </ProjectText>
                                    </InputLabel>
                                    {/* <LightTooltip
                                      title="Full legal name of your organisation as registered with the local authority."
                                      placement="right"
                                      className="tooltip"
                                    >
                                      <InfoOutlinedIcon style={{ marginLeft: "6px", marginBottom: "2px", fontSize: "1.3rem" }} />
                                    </LightTooltip> */}
                                  </Box>
                                  <Grid container spacing={2}>
                                    <Grid item lg={6}>
                                      <LandingPageTextField
                                        BgColor="#ECECEC"
                                        BoderRadius="10px"
                                        inputProps={{
                                          "data-test-id": "TERMS",
                                        }}
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        value={
                                          this.state.selectedPendingReq.container_details[1]?.terms.split(
                                            "/"
                                          )[0]
                                        }
                                        id="TERMS"
                                        name="TERMS"
                                        fullWidth
                                      />
                                      <div className={classes.positionRelative}>
                                        <span className={classes.slashTerms}>
                                          /
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item lg={6}>
                                      <LandingPageTextField
                                        BgColor="#ECECEC"
                                        BoderRadius="10px"
                                        inputProps={{
                                          "data-test-id": "TERMS2",
                                        }}
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        value={
                                          this.state.selectedPendingReq.container_details[1]?.terms.split(
                                            "/"
                                          )[1]
                                        }
                                        fullWidth
                                        onChange={(
                                          e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                          >
                                        ) => {
                                          this.AddListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "OFFEREDTERMTHREE"
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "15px" }}
                              >
                                <Grid item lg={6}>
                                  <InputLabel htmlFor="OFFEREDRATETWO">
                                    <ProjectText
                                      Upercase
                                      variant="subtitle1"
                                      TextSize="14px"
                                      fontweight="900"
                                      textcolor="black"
                                    >
                                      Seller Price
                                    </ProjectText>
                                  </InputLabel>
                                  {this.renderSecondContainerOfferedRate(classes, errors, touched, values, setFieldValue, validateSeconsContainer)}
                                </Grid>
                                <Grid item lg={6}>
                                  <Box className={classes.leftBar}>
                                    <InputLabel htmlFor="TERMSTHREE">
                                      <ProjectText
                                        Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                      >
                                        Terms
                                      </ProjectText>
                                    </InputLabel>
                                    {/* <LightTooltip
                                      title="Full legal name of your organisation as registered with the local authority."
                                      placement="right"
                                      className="tooltip"
                                    >
                                      <InfoOutlinedIcon style={{ marginLeft: "6px", marginBottom: "2px", fontSize: "1.3rem" }} />
                                    </LightTooltip> */}
                                  </Box>
                                  <Grid container spacing={2}>
                                    <Grid item lg={6}>
                                      {this.renderSecondContainerOfferedTermsOne(classes, errors, touched, values, setFieldValue, validateSeconsContainer)}
                                    </Grid>
                                    <Grid item lg={6}>
                                      {this.renderSecondContainerOfferedTermsTwo(classes, errors, touched, values, setFieldValue, validateSeconsContainer)}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </section>
                        <div className={classes.dFlexJCenter}>
                          <ContainedButton
                            type="submit"
                            data-test-id="SubmitForm"
                            className={classes.btnTheme}
                          >
                            Submit
                          </ContainedButton>
                        </div>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </StyleModel>
          )}
          {/* Make an offer Modal End */}

          {this.offerSubmitted(classes)}
        </Dialog>
      </div>
    );
  }
}

export { SellerPendingRequest };
export default withRouter(withStyles(useStyles)(SellerPendingRequest));

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.25)",
    backgroundColor: theme.palette.common.white, color: "black",
    fontSize: 14,
    display: " inline-flex",
    justifyContent: "center",
    borderRadius: "8px",
    alignItems: "center",
    fontFamily: "Asap",
    padding: "8px 16px",
  },
  arrow: { color: "white" },
}))(Tooltip);

const StyleModel = styled(Box)({
  "& .PhoneInputPackgeClass": {
    width: "100%", height: "40px",
    borderRadius: "10px",fontSize: "15px",
    fontFamily: "Asap, sans-serif",fontWeight: 500, 
    backgroundColor: "white",
    "@media (max-width: 1200px)": {
      fontSize: "12px", height: "30px",
    },
  }, "& .dial-code": {
    fontFamily: "Asap, sans-serif",color: "black !important", 
    fontWeight: "500",
  },
  "& .no-entries-message": {
    fontFamily: "Asap, sans-serif", color: "black !important",
    fontWeight: "500",
  },
  "& .country-name": { 
    fontFamily: "Asap, sans-serif",
  },
  "& .ErrorPhoneClass": {
    "& .PhoneInputPackgeClass": { 
      backgroundColor: "#FBE9E9",
    },
    "& .flag-dropdown": { 
      backgroundColor: "#FBE9E9",
    },
    "& .selected-flag": { 
      backgroundColor: "#FBE9E9",
    },
  },
  "& .flag-dropdown": {
    borderRight: "none", borderRadius: "10px 0px 0px 10px",
  },
  "& .selected-flag": {
    backgroundColor: "white",borderRadius: "10px",
  },
  "& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
      fontSize: "18px", color: "red",
      position: "absolute",margin: "6px 14px",
    },
  },
  "& .CodesMultiSelect": {
     height: "56px"
    },
    "& .AutoComplateTextField": {
      color: "#3CA8D6",
    },
    "& .DeleteChip": {
        margin: "0 2px", 
        backgroundColor: "#e3e6ed",
        color: "black",  
        borde: "none",
        fontWeight: "700"
    },   
    "& .MuiAutocomplete-inputRoot":{ 
      fontFamily:'asap'   },
    "& MuiTypography-body1": {
      fontSize: "1.1rem",fontFamily: "Asap",
      fontWeight: 400,lineHeight: "1.6px",
      letterSpacing: "0.00938em"
    },
    "& .MultiSelect": {
        "& .MuiOutlinedInput-input": 
        {
            padding: "5px 12px", height: "33px"
        }
    },
    "& .ViewlessContainer": {
        "& .MuiInputBase-input .Mui-disabled": 
        {
           color: "black"
        }
    },
    "& .SellerMultipleSelect": {
        "& .SellerReactSelects__control":
         {
          borderRadius: "10px",  }
    },
    "& .ErrorTextContainer": {
        display: "flex", alignItems: "center",
        marginTop: "5px",
        "& .TextHidden": 
        { 
          visibility: "hidden"   
        },
        "& .ErrorIconContainer": { 
          marginRight: "6px",
            "& .MuiSvgIcon-root": 
            {
              color: "red"
            }
        },
        "& .TextError": {
            color: "red", fontSize: "16px",
             transition: "width 1s ease-out ",marginBottom: "0px",
            fontFamily: "Asap, sans-serif",
           },
    },
    "& .centerGrid": { 
      justifyContent: "center" },
    "& .AddContainerFirstButton": {
        display: "flex", whiteSpace: "nowrap",
        justifyContent: "flex-end", 
        "& .Mui-disabled": {
          color: "grey", backgroundColor: "lightgrey", 
            "& .BlackPlusIcon": { 
              filter: "brightness(1)" 
            },
            },
        "@media (max-width: 960px)": {
          justifyContent: "center",
            display: "flex", 
          }
    },
    "& .SlashClassContainer": { 
      position: "relative",
        "& .SlashClass": 
        {
          justifyContent: "center",display: "flex", 
            alignItems: "center", position: "absolute",
            transform: "translate(40%, 100%)"
           },
    },
    "& .CenterAddContainer": 
    { 
      display: "flex",
        justifyContent: "center !important",
        "& .Mui-disabled": { 
          backgroundColor: "lightgrey", 
           color: "grey",
            },
        "@media (max-width: 960px)": 
        {
            display: "flex",
            justifyContent: "center" }
    },
    "& .CurrencyBox": 
    {
       marginRight: "5px"
    },
    "& .ViewMoreContainer": {
        position: "absolute", right: "20px",
        "@media (max-width: 600px)": 
        { 
          marginTop: "20px" 
        }
    },
    "& .BlackPlusIcon": 
    { 
      filter: "brightness(0)"
    },
    "& .AddContainerSecondButton": 
    { 
      display: "flex", justifyContent: "flex-start",
        "& .Mui-disabled": 
        {
            backgroundColor: "lightgrey",   
            color: "grey"
        }
    },
    "& .AddContainerSecondButtonCenter": {
      display: "flex", 
      justifyContent: "flex-start",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",  
            color: "grey"
        },
        "@media (max-width: 960px)":
         {
            display: "flex",
            justifyContent: "center"
        }
    },
    "& .DivedSection": {
        "& .MuiDivider-root": {
            backgroundColor: "white",
            height: "5px"
        }
    },
});

const DateContainer = styled(Box)({
  "& .ButtonContaienr": {
    position: "absolute", 
    display: "flex",
    justifyContent: "flex-end", 
    bottom: "2px",
    right: "10px", 
    zIndex: 9,
    padding: ".625rem .375rem"
  },
  "& .DateMainClassName": {
    "& .MuiInputBase-input": 
    {
      fontFamily: "Asap, sans-serif",
    },
    "& .MuiPaper-root": {
      paddingBottom: "20px",
      "& .MuiGrid-wrap-xs-nowrap":
       {
        "&.MuiGrid-root": {
          "& .materialui-daterange-picker-MuiList-root-59": {
            display: "none" 
          }
        }
      }
    },
    "& .MuiTypography-root": 
    {
      fontWeight: "500 !important",
      fontFamily: 'Asap !important'   
    },
    "& .materialui-daterange-picker-makeStyles-highlighted-57": {
      color: "red !important",
    }
  },
  "& ul": {
    marginBottom: "20px"},
  "& li": {
     fontFamily: "Asap, sans-serif" }
})

const DatePickerStyle = styled(DateRangePicker)({
  "& .MuiInputLabel-animated": {
    transition: "unset"},
  "& .DateMainClassName": {
    "& .MuiPaper-root": 
    {
      paddingBottom: "60px !important", backgroundColor: "grey", }
  }
})

const CalendarImgBox = styled(Box)({
  root: {
    position: "relative"
  },
  "& img": {
    height: "24px", 
    width: "24px", 
    position: "absolute",
    top: "32px", 
    zIndex: 1, 
    right: "12px",
    cursor: "pointer"

  }
})
const DropdownArrowBox = styled(Box)({
  root: {
    position: "relative"
  },
  "& img": {
    height: "10px", 
    width: "10px", 
    position: "absolute",
    top: "15px", 
    zIndex: 1, 
    right: "20px",
    cursor: "pointer"

  }
});
const CustomBox = styled(Box)({
  background: "#FFF",
  margin: "0px",
  padding: "25px 20px",
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 1280px)": {
    display: "block",
  },
  "& MuiTypography-body1": {
    fontSize: "1.1rem",
    fontFamily: "Asap",
    fontWeight: 400,
    lineHeight: "1.6px",
    letterSpacing: "0.00938em"
  },
})

const CustomFormControlLabel = styled(FormControlLabel)({
  "& span": {
    fontFamily: "Asap"
  }
})

// Customizable Area End