import React from "react";
// Customizable Area Start
//import { Box, Button, ButtonGroup, Card, ClickAwayListener, Container, Dialog, Fade, FormControl, Grid, IconButton, InputBase, InputLabel, Paper, Popper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, styled, withStyles } from "@material-ui/core";
import {
    styled, Box, Tab, Tabs, FormControl,
    InputBase,
    InputLabel,
    withStyles,
    Dialog,
    Button, 
    Container,
    Grid, 
    Paper, TableContainer, TableHead, TableRow, Table, TableCell, TableBody, Snackbar, SnackbarContent, ClickAwayListener, Collapse,
    FormControlLabel, Checkbox, Chip, Typography,MenuProps, Popper, ButtonGroup, IconButton, Card, Fade

} from "@material-ui/core";
import Navbar from "../../../components/src/NavBar.web";
import ProjectText from "../../../components/src/ProjectText";
import { AddcloseImg, resultNotFound,reject ,SuccessGrad,image_dropdown, DateTimeIcon} from "./assets";
import BuyersOfferController, { } from "./BuyersOfferController";
import { Check, Clear } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BuyerMatches from "./BuyerMatches.web";
import LandingPageTextField, { OptionForSelect, ProjectTextField} from "../../../components/src/ProjectTextField";
import { ContainedButton } from "../../../components/src/GlobalButton";
import moment from "moment";
import BuyerToggelComponentWeb from "../../../components/src/BuyerToggelComponent.web";
import { 
    DateRangePicker, 
    DateRange } from "materialui-daterange-picker";
import { 
    CustomBox, 
    SectionWrapperLeft } from "./SellerListing.web";



const style = {
    table: {
         minWidth: 650,
    },
    tableHeadContainer: {
        borderRadius: "0px 0px 8px 8px", 
        borderBottom: "2px solid #8A99BB", 
        background: "#E1E5EE", 
        marginBottom: "5px"
    },
    tableContainer: { 
        margin: "6px",
    },
    borderRight: {
        borderRight: "1px solid #BEBEBE"
    },
    borderLeft: {
        borderLeft: "4px solid #FFC900", 
        borderRadius: "8px",
    },
    centeredText: {
        textAlign: "center",
    },
    combinedClass:{
        textAlign: "center" as "center",
        padding: "10px",
        fontWeight: 900,
        fontFamily: "Asap", 
        borderBottom: "none"
    },
    bgblue: {
        background: "#fcffd4", 
        borderRadius: "4px", 
         borderBottom: "4px solid rgb(239, 239, 239)"
    },
    bgColumn: {
        background: "#EFEFEF", 
        borderRadius: "4px",
    },
    rightBorderClass: {
        borderRight: "1px solid #BEBEBE",
        paddingRight: "30px",
        paddingTop: "12px",
        paddingBottom: "12px",
        fontFamily:"Asap"
    },
    rightBorderstyle: {
        borderRight: "1px solid #BEBEBE",
        paddingRight: "60px",
        paddingTop: "28px", 
        paddingBottom: "5px"
    },
    rightBorderstyles: {
        borderRight: "1px solid #BEBEBE",
        paddingTop: "16px", 
        paddingBottom: "16px",
        alignItems: 'center',
        justifyContent: "center",
        display: 'flex',
    },
    bgWhite: {
        background: "#fff",
        borderBottom: "4px solid #EFEFEF",
    },
    headStyle: {
        padding: "10px 0px 10px 24px",
        fontWeight: 900,
        fontFamily: "Asap",
        borderBottom: "none",
        textAlign: "center" as "center",
        marginLeft: "11px"
    },
    headStylea1: {
        padding: "0px 0px 10px 0px",
        fontWeight: 900,
        fontFamily: "Asap",
        borderBottom: "none",
        textAlign: "center" as "center",
    },
    combineHeadBorderClass: {
        padding: "10px",
        fontWeight: 900,
        fontFamily: "Asap",
        borderBottom: "none",
        paddingLeft: "14px"
    },
    combibeHeadClass: {
        padding: "10px", fontWeight: 900,
        fontFamily: "Asap", borderBottom: "none",
        background: "#EFEFEF",border: 'none'
    },
    headStyleOrg: {
        padding: "10px", fontWeight: 900,
        fontFamily: "Asap",textAlign: "center" as "center",
    },
    headStyle1: {
        paddingLeft: "20px", padding: "10px",
        fontWeight: 900,fontFamily: "Asap",
        border: "none",textAlign: "center" as "center",
    },
    headStyle2: {
        fontWeight: 900, fontFamily: "Asap",
        border: "none",textAlign: "center" as "center",
    },
    headStyle5: {
        fontWeight: 900,fontFamily: "Asap", 
        display: "flex",gap: "6px",
        marginBottom:"4px"
    },
    headStyle6: {
        fontWeight: 900,fontFamily: "Asap", 
        display: "flex",gap: "6px"
    },
    headStyle3: {
        fontWeight: 900,fontFamily: "Asap", 
        paddingLeft: "20px"
    },
    textBold: {
        fontWeight: 600,
    },
    textStyle: {
        fontSize: "14px",
        color: "#363636",
    },
    spanText: {
        margin: "0 20px",
        fontWeight: 600
    },
    border: {
        border: "1px solid #6495D2",
        borderRadius: "4px",
        background: "#fafd9f", 
        margin: "2px 0", 
        width: "fit-content",
    },
    textStyling: {
        padding: "4px 10px", 
        fontSize: "14px",
        color: "#1E1E1E",
        lineHeight: "12px",
        border: "none",  
        fontFamily:"Asap",
        textAlign: "center" as "center",
        paddingLeft: '40px'
    },
    textStyling1a: {
        fontSize: "14px",
        color: "#1E1E1E",
        lineHeight: "12px",
        border: "none",  
        fontFamily:"Asap",
        textAlign: "center" as "center",
    },
    textStylingDate: {
        padding: "4px 0px",
        fontSize: "14px",
        color: "#1E1E1E", 
        lineHeight: "12px",
        border: "none",
        fontFamily:"Asap",
        textAlign: "center" as "center",
        whiteSpace: "nowrap" as "nowrap"
    },    
    textStylingCode: {
        padding: "4px 10px",
        fontSize: "14px",
        color: "#1E1E1E", 
        lineHeight: "12px",
        border: "none", 
        fontFamily:"Asap",
        textAlign: "center" as "center",
        width:"96px"
    },
    textStylingDays:{
          display:"flex", 
          flexDirection:"column" as "column",
          borderBottom:"none",
    },
    combineRightBorderClass: {
        padding: "4px 10px",
        fontSize: "14px",
        color: "#1E1E1E",  
        lineHeight: "12px",
        border: "none", 
        borderLeft:" 2px solid #FFC900",
        height: "65px", 
        textAlign: "center" as "center",
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px"
    },
    combineBorderRightClass: {
        padding: "6px 10px", fontSize: "14px",
        color: "#1E1E1E", lineHeight: "12px",
        border: "none",borderRight: "1px solid #BEBEBE",
        fontFamily:"Asap",fontWaight:600,
        borderRightColor:"gray"
    },
    combineStatusPending: {
        padding: "4px 10px", 
        fontSize: "14px",
        color: "#1E1E1E", 
        lineHeight: "12px",
        border: "none", 
        fontWeight: 900,
        fontFamily: 'Asap'
    },
    combineTdClass: {
        padding: "4px 10px",
        fontSize: "14px",
        color: "#1E1E1E",
        lineHeight: "12px",
        background: "#EFEFEF",
        border: 'none',
    },
    combineBorderClass: {
        padding: "4px 10px",
        fontSize: "14px",
        color: "#1E1E1E",
        lineHeight: "12px",
        border: "none",
        borderBottom: "none",
    },
    combineBorderClasses: {
        padding: "4px 10px",
        fontSize: "14px",
        color: "#1E1E1E",
        lineHeight: "12px",
        border: "none",
        borderBottom: "none",
    },
    textBorder: {
        width: "fit-content",
        marginLeft:"4px",fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 900,fontFamily: "Asap",
        height: "fit-content", padding: "4px 16px 4px",background: "rgb(250, 253, 159)",
        whiteSpace: "nowrap" as "nowrap",border: "1px solid rgb(255, 201, 0)",
        borderRadius:"4px",
        bordeRadius:"4px"
    },
    textBorders: {
        background: "#E1E5EE",
        border: "1px solid #6495D2",
        padding: "1px 6px",
        borderRadius: "4px",
        fontFamily:"Asap"
    },
    statusContainer: {
        display: "flex", 
        flexDirection: "column" as "column",
        alignItems: "center",
         ustifyContent: "center"
    },
    statusText: {
        fontWeight: 900,
        fontFamily: 'Asap'
    },
    pendingText: {
        marginLeft: "3px",
        color: "#EE8F00"
    },
    rejectedText: {
        marginLeft: "3px", 
        color: "#DB2525",
        fontFamily: 'Asap' 
     },
    emptyTd: {
        background: "#EFEFEF",
        border: 'none',
    },
    rateText: {
        marginLeft: "30px",
        fontWeight: 600,
    },
    btnWrapper: {
        padding: "4px 10px"
    },
    buttons: {
        display: "flex",
        gap: "18px",
        padding: "4px 10px",
        justifyContent: "flex-end"
    },
    combineButtonBorderClass: {
        display: "flex",
        gap: "18px",
        padding: "4px 10px",
        ustifyContent: "flex-end",
        borderBottom: "none",
    },
    bookBtn: {
        width: "92px",
        height: "32px",
        padding: "6px 16px",
        borderRadius: "24px",
        border: "1px solid #12DB00",
        background: "#BFFFBA",
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        cursor:"pointer"
    },
    arrow: {
        position: "absolute",
        left: '26%',
        bottom: '130px',
        borderBottom: "none"   
    },
    arrowText: {
        position: "absolute",
        top: "16%", 
        left: "35%",
        fontSize: "10px", 
        color: '#706464'
    },
    rejectBtn: {
        width: "92px", 
        height: "32px",
        padding: "6px 16px", 
        borderRadius: "24px",
        border: "1px solid #DB2525",
        background: "#FFA1A1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor:"pointer"
    },
    removeBorderBottom: {
        borderBottom: "none",
    },
    borderLefts: {
        borderLeft:" 2px solid #FFC900",
        height: "50px",
        textAlign: "center",
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px"
      },
    rightBorderClasss: {
        borderRight: "1px solid #BEBEBE",
        paddingRight: "30px", 
        paddingTop: "12px", 
        paddingBottom: "12px",
        fontFamily:"Asap",
        marginLeft: "15px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '40px',
    },
    curveRadius: {
        borderLeft: "4px solid rgb(255, 201, 0)", 
        borderRadius: "4px", 
        display: 'flex', 
        justifyContent: 'center', 
         alignItems: 'center',
          paddingTop: '18px',
        borderTopLeftRadius: '8px', 
        borderBottomLeftRadius: '8px',
        paddingBottom:"20px",
        height:"100px",
    },
};

const buyerStyle = {
    root: {
        padding: "30px 0",
        background: "#eee",
        minHeight : "100vh"
    },
    boxtextp:{
        width: "fit-content",
        marginLeft:"4px",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 900,
        fontFamily: "Asap",
        height: "fit-content", 
        padding: "4px 16px 4px",
        background: "rgb(250, 253, 159)",
        whiteSpace: "nowrap" as "nowrap",
        border: "1px solid rgb(255, 201, 0)",
        borderRadius:"4px",
        bordeRadius:"4px"
    },
    noDataShow : {
        fontFamily: "Asap",
        paddingTop: "6rem",
        paddingBottom: "1rem",
        minWidth: 650,
    },
    noDataImgShow : {
        height: "5rem",
    },
    noData:{
        fontFamily: "Asap",
        display: "flex",
       justifyContent: "center",
       paddingTop: "96px",
       alignItems: "center",
       marginBottom:'30px'
      },
       noDataImg: {
        height: "80px",
      },
       head : {
        margin: "8px",
        fontFamily: "Asap",
        fontSize: "32px",
      },
       msg: {
        margin: "0px",
        fontFamily: "Asap",
        fontSize: "16px",
      },
    noDtataContent : {
        fontFamily: "Asap",
        fontSize: "2rem",
        margin: "0.5rem", 
        textAlign:"center" as "center",
    },
    dataMessage: {
        fontFamily: "Asap", 
        margin: "0rem",
        fontSize: "1rem"
    },
    slotsSection: {
        marginBottom: "50px"
    },
    leftBar: {
        alignItems: "center", 
        display: "flex"
    },
    slotsFor: {
        margin: 0,
        fontWeight: 800,
        fontFamily: `"Asap", "sans-serif"`,
        color: "#1E1E1E",
        fontSize: "24px"
    },
    selectSlot: {
        height: "3rem",
        width: "24.3125rem",  
        background: "rgba(255, 255, 255, 1)",
        marginLeft: ".9375rem",
        border: ".0625rem solid rgba(205, 205, 205, 1)",   
        display: 'flex',   
        padding: '1rem .75rem',
        borderRadius: ".5rem",
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',        
    },
    selectSlotDropdown: {
        position: 'absolute' as 'absolute',
        background: 'white',
        width: '25.125rem',
        boxShadow: "rgba(100, 100, 111, 0.2) 0rem .4375rem 1.8125rem 0rem",  
        borderRadius: '.5rem',
        padding: '.25rem .375rem',
        flexDirection: "column" as "column",
        display: 'flex',
        left: '.9375rem',
        gap: '.125rem',
        zIndex: 2,
        marginTop: '.25rem',
        
    },
    selectSlotItem: {
        display: 'flex',
        width: '100%',
        height: '2.25rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '1rem'
    },
    addListing: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: ".875rem",
        background: "#1653DD",
        border: "none",
        borderRadius: "2rem",
        padding: ".5625rem 1.125rem",
        color: "#FFFFFF",
        marginRight: "1.875rem",
        fontWeight: 600,
        height: "3rem",
        width: "16rem",
        textTransform: "uppercase",
        cursor: "pointer",
    },
    btnDiv: {
        alignItems: "center",
        display: "flex"
    },
    deleteText: {
        fontSize: "14px",
        fontFamily: "Asap",
        margin: 0,
        color: "#DC2626",
        cursor: 'pointer'
    },
    
    editText: {
        fontFamily: "Asap", 
        color: "#1E1E1E",
        fontSize: "14px", 
        cursor: 'pointer',
        margin: "0 0 15px 0",       
    },
    uncontrolledBox: {
        display: "inline-block",
        textAlign: "end",
        minWidth: "150px",
        padding: "15px",
    },
    addRequest: {
        display: "flex",
         alignItems: "center",
        justifyContent: "center",
        fontSize: "14px",
        border: "none", 
        textTransform: "uppercase",
        fontFamily: 'Asap',
        padding: "9px 18px",
        borderRadius: "32px",
        color: "#FFFFFF",
        width: "256px", 
        marginRight: "30px",
        fontWeight: 600, 
        background: "#FF5C00",
        cursor: "pointer",  
        height: "48px",
        
    },
    addRequestContainer: {
        display: "flex", 
        alignItems: "center",
        border: "1px solid #000", 
        fontFamily: 'Asap',
        fontSize: "14px", 
        textTransform: "uppercase",
        justifyContent: "center",
         borderRadius: "32px",
        color: "black", 
        padding: "9px 18px",
        fontWeight: 600, 
        width: "256px",
        height: "48px", 
        marginRight: "30px",
    },
    btnImgDiv: {
        marginRight: "8px",
       
        width: "10%"
    },
    textEnd: {
        display: "flex",
        justifyContent: "end",       
    },
    org: {
        color: "#1E1E1E",
        fontSize: "15px",
        padding: "6px 12px",
        background: "#B8D5F9",
        fontWeight: 500,
        fontFamily: 'Asap',
        borderRadius: "4px",
    },
    tableContent: {
        color: "#363636",
        fontSize: "15px",
        fontFamily: 'Asap',
        fontWeight: 500,
    },
    highlightedTableContent: {
        padding: '8px 0px',
        textAlign: 'center',
      width: '100%'
    },
    totalRate: {
        color: "#363636",
        fontFamily: 'Asap',
        fontWeight: 800,
        fontSize: "15px",
    },
    typeDiv: {
        display: "flex",
        fontFamily: "Asap",
        color: "#1E1E1E",
        fontSize: "15px",
        background: "#B8D5F9",
        fontWeight: 500,
        padding: "6px 12px",
        justifyContent: "space-between",
        borderRadius: "4px",
        border: "1px solid #6495D2",
        marginBottom: "3px"
    },
    pipe: {
        position: "absolute",
        height: "32px",
        background: "#6495D2",
        top: 0,
        width: "1px",
        left: "78px"
    },
    quantityDiv: {
        alignItems: "center",
        borderRadius: "8px",

        border: "1px solid #DADADA",
        display: "flex",
        padding: "10px 20px",
        marginRight: "15px",
    },
    available: {
        fontSize: "15px",
        fontFamily: "Asap",
        color: "#3C7C09",
        fontWeight: 500,
    },
    availableQuantity: {
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "Asap",
        color: "#3C7C09",
        margin: 0
    },
    total: {
        color: "#1E1E1E",
        fontWeight: 500,
        fontFamily: "Asap",
        padding: "5px 10px",
        background: "#EAEAEA",
        borderRadius: "4px",
        fontSize: "15px",
    },
    bookedAmount: {
        fontSize: "15px",
        textAlign: "center",
        fontFamily: "Asap",
        fontWeight: 500,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        color: "#DB2525",
        marginTop: "10px"
    },
    mr15: {
        marginRight: "15px"
    },
    tableLeftBorder: {
        borderRadius: "6px",
        textAlign: "center",
        borderLeft: "3px solid #B8D5F9",
    },
    tableRightBorder: {
        borderTopRightRadius: "6px",
        borderRight: "3px solid #ffffff",
        textAlign: "center",
        borderBottomRightRadius: "6px",
    },
    dFlex: {
        listStyle: "none",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
    textCenter: {
        textAlign: "center"
    },
    MainDiv: {
        height: "192px",
        width: "304px",
        marginLeft: "33%",
        flexDirection: "column",
        alignItems: "center",
        display: "inline-flex",
        marginTop: "6%"
    },
    InnerDiv: {
        width: "180%",
        marginLeft: "40%",
        height: "100%",
    },
    headerImagess: {
        width: "100px",
        marginLeft: "30%",
        height: "100px",
    },
    headingNotFound: {
        color: "#1E1E1E",
        fontSize: "24px",
        fontFamily: "Asap",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: "normal",
    },
    headingChanges: {
        color: "#363636",
        fontSize: "16px",
        fontFamily: "Asap",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        height: "18px",
        width: "284px",
        marginTop: "auto"
    },
    tableYellowBorder: {
        borderRadius: "6px",
        textAlign: "center",
        borderLeft: "3px solid #D0B655",
    },
    orgYellow: {
        fontSize: "15px",
        color: "#1E1E1E",
        padding: "6px 12px",
        fontFamily: "Asap",
        background: "#FFECA5",
        fontWeight: 500,
        borderRadius: "4px"
    },
    typeDivYellow: {
        display: "flex",
         justifyContent: "space-between",
        color: "#1E1E1E",
        background: "#FFECA5", 
        border: "1px solid #FFECA5",
        fontSize: "15px",
        fontFamily: "Asap",   
        fontWeight: 500,
        borderRadius: "4px", 
        padding: "6px 12px",
        marginBottom: "3px"
    },
    tableGreenBorder: {
        borderLeft: "3px solid #7DBD43",  
        textAlign: "center",
        borderRadius: "6px",
    },
    orgGreen: {
        background: "#CFF0B0", 
        fontWeight: 500,
        fontFamily: "Asap",
        fontSize: "15px",
        padding: "6px 12px", 
        borderRadius: "4px",
        color: "#1E1E1E",
    },
    typeDivGreen: {
        justifyContent: "space-between",  
        display: "flex",
        background: "#CFF0B0",  
        border: "1px solid #7DBD43",
        fontSize: "15px",   
        color: "#1E1E1E",
        fontFamily: "Asap",   
        padding: "6px 12px",
        borderRadius: "4px", 
        fontWeight: 500,
        marginBottom: "3px"
    },
    tableDarkBlueBorder: {
        borderRadius: "6px",   
        textAlign: "center",
        borderLeft: "3px solid #6AA2C6",
    },
    orgDarkBlue: {
        background: "#C2E7FF",
        fontWeight: 500,
        fontFamily: "Asap",
        fontSize: "15px",
        padding: "6px 12px",  
        borderRadius: "4px",
        color: "#1E1E1E",
    },
    typeDivDarkBlue: {
        display: "flex", 
        justifyContent: "space-between",
        color: "#1E1E1E",
        fontSize: "15px", 
        fontWeight: 500,
        borderRadius: "4px",
        fontFamily: "Asap",  
        background: "#C2E7FF",
        padding: "6px 12px", 
        border: "1px solid #6AA2C6",
        marginBottom: "3px"
    },
    
    tablePinkBorder: {
        textAlign: "center", 
        borderLeft: "3px solid #FF8276",
        borderRadius: "6px",
    },

    orgPink: {
        fontWeight: 500, 
        background: "#F5B7B1",
        fontFamily: "Asap",  
        fontSize: "15px",
        color: "#1E1E1E", 
        padding: "6px 12px",
        borderRadius: "4px"
    },
    typeDivPink: {
        background: "#F5B7B1", 
        display: "flex",
        color: "#1E1E1E",  
        justifyContent: "space-between",
        fontSize: "15px", 
        fontFamily: "Asap",
        padding: "6px 12px",  
        fontWeight: 500,
        borderRadius: "4px", 
        border: "1px solid #FF8276",
        marginBottom: "3px"
    },

    tablePurpleBorder: {
        textAlign: "center",
        borderRadius: "6px",   
        borderLeft: "3px solid #D87DFF",
    },
    orgPurple: {
        background: "#D7BDE2", 
        fontFamily: "Asap",
        color: "#1E1E1E", 
        padding: "6px 12px",
        fontWeight: 500, 
        fontSize: "15px",
        borderRadius: "4px"
    },
    typeDivPurple: {
        display: "flex", 
        justifyContent: "space-between",
        color: "#1E1E1E",
        background: "#D7BDE2",
        fontSize: "15px", 
        padding: "6px 12px",
        fontFamily: "Asap", 
        border: "1px solid #D87DFF",
        borderRadius: "4px",
        fontWeight: 500, 
        marginBottom: "3px"
    },
    tableMustardBorder: {
        textAlign: "center",
        borderLeft: "3px solid #FFAA62",
        borderRadius: "6px",
    },
    orgMustard: {
        fontSize: "15px", 
        fontWeight: 500,
        padding: "6px 12px",
        color: "#1E1E1E", 
        background: "#F5CBA7",
        fontFamily: "Asap",  
        borderRadius: "4px"
    },
    typeDivMustard: {
        display: "flex", 
        justifyContent: "space-between",
        background: "#F5CBA7", 
        fontSize: "15px",
        fontWeight: 500, 
        border: "1px solid #FFAA62",
        padding: "6px 12px",  
        color: "#1E1E1E",
        fontFamily: "Asap",
        borderRadius: "4px", 
        marginBottom: "3px"
    },
    ellipsis: {
        cursor: "pointer"
    },
  
    addListingModal: {
        padding: "20px 10px", 
        borderRadius: "24px",
    },

    p_10_b_30: {
        padding: "10px 30px"
    },
    
    close: {
        cursor: "pointer",     
        textAlign: "end",
    },
    title: {
        fontSize: "28px",
        margin: "0 0 15px 0", 
        fontFamily: "Asap, sans-serif",
        color: "#1E1E1E",
        textAlign: "center"
    },
    organisation: {
        margin: "0 20px 20px 20px"
    },
    principalLabel: {
        fontWeight: 600,
        fontSize: "14px",  
        color: "#363636",
        fontFamily: "Asap, sans-serif",
    },
}
// Customizable Area End

const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};



export default class BuyersOffer extends BuyersOfferController {

    // Customizable Area Start

    // Customizable Area End


    // Customizable Area Start
    // Customizable Area End

     FormRequest =()=>{
        return(
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
                <Box 
                style={{ marginTop: "24px" }
            }>
                     <StyledBox
                        onClick={() => this.handleBuyerOfferExpandedMethod("listingID")}
                        data-test-id="buyerListingfilter"
                    >
                        <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpanded ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpandedMethod("listingID")} />
                  </DropdownArrowBox>
                    </StyledBox>
            <StyledInputLable shrink>REQUEST ID</StyledInputLable>
          
            {this.state.buyerExpanded&&(<ClickAwayListener
                onClickAway={() => this.setState({ buyerExpanded: false })}
                data-test-id="buyerExpanded"
            >
                <Box style={{ marginTop: "24px" }}>
                    
                    <Collapse
                        in={this.state.buyerExpanded}
                        style={{
                            position: "absolute",
                            width: "100%",
                            zIndex: 1,
                            display: "flex",
                            flexDirection: "column",
                            background: "#fff",
                            maxHeight: "178px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                           minHeight: "30px",
                        }}
                        timeout="auto"
                        disableStrictModeCompat={false}
                        unmountOnExit
                    >
        
        
                        {this.state.buyerFilter?.listingID?.map(
                            (item: { label: string; isChecked: boolean }) => {
                                return (
                                    <CustomFormControlLabel
                                        
                                    control={
                                            <Checkbox

                                                color="primary"
                                            
                                                onClick={(e) =>
                                                   
                                                    this.handleBuyerOfferChangeMethod(
                                                          e,
                                                          this.state.buyerFilter.listingID,
                                                          "listingID"
                                                      )
                                                  }
                                                data-test-id="buyerListingcheck"
                                                
                                                name={item.label}
                                                key={item.label}
                                                style={{ paddingRight: "30px" }}
                                                checked={item.isChecked}
                                            />
                                        }
                                        label={item.label}
                                        data-test-id="formcheck"
                                        style={{
                                            fontSize: "14px",
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                            fontFamily: "Asap",
                                        }}
                                        labelPlacement="start"

                                    />
                                );
                            }
                        )}
        
                    </Collapse>
        
                </Box>
        
            </ClickAwayListener>)}
        
            <Box
                style={{
                    padding: "2px",
                    justifyContent: "start",
                    display: "flex",
                    listStyle: "none",
                    flexWrap: "wrap",
                    gap: "5px",
                    width: "220px",
                    marginTop: "3px",

                }}
            >
        
                {this.state.buyerFilter?.listingID.map(
                    (option: { label: string; isChecked: boolean }) => {
              
                      if (option.isChecked) {
              
                        return (
                                <li
                                style={{ fontFamily: "Asap", fontSize: "14px" }}
                                    key={option.label}
                                                                        
                                >
              
                                    <StyledChip
                                        data-test-id="buyerDeletListingIdChip"
                                        
                                        label={option.label}
                                        
                                        onDelete={() =>
                                            
                                            this.handleBuyerOfferDeleteMethod(
                                            
                                                option,
                                            
                                                this.state.buyerFilter.listingID,
                                                "listingID"
                                            )
                                            }
                                    />


              
                                </li>
                            );
                        }
                    }
                )}
        
            </Box>
        </Box>
        </FormControl>
        )
        }


        PolForm =()=>{
          return(
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
                <Box style={{ marginTop: "24px" }}>
                <StyledBox  
                            onClick={() => this.handleBuyerOfferExpandedMethod("pol")} 
                            data-test-id="polfilter"
                            >
                                <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedPol ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpandedMethod("pol")} />
                  </DropdownArrowBox>
                            </StyledBox>
                    <StyledInputLable shrink>POL</StyledInputLable>{" "}
                  
                       
                    {this.state.buyerExpandedPol&&<ClickAwayListener
                        onClickAway={() => this.setState({ buyerExpandedPol: false })}
                        data-test-id="polClickAway"                        
                    >
                       
                       
                            <Collapse
                                timeout="auto"
                                in={this.state.buyerExpandedPol}
                                disableStrictModeCompat={false}
                                style={{
                                    position: "absolute",
                                    background: "#fff",
                                    zIndex: 1,
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    maxHeight: "178px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                   minHeight: "30px",
                                }}
                                unmountOnExit
                               
                            >
                                {this.state.buyerFilter?.pol?.map(
                                    
                                    (item: { label: string; isChecked: boolean }) => {
                                      
                                      return (
                                            <CustomFormControlLabel
                                                key={item.label}
                                                control={
                                                    <Checkbox
                                                    checked={item.isChecked}
                                                        data-test-id="buyerPolcheck"
                                                        onClick={(e) =>
                                                            this.handleBuyerOfferChangeMethod(
                                                                e,
                                                                this.state.buyerFilter.pol,
                                                                "pol"
                                                            )
                                                        }
                                                        
                                                        name={item.label}
                                                        color="primary"
                                                        style={{ paddingRight: "30px" }}
                                                    />
                                                }
                                    
                                                label={item.label}
                                    
                                                style={{
                                                    fontFamily: "Asap",
                                                    flexDirection: "row-reverse",
                                                    display: "flex",
                                                    fontSize: "14px",  
                                                    justifyContent: "space-between",                                                 
                                                }}
                                             
                                                labelPlacement="start"
                                            />
                                        );
                                    }
                                )}
                            </Collapse>

                        

                    </ClickAwayListener>}
                    
                    <Box
                        style={{
                            width: "220px",
                            display: "flex",
                            marginTop: "3px",
                            flexWrap: "wrap",
                            justifyContent: "start",
                            listStyle: "none",
                            gap: "5px",
                            padding: "2px",
                        }}
                    >
                        {this.state.buyerFilter?.pol.map(
                            (option: { label: string; isChecked: boolean }) => {
                    
                              if (option.isChecked) {
                                    return (
                                        
                                        <li
                                            key={option.label}
                                            style={{ fontSize: "14px",fontFamily: "Asap" }}
                                           
                                        >
                    
                                            <StyledChip
                                                label={option.label}
                                                data-test-id="buyerDeletPolIdChip"
                                                onDelete={() =>
                                                    this.handleBuyerOfferDeleteMethod(
                                                     option,this.state.buyerFilter.pol,
                                                        "pol"
                                                    )
                                                }
                                            />

                                        </li>
                                    );
                                }
                            }
                        )}

                    </Box>
                    </Box>
                </FormControl>
          )
        }

        PodForm = () =>{
        return(
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
             <Box style={{ marginTop: "24px" }}>            
             <StyledBox  
                    onClick={() => this.handleBuyerOfferExpandedMethod("pod")}
                    data-test-id="podfilter"
                    >
                        <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedPod ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpandedMethod("pod")} />
                  </DropdownArrowBox>
                    </StyledBox>
            <StyledInputLable shrink>POD</StyledInputLable>{" "}
            
            {this.state.buyerExpandedPod&&<ClickAwayListener
                            onClickAway={() => this.setState({ buyerExpandedPod: false })}
                data-test-id="podClickAway"
            >
                
            
                    <Collapse
                        timeout="auto"
                        in={this.state.buyerExpandedPod}
                        disableStrictModeCompat={false}
                        unmountOnExit
                        style={{
                            position: "absolute",
                            width: "100%",
                            zIndex: 1,
                            flexDirection: "column",
                            display: "flex",
                            background: "#fff",
                            maxHeight: "178px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                           minHeight: "30px",
                        }}
                    >
            
                        {this.state.buyerFilter?.pod?.map(
                            (item: { label: string; isChecked: boolean }) => {
                                return (
                                    <CustomFormControlLabel
                                        key={item.label}
                                        control={
                                            <Checkbox
                                               checked={item.isChecked}
                                                color="primary"
                                                data-test-id="buyerPodCheck"

                                                onClick={(e) =>
                                                    this.handleBuyerOfferChangeMethod(
                                                        e,this.state.buyerFilter.pod,"pod"
                                                    )
                                                }
                                                style={{ paddingRight: "30px" }}
                                                name={item.label}
                                            />
                                        }
                                        labelPlacement="start"
                                        label={item.label}
                                        style={{
                                            display: "flex",
                                            fontFamily: "Asap",
                                            justifyContent: "space-between",
                                            flexDirection: "row-reverse",
                                            fontSize: "14px",
                                        }}
                                    />
                                );
                            }
                        )}

                    </Collapse>

            </ClickAwayListener>}
            
            <Box
                style={{
                    padding: "2px",
                    width: "220px",
                    justifyContent: "start",
                    display: "flex",
                    gap: "5px",
                    flexWrap: "wrap",
                    marginTop: "3px",
                    listStyle: "none",
                }}
            >
                {this.state.buyerFilter?.pod.map(
                    (option: { label: string; isChecked: boolean }) => {
            
                      if (option.isChecked) {
            
                        return (
                                <li
                                style={{ fontSize: "14px",fontFamily: "Asap"}}
                                    key={option.label}
                                >
                                    <StyledChip
                                        data-test-id="buyerDeletPodIdChip"
                                        onDelete={() =>
                                            this.handleBuyerOfferDeleteMethod(
                                                option,this.state.buyerFilter.pod,
                                                "pod"
                                            )
                                        }
                                        label={option.label}

                                    />
                                </li>
                            );
                        }
                    }
                )}
            </Box>
              </Box>
        </FormControl>
        )

        }


        TypeForm =()=>{
            return(
                <FormControl style={{marginRight: "15px", width: "100%"  }}>
                    <Box style={{ marginTop: "24px" }}>
                    <StyledBox  onClick={() => this.handleBuyerOfferExpandedMethod("type")}  data-test-id="typefilter">
                    <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedType ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpandedMethod("type")} />
                  </DropdownArrowBox>
                    </StyledBox>
                    <StyledInputLable shrink>STATUS</StyledInputLable>{" "}
                    
                    {this.state.buyerExpandedType&&<ClickAwayListener
                        onClickAway={() => this.setState({ buyerExpandedType: false })}
                        data-test-id="typeClickAway"
                    >                 
                            <Collapse
                                timeout="auto"
                                in={this.state.buyerExpandedType}
                                
                                disableStrictModeCompat={false}
                                style={{
                                    position: "absolute",
                                    zIndex: 1,
                                    width: "100%",
                                    background: "#fff",
                                    flexDirection: "column",
                                    display: "flex",
                                    maxHeight: "178px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                   minHeight: "30px",
                                }}
                                unmountOnExit
                            >
                                {this.state.buyerFilter?.type?.map(
                                    (item: { label: string; isChecked: boolean }) => {
                    
                                      return (
                                            <CustomFormControlLabel
                                                key={item.label}
                                                control={
                                                    <Checkbox
                                                        data-test-id="buyerTypeCheck"
                                                        checked={item.isChecked}
                                                        color="primary"
                                                        name={item.label}
                                                        onClick={(e) =>
                                                            this.handleBuyerOfferChangeMethod(
                                                                e,
                                                                this.state.buyerFilter.type,
                                                                "type"
                                                            )
                                                        }
                                                        style={{ paddingRight: "30px" }}
                                                    />
                                                }
                                                labelPlacement="start"
                                                label={item.label}
                                                style={{
                                                    display: "flex",
                                                    fontFamily: "Asap",
                                                    flexDirection: "row-reverse",
                                                    fontSize: "14px",
                                                    justifyContent: "space-between",
                                                }}
                    
                                                />
                                        );
                                    }
                                )}
                    
                            </Collapse>
                    
                    </ClickAwayListener>}
                    
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            width: "220px",
                            gap: "5px",
                            marginTop: "3px",
                            padding: "2px",
                            flexWrap: "wrap",
                            listStyle: "none",
                        }}
                    >

                        {this.state.buyerFilter.type.map(
                            (option: { label: string; isChecked: boolean }) => {
                                if (option.isChecked) {
                                    return (
                      
                                      <li
                                            key={option.label}
                                            style={{ fontSize: "14px",fontFamily: "Asap"}}
                                        >
                      
                                            <StyledChip
                                                
                                                onDelete={() =>
                                                    this.handleBuyerOfferDeleteMethod(
                                                        option,this.state.buyerFilter.type,"type"
                                                    )
                                                }
                                                data-test-id="buyerDeletTypeIdChip"
                                                label={option.label}
                                            />

                                        </li>

                                    );

                                }

                            }

                        )}
                    </Box>
                    </Box>
                </FormControl>
            )
        }




    renderFilterSection = () => {
        return (
            <CustomBox>


                 {this.FormRequest()}
                 {this.PolForm()}
                 {this.PodForm()}
                 {this.TypeForm()}
                
                <FormControl variant="standard" style={{ width: "100%" }}>                
                    <StyledInputLable   htmlFor="ETB DATE RANGE"
                        style={{ fontWeight: 700 }} shrink >
                        LOADING DATE RANGE
                    </StyledInputLable>
                    <BootstrapInput
               defaultValue=""  fullWidth
               id="ETB DATE RANGE"
               data-test-id="dateopne"  onClick={this.TogolFilter}
               value={this.state.StartDate !== "" && this.state.EndDate !== "" ? `${this.state.RenderStart} - ${this.state.RenderEndDate}` 
               : "" }
                    />
                    <CalendarImgBox><img src={DateTimeIcon} alt="" onClick={this.TogolFilter} /></CalendarImgBox>
                     <Box style={{ position: "absolute",
            top: "70px", right: "0px"  }}
            >
            { this.state.open &&
              <DateContainer style={{ position: "relative" }}
               data-test-id="DateFilterMainDiv">
                <DatePickerStyle open={this.state.open}
                  toggle={this.TogolFilter}
                  wrapperClassName="DateMainClassName" className="DateMainClassName"
                  onChange={(range: DateRange) => 
                    {
                    this.SetDateFilterData(range)
                  }}
                  initialDateRange={{
                    startDate: this.state.DateRangeStartDate || new Date(),
                    endDate: this.state.DateRangeEndDate || new Date()
                  }}
                />
                <Box 
                className="ButtonContaienr">
                  <Button style={{ marginRight: "10px" }}
                   data-test-id="ClearFilter" variant="text" onClick={()=> {
                    this.ClearFilterData()
                  } }>
                    <ProjectText variant="h6" 
                    TextSize="16px" textcolor="primary">
                      clear   </ProjectText>
                  </Button>
                  <Button variant="contained"
                   data-test-id="SelectFilter" onClick={this.FilterDateSelect} color="primary" 
                   className="SelectButton">
                    <ProjectText variant="h6" TextSize="16px" 
                    textcolor="white">
                      Select   </ProjectText>
                  </Button>  </Box>
              </DateContainer>
            }
          </Box>              
                </FormControl>            
            </CustomBox>
        )
    }

    renderSnackbar = ()=> {
        return (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.buyerRequestSnackbarOpen}
            autoHideDuration={5000}
            onClose={this.handleSnackbarClose}
          >
            <SnackbarContent
              style={{ backgroundColor: '#fff', color: '#f00', fontWeight: 'bolder' }}
              message="Can not switch user as organisation role is not both"
            />
          </Snackbar>
        );
      }
      darkenColor(hex:any, percent:number) 
      {
        hex = hex.replace('#', '')    

    let r = 
    parseInt(hex.substring(0, 2), 16);
    let g = 
    parseInt(hex.substring(2, 4), 16);
    let b = 
    parseInt(hex.substring(4, 6), 16);
    
    // Darken the RGB values
    r = 
    Math.floor(r * (100 - percent) / 100);
    g = 
    Math.floor(g * (100 - percent) / 100);
    b = 
    Math.floor(b * (100 - percent) / 100);
    
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b =
     Math.min(255, Math.max(0, b));
    
    return '#' + (r * 65536 + g * 256 + b).toString(16).padStart(6, '0')
    }
    
    
      renderTableRow(item:any) {    
        const principalColorCode = item?.request?.data?.attributes?.color_name ?? "#fafd9f"
        const darkenedBorderColor = this.darkenColor(principalColorCode ?? '',15);
        const darkenedBorderColors = this.darkenColor(principalColorCode ?? '',50);
        
        const totalPrice = item?.container_details?.length === 1 ? item?.container_details[0]?.desired_rates * item?.container_details[0]?.quantity
 : item?.container_details[0]?.desired_rates * item?.container_details[0]?.quantity + item?.container_details[1]?.desired_rates * item?.container_details[1]?.quantity
        return (
            <TableRow 
            // style={style.bgblue} 
            style={{...style.bgblue,background: principalColorCode}}
            >
            <div 
            style={{...style.curveRadius,borderLeftColor: darkenedBorderColor !== "#d4d787" ? darkenedBorderColor: "rgb(255, 201, 0)"}}
            >
                <TableCell style={style.textStylingCode}>
                    <span 
                    style={{...style.textBorder,border: `1px solid ${darkenedBorderColors}`,color:darkenedBorderColors,backgroundColor:principalColorCode}}>
                        {item?.request?.data?.attributes?.principal_org_code || item?.request?.data?.attributes?.organization_code}</span>
                </TableCell>
            </div>
            <TableCell style={style.textStyling1a}>
                <span style={style.rightBorderstyles}>
                    {item?.request?.data?.attributes?.request_uid}
                </span>
            </TableCell>
            <TableCell style={style.textStylingDate}>{moment(item?.request?.data?.attributes?.container_loading_range_start).format("DD-MMM-YYYY")}-{moment(item?.request?.data?.attributes?.container_loading_range_end).format("DD-MMM-YYYY")}</TableCell>
            <TableCell style={style.textStyling}>{item?.request?.data?.attributes?.pol}/<br /><br />{item?.request?.data?.attributes?.pol_terminal}</TableCell>
            <TableCell>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="8"
                    viewBox="0 0 35 8"
                    fill="none"
                >
                    <path
                        d="M34.3536 4.35356C34.5488 4.15829 34.5488 3.84171 34.3536 3.64645L31.1716 0.464469C30.9763 0.269207 30.6597 0.269207 30.4645 0.464469C30.2692 0.659731 30.2692 0.976313 30.4645 1.17158L33.2929 4L30.4645 6.82843C30.2692 7.02369 30.2692 7.34027 30.4645 7.53554C30.6597 7.7308 30.9763 7.7308 31.1716 7.53554L34.3536 4.35356ZM-4.37114e-08 4.5L34 4.5L34 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                        fill="black"
                    />
                </svg>
            </TableCell>
            <TableCell style={style.textStyling}>
                    {item?.request?.data?.attributes?.pod}/<br /><br />{item?.request?.data?.attributes?.pod_terminal}
            </TableCell>
            <TableCell style={style.textStyling}>{item?.container?.container_type}</TableCell>
            <TableCell style={style.textStyling}>
                    <span style={{fontFamily:"Asap",fontWeight:500}}>{item.container.codes?.join('/')}</span>
            </TableCell>
            <TableCell style={style.textStyling}>
                {item?.container_details?.map((container: any) => (
                    container.quantity > 0 && (
                <Box className="qauntity_ui" style={{background: principalColorCode,border: `1px solid ${darkenedBorderColors}`, marginLeft: '-10px'}}>
                    <TableCell style={style.combineBorderRightClass}>{container?.container_size}</TableCell>
                    <TableCell style={style.combineBorderRightClass}>
                        <span style={{fontFamily:"Asap",fontWeight:400}}>{container?.quantity}</span><span style={style.headStyle}>{container?.desired_rates ? 
                     `$${parseInt(container?.desired_rates).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}</span>/{container.container_size}
                    </TableCell>
                    <TableCell style={style.textStyling}>{container?.terms}</TableCell>
                </Box>
                    )                
                ))}
            </TableCell>
           <TableCell style={style.headStyle}>{item?.container_details.length > 0
            ? `$${totalPrice.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
            :""}
            </TableCell>
            <TableCell style={style.textStyling}>
                <Box style={style.statusContainer}>
                    <Box>
                        <TableCell style={style.combineStatusPending}>
                            <span style={{
                                fontWeight: 900,
                                fontFamily: 'Asap',
                            }}>
                                {item?.offer.data[0]?.attributes?.pending_status_count} OFFERS
                            </span>

                            <span style={style.pendingText}>PENDING</span>
                        </TableCell>
                    </Box>
                    <Box>
                        <TableCell style={style.combineStatusPending}>
                             {item?.offer.data[0]?.attributes?.rejected_status_count} OFFER
                            <span style={style.rejectedText}>REJECTED</span>
                        </TableCell>
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
        );
      }
      getPrincipalOrg(principalOrgList: any): string | JSX.Element {
        if (Array.isArray(principalOrgList)) {  
            return "ALL";
        } else if (principalOrgList.type === "organization") {
          return(
            <><span>{principalOrgList?.attributes?.name} </span>
      <p  style={buyerStyle.boxtextp}>
          {principalOrgList?.attributes?.code} 
          </p></> ) }  
        return "ALL";
      }
       MapData = () => {
        // Extracting state variables
        const activeSlotFilter = this.state.activeSlotFilter;
        let backgroundColor = activeSlotFilter[0]?.attributes?.color_name;
        let text = "";
      
        activeSlotFilter.forEach((slot: any) => {
          text += `${slot.attributes?.name?.trim().split(" ").join("/")} ${slot.attributes?.code} `;
          if (slot.attributes?.color_name)
           {
            backgroundColor += ` ${slot.attributes?.color_name} ` }}
        );        
        if (activeSlotFilter.length === 0) 
        {
          return this.getPrincipalOrg(this.state.buyerPrinicpalOrgList);
        }
      
        // Truncating text if necessary
        const truncatedText = text.length > 28 ? text.slice(0, 28) + " ..."  : text;
      
        return ( <div 
            className="DropDownDiv" 
          title={text?.split("/").join(" ")} >
            {truncatedText.split(" ").filter(txt => txt !== "").map((txt, index) => {
              if (index % 2 === 0) {
                return (
                  <h3 className="DropDownDivh3" 
                  key={index}> {txt?.split("/").join(" ")} 
                  </h3>  );
              } else {
                return (
                  <p  key={index}
                  className="DropDownDivP" 
                    style={{
                        borderRadius: ".25rem",
                      padding: ".25rem .625rem",backgroundColor: backgroundColor?.split(" ").filter((txt:any) => txt !== "")[index],
                    } }  > 
                  {txt}
                   </p>
                );
              } }
              )
              } </div>   
              );
      }; 
      renderSlotForSwitchAndOrgSelectSection = () => {

        const MapDataElse = () => {
          return (
            <div 
            className="DropDownDiv">
              <h3 className="DropDownDivh3">
                {this.state.activeSlotFilter?.attributes?.name}</h3>
              <p 
              className="DropDownDivP" style={{fontFamily:"Asap",fontWeight:500 ,padding: '.25rem .625rem', borderRadius: '.25rem', backgroundColor: this.state.activeSlotFilter?.attributes?.color_name }}>{this.state.activeSlotFilter?.attributes?.code}</p>
            </div>
          )
        }    
        return (
            <Grid 
            item lg={6} md={6}>
              <SectionWrapperLeft
               style={buyerStyle.leftBar}>
                <StyledSwitch>
                  <div className="switch" 
                  onClick={() => this.SwitchChnages()} data-test-id="switch-changes">
                    <input   className="Inputswitch"     type="checkbox"
                      checked={this.state.switch}  />
                    <span className="slider round">
                      <ProjectText TextSize="1rem" 
                      fontweight="700"  className="text">
                        {this.state.switch 
                        ? 
                        "BUY" :
                         ""} </ProjectText>
                    </span>    </div>
                </StyledSwitch> 
                <h1 style={buyerStyle.slotsFor}>
                    Slots for
                    </h1>
                    {!this.state.isSlotFilterOpen && ( <div 
                  style={{ position: 'relative',  fontWeight: 500, 
                  fontFamily: 'Asap',color: 'rgba(30, 30, 30, 1)',fontSize: '.875rem' }}
                  >
                    <div data-test-id="organizationFilter" className="selectSlotInput"
                    onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                      style={buyerStyle.selectSlot}>
                      <div style={{ display: 'flex', fontWeight: 500,overflow: 'auto',alignItems:"center" }}>
                        {Array.isArray(this.state.activeSlotFilter) ?
                          this.MapData() : 
                          <MapDataElse/>  }  </div>
                      <img 
                      src={image_dropdown}
                      style={{width: '10px', height: '10px', objectFit: 'contain'}}
                       alt="dropdown1" />
                    </div>
                    </div>)}
                    {this.state.isSlotFilterOpen && (
                      <ClickAwayListener 
                onClickAway={() => this.setState({ isSlotFilterOpen: false })}
                >
                  <div 
                  style={{ position: 'relative',fontWeight: 500,  
                  fontFamily: 'Asap' ,color: 'rgba(30, 30, 30, 1)', fontSize: '.875rem'  }}
                  >
                    <div  style={buyerStyle.selectSlot} className="selectSlotInput" data-test-id="organizationFilter"
                    onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                      >
                      <div style={{ overflow: 'auto', display: 'flex', fontWeight: 500,alignItems:"center" }}>
                        {Array.isArray(this.state.activeSlotFilter) ?
                          this.MapData() : 
                          <MapDataElse/>  }  </div>
                      <img 
                      src={image_dropdown}
                      style={{width: '10px', height: '10px', objectFit: 'contain'}}
                       alt="dropdown" />
                    </div>
                    {this.state.isSlotFilterOpen && (  <Box>
                  
                    {Array.isArray(this.state.buyerPrinicpalOrgList) 
                    ? (  <div data-test-id="organizationFilterDropdown" 
                    className="selectSlotDropdown" style={buyerStyle.selectSlotDropdown}>
                     {this.state.buyerPrinicpalOrgList?.map((val: any) => {
                      return (
                        <div key={val.id} style={{ borderLeft: `.25rem solid ${val.attributes.color_name}`, ...buyerStyle.selectSlotItem }}>
                          <h4 style={{ textTransform: 'uppercase' }}>
                            {val.attributes.name}  </h4>
                          <Checkbox color="primary"  checked={this.state.activeSlotFilter.includes(val)}
                            data-test-id="organizationFilterDropdownCheck"  onChange={(e) => this.handleSlotFilter(val, e.target.checked)}
                          />  </div>
                     )
                     })
                     }
                       </div>
                       ):
                        this.state.buyerPrinicpalOrgList.type !== "organization" 
                        && ( <div data-test-id="organizationFilterDropdown" 
                    className="selectSlotDropdown" style={buyerStyle.selectSlotDropdown}>
                    <div  key={this.state.buyerPrinicpalOrgList.id}
                          style={{ borderLeft: `.25rem solid ${this.state.buyerPrinicpalOrgList.attributes.color_name}`, 
                          ...buyerStyle.selectSlotItem }}
                          >
                          <h4 style={{ textTransform: 'uppercase' }} > {this.state.buyerPrinicpalOrgList.attributes.name}
                          </h4>
                          <Checkbox 
                          color="primary" 
                          checked=
                          {this.state.activeSlotFilter.includes(this.state.buyerPrinicpalOrgList)}
                          onChange={(e) => this.handleSlotFilter(this.state.buyerPrinicpalOrgList, e.target.checked)}/>
                        </div>
                        </div>  )   }
                    </Box>
                    )}
                  </div>
                </ClickAwayListener>)}   </SectionWrapperLeft>
            </Grid>
        )
      }

      renderSecondaryOfferDetails = (offer:any) => {
        const secContainerSizes = offer?.attributes?.offer_sec_container_sizes;
        const secQuantity = offer?.attributes?.offer_sec_quantity;
        const secRateBySeller = offer?.attributes?.offer_sec_rate_by_seller;
    
        return (
            <Box style={style.headStyle6}>
                { offer.attributes?.offer_sec_quantity &&  <> <TableCell style={style.combineBorderClass}>
                                <span style={style.textBorders}>{secContainerSizes}</span>
                                <span style={style.headStyle2}> X{secQuantity} </span>
                        </TableCell>
                        {secQuantity < 999 ? "\u00A0" : ""}
                        <TableCell style={style.combineBorderClasses}>
                                <span style={style.headStyle2}>
                                    {secRateBySeller ?
                                    `$${parseInt(secRateBySeller).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : ""}
                                    /
                                </span>
                                <span style={style.textBorders}>{secContainerSizes?.trim()}</span>
                        </TableCell>  </> }
        </Box>
        );
    };

      tableDataBuyerOffer = (item:any)=>{
        const { isBookButtonDisabled } = this.state;
        return(
           <>
            {item?.offer?.data.map((offer: any, index: any) => (
                <TableBody data-test-id="offerTestID" >
                    <TableRow style={style.bgWhite} key={1}>
                        <TableCell style={style.combineTdClass}></TableCell>
                        <div >
                            <TableCell style={style.combineRightBorderClass}>
                                <span style={style.rightBorderClasss}>
                                    {offer?.attributes?.offer_uid}
                                </span>
                            </TableCell>
                        </div>
                        <TableCell style={style.textStyling}>{moment.parseZone(offer?.attributes?.etb_pol).format("DD-MMM-YYYY")} </TableCell>
                        <TableCell style={style.textStylingDays} >
                            <span style={{fontFamily:"Asap",fontSize:"14px",fontWeight:500,marginLeft:"16px"}}>+{this.DayMinus(offer?.attributes?.eta_pod,offer?.attributes?.etb_pol)}days</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="81"
                                height="8"
                                viewBox="0 0 81 8"
                                fill="none"
                                className="__web-inspector-hide-shortcut__">
                                <path d="M80.3536 4.35355C80.5488 4.15828 80.5488 3.8417 80.3536 3.64644L77.1716 0.464459C76.9763 0.269197 76.6597 0.269197 76.4645 0.464459C76.2692 0.659722 76.2692 0.976304 76.4645 1.17157L79.2929 3.99999L76.4645 6.82842C76.2692 7.02368 76.2692 7.34027 76.4645 7.53553C76.6597 7.73079 76.9763 7.73079 77.1716 7.53553L80.3536 4.35355ZM4.37114e-08 4.5L80 4.49999L80 3.49999L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                                    fill="black" />
                            </svg>
                        </TableCell>
                        <TableCell style={style.textStyling}>
                        </TableCell>
                        <TableCell style={style.textStyling}>
                            <span style={style.rightBorderClass}>
                                {moment.parseZone(offer?.attributes?.eta_pod).format("DD-MMM-YYYY")}
                            </span>
                        </TableCell>
                        <TableCell
                            style={style.textStyling}>{offer?.attributes?.offer_container_type}</TableCell>
                        <TableCell
                            style={style.textStyling}>
                                <Typography style={{fontFamily:"Asap",fontWeight:500}}>{offer.attributes?.offer_container_codes?.join('/')}</Typography>
                        </TableCell>
                        <TableCell style={style.textStyling}>
                            <Box style={style.headStyle5}>
                                { offer.attributes?.offer_quantity &&  <> <TableCell style={style.combineBorderClass}>
                                            <span style={style.textBorders}>{offer?.attributes?.offer_container_sizes}</span> <span style={style.headStyle2}> X{offer?.attributes?.offer_quantity}</span>
                                        </TableCell>
                                        
                                            {offer?.attributes?.offer_quantity < 500 ? "\u00A0" : ""}
                                        
                                        <TableCell style={style.combineBorderClass}>
                                            <span style={style.headStyle2}>
                                                {offer.attributes?.offer_seller_rate ?
                                                `$${parseInt(offer.attributes?.offer_seller_rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                                                /</span><span style={style.textBorders}>{offer?.attributes.offer_container_sizes}</span>
                                        </TableCell> 
                                        </> }
                            </Box>
                            {this.renderSecondaryOfferDetails(offer)}
                        </TableCell>
                        <TableCell
                        
                            style={style.headStyle}>{offer?.attributes?.total ?
                                `$${parseInt(offer?.attributes?.total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                            
                            </TableCell>
                        <TableCell style={style.btnWrapper}>
                            <Box>
                                <TableCell style={style.combineButtonBorderClass}>
                                    <button data-test-id="bookclickTestID" style={style.bookBtn}
                                    disabled={isBookButtonDisabled.orderID === offer.id && isBookButtonDisabled.offer==='accepted'}
                                        onClick={() => this.getBuyerOfferAceeptReject('accepted', offer.id)}>
                                        BOOK <Check style={{ color: "green" }} />
                                    </button>
                                    <button data-test-id="rejectclick" style={style.rejectBtn}
                                     disabled={isBookButtonDisabled.orderID === offer.id && isBookButtonDisabled.offer==='rejected'}
                                        onClick={() => this.getBuyerOfferAceeptReject('rejected', offer.id)}>
                                        REJECT <Clear style={{ color: "red" }} />
                                    </button>
                                    <ClickAwayListener onClickAway={(e) => this.handleClickAway(e)} >
                                        <div>
                                            <Popper open={this.state.popup}
                                                anchorEl={this.state.anchorEl}
                                                placement={"right-end"}
                                                transition >
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Paper>
                                                            <ButtonGroup orientation="vertical" variant="text" >
                                                                <Button color="secondary"
                                                                    onClick={() => this.setState({ show: true })}
                                                                    style={{
                                                                        color: "#1E1E1E",
                                                                        fontFamily: "Asap",
                                                                        fontSize: "14px",
                                                                        fontStyle: "normal", fontWeight: 600,
                                                                        lineHeight: "20px",
                                                                        textTransform: "uppercase",
                                                                        display: "flex",
                                                                        padding: "9px 12px 9px 16px",
                                                                        justifyContent: "flex-end",
                                                                        alignItems: "center",
                                                                    }}
                                                                    className="deleteBtn">Review Offer</Button>
                                                            </ButtonGroup>
                                                        </Paper>
                                                    </Fade>
                                                )}
                                            </Popper>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => this.handlePopupState(e, offer)} >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </div>
                                    </ClickAwayListener>
                                </TableCell>
                            </Box>
                        </TableCell>
                    </TableRow>
                    
                </TableBody>
                ))}
           </>
        )
      }
offerFilterData = (offerData:any)=>{
    return(
        this.state.buyerOffer.map((buyerOffer:any)=> {
            if(Array.isArray(buyerOffer?.offer?.data) && buyerOffer?.offer?.data?.length > 0){
                offerData.push(...buyerOffer?.offer?.data)
            }
            return buyerOffer
        })
    )
}
    render() {
        // // Customizable Area End
        let offerData:any[] = [];
        this.offerFilterData(offerData)
        return (
            // Customizable Area Start
            <>
               <BuyerOffestyledrScreen data-test-id="buyerOffer" >

                <Navbar SellerPendingScreen={true} isBuyerScreen={true} AppBarTransparent = {false} TabDataCount={this.DynamicDataSet} />
                    <section style={buyerStyle.root}>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={this.state.buyerRequestSnackbarOpen}
                    autoHideDuration={5000}
                    onClose={this.handleSnackbarClose}
                >
                    <SnackbarContent
                    style={{ backgroundColor: '#fff', color: '#f00', fontWeight: 'bolder' }}
                    message="Can not switch user as organisation role is not both"
                    />
                </Snackbar>

                <Container  data-test-id="BuyerListing" maxWidth="xl">
                <Grid container alignItems="center" 
                        style={buyerStyle.slotsSection}>
                            {this.renderSlotForSwitchAndOrgSelectSection()}
                            <BuyerToggelComponentWeb />
                            </Grid>
                    

                        <StyledTabs
                            value={this.state.currentTab}
                            onChange={this.handleTabChange}
                            TabIndicatorProps={{
                                style: { display: "none" },
                            }}
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            data-test-id="tab"
                        >
                            <StyledTab disableTouchRipple label={`REQUESTS (${this.state.DynamicCount.requst})`} onClick={() => this.props.navigation.navigate("BuyersRequest")} />
                            <StyledTab disableTouchRipple label={`SELLER OFFERS (${this.state.DynamicCount.offers})`}/>
                            <StyledTab disableTouchRipple label={`BOOKINGS (${this.state.DynamicCount.booking})`}  onClick={() => this.props.navigation.navigate("BuyerMatches")}/>
                        </StyledTabs>
                        {this.renderFilterSection()}

                        <TabPanel value={this.state.currentTab} index={1}>
                            <TableContainer style={style?.tableContainer} component={Paper} >
                                <Table style={style.table} aria-label="simple table">
                                    <TableHead style={style?.tableHeadContainer}>
                                        <TableRow >
                                            <TableCell style={style.headStyleOrg}>ORG </TableCell>
                                            <TableCell style={style.headStylea1}>REQUEST ID </TableCell>
                                            <TableCell style={style.headStyle}>DATE RANGE</TableCell>
                                            <TableCell style={style.headStyle}>POL</TableCell>
                                            <TableCell style={style.headStyle}></TableCell>
                                            <TableCell style={style.headStyle}>POD</TableCell>
                                            <TableCell style={style.headStyle}>STATUS</TableCell>
                                            <TableCell style={style.headStyle}>TYPES</TableCell>
                                            <TableCell>
                                                <TableCell style={style.headStyle}>QUANTITY</TableCell>
                                                <TableCell style={style.headStyle}>BUYER PRICE(USD)</TableCell>
                                                <TableCell style={style.headStyle}>TERMS</TableCell>
                                            </TableCell>
                                            <TableCell style={style.headStyle}>TOTAL</TableCell>
                                            <TableCell style={style.combinedClass}>OFFER STATUS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                       
                                    {(Array.isArray(offerData)&& offerData.length > 0) && this.state.buyerOffer.map((item: any) => {
            const hasNonEmpty = item.container_details.some((details:any)=>details.quantity == 0)
                                        
                                        return (<> 
                                    <TableBody>
                                        {(!hasNonEmpty && item?.offer?.data.length>0) && this.renderTableRow(item)}
                                    </TableBody>

                                        {(!hasNonEmpty && item?.offer?.data.length>0)&&<TableHead >
                                            <TableRow style={style.bgColumn}>
                                                <TableCell style={style.combibeHeadClass}> </TableCell>
                                                <TableCell style={style.headStyle}>OFFER ID</TableCell>
                                                <TableCell style={style.headStyle1}>ETB</TableCell>
                                                <TableCell style={style.headStyle}></TableCell>
                                                <TableCell style={style.headStyle}> </TableCell>

                                                <TableCell style={style.headStyle2}>ETA</TableCell>
                                                <TableCell style={style.headStyle}>STATUS</TableCell>
                                                <TableCell style={style.headStyle}>TYPES</TableCell>
                                                <TableCell style={style.headStyle}>
                                                    <TableCell style={style.combineHeadBorderClass}>QUANTITY</TableCell>
                                                    <TableCell style={style.combineHeadBorderClass}>
                                                        SELLER PRICE(USD)
                                                    </TableCell>
                                                </TableCell>
                                                <TableCell style={style.headStyle}>TOTAL</TableCell>
                                                <TableCell style={style.headStyle}></TableCell>
                                            </TableRow>
                                        </TableHead>}
                                      {!hasNonEmpty && this.tableDataBuyerOffer(item)}
                                    </>
                                    )})}
                                </Table>
                                
                                        {!(Array.isArray(offerData)&& offerData?.length > 0) && (
                                            <div  style={{...buyerStyle.noData, flexDirection: "column"}}>
                                            <img
                                              src={resultNotFound}
                                              alt="resultNotFound"
                                              style={buyerStyle.noDataImg}
                                          />
                                          <p style={{...buyerStyle.head,fontWeight: "bold"}}>No Results Found!</p>
                                          <p  style={{...buyerStyle.msg}}>Change or Remove Filters and Try Again.</p>                                          
                                          </div>
                                          )}
                                    
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={this.state.currentTab} index={1}>
                        </TabPanel>
                        <TabPanel value={this.state.currentTab} index={2}>
                            <BuyerMatches navigation={undefined} id={""} />
                        </TabPanel>
                    </Container>

                    <Dialogs
                        fullWidth
                        maxWidth="md"
                        open={this.state.show}
                        onClose={this.handleClose}
                    >

                        <Box className="addListingModal">
                            <Box className="close" onClick={this.handleClose} data-test-id="close-btn"><img src={AddcloseImg} alt="" /></Box>
                            <Typography className="title">Review Offer</Typography>
                            <section className="organisation">

                                <Typography className="principalLabel">
                                    REQUEST ID
                                </Typography>
                                <Typography className="principalLabel">
                                    {this.state.ModelData?.attributes?.request_uid}
                                </Typography>

                            </section>
                            <section className="vesselContainer">
                                <Typography className="vesselHeading">Vessel Information</Typography>

                                <Grid  spacing={2} container  style={{ marginBottom: "10px" }}>
                                    <Grid  xs={12}  item lg={6}>
                                        <Grid spacing={1} container >
                                            <Grid  xs={12} item lg={6}>
                                                <InputLabel htmlFor="POL">
                                                    <ProjectText variant="subtitle1" Upercase fontweight="900"  TextSize="14px"  textcolor="black">
                                                        POL
                                                    </ProjectText>
                                                </InputLabel>
                                                <input type="text" disabled value={this.state.ModelData?.attributes?.pol} className="inputText"  /></Grid>
                                            <Grid item  lg={6} xs={12}>
                                                <Box>
                                                    <InputLabel htmlFor="POL TERMINAL">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            POL TERMINAL
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text" className="inputText" disabled value={this.state.ModelData?.attributes?.pol_terminal} />
                                            </Grid></Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            POD
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text" className="inputText" disabled value={this.state.ModelData?.attributes?.pod} />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            POD TERMINAL
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text" className="inputText" disabled value={this.state.ModelData?.attributes?.pod_terminal} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                                    <Grid item xs={12} lg={6}>
                                        <Box>
                                            <InputLabel>
                                                <ProjectText variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                    ETB AT POL
                                                </ProjectText>
                                            </InputLabel>
                                        </Box>
                                        <LandingPageTextField BoderRadius="10px" inputProps={{
                                        }}
                                            name="  ETB at POL"
                                            variant="outlined"
                                            className="inputTexts"
                                            type='text'
                                            disabled 
                                            value={moment.parseZone(this.state.ModelData?.attributes?.etb_pol).format("DD-MMM-YYYY | hh:mm A")}
                                            defaultValue="2017-05-24 | 10:30"
                                            id="  datetime-local"
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>

                                        <Box>
                                            <InputLabel>
                                                <ProjectText variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                    ETA AT POD
                                                </ProjectText>
                                            </InputLabel>
                                        </Box>
                                        <LandingPageTextField BoderRadius="10px" inputProps={{
                                        }}
                                            defaultValue="2017-05-24 | 10:30"
                                            variant="outlined"
                                            id="  datetime-local"
                                            type='text'
                                            disabled
                                            value={moment.parseZone(this.state.ModelData?.attributes?.eta_pod).format("DD-MMM-YYYY | hh:mm A")}
                                            name="  ETA at POD"
                                            className="inputTexts"
                                            fullWidth />
                                    </Grid>
                                </Grid>

                            </section>

                            <section className="vesselContainer">
                                <Typography className="vesselHeading">Container & Pricing Details</Typography>
                                <Grid style={{ marginBottom: "10px" }} container spacing={2} >
                                    <Grid xs={12} item  lg={6}>
                                        <Grid  spacing={1} container>
                                            <Grid  lg={6} item xs={12} >
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText  variant="subtitle1" Upercase TextSize="14px" textcolor="black" fontweight="900" >
                                                            CONTAINER STATUS
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text"  disabled value={this.state.ModelData?.attributes?.offer_container_type} className="inputText"/>
                                            </Grid>
                                            <Grid  xs={12} item lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText TextSize="14px" Upercase variant="subtitle1"  textcolor="black" fontweight="900" >
                                                            CONTAINER SIZE 
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text" disabled className="inputText" value={this.state.ModelData?.attributes?.offer_container_sizes} />
                                            </Grid></Grid>
                                    </Grid>
                                    <Grid item  lg={6}  xs={12} >
                                        <Grid   spacing={1} container >
                                            <Grid  lg={6} item    xs={12} >
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase  TextSize="14px" variant="subtitle1" textcolor="black"  fontweight="900" >
                                                            TYPES
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input className="inputText"  type="text"  value={this.state.ModelData?.attributes?.offer_container_codes} />
                                            </Grid>
                                            <Grid item  lg={6} xs={12} >
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase  TextSize="14px" textcolor="black" fontweight="900" variant="subtitle1" >
                                                            QUANTITY
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input disabled type="text"   value={this.state.ModelData?.attributes?.offer_quantity}  className="inputText"/>
                                            </Grid></Grid>
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: "10px" }} spacing={2} >
                                    <Grid item lg={6} xs={12} >
                                        <Grid spacing={1} container >
                                            <Grid item lg={6} xs={12} >
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase  TextSize="14px" textcolor="black" fontweight="900" variant="subtitle1" >
                                                            TERMS
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text" className="inputText" disabled value={this.state.ModelData?.attributes?.offer_terms_buyers} />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            SELLER PRICE
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <LandingPageTextField BoderRadius="10px"
                                                    variant="outlined"
                                                    className="textfield"
                                                    type='text'
                                                    disabled
                                                    value={this.state.ModelData?.attributes?.offer_seller_rate 
                                                        ? `$${parseInt(this.state.ModelData?.attributes?.offer_seller_rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : ""}
                                                    
                                                        fullWidth
                                                    InputProps={{
                                                        endAdornment: <Box>
                                                            <ProjectText TextSize="1rem" textcolor="#ACACAC" >/{this.state.ModelData?.attributes?.offer_container_sizes?.split(" ").join('')}</ProjectText>
                                                        </Box>,
                                                        startAdornment: <Box className="CurrencyBox">
                                                            <ProjectText TextSize="1rem" fontweight="700" >
                                                                USD
                                                            </ProjectText>
                                                        </Box>
                                                    }}
                                                    
                                                />
                                            </Grid></Grid>
                                    </Grid>


                                    <Grid item lg={6} xs={12} >
                                        <Grid  spacing={1} container >
                                            <Grid  sm={12} item>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText  variant="subtitle1" Upercase TextSize="14px" textcolor="black" fontweight="900" >
                                                            TOTAL PRICE
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <LandingPageTextField BoderRadius="10px"
                                                    variant="outlined"
                                                    disabled
                                                    type='text'
                                                    value={this.state.ModelData?.attributes?.offer_first_rate 
                                                        ? `$${parseInt(this.state.ModelData?.attributes?.offer_first_rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : ""}
                                                   
                                                    InputProps={{
                                                        endAdornment: <Box>
                                                            <ProjectText TextSize="1rem" textcolor="#ACACAC" >
                                                                /{this.state.ModelData?.attributes?.offer_container_sizes?.split(" ").join('')}
                                                            </ProjectText>
                                                        </Box>,
                                                        startAdornment: <Box className="CurrencyBox">
                                                            <ProjectText TextSize="1rem" fontweight="700" >
                                                                USD
                                                            </ProjectText>
                                                        </Box>
                                                    }}
                                                    fullWidth
                                                    className="textfield"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                {this.state.ModelData?.attributes?.offer_sec_container_sizes &&<><Grid  spacing={2} container style={{ marginBottom: "10px" }}>
                                    <Grid  xs={12} item lg={6}>
                                        <Grid  spacing={1} container >
                                            <Grid  lg={6} item xs={12} >
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText fontweight="900"  variant="subtitle1" Upercase TextSize="14px"  textcolor="black">
                                                            CONTAINER STATUS
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input  className="inputText"  type="text"  value={this.state.ModelData?.attributes?.offer_container_type} disabled />
                                            </Grid>
                                            <Grid  xs={12}  item lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText  TextSize="14px" variant="subtitle1"  textcolor="black" Upercase  fontweight="900" >
                                                            CONTAINER SIZE 
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text"  className="inputText" disabled value={this.state.ModelData?.attributes?.offer_sec_container_sizes} />
                                            </Grid></Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={1}>
                                            <Grid  xs={12} item lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            TYPES
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text" className="inputText" value={this.state.ModelData?.attributes?.offer_container_codes} />
                                            </Grid>
                                            <Grid  xs={12} item lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            QUANTITY
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text"  className="inputText" disabled value={this.state.ModelData?.attributes?.offer_sec_quantity} />
                                            </Grid></Grid>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                                    <Grid item lg={6} xs={12} >
                                        <Grid container spacing={1}>
                                            <Grid  xs={12} item lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            TERMS
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <input type="text" className="inputText" disabled value={this.state.ModelData?.attributes?.offer_sec_terms_buyers} />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            SELLER PRICE
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <LandingPageTextField BoderRadius="10px"
                                                    variant="outlined"
                                                    className="textfield"
                                                    type='text'
                                                    disabled
                                                    value={this.state.ModelData?.attributes?.offer_sec_rate_by_seller 
                                                        ? `$${parseInt(this.state.ModelData?.attributes?.offer_sec_rate_by_seller).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : ""}
                                                    
                                                    InputProps={{
                                                        endAdornment: <Box>
                                                            <ProjectText textcolor="#ACACAC" TextSize="1rem">/{this.state.ModelData?.attributes?.offer_sec_container_sizes?.split(" ").join('')}</ProjectText>
                                                        </Box>,
                                                        startAdornment: <Box className="CurrencyBox">
                                                            <ProjectText fontweight="700" TextSize="1rem">
                                                                USD
                                                            </ProjectText>
                                                        </Box>
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid></Grid>
                                    </Grid>


                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={1}>
                                            <Grid item sm={12}>
                                                <Box>
                                                    <InputLabel htmlFor="POD">
                                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                            TOTAL PRICE
                                                        </ProjectText>
                                                    </InputLabel>
                                                </Box>
                                                <LandingPageTextField BoderRadius="10px"
                                                    variant="outlined"
                                                    className="textfield"
                                                    type='text'
                                                    disabled
                                                    value={this.state.ModelData?.attributes?.offer_sec_rate 
                                                        ? `$${parseInt(this.state.ModelData?.attributes?.offer_sec_rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : ""}
                                                   
                                                    InputProps={{
                                                        endAdornment: <Box>
                                                            <ProjectText textcolor="#ACACAC" TextSize="1rem">
                                                                /{this.state.ModelData?.attributes?.offer_sec_container_sizes?.split(" ").join('')}
                                                            </ProjectText>
                                                        </Box>,
                                                        startAdornment: <Box className="CurrencyBox">
                                                            <ProjectText fontweight="700" TextSize="1rem">
                                                                USD
                                                            </ProjectText>
                                                        </Box>
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid></>}

                            </section>

                            <Grid container spacing={1} className="gridContainer">
                                <Grid item xs={12} lg={6} className="flexBox">
                                    <label className="principalLabel"> GRAND TOTAL</label>
                                    <LandingPageTextField BoderRadius="10px"
                                        variant="outlined"
                                        className="textfields"
                                        disabled
                                        value={this.state.ModelData?.attributes?.total 
                                            ? `$${parseInt(this.state.ModelData?.attributes?.total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : ""}
                                        type='text'
                                        InputProps={{
                                            startAdornment: <Box className="CurrencyBox">
                                                <ProjectText fontweight="700" TextSize="1rem">
                                                    USD
                                                </ProjectText>
                                            </Box>
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box className="dFlexWrapper">

                                <Box className="dFlexJCenter">
                                    <ContainedButton className="submitBtn" data-test-id="getBuyerOfferAceeptRejectOne" onClick={() => this.getBuyerOfferAceeptReject('accepted', this.state.ModelData?.id)}>Book</ContainedButton>
                                    <ContainedButton bgcolor="#fff" data-test-id="getBuyerOfferAceeptRejectTwo"  className="submitBtns" onClick={() => this.getBuyerOfferAceeptReject('rejected', this.state.ModelData?.id)}>Reject</ContainedButton>
                                </Box>
                            </Box>

                        </Box>

                        {this.state.showGuideVedios && (
                            <Box className="ByerOfferSubmittedBox">
                                <Box className="textEnd">
                                    <img src={AddcloseImg} alt="" />
                                </Box>
                                <Box className="pTB500">
                                    <Typography>
                                        User Guide Videos
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {[0, 1, 2, 3].map((elem: any, i: number) => {
                                            return (
                                                <Grid item sm={6} key={i}>
                                                    <Box className="videoContainer">
                                                        <iframe
                                                            width="455"
                                                            height="250"
                                                            src="https://www.youtube.com/embed/dltHi9GWMIo?si=0Y9uLLbWXpEzmmxo"
                                                            title="YouTube video player"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            allowFullScreen
                                                        ></iframe>
                                                        <Typography className="videoText">
                                                            Walkthrough video (How to add listing, update
                                                            listing and delete listing)
                                                        </Typography>
                                                        <Typography className="timeStamp">2:38 / 6:58</Typography>
                                                    </Box>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </Box>
                        )}
                        {/* Video Screen  end*/}



                    </Dialogs>


                     <Dialogs
                        fullWidth
                        maxWidth="sm"
                        open={this.state.RejectModal}
                        onClose={this.handleClose}
                    >
                        <Box className="ByerOfferSubmittedBox">
                            <Box className="textEnd" onClick={this.handleClose}>
                                <img src={AddcloseImg} alt="" />
                            </Box>
                            <Box className="PTB50 boxItemCenter">
                                <Box className="mb20">
                                    <img src={reject} alt="" className="successBtn" />
                                </Box>
                                <Typography className="BuyerHeading"> Rejected !</Typography>

                                <Typography className="BuyerPara">
                                    No worries! There are more offers available.
                                </Typography>
                            </Box>
                        </Box>
                    </Dialogs>

                    <Dialogs
                        fullWidth
                        maxWidth="sm"
                        open={this.state.slotBooked}
                        onClose={this.handleClose}
                    >
                        <Box className="ByerOfferSubmittedBox">
                            <Box className="textEnd" onClick={this.handleClose}>
                                <img src={AddcloseImg} alt="" />
                            </Box>
                            
                            <Box className="PTB50 boxItemCenter">
                                <Box className="mb20">
                                    <img src={reject} alt="" style={{width:'50px',height:'50px'}} />
                                </Box>
                                <Typography className="BuyerPara">
                                {this.state.slotMessage}                               
                              </Typography>
                            </Box>
                        </Box>
                    </Dialogs>
                    <Dialogs
                        fullWidth
                        maxWidth="sm"
                        open={this.state.SuccessModal}
                        onClose={this.handleClose}
                    >
                        <Box className="ByerOfferSubmittedBox">
                            <Box className="textEnd" onClick={this.handleClose}>
                                <img src={AddcloseImg} alt="" className="imgClose" />
                            </Box>
                            <Box className="pTB50 boxItemCenter">
                                <Box className="mb20">
                                    <img src={SuccessGrad} alt="" className="successBtn" />
                                </Box>
                                <Typography className="BuyerHeading">Success!</Typography>
                                <Typography className="BuyerPara">
                                    Your booking has been submitted. You will be notified
                                </Typography>
                                <Typography className="BuyerPara">
                                    soon regarding further actions required. Till then sit
                                </Typography>
                                <Typography className="BuyerPara">
                                    back, relax and hustle.
                                </Typography>
                            </Box>
                        </Box>
                    </Dialogs>
                    </section>
                </BuyerOffestyledrScreen>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const CalendarImgBox = styled(Box)({
    root: {position: "relative"},
    "& img": {
      cursor: "pointer",
      height: "24px", 
      width: "24px",
      position: "absolute",
      top: "32px", 
      zIndex: 1,
      right: "12px"
    }
  });
  const DropdownArrowBox = styled(Box)({
    root: {
      position: "relative"
    },
    "& img": {
      height: "10px", 
      width: "10px", 
      position: "absolute", 
      top: "15px", 
      zIndex: 1, 
      right: "20px",
      cursor: "pointer"
  
    }
  });

const BuyerOffestyledrScreen = styled(Box)({
    root: {
        padding: "30px 0",height: "100vh"
    },
    "& .DropDownDiv": {
        display: 'flex', 
        flexShrink: 0, 
        textTransform: 'uppercase', 
        gap: '.625rem', 
        alignItems: 'center', 
        justifyContent: 'center',
         paddingRight: '.625rem', 
         paddingLeft: '.625rem',
        
        "& .DropDownDivh3": {
          fontSize: '1rem',
          margin:"0px",
          "@media (max-width: 600px)": {
            fontSize: '10px',
          },
        },
        "& .DropDownDivP": {
          fontSize: '1rem',
          "@media (max-width: 600px)": {
            fontSize: '10px',
          },
        },
        "@media (max-width: 600px)": {
          paddingLeft: '0px',
        },
      },

    "& .container_bg ": {
        background: "#efefef"
    },
    "& .qauntity_ui": {
        width: 'max-content', 
        border: '1px solid #6495D2',
        margin: '2px 0', 
        background: '#fafd9f',
        borderRadius: '4px'
    },

    "& .slotsFor": {
        margin: 0,
        fontFamily: `"Asap", "sans-serif"`,
        fontWeight: 800, 
        fontSize: "28px",
        color: "#1E1E1E"
    },
    "& .selectSlot": {
        width: "400px", marginLeft: "15px"
    },

    "& .slotsSection": {
        marginBottom: "16px", 
        marginTop: "20px",
        "& .leftBar": {
            display: "flex",
            alignItems: "center"
        },    },

    "& .btnDiv": {
        display: "flex", 
        alignItems: "center",
    },
    "& .addRequest": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Asap",
        border: "none",
        fontSize: "14px",
        textTransform: "uppercase",
        borderRadius: "32px",
        padding: "9px 18px",
        color: "#FFFFFF",
        width: "256px",
        fontWeight: "bold",
        background: "#AA4649", 
        marginLeft: "73%",
        height: "48px",
        cursor: "pointer"
    },
    "& .btnImgDiv": {
        marginRight: "8px",
        width: "10%"
    },
})

const StyledChip = withStyles((theme) => ({
    root: {
        fontFamily: "Asap, sans-serif",
        color: "white",
        background: "#FF5C00",
        fontSize: "14px",
        "& .MuiChip-deleteIcon": {
            color: "rgba(255, 255, 255, 1)",
        },
    },
}))(Chip);

const Dialogs = styled(Dialog)({

    "& .addListingModal": {
        padding: "0px 8px 40px 8px",
        borderRadius: "24px",
        gap: "24px", 
        height: "826px",
        "& .gridContainer": {
            display: "flex", 
            justifyContent: "flex-end"
        },
        "& .flexBox": {
            display: "flex", 
            alignItems: "center",
        },
        "& .principalLabel": {
            fontWeight: 1000,
            fontSize: "14px",
            color: "#363636",
            fontFamily: "Asap, sans-serif",
            width: "140px"
        },
    },
    "& .close": {
        cursor: "pointer",
        textAlign: "end",
        marginTop: "5px"
    },
    "& .title": {
        fontSize: "28px", 
        margin: "0 0 15px 0",
        fontFamily: "Asap, sans-serif",
        color: "#1E1E1E",
        textAlign: "center",
        fontWeight: 700
    },
    "& .organisation": {
        margin: "0 20px 20px 20px"
    },

    "& .vesselContainer": {
        background: "#E3E6ED", 
        padding: "25px 25px",
        borderRadius: "18px", 
        marginBottom: "15px",
        fontFamily: "Asap",
    },
    "& .vesselHeading": {
        color: "#1E1E1E",
        fontSize: "18px",
        fontFamily: "Asap, sans-serif",
        textAlign: "center",
        margin: "0 0 25px 0",
        fontWeight: 600
    },
    "& .inputText": {
        border: "0px solid #CBD5E1",
        height: "55px",
        width: "100%",
        fontFamily: "Asap",
        borderRadius: "10px",
        backgroundColor:'#fff',
        paddingLeft: "8px",
        fontWeight: 500
    },
    "& .inputTexts": {
        border: "0px solid #CBD5E1",
        height: "55px",
        width: "100%", 
        fontFamily: "Asap",
        borderRadius: "10px",
        backgroundColor:'#fff',
        fontWeight: 600,
    },
    "& .bBottom": {
        margin: "0 2px 20px 2px", 
        border: "1px solid #CBD5E1",
    },
    "& .principalLabelAverageBox": {
        color: "#363636",
        fontWeight: 600,
        fontFamily: "Asap, sans-serif", 
        fontSize: "14px",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "8px"
    },
    "& .icons": {
        width: "5%", height: "2%",
        marginLeft: "52%"},
    "& .rateBox": {
        alignItems: "center", 
        border: "1px solid #CBD5E1",
        display: "flex", 
        background: "#fff",
        borderRadius: "8px",
        height: "44px"
    },


    "& .rateDiv": {
        fontSize: "14px", 
        color: "#1E1E1E",
        fontFamily: "Asap, sans-serif"
    },
    "& .borderRight": {
        borderRight: "1px solid #000"
    },
    "& .textfield": {
        backgroundColor: "white",
        borderRadius: "10px",
        height: "57px",
        marginRight: "28px"
    },
    "& .textfields": {
        backgroundColor: "#E3E6ED", 
        borderRadius: "10px",
        height: "57px", 
        marginRight: "28px"
    },
    "& .CurrencyBox": {
        marginRight: "5px",
        
        padding: "15px 13px 15px 0",
        borderRight: "1px solid #CDCDCD",
    },
    "& .usd": {
        margin: "0 10px", 
        color: "#1E1E1E",
        fontFamily: "Asap", 
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "20px",
        textTransform: "uppercase",

    },
    "& .inputRateText": {
        height: "44px", 
        border: "none",
        borderTopLeftRadius: "5px",
         width: "40px",
        padding: "0 8px", 
        borderBottomLeftRadius: "5px",
    },
    "& .dFlex": {
        listStyle: "none",  
        display: "flex",
        justifyContent: "space-between", 
        alignItems: "center",
        backgroundColor: "red"
    },
    "& .mr15": {
        marginRight: "15px"
    },
    "& .slash": {
        position: "relative",
        color: "gray", left: "12px"
    },
    "& .addRequestContainer": {
        display: "flex", 
        border: "none",
        alignItems: "center", 
        fontSize: "14px",
        justifyContent: "center", 
        textTransform: "uppercase",
        color: "black",
        borderRadius: "32px",
        fontFamily: "Asap", 
        padding: "9px 18px",
        width: "256px", 
        fontWeight: 600,
        marginRight: "30px", 
        height: "48px",
    },
    "& .submitBtn": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Asap",
        fontSize: "16px", 
        background: "rgb(255, 92, 0)",
        border: "none", 
        borderRadius: "32px",
        color: "#FFFFFF",
        fontWeight: 600,
        height: "56", 
        width: "264",
        textTransform: "uppercase",
        cursor: "pointer"
    },
    "& .submitBtns": {
        display: "flex",alignItems: "center",
        justifyContent: "center",fontSize: "16px",
        border: "2px solid #000;",borderRadius: "45px",
        padding: "9px 18px", color: "#000",
        fontWeight: 600,height: "56px",
        fontFamily: "Asap",width: "264px",
        marginLeft: "1%", 
        textTransform: "uppercase",
        cursor: "pointer",
        "@media (max-width: 768px)": {
            padding: "9px 46px"
        }
    },

    "& .dFlexWrapper": {
        width: "100%", 
        display: "flex",
        alignItems: "center", 
        justifyContent: "center",

    },

    "& .dFlexJCenter": {
        width: "50%",
        display: "flex",
        justifyContent: "center", 
        alignItems: "center",
        gap: 16, 
        marginTop: "18px",
        paddingBottom: "40px"
    },
    "& .ByerOfferSubmittedBox": {
        padding: "10px 20px",
        "& .pTB50": {
            padding: "50px 0",
        },
        "& .boxItemCenter": {
            display: "flex", 
             alignItems: "center",
            flexDirection: "column"
        },
        "& .mb20": {
            marginBottom: "20px",
        },
        "& .BuyerHeading": {
            color: "#1E1E1E", 
            fontSize: "28px",
            fontFamily: "Asap, sans-serif",
            fontWeight: 700,
        },
        "& .BuyerPara": {
            color: "#363636", 
            fontSize: "16px",
            fontFamily: "Asap, sans-serif", 
            margin: "0 0 5px 0",
        },
        "& .videoContainer": {
            background: "#E3E6ED", 
            paddingBottom: "15px",
            borderRadius: "22px",
        },
        "& .videoText": {
            color: "#1E1E1E",
            borderRadius: "16px 16px 0px 0px",
            background: "#E3E6ED",  
            fontSize: "15px",
            fontFamily: "Asap, sans-serif",
             fontWeight: 600,
            margin: "15px 12px 5px 12px",
        },
        "& .timeStamp": {
            color: "#797979",
             fontSize: "15px",
            fontFamily: "Asap, sans-serif", 
            margin: "0 12px",
        },
        "& .pTB500": {
            padding: "50px 0",
            textAlign: "center",
            color: "#000",
            fontSize: "28px",
            fontFamily: "Asap, sans-serif", 
            fontWeight: "bold",
        },
    },
    "& .textEnd": {
        display: "flex",
        justifyContent: "end",
    },
    "& .successBtn": {
        width: "100px",
        height: "100px"
    },

});


const StyledBox = withStyles((theme) => ({
    root: {
        position: "relative", 
        backgroundColor: theme.palette.common.white,
        padding: "20px 110px",
        border: "1px solid #ced4da",
        fontSize: 16,cursor: "pointer",
        borderRadius: 8,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: "#000",
        },
    },
}))(Box);

const StyledTabs = withStyles((theme) => ({
    root: {
        background: "#DADADA", 
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
        color: " #363636",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        fontFamily: "Asap, sans-serif",
    },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
    root: {
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
        overflow: "visible",
        fontWeight: "bold",
        color: "#1E1E1E",
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "normal",
        textTransform: "uppercase",
        width: "451.5px",
        height: "72px",
        fontFamily: "Asap",
    },
    selected: {
        background: "#ffffff",
    },

}))(Tab);

const StyledInputLable = withStyles((theme) => ({
    root: {
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "Asap",
        color: "#363636",
        textTransform: "uppercase",
    }
}))(InputLabel);

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 5, 
        position: "relative",
        backgroundColor: theme.palette.common.white,
        border: "1px solid #ced4da",
        fontSize: "16px",
        padding: "10px 12px",
        fontFamily: 'Asap',
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: "#363636",
        "&:focus": {
            borderColor: "##DCDCD",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
        },
    },
}))(InputBase);
const StyledSwitch = styled("div")({
    position: 'relative', 
    height: '2.125rem',
    width: '75px',
    display: 'inline-block', 
    marginRight: "15px",
    '& input': {
        opacity: 0,
         width: 0,
        height: 0,
    },

    '& .slider': {
        position: 'absolute', 
        cursor: 'pointer',
        bottom: 0,
        right: 0,
        top: 0,
        left: 0,
        transition: 'background-color 0.4s',
        backgroundColor: '#ccc',
        "& .text": {
            color: "white",
            right: "9px",
            top: "5px", 
            position: "absolute",
            fontWeight: "bolder",
            fontFamily: "Asap",
            fontSize: "1rem",
        },
        '&:before': {
            position: 'absolute',
             bottom: '.25rem',
            width: '1.625rem',
             content: '""',
            transition: 'transform 0.4s', 
            height: '1.625rem',
            right: '0.56rem', 
            backgroundColor: 'white',
        },
    },

    '& input:checked + .slider': {
        backgroundColor: '#FF5C00',
    },

    '& input:focus + .slider': {
        boxShadow: '0 0 .0625rem #2196F3',
    },

    '& input:checked + .slider:before': {
        transform: 'translateX(-37px)',
    },

    '& .slider.round': {
        borderRadius: '2.125rem',

        '&:before': {
            borderRadius: '50%',
        },
    },
});


const DatePickerStyle = styled(DateRangePicker)({
    "& .MuiInputLabel-animated": 
    {
      transition: 
      "unset"
    },
    "& .DateMainClassName": 
    {
      "& .MuiPaper-root": {
        paddingBottom: 
        "60px !important",
        backgroundColor: 
        "grey",
      }
    }
  })
  
  const DateContainer = styled(Box)({
    "& .ButtonContaienr": 
    {
      position: "absolute", display: "flex",
      justifyContent: "flex-end", bottom: "2px",
      right: "10px", zIndex: 9, padding: ".625rem .375rem"
    },
    "& .DateMainClassName": {
        "& .MuiInputBase-input":{
            fontFamily: "Asap, sans-serif",
          },
      "& .MuiPaper-root": 
      {
        paddingBottom:
         "20px",
        "& .MuiGrid-wrap-xs-nowrap":
        {
          "&.MuiGrid-root":
          {
            "& .materialui-daterange-picker-MuiList-root-59":
            {
              display:
              "none"
            }
          }
        }
      },
      "& .MuiTypography-root": 
      {
        fontWeight: "500 !important",
      fontFamily: 'Asap !important'
      },
      "& .materialui-daterange-picker-makeStyles-highlighted-57":
      {
        color: 
        "red !important",
      }
    },
    "& ul":{ 
        marginBottom: "20px"
    },
    "& li":{ fontFamily: "Asap, sans-serif"}
  });

  const CustomFormControlLabel = styled(FormControlLabel)({
    "& span": {
      fontFamily: "Asap"
    }
  })

//export default withStyles(style)(BuyersOffer)
// Customizable Area End