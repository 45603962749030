// Customizable Area Start
import React from "react";
import SellerPendingOffersController from "./SellerPendingOffersController";
import {
    Container, 
    Grid,
    styled, 
    withStyles,
    Tab, 
    Tabs,
    InputLabel, 
    Box,
    InputBase, 
    TableCell,
    TableRow, 
    Table,
    Tooltip, 
    FormControl,
    TableBody, 
    TableContainer,
    TableHead, 
    Dialog,
    Checkbox,  
    Snackbar,
    SnackbarContent, 
    Button,
    Menu, 
    Paper,
    DialogContent, 
    ClickAwayListener,
    Collapse, 
    FormControlLabel,
    Chip, 
    TextField, 
    CircularProgress,
} from "@material-ui/core";
import { 
  Theme 
} from "@material-ui/core/styles";
import { 
  withRouter 
} from "react-router-dom";
import Navbar from "../../../components/src/NavBar.web";
import LandingPageTextField, 
{
  AutocompleteStyle, 
  OptionForSelect,
  ProjectTextField,
} from "../../../components/src/ProjectTextField";
import ProjectText from "../../../components/src/ProjectText";
import {
    close, 
    ellipsis,
    image_success, 
    tooltip,
    calender, 
    image_dropdown, 
    DateTimeIcon, 
    resultNotFound1
} from "./assets";
import { 
  Formik 
} from "formik";
import * as Yup from "yup";
import { 
  ContainedButton 
} from "../../../components/src/GlobalButton";
import moment from "moment"
import ProjectModel from "../../../components/src/ProjectModel"
const QuetionMarkImg = require("../../../components/src/QuetionMarkImg.png");
import Spinner from "../../../components/src/ProjectLoader"
import AddListing from "../../../components/src/AddListing"
import { 
  DateRangePicker, 
  DateRange 
} from "materialui-daterange-picker";
import { 
  AutocompleteRenderOptionState 
} from "@material-ui/lab/Autocomplete";
import { 
  SectionWrapperLeft 
} from "./SellerListing.web"
import { 
  toast 
} from "react-toastify";

const StyledTabs = withStyles((theme) => ({
    root: { 
        background: "#DADADA", borderTopRightRadius: "5px", 
        borderTopLeftRadius: "5px",color: " #363636", 
        fontFamily: "Asap, sans-serif",fontSize: "20px", 
        fontStyle: "normal", fontWeight: 500,  
        lineHeight: "normal"
      },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
    root: {
        borderTopRightRadius: "5px",borderTopLeftRadius: "5px",
        overflow: "visible",fontWeight: "bold",
        color: "#1E1E1E",fontFamily: "Asap",
        fontSize: "14px",fontStyle: "normal",
        lineHeight: "normal",textTransform: "uppercase",
        width: "451.5px",height: "72px"
      },selected: { 
        background: "#ffffff" },
}))(Tab);

const BorderedStyledTab = withStyles((theme) => ({
    root: {
      borderTopLeftRadius: "5px",borderTopRightRadius: "5px",
    fontWeight: "bold",overflow: "visible",
    color: "#1E1E1E",fontFamily: "Asap",
    fontSize: "14px",lineHeight: "normal",
    fontStyle: "normal",textTransform: "uppercase",
    height: "72px",width: "451.5px", 
    },
        
        selected: { background: "#ffffff" }, 
        wrapper: { borderRight: '1px solid rgba(172, 172, 172, 1)', 
        padding: '14px 0px' }
}))(Tab);

const StyledInputLable = withStyles((theme) => ({
    root: {
      fontSize: "18px",
      fontWeight: 700,
      color: "#363636",
      textTransform: "uppercase",
      fontFamily: "Asap"
    }
}))(InputLabel);

const BootstrapInput = withStyles((theme) => ({
    root: { "label + &": { marginTop: theme.spacing(3) }, },
    input: { 
      borderRadius: 5,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #ced4da",
      fontSize: "16px",
      padding: "10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#363636", 
      fontFamily: "Asap","&:focus": { borderColor: "##DCDCD", 
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"},},
}))(InputBase);

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: "#1E1E1E",fontWeight: 600,
      verticalAlign: "baseline", fontFamily: "Asap",
      fontSize: "14px",fontStyle: "normal",
      lineHeight: "24px",background: "#E1E5EE",
      borderBottom: "2px solid #8A99BB"
    }, 
      body: { fontSize: 14 },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({root: 
  {
    color: "#B8D5F9",
    borderRadius: "10px",
    fontFamily: "Asap",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    background: "#fff"}
    ,
}))(TableRow);

const TabPanel = (props: any) => {
    const { 
        children, 
        value, 
        index, 
        ...other 
    } = props;

    return (
        <div
            role="tabpanel" 
            hidden={value !== index} 
            id={`simple-tabpanel-${index}`} 
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

const StyledTable = withStyles((theme) => ({
    root: {
      border: "none",
      borderCollapse: "separate",
    borderSpacing: "0 3px",
    rowGap: "10px",
    background: "transparent"
  },
}))(Table);

const StyledTableHeaderRow = withStyles((theme) => ({ root: { alignItems: 'center'}}))(TableRow);

const columns = [
  { id: "organization", label: ["ORG"], minWidth: "auto" },
  { id: "offerId", label: ["OFFER ID"], minWidth: "auto" },
  { id: "etbpol", label: ["ETB"], minWidth: "70px" },
  { id: "ves/voy", label: ["VES/VOY"], minWidth: "auto" },
  { id: "polId", label: ["POL"], minWidth: "auto" },
  { id: "podId", label: ["POD"], minWidth: "auto" },
  { id: "etaPol", label: ["ETA"], minWidth: "70px" },
  { id: "type_", label: ["STATUS"], minWidth: "auto" },
  { id: "containerCode", label: ["TYPES"], minWidth: "auto" },
  { id: "quantity_", label: ["QUANTITY"], minWidth: "auto" },
  { id: "requestedRate", label: ["BUYER PRICE (USD)"], minWidth: "90px" },
  { id: "offeredRate", label: ["SELLER PRICE (USD)"], minWidth: "70px" },
  { id: "totalUsd", label: ["TOTAL (USD)"], minWidth: "auto" },
  { id: "terms_", label: ["TERMS"], minWidth: "90px" },
  { id: "status_", label: ["OFFER STATUS"], minWidth: "auto" },
  { id: "dot", label: [""], minWidth: "auto" }
];


const StyledSwitch = styled("div")({
    position: "relative",  display: "inline-block",
    width: "75px", marginRight: "15px",
      height: "2.125rem",
    "& input": { 
      opacity: 0,  width: 0,
        height: 0
    },
    "& .slider": {
        position: "absolute",cursor: "pointer",
        top: 0,left: 0,
        right: 0, bottom: 0,
        backgroundColor: "#ccc", transition: "background-color 0.4s",
        "& .text": {
            position: "absolute",left: ".625rem", 
            top: ".3rem", color: "white", 
            fontSize: "1rem",fontWeight: "bolder",
        },
        "&:before": {
            position: "absolute", content: '""',
            height: "1.625rem", width: "1.625rem",
            left: "0.56rem",   bottom: ".25rem",
            backgroundColor: "white", transition: "transform 0.4s",
        },
    },
    "& input:checked + .slider": {
        backgroundColor: "#1653DD", },
    "& input:focus + .slider": {
        boxShadow: "0 0 .0625rem #2196F3", },
    "& input:checked + .slider:before": {
        transform: "translateX(37.4px)", },
    "& .slider.round": {
        borderRadius: "2.125rem", "&:before": {
            borderRadius: "50%",
        },
    },
});


const LogoutModel = styled(Box)({
    "& .LogoutModelContainer": {
        paddingLeft: "10px", 
        paddingRight: "10px",
        paddingBottom: "80px",
        paddingTop: "30px"
    },
    "& .QuetionMarkImgClass": {
       display: "flex",justifyContent: "center",
    },
});


const useStyles = {
    reactSelectWrapper: {
        fontFamily: "Asap",
        "& .css-1p3m7a8-multiValue": {
            borderRadius: "28px",padding: "3px 9px",
        },
        "& .css-wsp0cs-MultiValueGeneric": {
            fontSize: "14px", fontWeight: 600,
            fontFamily: "Asap",  
           },
        "& .css-12a83d4-MultiValueRemove": {
            background: "#000", borderRadius: "100%",
            height: "20px",   width: "20px",
            position: "relative",top: "2px",
        },
        "& .css-tj5bde-Svg": {
            fill: "#fff",
        },
        "& .css-13cymwt-control": {
            height: '43px !important', 
            background: "#ECECEC",
            borderRadius: "12px",
              },
        "& .css-1hb7zxy-IndicatorsContainer": {
            display: "none",
        },
        "& .css-yk16xz-control": {
            background: "#ECECEC !important", 
            padding: "6.5px 0", 
            borderRadius: "8px",
        },
    },
    multiSelectBox: {
        "& .MuiTypography-body1, .MuiChip-label": {
            fontSize: "14px", fontFamily: "Asap",
            textTransform: "capitalize",
        },
        "& .MuiBox-root-60": {
            width: "100% !important",
        },
    },
    root: { 
      background: "#eee", padding: "30px 0",minHeight : "100vh"
    },
    slotsSection: {
        marginBottom: "50px",
    },
    leftBar: { 
      display: "flex",
        alignItems: "center",
    },
    slotsFor: {
        margin: 0, 
        fontFamily: `"Asap", "sans-serif"`,
        fontWeight: 800, 
        fontSize: "24px",
        color: "#1E1E1E",
    },
    selectSlot: {
        width: "389px", 
        height: "48px",
        background: "rgba(255, 255, 255, 1)",  
        border: "1px solid rgba(205, 205, 205, 1)",
        marginLeft: "15px", 
        padding: '16px 12px',
        borderRadius: "8px", 
        display: 'flex',
        alignItems: 'center', 
        cursor: 'pointer',
        justifyContent: 'space-between',
    },
    selectSlotDropdown: {
        position: 'absolute' as 'absolute',  
        width: '385px',
        background: 'white',  
        borderRadius: '8px',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
        padding: '4px 6px',
         display: 'flex', 
         flexDirection: "column" as "column",
        gap: '2px',  
        left: '15px',
        marginTop: '4px', 
        zIndex: 2,
    },
    selectSlotItem: {
        display: 'flex', 
        width: '100%',
        height: '36px', 
        justifyContent: 'space-between',
        paddingLeft: '16px', 
        alignItems: 'center',
    },
    btnDiv: {
        display: "flex", 
        alignItems: "center",
        justifyContent: "space-between",
    },
    addListing: {
      display: "flex", 
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      background: "#1653DD",
       border: "none",
      borderRadius: "32px", 
      padding: "9px 18px",
      color: "#FFFFFF",
       marginRight: "30px",
      fontWeight: 600, 
      height: "48px",
      width: "256px", 
      textTransform: "uppercase" as "uppercase",
      cursor: "pointer",
      fontFamily: "Asap",
    },
    monthlyPricing: {
        display: "flex", alignItems: "center",
        justifyContent: "center",fontSize: "14px",
        background: "transparent", borderRadius: "32px",
        marginRight: "30px",fontWeight: 600,
        color: "#1E1E1E", border: "1px solid #1E1E1E",
        height: "48px", width: "256px",
        cursor: "pointer",textTransform: "uppercase" as "uppercase",
        fontFamily: "Asap",
    },
    btnImgDiv: {
        marginRight: "8px",
    },
    org: {
        color: "#1E1E1E", 
         fontSize: "15px",
        fontWeight: 500, 
        fontFamily: "Asap",
        padding: "6px 12px", 
         borderRadius: "4px",
        whiteSpace: 'nowrap' as 'nowrap',
    },
    bodyOfferTextAlignment: {
      textAlign: "center" as "center",
    },
    tableContent: {
        color: "#363636",
        fontSize: "15px", 
        fontWeight: 500,
        textTransform: "uppercase" as "uppercase",
        display:"flex" as "flex", 
        alignItems: "center" as "center",
        justifyContent: "center" as "center",
        fontFamily: "Asap",
    },
    tableContentWhiteSpace: {
      color: "#363636", fontSize: "15px", 
      fontWeight: 500,textTransform: "uppercase" as "uppercase",
      display:"flex" as "flex",alignItems: "center" as "center",
      justifyContent: "center" as "center",
      fontFamily: "Asap", whiteSpace: "nowrap" as "nowrap"
  },

    textEnd: { 
      display: "flex",
        justifyContent: "end",
    },
    typeDiv: {
        display: "flex", 
        justifyContent: "space-between",
        background: "#B8D5F9", 
        color: "#1E1E1E",
        fontSize: "15px", 
        fontWeight: 500,
        fontFamily: "Asap", 
        padding: "6px 12px",
        border: "1px solid #6495D2", 
        borderRadius: "4px",
        marginBottom: "3px",
    },
    pipe: {
        position: "absolute" as "absolute", 
        height: "32px",
        top: 0, 
        width: "1px",
        background: "#6495D2", 
        left: "78px",
    },
    available: {
        color: "#3C7C09", 
        fontSize: "15px",
        fontWeight: 500, 
        fontFamily: "Asap",
    },
    
quantityDiv: {  
  display: "flex", 
  alignItems: "center", 

    border:"1px solid #DADADA", 
    borderRadius: "8px", 
    padding:"10px 20px",  
    marginRight: "15px",
  },

total: { 
  color: "#1E1E1E",  
  fontSize: "15px", 
    fontWeight: 500, 
    fontFamily: "Asap", 
    background: "#EAEAEA", 
    padding: "5px 10px", 
    borderRadius: "4px", 
  },

availableQuantity: { 
    color: "#3C7C09",  
    fontSize: "18px", 
    fontWeight: 500,  
    fontFamily: "Asap", 
    margin: 0,
},

mr15: { 
    marginRight: "15px", 
},

bookedAmount: {
    color: "#DB2525", 
    fontSize: "15px",
    fontWeight: 500, 
    fontFamily: "Asap",
    textAlign: "center" as "center", 
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    marginTop: "10px",
},

tableLeftBorder: {
    borderLeft: "3px solid rgb(184, 213, 249)", 
    borderRadius: "6px",
},

dFlex: {
    display: "flex", alignItems: "center",
    justifyContent: "center",listStyle: "none",
    padding: 0, margin: 0,
},
dFlex2: {
  display: "flex", 
  alignItems: "center",
  justifyContent: "start",
  listStyle: "none",
  padding: 0, 
  margin: 0,
},

textCenter: {
    textAlign: "center" as "center",
},

ellipsis: {
    cursor: "pointer",
},

addListingModal: {
    padding: "20px 10px",
},

close: { textAlign: "end" as "end",
    cursor: "pointer",
},

p_10_b_30: {
    padding: "10px 30px",
},

title: {
    fontSize: "28px",  
    color: "#1E1E1E",
    fontFamily: "Asap", 
    margin: "0 0 15px 0",
    textAlign: "center" as "center",
},

principalLabel: {
    color: "#363636", 
    fontSize: "14px",
    fontFamily: "Asap",  
    fontWeight: 600,
},

organisation: {
    margin: "0 20px 20px 20px",
},

vesselContainer: {
    background: "#E3E6ED", 
    padding: "25px 10px",
    borderRadius: "18px", 
    marginBottom: "15px",
},

inputText: {
    border: "1px solid #CBD5E1",
     height: "44px",
    width: "100%",  
    borderRadius: "5px",
    padding: "0 8px",
},

vesselHeading: {
    color: "#1E1E1E", 
    fontSize: "18px",
    fontFamily: "asap", 
    textAlign: "center" as "center",
    margin: "0 0 25px 0",
},

validityBox: {
    display: "flex", 
    alignItems: "center",
    border: "1px solid #CBD5E1", 
    borderRadius: "8px",
},

etbDiv: {
    marginLeft: "10px",
},

validtyInputText: {
    border: "none", 
    height: "44px",
    width: "40px",  
    borderTopLeftRadius: "5px",
    padding: "0 8px", 
    borderBottomLeftRadius: "5px",
},

beforeETB: {
    color: "#797979", 
    fontSize: "14px",
    fontFamily: "Asap",
},

expiryDateDiv: {
    display: "flex", 
    alignItems: "center",
    border: "1px solid #CBD5E1",
     borderRadius: "8px",
    height: "40px", 
    paddingLeft: "10px",
},

expiryDate: {
    color: "#1E1E1E", 
    fontSize: "14px",
    fontFamily: "Asap",
},

bBottom: {
  margin: "0 2px 20px 2px", 
  border: "1px solid #CBD5E1",
},

rateBox: {
    display: "flex",  
    alignItems: "center",
    border: "1px solid #CBD5E1", 
     borderRadius: "8px", 
    background: "#fff",
},
usd: {
    margin: "0 10px",
},
borderRight: { 
  height: "41px",
    borderRight: "1px solid #CDCDCD", 
},

rateDiv: {
    color: "#1E1E1E", 
    fontSize: "14px",
    fontFamily: "Asap",
},

inputRateText: {
    border: "none", 
     height: "44px",
    width: "40px",  
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    padding: "0 8px",
},

slash: {
    color: "gray",  
    position: "relative" as "relative",
    left: "12px",
},

dFlexJCenter: {
    display: "flex", 
    alignItems: "center",
    justifyContent: "center",
},

ml5: {
    marginLeft: "5px",
},


btnTheme: {
    display: "flex", alignItems: "center",
    justifyContent: "center",fontSize: "16px",
    background: "#1653DD",  border: "none",
    borderRadius: "32px",  padding: "9px 18px",
    color: "#FFFFFF", fontWeight: 600,
    height: "45px", width: "256px",
    textTransform: "uppercase" as "uppercase", 
    cursor: "pointer",marginRight: "15px", 
    fontFamily: "Asap",
},

withDrawbtn: {
    display: "flex", 
    alignItems: "center",
    border: "1px solid #DB2525", 
    borderRadius: "32px",
    padding: "9px 18px", 
    justifyContent: "center",
    fontSize: "16px", 
    background: "#fff",
    color: "#DB2525", 
    fontWeight: 600,
    height: "45px",  
    width: "256px",
    textTransform: "uppercase" as "uppercase", 
    cursor: "pointer",
    fontFamily: "Asap", 
    margin: "0 10px",
},

mb20: 
{
    marginBottom: "20px",
},

listingSubmittedBox: 
{
    padding: "10px 20px",
},

listingIdPara: {
    fontFamily: "Asap", 
    color: "#363636",
    fontSize: "16px", 
    margin: "0 0 5px 0",
},

listingHeading: {
    color: "#1E1E1E", 
    fontSize: "28px",
    fontFamily: "Asap", 
    fontWeight: 700,
},

pTB50: {
    padding: "50px 0",
},


// Listing submitted CSS

// Matching Requests Available for Your Listing! btn CSS

viewOfferBtn: {
    fontSize: "16px", 
     background: "#1653DD",
    color: "#FFFFFF", 
    fontWeight: 600,
    border: "none", 
    borderRadius: "32px",
    padding: "9px 18px",
     height: "45px",
    width: "212px", 
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer", 
    marginTop: "25px",
},

// Matching Requests Available for Your Listing! btn css

// Delete modal css

cancelBtn: {
    fontSize: "16px", 
    background: "#FFFFFF",
    border: "1px solid #000000",
     borderRadius: "32px",
    padding: "9px 18px", 
     color: "#000000",
    fontWeight: 600,  
    height: "45px",
    width: "212px", 
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer", 
    fontFamily: "Asap",
},

deleteBtn: {
    fontSize: "16px", 
    background: "#DB2525",
    border: "none", 
    borderRadius: "32px",
    padding: "9px 18px", 
    color: "#FFFFFF",
    fontWeight: 600,  
    width: "212px",
    height: "45px", 
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer", 
    marginRight: "15px",
    fontFamily: "Asap",
},

// Delete modal css

// User Guide videos modal css
videoText: {
    color: "#1E1E1E", 
    fontSize: "15px",
    fontFamily: "Asap",
     fontWeight: 600,
    margin: "15px 12px 5px 12px",
},

videoContainer: {
    background: "#E3E6ED",
     paddingBottom: "15px",
    borderRadius: "22px",
},

timeStamp: {
    color: "#797979",
     margin: "0 12px",
    fontSize: "15px", 
    fontFamily: "Asap",
},

// User Guide videos modal css


totalUSD: {
    fontSize: "14px", 
    fontFamily: "Asap",
    fontWeight: 600,
},

offerBtn: {
    fontSize: "14px", 
    background: "#1653DD",
    border: "none",
     borderRadius: "32px",
    padding: "9px 18px", 
    color: "#FFFFFF",
    marginRight: "15px", 
    fontWeight: 600,
    height: "43px",
     width: "115px",
    textTransform: "uppercase" as "uppercase", 
    cursor: "pointer",
    fontFamily: "Asap",
},

verticalLine: {
    width: "1.5px",
     height: "35px",
    position: "absolute" as "absolute", 
    top: "-6px",
    left: "77px", 
    background: "#6AA2C6",
},

positionRelative: {
    position: "relative" as "relative",
},

daysBeforeETB: {
    background: "#E9E9E9", 
    position: "absolute" as "absolute",
    top: "-39px",
     right: "1px",
    padding: "9px 22px", 
    borderTopRightRadius: "8px",
    display: "inline-block", 
    borderBottomRightRadius: "10px",
    fontSize: "14px", 
    fontFamily: "Asap",
    color: "#797979", 
    width: "200px",
    "@media (max-width: 1200px)": {
      top: "-29px",
      padding: "9px 4px",
    },
},

teu: {
    display: "inline-block", 
    fontSize: "14px",
    fontFamily: "Asap", 
     color: "#797979",
    position: "absolute" as "absolute",
    top: "-29px",  
    right: "10px",
},

ml15: {
    marginLeft: "15px",
},

slashTerms: {
    position: "absolute" as "absolute", 
    left: "220px",
    top: "-29px", 
    fontWeight: 600,
},

usdDiv: {
    background: "#ECECEC",
     border: "1px solid #ACACAC",
    fontSize: "15px", 
    fontFamily: "Asap",
    height: "40px", 
    padding: "8.5px 17px",
    borderTopLeftRadius: "8px", 
    borderBottomLeftRadius: "8px",
    fontWeight: 600,
},

offerStatus: {
    color: "#EE8F00",
     fontSize: "15px",
    fontWeight: 600, 
    fontFamily: "Asap",
},
rejectClass:{
  color: "#E90026", 
  fontSize: "15px",
    fontWeight: 600, 
    fontFamily: "Asap",
},

editText: {
    fontSize: "14px",
     color: "#1E1E1E",
    fontFamily: "Asap",
     margin: "0 0 10px 0",
    cursor: "pointer",
},

usdDivTransparent: {
    background: "#fff",
     border: "1px solid #ACACAC",
    fontSize: "15px",
    fontFamily: "Asap",
    height: "40px",
    padding: "8.5px 17px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    fontWeight: 600,
},

deleteText: {
    fontFamily: "Asap",
     fontSize: "14px",
    color: "#DC2626",
     margin: 0,
    cursor: "pointer",
},

uncontrolledBox: {
    background: "#fff", 
     display: "inline-block",
    padding: "15px", 
     minWidth: "100px",
    textAlign: "end" as "end",
     boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "8px",
},

dot: {
    height: "8px", 
    width: "8px",
    borderRadius: "100%",
    background: "#18B80A",
    display: "inline-flex",
    position: "relative" as "relative",
    left: "-5px",
},

redDot: {
  height: "8px", 
  width: "8px",
  borderRadius: "100%",
  background: "#E90026",
  display: "inline-flex",
  position: "relative" as "relative",
  left: "-5px",
},

formInputFieldContainer: {
    padding: '0px 12px 0px 12px', 
    width: '100%',
    border: '1px solid #CDCDCD', 
    borderRadius: '8px',
    marginTop: "8px", 
    backgroundColor: '#ffffff',
    display: "flex", 
    justifyContent: "space-between",
    alignItems: 'center',
     minHeight: "45px",
    overflow: 'hidden',
},

containerCodesContainer: {
    padding: '0px 12px 0px 12px', 
    width: '100%',
    border: '1px solid #CDCDCD',  
    borderRadius: '8px',
    marginTop: "8px",
     backgroundColor: '#ffffff',
    display: "flex", 
    justifyContent: "flex-start",
    alignItems: 'center', 
    overflow: 'hidden',
    minHeight: "45px",
},

formFieldText: 
{
    fontSize: "14px", 
    fontWeight: 500,
    fontFamily: "Asap", 
    color: "#1E1E1E",
    textTransform: "uppercase" as "uppercase",
     flex: 1,
},

dayBeforeField: {
    display: 'flex', 
    width: '150px',
    marginRight: '-12px', 
    backgroundColor: "#E9E9E9",
    fontWeight: 900, 
    alignItems: 'center',
    justifyContent: 'center', 
    borderLeft: '1px solid #CDCDCD',
    padding: '12px 0',
},

rateMainContainer: {
    display: 'flex',
     alignItems: 'center',
    justifyContent: "center",
     width: '101%',
    marginTop: "8px",
},

rateLeftContainer: {
    padding: '0px 12px 0px 12px',
     width: '15%',
    border: '1px solid #CDCDCD', 
    borderRadius: '8px',
    backgroundColor: '#ffffff',
     display: "flex",
    marginTop: "0px", 
    justifyContent: "center",
    alignItems: 'center', 
    borderTopRightRadius: '1px',
    borderBottomRightRadius: '1px',
},

rateRightContainer: {
    padding: '0px 12px 0px 12px', 
    width: '85%',
    border: '1px solid #CDCDCD', 
    borderRadius: '8px',
    marginTop: "-1px", 
    backgroundColor: '#ffffff',
    display: "flex",
     justifyContent: "space-between",
    alignItems: 'center', 
    overflow: 'hidden',
    borderTopLeftRadius: '1px',
     borderBottomLeftRadius: '1px',
},

rateTitleText: {
    fontSize: "14px", 
    fontWeight: 700,
    fontFamily: "Asap", 
    color: "#1E1E1E",
},

rateValueText: {
    fontSize: "14.3px",
    fontWeight: 500,
    fontFamily: "Asap",
    color: "#1E1E1E",
    flex: 1,
},

rateUnitText: {
    fontSize: "14px", 
    fontWeight: 300,
    fontFamily: "Asap",
    color: "#797979",
},

containerCodes: {
    backgroundColor: "#E3E6ED",
    padding: "6px 12px",
    borderRadius: '20px',
    margin: '5px',
    fontWeight:900,
}

}

const StyledBox = withStyles((theme) => ({
    root: {
      borderRadius: 8,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "20px 110px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      cursor: "pointer",
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        borderColor: "#000",
      },
    },
}))(Box);

const DateBox = withStyles((theme) => ({
    root: {
      "& .DateRangePicker":{
      },
      "& .e-date-range-wrapper":{
        border:"1px solid #ced4da !important",
        padding:"5px 9px",
        marginTop: "23px !important",
        borderRadius: "6px",
        fontFamily:'Asap !important'
      },
      "& ::before, ::after":{
        background:"transparent !important"
      },
      
    },
    
    "& .e-day-span":{
      background:"yellow !important"
    }
  }))(Box);
  
const StyledChip = withStyles((theme) => ({
root: {
    background: "blue",
    color: "white",
    fontFamily:"Asap, sans-serif",
    fontSize:"14px",
    "& .MuiChip-deleteIcon": {
    color: "rgba(255, 255, 255, 1)",
    },
},
}))(Chip);

class SellerPendingOffers extends SellerPendingOffersController {

    NewListingSchema = () => {
      const firstContainerActive = this.state.offeredQuantity != "" || 
                                    this.state.offeredRate != "" || 
                                    (this.state.offeredTermsOne?.length > 0 && this.state.offeredTermsOne?.toLowerCase() != "undefined") || 
                                  (this.state.offeredTermsTwo?.length > 0 && this.state.offeredTermsTwo?.toLowerCase() != "undefined")
    const secondContainerActive = this.state.secondOfferedQuantity != "" || 
                                  this.state.secondOfferedrate != "" || 
                                  (this.state.secondOfferedTermsOne?.length > 0 && this.state.secondOfferedTermsOne?.toLowerCase() != "undefined") || 
                                  (this.state.secondOfferedTermsTwo?.length > 0 && this.state.secondOfferedTermsTwo?.toLowerCase() != "undefined")
        
        return Yup.object()
              .shape({
            PRINCIPALORGANISATION: Yup.string().required("PRINCIPAL Organisation Name Required"),
            VESSEL: Yup.string().required("VESSEL Required"), 
            VOYAGE: Yup.string().required("VOYAGE Required"),
            POD: Yup.string().required("POD Required"),
            PODTERMINAL: Yup.string().required("POD Terminal Required"), 
            POL: Yup.string().required("POL Required"),
            POLTERMINAL: Yup.string().required("POL Terminal Required"),
            ETBATPOL: Yup.string().required("ETB AT POL Required"), 
            ETAATPOD: Yup.string().required("ETA AT POD Required"),
            QUANTITYOFTEUSAVAILABLEFORSALE: Yup.string().required("QUANTITY Of TEUS Available For Sale Required"), 
            VALIDITYOFLISTING: Yup.string().required("Validity Of Offer Required"),
            
            ...( ( firstContainerActive || (!this.state.sellerViewOfferData.attributes?.offer_sec_quantity && !secondContainerActive)) && {
              TOTALQUANTITY: Yup.number().min(1, "Please add value more than 0")
              .max(this.state.sellerViewOfferData.attributes?.requested_quantity[0]?.quantity, "Offered Quantity can't be more than Requested Quantity")
              .required("Offered Quantity Required"),
              OFFEREDRATE: Yup.number().min(1, "Please add value more than 0").required("Seller Price Required"),
              OFFEREDTERM: Yup.string().required("Terms Required"), 
              OFFEREDTERMTWO: Yup.string().required("Terms Required"),
            }),
            ...(secondContainerActive && {
              TOTALQUANTITYTWO: Yup.number().min(1, "Please add value more than 0")
              .max(this.state.sellerViewOfferData.attributes?.requested_quantity[1]?.quantity, "Offered Quantity can't be more than Requested Quantity")
              .required("Offered Quantity Required"),
              OFFEREDRATETWO: Yup.number().min(1, "Please add value more than 0").required("Seller Price Required"), 
              OFFEREDTERMTHREE: Yup.string().required("Terms Required"),
              OFFEREDTERMFOUR: Yup.string().required("Terms Required"),
            }),
          });
    };

    ErrorRender = (
      ErrorMessage: string | undefined, 
      TextShow: boolean
      ) => {
        return (
            <Box className="ErrorTextContainer">
                <ProjectText className={`TextError ${TextShow ? "" : "TextHidden"}`}> {typeof ErrorMessage === "string" ? ErrorMessage : ""} </ProjectText>
            </Box>
        );
    };

    renderVasselBlock = (
        classes: {
          positionRelative: any,
          daysBeforeETB: any,
          formInputFieldContainer: any,
          formFieldText: any,
          dayBeforeField: any
        }
      ) => {
        const givenDateString = this.state.sellerViewOfferData.attributes?.etb_pol;
        const givenDate = moment(givenDateString);
        const newDate = givenDate.subtract(this.state.sellerViewOfferData.attributes?.listing_validity,'days').parseZone();
        const newDateString = newDate.format('DD-MMM-YYYY');
        return (
          <section>
            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="VESSEL">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    VESSEL
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    {String(this.state.sellerViewOfferData.attributes?.vessel_code)?.toUpperCase()}
                  </h2>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="VESSEL">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    VOYAGE
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    IN {String(this.state.sellerViewOfferData.attributes?.in_voyage)?.toUpperCase()} OUT {String(this.state.sellerViewOfferData.attributes?.in_voyage)?.toUpperCase()}
                  </h2>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="POL">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        fontweight="900"
                        textcolor="black"
                      >
                        POL
                      </ProjectText>
                    </InputLabel>
                    <div className={classes.formInputFieldContainer}>
                      <h2 className={classes.formFieldText}>
                        {String(this.state.sellerViewOfferData.attributes?.pol)?.toUpperCase()}
                      </h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="POLTERMINAL">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        fontweight="900"
                        textcolor="black"
                      >
                        POL TERMINAL
                      </ProjectText>
                    </InputLabel>
                    <div className={classes.formInputFieldContainer}>
                      <h2 className={classes.formFieldText}>
                        {String(this.state.sellerViewOfferData.attributes?.pol_terminal)?.toUpperCase()}
                      </h2>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="POD">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        fontweight="900"
                        textcolor="black"
                      >
                        POD
                      </ProjectText>
                    </InputLabel>
                    <div className={classes.formInputFieldContainer}>
                      <h2 className={classes.formFieldText}>
                        {String(this.state.sellerViewOfferData.attributes?.pod)?.toUpperCase()}
                      </h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="PODTERMINAL">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        fontweight="900"
                        textcolor="black"
                      >
                        POD TERMINAL
                      </ProjectText>
                    </InputLabel>
                    <div className={classes.formInputFieldContainer}>
                      <h2 className={classes.formFieldText}>
                        {String(this.state.sellerViewOfferData.attributes?.pod_terminal)?.toUpperCase()}
                      </h2>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2} style={{ marginBottom: '15px' }}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="ETBATPOL">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    ETB AT POL
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    {String(moment.parseZone(this.state.sellerViewOfferData.attributes?.etb_pol).format('DD-MMM-YYYY hh:mm A'))?.toUpperCase()}
                  </h2>
                  <img src={calender} alt="Calender icon" width="20px" height="20px" />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="ETAATPOD">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    ETA AT POL
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    {String(moment.parseZone(this.state.sellerViewOfferData.attributes?.eta_pod).format('DD-MMM-YYYY hh:mm A'))?.toUpperCase()}
                  </h2>
                  <img src={calender} alt="Calender icon" height="20px" width="20px" />
                </div>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="CONTAINERSIZE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    VALIDITY OF OFFER
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    {String(this.state.sellerViewOfferData.attributes?.listing_validity)?.toUpperCase()}
                  </h2>
                  <div className={classes.dayBeforeField}>DAYS BEFORE ETB</div>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="CONTAINERSIZE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    Expiry Date
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    {String(newDateString)?.toUpperCase()}
                  </h2>
                </div>
              </Grid>
            </Grid>
          </section>
        );
      };


    
      renderSecondViewContainer = (classes: any) => {
        return (
          <>
            <Grid spacing={2} container style={{ marginBottom: "15px" }}>
              <Grid item xs={12} lg={4}>
                <InputLabel htmlFor="CONTAINERTYPE">
                  <ProjectText
                    variant="subtitle1"
                    Upercase
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    CONTAINER SIZE 
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    {String(this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes)?.toUpperCase()}
                  </h2>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel htmlFor="CONTAINERTYPE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    Offered Quantity
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    {this.state.sellerViewOfferData.attributes?.offer_sec_quantity?.toString()?.toUpperCase()}
                  </h2>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel htmlFor="CONTAINERSIZE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    AVG. TONS/CONTAINER
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer}>
                  <h2 className={classes.formFieldText}>
                    {this.state.sellerViewOfferData.attributes?.requested_quantity[1]?.tons_per_teu_extra}
                  </h2>
                </div>
              </Grid>
            </Grid>
            <Grid spacing={2} container style={{ marginBottom: "15px" }}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="CONTAINERTYPE">
                  <ProjectText
                    variant="subtitle1"
                    Upercase
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    BUYER PRICE
                  </ProjectText>
                </InputLabel>
                <div className={classes.rateMainContainer}>
                  <div className={classes.rateLeftContainer}>
                    <h2 className={classes.rateTitleText}>USD</h2>
                  </div>
                  <div className={classes.rateRightContainer}>
                    <h2 className={classes.rateValueText}>
                    {this.state.sellerViewOfferData.attributes?.offer_sec_rate_by_buyer ? 
                     `${parseInt(this.state.sellerViewOfferData.attributes?.offer_sec_rate_by_buyer).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                    </h2>
                    <h2 className={classes.rateUnitText}>
                      /{String(this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes)?.toUpperCase()}
                    </h2>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box className={classes.leftBar}>
                  <InputLabel htmlFor="CONTAINERSIZE">
                    <ProjectText
                      Upercase
                      variant="subtitle1"
                      TextSize="14px"
                      fontweight="900"
                      textcolor="black"
                    >
                      Terms
                    </ProjectText>
                  </InputLabel>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <div className={classes.formInputFieldContainer}>
                      <h2 className={classes.formFieldText}>
                        {this.state.sellerViewOfferData.attributes?.offer_sec_terms_buyers?.split("/")[0]?.trim()?.toString()?.toUpperCase()}
                      </h2>
                    </div>
                    <div className={classes.positionRelative}><span className={classes.slashTerms}>/</span></div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div className={classes.formInputFieldContainer}>
                      <h2 className={classes.formFieldText}>
                        {this.state.sellerViewOfferData.attributes?.offer_sec_terms_buyers?.split("/")[1]?.trim()?.toString()?.toUpperCase()}
                      </h2>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: "15px" }} spacing={2}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="CONTAINERTYPE">
                  <ProjectText
                    Upercase
                    TextSize="14px"
                    variant="subtitle1"
                    fontweight="900"
                    textcolor="black"
                  >
                    Seller Price
                  </ProjectText>
                </InputLabel>
                <div className={classes.rateMainContainer}>
                  <div className={classes.rateLeftContainer}>
                    <h2 className={classes.rateTitleText}>USD</h2>
                  </div>
                  <div className={classes.rateRightContainer}>
                    <h2 className={classes.rateValueText}>
                    {this.state.sellerViewOfferData.attributes?.offer_sec_rate_by_seller ? 
                     `${parseInt(this.state.sellerViewOfferData.attributes?.offer_sec_rate_by_seller).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                    </h2>
                    <h2 className={classes.rateUnitText}>
                      /{String(this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes)?.toUpperCase()}
                    </h2>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box className={classes.leftBar}>
                  <InputLabel htmlFor="CONTAINERSIZE">
                    <ProjectText
                      variant="subtitle1"
                      TextSize="14px"
                      Upercase
                      fontweight="900"
                      textcolor="black"
                    >
                      Terms
                    </ProjectText>
                  </InputLabel>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <div className={classes.formInputFieldContainer}>
                      <h2 className={classes.formFieldText}>
                        {this.state.sellerViewOfferData.attributes?.offer_sec_terms_sellers?.split("/")[0]?.trim()?.toString()?.toUpperCase()}
                      </h2>
                    </div>
                    <div className={classes.positionRelative}><span className={classes.slashTerms}>/</span></div>
                  </Grid>
                  <Grid lg={6} item>
                    <div className={classes.formInputFieldContainer}>
                      <h2 className={classes.formFieldText}>
                        {this.state.sellerViewOfferData.attributes?.offer_sec_terms_sellers?.split("/")[1]?.trim()?.toString()?.toUpperCase()}
                      </h2>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      };

      renderSecondContainerOfferedQuantity = (errors: any, touched: any, values: any, setFieldValue: any, validateSeconsContainer: boolean) => {
        return(
          <>
          <LandingPageTextField
                  BoderRadius="10px"
                  inputProps={{ "data-test-id": "TOTALQUANTITYTWO" }}
                  variant="outlined"
                  size="small"
                  FormError={
                    errors.TOTALQUANTITYTWO !== undefined &&
                    touched.TOTALQUANTITYTWO !== undefined && validateSeconsContainer ||  (touched.TOTALQUANTITYTWO !== undefined && validateSeconsContainer && values.TOTALQUANTITYTWO == "")
                  }
                  type="text"
                  value={values.TOTALQUANTITYTWO}
                  id="TOTALQUANTITYTWO"
                  name="TOTALQUANTITYTWO"
                  onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
                  fullWidth
                  onChange={(e) => {
                    this.setState({ offer_sec_quantity: e.target.value, secondOfferedQuantity: e.target.value });
                    this.AddListingFormTextFieldChange(e, setFieldValue, "TOTALQUANTITYTWO");
                  }}
                />
                {this.ErrorRender(
            errors.TOTALQUANTITYTWO || "Offered Quantity Required",
            (errors.TOTALQUANTITYTWO !== undefined && touched.TOTALQUANTITYTWO !== undefined && validateSeconsContainer) ||  (touched.TOTALQUANTITYTWO !== undefined && validateSeconsContainer && values.TOTALQUANTITYTWO == "")
          )}
          </>
        )
      }

      renderSecondContainerOfferedRate = (classes: any, errors: any, touched: any, values: any, setFieldValue: any, validateSeconsContainer: boolean) => {
        return(
          <>
          <section className={classes.leftBar}>
                  <div className={classes.usdDivTransparent}>USD</div>
                  <LandingPageTextField
                    BoderRadius="10px"
                    BorderTopLeftRadius="0px"
                    BorderBottomLeftRadius="0px"
                    inputProps={{ "data-test-id": "OFFEREDRATETWO" }}
                    variant="outlined"
                    size="small"
                    FormError={
                      errors.OFFEREDRATETWO !== undefined &&
                      touched.OFFEREDRATETWO !== undefined && validateSeconsContainer ||  (touched.OFFEREDRATETWO !== undefined && validateSeconsContainer && values.OFFEREDRATETWO == "")
                    }
                    type="text"
                    value={values.OFFEREDRATETWO}
                    id="OFFEREDRATETWO"
                    name="OFFEREDRATETWO"
                    onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
                    fullWidth
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if(!newValue.includes('.')){
                        this.setState({ offer_sec_rate_by_seller: e.target.value, secondOfferedrate: e.target.value });
                        this.AddListingFormTextFieldChange(e, setFieldValue, "OFFEREDRATETWO");
                      }
                    }}
                  />
                </section>
                <div className={classes.positionRelative}><div className={classes.teu}>/{this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes}</div></div>
                {this.ErrorRender(
                  errors.OFFEREDRATETWO || "Seller Price Required",
                  (errors.OFFEREDRATETWO !== undefined && touched.OFFEREDRATETWO !== undefined && validateSeconsContainer) ||  (touched.OFFEREDRATETWO !== undefined && validateSeconsContainer && values.OFFEREDRATETWO == "")
                )}
          </>
        )
      }

      renderSecondUpdateContainer = (classes: any, errors: any, touched: any, values: any, setFieldValue: any, validateSeconsContainer: any) => {
        return (
          <>
            <Grid container spacing={2} style={{ marginBottom: "15px" }}>
              <Grid item xs={12} lg={4}>
                <InputLabel htmlFor="CONTAINERTYPE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    CONTAINER SIZE 
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer} style={{ marginTop: '0px', backgroundColor: "#ECECEC" }}>
                  <h2 className={classes.formFieldText} style={{ lineHeight: '14px' }}>
                    {String(this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes)?.toUpperCase()}
                  </h2>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel htmlFor="CONTAINERTYPE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    Offered Quantity
                  </ProjectText>
                </InputLabel>
                {this.renderSecondContainerOfferedQuantity(errors, touched, values, setFieldValue, validateSeconsContainer)}
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel htmlFor="CONTAINERSIZE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    AVG. TONS/CONTAINER
                  </ProjectText>
                </InputLabel>
                <div className={classes.formInputFieldContainer} style={{ marginTop: '0px', backgroundColor: "#ECECEC" }}>
                  <h2 className={classes.formFieldText} style={{ lineHeight: '14px' }}>
                    {this.state.sellerViewOfferData.attributes?.requested_quantity[1]?.tons_per_teu_extra}
                  </h2>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: "15px" }}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="CONTAINERTYPE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    BUYER PRICE
                  </ProjectText>
                </InputLabel>
                <div className={classes.rateMainContainer} style={{ marginTop: '0px' }}>
                  <div className={classes.rateLeftContainer} style={{ backgroundColor: "#ECECEC" }}>
                    <h2 className={classes.rateTitleText} style={{ lineHeight: "14px" }}>USD</h2>
                  </div>
                  <div className={classes.rateRightContainer} style={{ backgroundColor: "#ECECEC" }}>
                    <h2 className={classes.rateValueText} style={{ lineHeight: "14px" }}>
                    {this.state.sellerViewOfferData.attributes?.offer_sec_rate_by_buyer ? 
                     `${parseInt(this.state.sellerViewOfferData.attributes?.offer_sec_rate_by_buyer).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                    </h2>
                    <h2 className={classes.rateUnitText} style={{ lineHeight: "14px" }}>
                      /{this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes}
                    </h2>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box className={classes.leftBar}>
                  <InputLabel htmlFor="CONTAINERSIZE1">
                    <ProjectText
                      Upercase fontweight="900"
                      variant="subtitle1" TextSize="14px"
                      textcolor="black"
                    >Terms
                    </ProjectText>
                  </InputLabel>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <div className={classes.formInputFieldContainer} style={{ marginTop: '0px', backgroundColor: "#ECECEC" }}>
                      <h2 className={classes.formFieldText} style={{ lineHeight: '14px' }}>
                        {String(this.state.sellerViewOfferData.attributes?.offer_sec_terms_buyers?.split("/")[0]?.trim())?.toUpperCase()}
                      </h2>
                    </div>
                    <div className={classes.positionRelative}><span className={classes.slashTerms}>/</span></div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div className={classes.formInputFieldContainer} style={{ marginTop: '0px', backgroundColor: "#ECECEC" }}>
                      <h2 className={classes.formFieldText} style={{ lineHeight: '14px' }}>
                        {String(this.state.sellerViewOfferData.attributes?.offer_sec_terms_buyers?.split("/")[1]?.trim())?.toUpperCase()}
                      </h2>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: "15px" }}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="CONTAINERTYPE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    SELLER PRICE
                  </ProjectText>
                </InputLabel>
                {this.renderSecondContainerOfferedRate(classes, errors, touched, values, setFieldValue, validateSeconsContainer)}
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box className={classes.leftBar}>
                  <InputLabel htmlFor="CONTAINERSIZE2">
                    <ProjectText
                      Upercase textcolor="black"
                      fontweight="900"
                      variant="subtitle1" TextSize="14px"
                    >
                      Terms </ProjectText>
                  </InputLabel>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}
                  >
                    <LandingPageTextField
                      BoderRadius="10px"
                      inputProps={{ "data-test-id": "OFFEREDTERMTHREE" }}
                      variant="outlined"
                      size="small"
                      FormError={
                        errors.OFFEREDTERMTHREE !== undefined &&
                        touched.OFFEREDTERMTHREE !== undefined && validateSeconsContainer ||  (touched.OFFEREDTERMTHREE !== undefined && validateSeconsContainer && values.OFFEREDTERMTHREE == "")
                      }
                      type="text"
                      value={values.OFFEREDTERMTHREE}
                      id="OFFEREDTERMTHREE"
                      name="OFFEREDTERMTHREE"
                      fullWidth
                      onChange={(e) => {
                        this.setState({ offer_sec_terms_sellers: e.target.value, secondOfferedTermsOne: e.target.value });
                        this.AddListingFormTextFieldChange(e, setFieldValue, "OFFEREDTERMTHREE");
                      }}
                    />
                    <div className={classes.positionRelative}><span className={classes.slashTerms}>/</span></div>
                    {this.ErrorRender(
                                    errors.OFFEREDTERMTHREE || "Terms Required",
                                    (errors.OFFEREDTERMTHREE !== undefined && touched.OFFEREDTERMTHREE !== undefined && validateSeconsContainer) ||  (touched.OFFEREDTERMTHREE !== undefined && validateSeconsContainer && values.OFFEREDTERMTHREE == "")
                                  )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <LandingPageTextField
                      BoderRadius="10px"
                      inputProps={{ "data-test-id": "OFFEREDTERMFOUR" }}
                      variant="outlined"
                      size="small"
                      FormError={
                        errors.OFFEREDTERMFOUR !== undefined &&
                        touched.OFFEREDTERMFOUR !== undefined && validateSeconsContainer ||  (touched.OFFEREDTERMFOUR !== undefined && validateSeconsContainer && values.OFFEREDTERMFOUR == "")
                      }
                      type="text"
                      value={values.OFFEREDTERMFOUR}
                      id="OFFEREDTERMFOUR"
                      name="OFFEREDTERMFOUR"
                      fullWidth
                      onChange={(e) => {
                        this.setState({ offer_sec_terms_sellers2: e.target.value, secondOfferedTermsTwo: e.target.value });
                        this.AddListingFormTextFieldChange(e, setFieldValue, "OFFEREDTERMFOUR");
                      }}
                    />
                    {this.ErrorRender(
                                    errors.OFFEREDTERMFOUR || "Terms Required",
                                    (errors.OFFEREDTERMFOUR !== undefined && touched.OFFEREDTERMFOUR !== undefined && validateSeconsContainer) ||  (touched.OFFEREDTERMFOUR !== undefined && validateSeconsContainer && values.OFFEREDTERMFOUR == "")
                                  )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      };

      VesselRedner = (
        errors: {
          VOYAGE?: string;
          POLTERMINAL?: string;
          ETBATPOL?: string;
          POD?: string;
          VALIDITYOFLISTING?: string;
          PODTERMINAL?: string;
          ETAATPOD?: string;
          VESSEL?: string;
          POL?: string;
          QUANTITYOFTEUSAVAILABLEFORSALE?: string;
          EXPIRYDATE?: string;
        },
        touched: {
          ETBATPOL?: string | boolean;
          ETAATPOD?: string | boolean;
          POL?: string | boolean;
          VOYAGE?: string | boolean;
          POLTERMINAL?: string | boolean;
          PODTERMINAL?: string | boolean;
          QUANTITYOFTEUSAVAILABLEFORSALE?: string | boolean;
          EXPIRYDATE?: string | boolean;
          POD?: string | boolean;
          VALIDITYOFLISTING?: string | boolean;
          VESSEL?: string | boolean;
        },
        values: {
          VESSEL: string;
          POL: string;
          VOYAGE: string;
          POLTERMINAL: string;
          ETAATPOD?: string;
          POD: string;
          PODTERMINAL: string;
          QUANTITYOFTEUSAVAILABLEFORSALE?: string;
          VALIDITYOFLISTING: string;
          EXPIRYDATE?: string;
          ETBATPOL: string;
        },
        setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
      ) => {
        return (
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} lg={6}>
              <InputLabel htmlFor="VESSEL">
                <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                  VESSEL
                </ProjectText>
              </InputLabel>
              <AutocompleteStyle
                id="VESSELAutoSelectId"
                options={this.state.VesselDropDownData}
                onChange={(event: React.ChangeEvent<{}>, value) => {
                  this.AutocompleteOnChange(setFieldValue, value, "VESSEL");
                }}
                onOpen={this.OnOpenDropDown}
                getOptionSelected={(option: unknown, value) => (option as { name: string }).name === values.VESSEL}
                size="small"
                FormError={errors.VESSEL !== undefined && touched.VESSEL !== undefined}
                getOptionLabel={(option: unknown) => (option as { name: string }).name ?? ""}
                inputValue={this.state.VesselDropDownString?.toUpperCase()}
                onInputChange={(event, newInputValue) => {
                  if (event !== null) {
                    setFieldValue("VESSEL", newInputValue);
                    this.setState({
                      VesselDropDownString: newInputValue
                    });
                    this.AddListingFormTextFieldChange(event, setFieldValue, "VESSEL");
                  }
                }}
                data-test-id="VESSEL"
                closeIcon={false}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      data-test-id="VESSELDrop"
                      onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        this.AutocompleteTextfieldChange(event);
                      }}
                      value={values.VESSEL?.toUpperCase()}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Box className="AutoComplateTextField">
                            {this.state.VesselDropDownOpen && this.state.VesselDropDownData.length === 0 || this.state.PortNetAPiCall ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            <ProjectText variant='h6' TextSize='16px'>
                              {params.InputProps.endAdornment}
                            </ProjectText>
                          </Box>
                        )
                      }}
                    />
                  );
                }}
                onClose={this.OnOpenDropDown}
                open={this.state.VesselDropDownOpen}
                clearOnEscape={false}
                clearOnBlur={false}
                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => {
                  return (
                    <ProjectText
                      variant="h6"
                      TextSize="14px"
                      fontweight="500"
                      data-test-id={(option as { name: string }).name}
                    >
                      {(option as { name: string }).name ?? ""}
                    </ProjectText>
                  );
                }}
              />
              {this.ErrorRender(errors.VESSEL, errors.VESSEL !== undefined && touched.VESSEL !== undefined)}
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel htmlFor="VESSEL">
                <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                  VOYAGE
                </ProjectText>
              </InputLabel>
              <AutocompleteStyle
                id="VOyogesdata"
                options={this.state.VoyageDropDownData}
                onChange={(event: React.ChangeEvent<{}>, value) => {
                  this.AutocompleteOnChange(setFieldValue, value, "VOYAGE");
                  this.AddListingFormTextFieldChange(event, setFieldValue, "VOYAGE");
                  this.setState({newVoyageValue: value})
                }}
                onOpen={this.OnOpenDropDownVoyage}
                getOptionSelected={(option: unknown, value) => (option as { inVoyN: string }).inVoyN + (option as { outVoyN: string }).outVoyN === this.state.InVoyogeAndOutVoyoge}
                size="small"
                FormError={errors.VOYAGE !== undefined && touched.VOYAGE !== undefined}
                getOptionLabel={(option: unknown) => `In ${(option as { inVoyN: string }).inVoyN} Out ${(option as { outVoyN: string }).outVoyN}` ?? ""}
                closeIcon={false}
                inputValue={this.state.InVoyogeAndOutVoyoge?.toUpperCase()}
                data-test-id="VOYAGE"
                renderInput={(params) => {
                  return <TextField style={{textTransform: 'uppercase'}} {...params} variant="outlined" data-test-id="VOYAGEDrop" />;
                }}
                onClose={this.OnOpenDropDownVoyage}
                onInputChange={(event, newInputValue) => {
                  if (event !== null) {
                    setFieldValue("VOYAGE", newInputValue);
                    this.setState({
                    InVoyogeAndOutVoyoge: newInputValue
                    });
                  }
                }}
                open={this.state.VoyageDropDownOpen}
                clearOnEscape={false}
                clearOnBlur={false}
                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => {
                  return (
                    <Box data-test-id={(option as { name: string }).name ?? ""}>
                      <ProjectText variant="h6" TextSize=".875rem" fontweight="500">
                        <ProjectText TextSize=".875rem" fontweight="500" variant="caption" textcolor="grey">
                          IN
                        </ProjectText>{" "}
                        {(option as { inVoyN: string }).inVoyN ?? ""} &nbsp; &nbsp;{" "}
                        <ProjectText variant="caption" TextSize=".875rem" fontweight="500" textcolor="grey">
                          OUT
                        </ProjectText>{" "}
                        {(option as { outVoyN: string }).outVoyN ?? ""}
                      </ProjectText>
                    </Box>
                  );
                }}
              />
              {this.ErrorRender(errors.VOYAGE, errors.VOYAGE !== undefined && touched.VOYAGE !== undefined)}
            </Grid>
          </Grid>
        );
      };
      


    renderUpdateVasselBlock = ( 
        errors: any,
        touched: any,
        values: any,
        setFieldValue: ( 
            field: string, 
            value: string, 
            shouldValidate?: boolean
        ) => void,
        classes: any
        ) => {
            const givenDateString = this.state.etb_pol;
            const givenDate = moment(givenDateString);
            const newDate = givenDate.subtract(this.state.listing_validity, 'days').parseZone();
            const newDateString = newDate.format('DD-MMM-YYYY');
            let samplePolData = this.state.polData?.map((item: any) => { return item?.attributes?.port_code});
            let uniquePolData = [... new Set(samplePolData)]
            let samplePodData = this.state.polData?.map((item: any) => {return item?.attributes?.port_code});
            let uniquePodData = [... new Set(samplePodData)]
            let tempPodData = [... new Set(this.state.tempPodTerminal)]
            
            
        return (
        <section>
            {this.VesselRedner(
                errors, 
                touched, 
                values, 
                setFieldValue)
            }
                <Grid 
                    container 
                    spacing={2} 
                    style={{ 
                        marginBottom: "15px" 
                        }}
                    > 
                    <Grid 
                        item 
                        xs={12}
                        lg={6}
                    > 
                        <Grid 
                            container 
                            spacing={1}
                        >
                            <Grid 
                                item 
                                xs={12}
                                lg={6}
                            > 
                                <InputLabel htmlFor="POL"> 
                                    <ProjectText 
                                        Upercase 
                                        variant="subtitle1" 
                                        TextSize="14px" 
                                        fontweight="900" 
                                        textcolor="black"  
                                    > 
                                        POL 
                                    </ProjectText>
                                </InputLabel>
                                <ProjectTextField 
                                    variant="outlined" 
                                    fullWidth  
                                    defaultValue="all" 
                                    FormError={
                                        errors.POL !== undefined && touched.POL !== undefined
                                    } 
                                    disabled BgColor="#ECECEC"
                                    value={values.POL} 
                                    id="POL" 
                                    name="POL" 
                                    inputProps={{ 
                                        "data-test-id": "POL" 
                                    }}
                                    onChange={(
                                        e: React.ChangeEvent<{ value: unknown }>
                                        ) => {
                                            this.setState({ pol: e.target.value});
                                            this.AddListingFormTextFieldChange(e, setFieldValue, "POL"); 
                                    }}
                                >
                                    {uniquePolData?.map((
                                        vessel_code: any, index: number
                                        ) => {
                                            return ( 
                                                <OptionForSelect 
                                                    value={String(vessel_code)} 
                                                    key={index}
                                                > 
                                                    <ProjectText 
                                                        variant="h6" 
                                                        TextSize="14px" 
                                                        fontweight="500" 
                                                    >
                                                        {vessel_code}
                                                    </ProjectText>
                                                </OptionForSelect>
                                                );
                                            } 
                                    )
                                }
                                </ProjectTextField>
                            </Grid>
                            <Grid 
                                item 
                                xs={12}
                                lg={6}
                            > 
                                <InputLabel htmlFor="POLTERMINAL">
                                    <ProjectText 
                                        Upercase 
                                        variant="subtitle1" 
                                        TextSize="14px" 
                                        fontweight="900" 
                                        textcolor="black"
                                    >
                                        POL TERMINAL
                                    </ProjectText>
                                </InputLabel>
                                <ProjectTextField 
                                    variant="outlined" 
                                    fullWidth  
                                    defaultValue="all" 
                                    disabled
                                    BgColor="#ECECEC"
                                    FormError={
                                        errors.POLTERMINAL !== undefined && touched.POLTERMINAL !== undefined
                                    } 
                                    value={values.POLTERMINAL} 
                                    id="POLTERMINAL" 
                                    name="POLTERMINAL" 
                                    inputProps={{ 
                                        "data-test-id": "POLTERMINAL" 
                                    }}
                                    onChange={(
                                        e: React.ChangeEvent<{ value: unknown }>
                                        ) => {
                                            this.setState({ pol_terminal: e.target.value });
                                            this.AddListingFormTextFieldChange(e, setFieldValue, "POLTERMINAL"); 
                                            }
                                        }
                                >
                                    {tempPodData?.map((
                                        vessel_code: any, 
                                        index: number
                                        ) => {
                                            return ( 
                                                <OptionForSelect 
                                                    value={String(vessel_code)} 
                                                    key={index}
                                                > 
                                                    <ProjectText 
                                                        variant="h6" 
                                                        TextSize="14px" 
                                                        fontweight="500" 
                                                    >
                                                        {vessel_code}
                                                    </ProjectText>
                                                </OptionForSelect>
                                            );
                                        } 
                                    )
                                }
                                </ProjectTextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid 
                        item 
                        xs={12}
                        lg={6}
                    >
                        <Grid 
                            container 
                            spacing={3}
                        >
                            <Grid 
                                item 
                                xs={12}
                                lg={6}
                            >
                                <InputLabel htmlFor="POD">
                                    <ProjectText 
                                        Upercase 
                                        variant="subtitle1" 
                                        TextSize="14px" 
                                        fontweight="900" 
                                        textcolor="black"
                                    > 
                                        POD 
                                    </ProjectText>
                                </InputLabel>
                                <ProjectTextField 
                                    BgColor="#ECECEC" variant="outlined" 
                                    fullWidth  
                                    defaultValue="all" 
                                    FormError={
                                        errors.POD !== undefined && touched.POD !== undefined
                                    } 
                                    value={values.POD} 
                                    id="POD" 
                                    name="POD" 
                                    inputProps={{ 
                                        "data-test-id": "POD" 
                                    }}
                                    disabled
                                    onChange={(
                                        e: React.ChangeEvent<{ value: unknown }>
                                        ) => { 
                                            this.setState({ pod: e.target.value });
                                            this.AddListingFormTextFieldChange(e, setFieldValue, "POD"); 
                                            }
                                    }
                                >
                                    {uniquePodData?.map(
                                        (vessel_code: any, index: number) => {
                                            return ( 
                                                <OptionForSelect 
                                                    value={String(vessel_code)} 
                                                    key={index}
                                                > 
                                                    <ProjectText 
                                                        variant="h6" 
                                                        TextSize="14px" 
                                                        fontweight="500" 
                                                    >
                                                        {vessel_code}
                                                    </ProjectText>
                                                </OptionForSelect>
                                            );
                                        } 
                                    )
                                } 
                                </ProjectTextField> 
                            </Grid>
                            <Grid 
                                item 
                                xs={12}
                                lg={6}
                            > 
                                <InputLabel htmlFor="PODTERMINAL"> 
                                    <ProjectText 
                                        Upercase 
                                        variant="subtitle1" 
                                        TextSize="14px" 
                                        fontweight="900" 
                                        textcolor="black" 
                                    >
                                        POD TERMINAL
                                    </ProjectText>
                                </InputLabel>
                                <ProjectTextField 
                                    BgColor="#ECECEC"
                                    variant="outlined" 
                                    fullWidth  
                                    defaultValue={
                                        this.state.pod_terminal  || "all"
                                    } 
                                    FormError={errors.PODTERMINAL !== undefined && touched.PODTERMINAL !== undefined} 
                                    value={values.PODTERMINAL} 
                                    id="PODTERMINAL" 
                                    disabled
                                    name="PODTERMINAL" 
                                    inputProps={{ 
                                        "data-test-id": "PODTERMINAL" 
                                    }}
                                    onChange={(
                                        e: React.ChangeEvent<{ value: unknown }>
                                        ) => { 
                                            this.setState({ pod_terminal: e.target.value });
                                            this.AddListingFormTextFieldChange(e, setFieldValue, "PODTERMINAL"); 
                                            }
                                    }
                                >
                                {tempPodData?.map(
                                    (vessel_code: any, index: number) => {
                                        return ( 
                                            <OptionForSelect 
                                                value={String(vessel_code)} 
                                                key={index}
                                            > 
                                                <ProjectText 
                                                    variant="h6" 
                                                    TextSize="14px" 
                                                    fontweight="500" 
                                                >
                                                    {vessel_code}
                                                </ProjectText>
                                            </OptionForSelect>
                                            );
                                        }
                                    )
                                }
                                </ProjectTextField> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    spacing={2} 
                    style={{ 
                        marginBottom: "15px" 
                        }}
                >
                    <Grid 
                        item 
                        xs={12}
                        lg={6}
                    >
                        <InputLabel htmlFor="ETBATPOL">
                            <ProjectText 
                                Upercase 
                                variant="subtitle1" 
                                TextSize="14px" 
                                fontweight="900" 
                                textcolor="black"
                            >
                                ETB AT POL
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField 
                            BoderRadius="10px" 
                            inputProps={{ 
                                "data-test-id": "ETBATPOL",  
                                "min": moment().format("YYYY-MM-DD"),
                            }} 
                            variant="outlined" 
                            size="small" 
                            FormError={errors.ETBATPOL !== undefined && touched.ETBATPOL !== undefined} 
                            type="Date" 
                            value={moment(this.state.etb_pol).format("YYYY-MM-DD")} 
                            defaultValue="2023-01-01" 
                            id="ETBATPOL" 
                            name="ETBATPOL" 
                            fullWidth
                            onChange={( 
                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> 
                                ) => { 
                                    this.setState({etb_pol: e.target.value}) 
                                }
                            }
                        />
                    </Grid> 
                    <Grid 
                        item 
                        xs={12}
                        lg={6}
                    > 
                        <InputLabel htmlFor="ETAATPOD">
                            <ProjectText 
                                Upercase 
                                variant="subtitle1" 
                                TextSize="14px" 
                                fontweight="900" 
                                textcolor="black" 
                            > 
                                ETA AT POL 
                            </ProjectText> 
                        </InputLabel>
                        <LandingPageTextField 
                            BoderRadius="10px" 
                            inputProps={{ 
                                "data-test-id": "ETAATPOD",  
                                "min" :  moment(this.state.etb_pol).format("YYYY-MM-DD"), 
                            }} 
                            variant="outlined" 
                            size="small" 
                            FormError={errors.ETAATPOD !== undefined && touched.ETAATPOD !== undefined} 
                            type="Date" 
                            value={moment(this.state.eta_pod).format("YYYY-MM-DD")} 
                            id="ETAATPOD" 
                            name="ETAATPOD" 
                            fullWidth
                            onChange={( 
                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> 
                                ) => {
                                    this.setState({eta_pod: e.target.value}); 
                                    this.AddListingFormTextFieldChange(e, setFieldValue, "ETAATPOD"); 
                                }
                            } 
                        />
                    </Grid> 
                </Grid>
                <Grid 
                    container 
                    spacing={2}
                > 
                    <Grid 
                        item 
                        xs={12}
                        lg={6}
                    > 
                        <InputLabel htmlFor="CONTAINERSIZE"> 
                            <ProjectText 
                                Upercase 
                                variant="subtitle1" 
                                TextSize="14px" 
                                fontweight="900" 
                                textcolor="black" 
                            > 
                                VALIDITY OF OFFER
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField 
                            BoderRadius="10px" 
                            inputProps={{ 
                                "data-test-id": "AVERAGETONSBOX", 
                            }} 
                            variant="outlined" 
                            size="small" 
                            FormError={errors.VALIDITYOFLISTING !== undefined && touched.VALIDITYOFLISTING !== undefined } 
                            type="text" 
                            value={this.state.listing_validity} 
                            id="VALIDITYOFLISTING" 
                            name="VALIDITYOFLISTING" 
                            fullWidth
                            onChange={( 
                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> 
                                ) => { 
                                    this.setState({listing_validity: e.target.value}); 
                                    this.AddListingFormTextFieldChange(e, setFieldValue, "VALIDITYOFLISTING"); 
                                }
                            }
                         />
                          <div className={classes.positionRelative}> 
                            <div className={classes.daysBeforeETB}>
                                DAYS BEFORE ETB
                            </div>
                        </div>
                         {this.ErrorRender(
                                    errors.VALIDITYOFLISTING,
                                    errors.VALIDITYOFLISTING !== undefined &&
                                    touched.VALIDITYOFLISTING !== undefined 
                                )}
                       
                    </Grid> 
                    <Grid 
                        item 
                        xs={12}
                        lg={6}
                    > 
                        <InputLabel htmlFor="CONTAINERSIZE"> 
                            <ProjectText 
                                Upercase 
                                variant="subtitle1" 
                                TextSize="14px" 
                                fontweight="900" 
                                textcolor="black"
                            >
                                Expiry Date
                            </ProjectText>
                        </InputLabel>
                        <div 
                            className={classes.formInputFieldContainer} 
                            style={{
                                marginTop: '0px', 
                                backgroundColor: "#ECECEC"
                            }}
                        >
                            <h2 
                                className={classes.formFieldText} 
                                style={{lineHeight: '14px'}}
                            > 
                                {String(newDateString)?.toUpperCase()} 
                            </h2> 
                        </div> 
                    </Grid>
                </Grid> 
            </section>
        ); 
    }; 

    renderUpdateOfferModal = (classes: any) => {
        if(this.state.addListing && this.state.sellerViewOfferData?.attributes  && this.state.isUpdating){
            return(<> {this.updateOfferModal(classes)} </>)
        }
    }

    renderFirstContainerOfferedQuantity = (errors: any, touched: any, values: any, setFieldValue: any, validateFirstContainer: boolean) => {
      return(
        <>
        <LandingPageTextField 
          BoderRadius="10px" 
          inputProps={{ 
              "data-test-id": "TOTALQUANTITY", 
          }}
          variant="outlined"
          size="small"
          FormError={
            errors.TOTALQUANTITY !== undefined &&
            touched.TOTALQUANTITY !== undefined && validateFirstContainer ||  (touched.TOTALQUANTITY !== undefined && validateFirstContainer && values.TOTALQUANTITY == "")
          }
          type="text" 
          value={values.TOTALQUANTITY} 
          id="TOTALQUANTITY" 
          name="TOTALQUANTITY" 
          fullWidth
          onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
          onChange={
              (e) =>{
                  this.setState({offer_quantity: e.target.value, offeredQuantity: e.target.value}); 
                  this.AddListingFormTextFieldChange(e, setFieldValue, "TOTALQUANTITY");
              }
          }
      />
      {this.ErrorRender(
        errors.TOTALQUANTITY || "Offered Quantity Required",
        (errors.TOTALQUANTITY !== undefined && touched.TOTALQUANTITY !== undefined && validateFirstContainer) ||  (touched.TOTALQUANTITY !== undefined && validateFirstContainer && values.TOTALQUANTITY == "")
      )}
        </>
      )
    }

    renderFirstContainerOfferedRate = (classes: any, errors: any, touched: any, values: any, setFieldValue: any, validateFirstContainer: boolean) => {
      return(
        <>
          <section className={classes.leftBar}>
            <div className={classes.usdDivTransparent}>
                USD
            </div>
            <LandingPageTextField 
                BoderRadius="10px" 
                BorderTopLeftRadius="0px" 
                BorderBottomLeftRadius="0px" 
                inputProps={{ 
                    "data-test-id": "OFFEREDRATE", 
                }} 
                variant="outlined" 
                size="small" 
                FormError={
                  errors.OFFEREDRATE !== undefined &&
                  touched.OFFEREDRATE !== undefined && validateFirstContainer ||  (touched.OFFEREDRATE !== undefined && validateFirstContainer && values.OFFEREDRATE == "")
                }type="text" 
                value={values.OFFEREDRATE} 
                id="OFFEREDRATE" 
                name="OFFEREDRATE" 
                onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
                fullWidth
                onChange={(e) =>{
                  const newValue = e.target.value;
                  if(!newValue.includes('.')){
                    this.setState({offer_seller_rate: e.target.value, offeredRate: e.target.value}); 
                    this.AddListingFormTextFieldChange(e, setFieldValue, "OFFEREDRATE"); 
                  }}
                }
            />
        </section>
        <div className={classes.positionRelative}>
            <div className={classes.teu}>
                /{this.state.sellerViewOfferData.attributes?.offer_container_sizes}
            </div> 
        </div>
        {this.ErrorRender(
                        errors.OFFEREDRATE || "Seller Price Required",
                        (errors.OFFEREDRATE !== undefined && touched.OFFEREDRATE !== undefined && validateFirstContainer) ||  (touched.OFFEREDRATE !== undefined && validateFirstContainer && values.OFFEREDRATE == "")
                      )}
        </>
      )
    }

    renderFirstContainerTermOne = (classes: any, errors: any, touched: any, values: any, setFieldValue: any, validateFirstContainer: boolean) => {
      return(
        <>
          <LandingPageTextField 
            BoderRadius="10px" 
            inputProps={{
                "data-test-id": "OFFEREDTERM",
            }} 
            variant="outlined" 
            size="small" 
            FormError={
              errors.OFFEREDTERM !==
              undefined &&
              touched.OFFEREDTERM !== undefined && validateFirstContainer ||  (touched.OFFEREDTERM !== undefined && validateFirstContainer && values.OFFEREDTERM == "")
            }
            type="text" 
            value={values.OFFEREDTERM} 
            id="OFFEREDTERM" 
            name="OFFEREDTERM" 
            fullWidth
            onChange={(e) =>{
                this.setState({offer_terms_sellers: e.target.value, offeredTermsOne: e.target.value}); 
                this.AddListingFormTextFieldChange(e, setFieldValue, "OFFEREDTERM");
              }
            }
        />
        <div className={classes.positionRelative}>
            <span className={classes.slashTerms}>/</span>
        </div>
        {this.ErrorRender(
                    errors.OFFEREDTERM || "Terms Required",
                    (errors.OFFEREDTERM !== undefined && touched.OFFEREDTERM !== undefined && validateFirstContainer) ||  (touched.OFFEREDTERM !== undefined && validateFirstContainer && values.OFFEREDTERM == "")
                  )}
        </>
      )
    }

    renderFirstContainerTermTwo = (errors: any, touched: any, values: any, setFieldValue: any, validateFirstContainer: boolean) => {
      return(
        <>
        <LandingPageTextField 
                                BoderRadius="10px" 
                                inputProps={{ 
                                    "data-test-id": "OFFEREDTERMTWO", 
                                }} 
                                variant="outlined" 
                                size="small" 
                                FormError={
                                  errors.OFFEREDTERMTWO !==
                                  undefined &&
                                  touched.OFFEREDTERMTWO !== undefined && validateFirstContainer ||  (touched.OFFEREDTERMTWO !== undefined && validateFirstContainer && values.OFFEREDTERMTWO == "")
                                }
                                type="text" 
                                value={values.OFFEREDTERMTWO} 
                                id="OFFEREDTERMTWO" 
                                name="OFFEREDTERMTWO" 
                                fullWidth
                                onChange={(e) =>{
                                    this.setState({offer_terms_sellers2: e.target.value, offeredTermsTwo: e.target.value}); 
                                    this.AddListingFormTextFieldChange(e, setFieldValue, "OFFEREDTERMTWO"); 
                                  }
                                }
                            />
                            {this.ErrorRender(
                                        errors.OFFEREDTERMTWO || "Terms Required",
                                        (errors.OFFEREDTERMTWO !== undefined && touched.OFFEREDTERMTWO !== undefined && validateFirstContainer) ||  (touched.OFFEREDTERMTWO !== undefined && validateFirstContainer && values.OFFEREDTERMTWO == "")
                                      )}
        </>
      )
    }

    renderUpdateOfferForm = (errors: any,touched: any,setFieldValue: any,handleSubmit: any, isValid: any ,values: any, classes: any) => {
      const firstContainerActive = this.state.offeredQuantity != "" || this.state.offeredRate != "" || (this.state.offeredTermsOne?.length > 0 && this.state.offeredTermsOne?.toLowerCase() != "undefined") || 
      (this.state.offeredTermsTwo?.length > 0 && this.state.offeredTermsTwo?.toLowerCase() != "undefined")
    const secondContainerActive = this.state.secondOfferedQuantity != "" || this.state.secondOfferedrate != "" || (this.state.secondOfferedTermsOne?.length > 0 && this.state.secondOfferedTermsOne?.toLowerCase() != "undefined") || 
    (this.state.secondOfferedTermsTwo?.length > 0 && this.state.secondOfferedTermsTwo?.toLowerCase() != "undefined")
    const validateFirstContainer = firstContainerActive || (!this.state.sellerViewOfferData.attributes?.offer_sec_quantity && !secondContainerActive)
    const validateSeconsContainer = secondContainerActive
    
    return(
        <form 
            onSubmit={handleSubmit} 
            autoComplete="off" 
            className="FormOrganisationDetails" 
        >
            <Box className={classes.addListingModal}> 
                <Box 
                    className={classes.close} 
                    onClick={this.handleClose} 
                    data-test-id="close-btn"
                >
                    <img src={close} alt="" /> 
                </Box>
                <h1 className={classes.title}>
                    Update Offer
                </h1>
                <section 
                    className={classes.organisation}
                >
                    <ProjectText 
                        fontweight="800" 
                        TextSize="15px" 
                        textcolor="#000"
                    >
                        REQUEST ID
                    </ProjectText>
                    <ProjectText 
                        TextSize="14px" 
                        textcolor="#000"
                    >
                        {this.state.sellerViewOfferData?.attributes?.request_uid}
                    </ProjectText>
                    <InputLabel 
                        htmlFor="PRINCIPALORGANISATION" 
                        style={{ marginTop: "20px" }}
                    >
                        <ProjectText 
                            Upercase 
                            variant="subtitle1" 
                            TextSize="14px" 
                            fontweight="900" 
                            textcolor="black"
                        >
                            PRINCIPAL ORGANISATION NAME
                        </ProjectText>
                    </InputLabel>
                    <div className={classes.formInputFieldContainer}>
                        <h2 className={classes.formFieldText}>
                            {this.state.sellerViewOfferData.attributes?.principal_organization_name || this.state.sellerViewOfferData.attributes?.organization_code}
                        </h2>
                    </div>
                </section>
                <section className={classes.vesselContainer}>
                    <h3 className={classes.vesselHeading}>
                        Port & Vessel Information
                    </h3>
                    {this.renderUpdateVasselBlock(errors,touched,values,setFieldValue,classes)}
                </section>
                <section className={classes.vesselContainer}>
                    <h3 className={classes.vesselHeading}>
                        Container & Pricing Details
                    </h3>
                    <Grid 
                        container 
                        spacing={2} 
                        style={{ marginBottom: "15px" }}
                    >
                        <Grid 
                            item 
                            xs={12}
                            lg={6}
                        >
                            <InputLabel htmlFor="CONTAINERTYPE">
                                <ProjectText 
                                    Upercase 
                                    variant="subtitle1" 
                                    TextSize="14px" 
                                    fontweight="900" 
                                    textcolor="black"
                                >
                                    CONTAINER STATUS
                                </ProjectText>
                            </InputLabel>
                            <div 
                                className={classes.formInputFieldContainer} 
                                style={{marginTop: "0px", backgroundColor: "#ECECEC"}}
                            >
                                <h2 className={classes.formFieldText}>
                                    {String(this.state.sellerViewOfferData.attributes?.offer_container_type)?.toUpperCase()}
                                </h2>
                            </div>
                        </Grid>
                        <Grid 
                            item 
                            xs={12}
                            lg={6}
                        >
                            <InputLabel htmlFor="EMAILADDRESS"> 
                                <ProjectText 
                                    Upercase 
                                    variant="subtitle1" 
                                    TextSize="14px" 
                                    fontweight="900" 
                                    textcolor="black"
                                >
                                    Container Types
                                </ProjectText>
                            </InputLabel>
                            <div 
                                className={classes.containerCodesContainer} 
                                style={{marginTop: '0px', backgroundColor: "#ECECEC"}}
                            >
                                {this.state.sellerViewOfferData.attributes?.offer_container_codes?.map(
                                    (item: string) => {
                                        return(<div className={classes.containerCodes}>{item}</div>)
                                        }
                                    )
                                }
                            </div>
                        </Grid> 
                    </Grid>
                    <Grid 
                        container 
                        spacing={2} 
                        style={{ marginBottom: "15px" }}
                    >
                        <Grid 
                            item 
                            xs={12}
                            lg={4}
                        > 
                            <InputLabel htmlFor="CONTAINERTYPE">
                                <ProjectText 
                                    Upercase 
                                    variant="subtitle1" 
                                    TextSize="14px" 
                                    fontweight="900" 
                                    textcolor="black"
                                >
                                    CONTAINER SIZE 
                                </ProjectText>
                            </InputLabel>
                            <div 
                                className={classes.formInputFieldContainer} 
                                style={{marginTop: '0px', backgroundColor: "#ECECEC"}}
                            >
                                <h2 
                                    className={classes.formFieldText} 
                                    style={{lineHeight: '14px'}}
                                >
                                    {String(this.state.sellerViewOfferData.attributes?.offer_container_sizes)?.toUpperCase()}
                                </h2> 
                            </div>
                        </Grid>
                        <Grid 
                            item 
                            xs={12}
                            lg={4}
                        >
                            <InputLabel htmlFor="CONTAINERTYPE"> 
                                <ProjectText 
                                    Upercase 
                                    variant="subtitle1" 
                                    TextSize="14px" 
                                    fontweight="900" 
                                    textcolor="black"
                                >
                                    Offered Quantity
                                </ProjectText>
                            </InputLabel>
                            {this.renderFirstContainerOfferedQuantity(errors, touched, values, setFieldValue, validateFirstContainer)}
                        </Grid>
                        <Grid 
                            item 
                            xs={12}
                            lg={4}
                        >
                            <InputLabel htmlFor="CONTAINERSIZE">
                                <ProjectText 
                                    Upercase 
                                    variant="subtitle1" 
                                    TextSize="14px" 
                                    fontweight="900" 
                                    textcolor="black"
                                >
                                    AVG. TONS/CONTAINER
                                </ProjectText>
                            </InputLabel>
                            <div 
                                className={classes.formInputFieldContainer} 
                                style={{marginTop: '0px', backgroundColor: "#ECECEC"}}
                            >
                                <h2 
                                    className={classes.formFieldText} 
                                    style={{lineHeight: '14px'}}
                                >
                             {this.state.sellerViewOfferData.attributes?.requested_quantity[0]?.tons_per_teu_extra}

                                </h2>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid 
                        container 
                        spacing={2} 
                        style={{ marginBottom: "15px" }}
                    >
                        <Grid item xs={12} lg={6}>
                            <InputLabel htmlFor="CONTAINERTYPE"> 
                                <ProjectText 
                                    Upercase 
                                    variant="subtitle1" 
                                    TextSize="14px" 
                                    fontweight="900" 
                                    textcolor="black"
                                >
                                    BUYER PRICE
                                </ProjectText>
                            </InputLabel>
                            <div 
                                className={classes.rateMainContainer} 
                                style={{marginTop: '0px', backgroundColor: "#ECECEC"}}
                            >
                                <div 
                                    className={classes.rateLeftContainer} 
                                    style={{backgroundColor: "#ECECEC"}}
                                >
                                    <h2 
                                        className={classes.rateTitleText} 
                                        style={{lineHeight: "14px"}}
                                    >
                                        USD
                                    </h2> 
                                </div>
                                <div 
                                    className={classes.rateRightContainer} 
                                    style={{backgroundColor: "#ECECEC"}}
                                >
                                    <h2 
                                        className={classes.rateValueText} 
                                        style={{lineHeight: "14px"}}
                                    >
                                      {this.state.sellerViewOfferData.attributes?.offer_rate_by_buyer ? 
                     `${parseInt(this.state.sellerViewOfferData.attributes?.offer_rate_by_buyer).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                                    </h2>
                                    <h2 
                                        className={classes.rateUnitText} 
                                        style={{lineHeight: "14px"}}
                                    >
                                        /{this.state.sellerViewOfferData.attributes?.offer_container_sizes}
                                    </h2>
                                </div>
                            </div>
                        </Grid>
                    <Grid item xs={12} lg={6}>
                    <Box 
                        className={classes.leftBar}
                    > 
                        <InputLabel htmlFor="CONTAINERSIZE"> 
                            <ProjectText 
                                Upercase 
                                variant="subtitle1" 
                                TextSize="14px" 
                                fontweight="900" 
                                textcolor="black"
                            >
                                Terms
                            </ProjectText>
                        </InputLabel>
                    </Box>
                    <Grid 
                        container 
                        spacing={2}
                    >
                        <Grid 
                            item 
                            xs={12}
                            lg={6}
                        >
                            <div 
                                className={classes.formInputFieldContainer} 
                                style={{marginTop: '0px', backgroundColor: "#ECECEC"}}
                            >
                                <h2 
                                    className={classes.formFieldText} 
                                    style={{lineHeight: '14px'}}
                                >
                                    {String(this.state.sellerViewOfferData.attributes?.offer_terms_buyers?.split("/")[0]?.trim())?.toUpperCase()}
                                </h2>
                            </div>
                            <div className={classes.positionRelative}>
                                <span className={classes.slashTerms}>/</span>
                            </div>
                        </Grid>
                        <Grid 
                            item 
                            xs={12}
                            lg={6}
                        >
                            <div 
                                className={classes.formInputFieldContainer} 
                                style ={{marginTop: '0px', backgroundColor: "#ECECEC"}}
                            >
                                <h2 
                                    className={classes.formFieldText} 
                                    style={{lineHeight: '14px'}}
                                >
                                    {String(this.state.sellerViewOfferData.attributes?.offer_terms_buyers?.split("/")[1]?.trim())?.toUpperCase()}
                                </h2>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid 
                container 
                spacing={2} 
                style={{ marginBottom: "15px" }}
            >
                <Grid 
                    item 
                    xs={12}
                    lg={6}
                >
                    <InputLabel htmlFor="CONTAINERTYPE"> 
                        <ProjectText 
                            Upercase 
                            variant="subtitle1" 
                            TextSize="14px" 
                            fontweight="900" 
                            textcolor="black"
                        >
                            SELLER PRICE
                        </ProjectText>
                    </InputLabel>
                    {this.renderFirstContainerOfferedRate(classes, errors, touched, values, setFieldValue, validateFirstContainer)}
                </Grid>
                <Grid 
                    item 
                    xs={12}
                    lg={6}
                >
                    <Box 
                        className={classes.leftBar}
                    > 
                        <InputLabel htmlFor="CONTAINERSIZE">
                            <ProjectText 
                                Upercase 
                                variant="subtitle1" 
                                TextSize="14px" 
                                fontweight="900" 
                                textcolor="black"
                            >
                                Terms
                            </ProjectText>
                        </InputLabel>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            {this.renderFirstContainerTermOne(classes, errors, touched, values, setFieldValue, validateFirstContainer)}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            {this.renderFirstContainerTermTwo(errors, touched, values, setFieldValue, validateFirstContainer)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                {
                    this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes 
                    && this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes != "" 
                    && this.renderSecondUpdateContainer(classes, errors, touched, values, setFieldValue, validateSeconsContainer)
                }
        </section>
        <div className={classes.dFlexJCenter}>
            <button 
                type="submit" 
                data-test-id="UpdateForm" 
                className={classes.btnTheme}
            >
                UPDATE
            </button> 
        </div>
    </Box>
</form>
      )
    }

    onUpdateOfferFormSubmit = (values: any) => {

      const voyageDropdownArray = this.state.VoyageDropDownData?.map((item) => {return `In ${item.inVoyN} Out ${item.outVoyN}`
    })
    if (voyageDropdownArray?.includes(this.state.InVoyogeAndOutVoyoge)){this.onSubmitHandler(values);this.onFormUpdate();}
      if(!voyageDropdownArray?.includes(this.state.InVoyogeAndOutVoyoge)){
        toast.warning("Please select Voyage value")
      }
    }

    updateOfferModal = (
        classes: any
        ) => { 
            return( 
                <StyleModel> 
                    <Formik 
                        validationSchema={() => this.NewListingSchema()} 
                        validateOnChange={true} 
                        
                        initialValues={{ 
                            PRINCIPALORGANISATION: "0", 
                            VESSEL: this.state.sellerViewOfferData.attributes?.vessel_code,
                            VOYAGE: `IN ${this.state.sellerViewOfferData.attributes?.in_voyage} OUT ${this.state.sellerViewOfferData.attributes?.out_voyage}`, 
                            POD:  String(this.state.sellerViewOfferData.attributes?.pod), 
                            PODTERMINAL: String(this.state.sellerViewOfferData.attributes?.pod_terminal), 
                            POL: String(this.state.sellerViewOfferData.attributes?.pol), 
                            POLTERMINAL:  String(this.state.sellerViewOfferData.attributes?.pol_terminal),
                            ETBATPOL:  String(this.state.sellerViewOfferData.attributes?.etb_pol), 
                            ETAATPOD:  String(this.state.sellerViewOfferData.attributes?.eta_pod), 
                            QUANTITYOFTEUSAVAILABLEFORSALE: String(this.state.sellerViewOfferData.attributes?.offer_quantity), 
                            VALIDITYOFLISTING: this.state.sellerViewOfferData.attributes?.listing_validity || "", 
                            EXPIRYDATE:  "", 
                            CONTAINERTYPE:  "", 
                            CONTAINERCODES:  "", 
                            CONTAINERSIZE:  "", 
                            AVERAGETONSBOX:  "", 
                            TOTALQUANTITY:  this.state.sellerViewOfferData.attributes?.offer_quantity || "", 
                            TOTALQUANTITYTWO:  this.state.sellerViewOfferData.attributes?.offer_sec_quantity || "", 
                            OFFEREDRATE:  this.state.sellerViewOfferData.attributes?.offer_seller_rate || "", 
                            OFFEREDTERM:  this.state.sellerViewOfferData.attributes?.offer_terms_sellers?.split("/")[0]?.trim()?.toUpperCase() || "", 
                            OFFEREDTERMTWO:  this.state.sellerViewOfferData.attributes?.offer_terms_sellers?.split("/")[1]?.trim()?.toUpperCase() || "", 
                            OFFEREDRATETWO:  this.state.sellerViewOfferData.attributes?.offer_sec_rate_by_seller || "", 
                            OFFEREDTERMTHREE:  this.state.sellerViewOfferData.attributes?.offer_sec_terms_sellers?.split("/")[0]?.trim()?.toUpperCase() || "", 
                            OFFEREDTERMFOUR:  this.state.sellerViewOfferData.attributes?.offer_sec_terms_sellers?.split("/")[1]?.trim()?.toUpperCase() || "", 
                            BUYERRATE:  "", 
                            BUYERTERMS:  "", 
                            BUYERTERMSTWO:  "", 
                            RATE: this.state.sellerViewOfferData.attributes?.offer_seller_rate || "", 
                            TERMS:  "", 
                            TERMSTWO:  "", 
                            FULLNAME: "", 
                            EMAILADDRESS: "", 
                            ContactNumber: "", 
                            EMAILDISTRIBUTIONLIST: "",
                        }}
                        onSubmit={(values) => { this.onUpdateOfferFormSubmit(values)}} 
                        validateOnBlur={true}
                    >
                        {({errors,touched,setFieldValue,handleSubmit,isValid,values,}: any) => {
                          this.setValuesRef = setFieldValue;
                            return (
                                <>
                                {this.renderUpdateOfferForm(errors,touched,setFieldValue,handleSubmit,isValid,values, classes)}
                                </>
                    )
                }
            }
            </Formik>
        </StyleModel>
        )
    }

    renderViewOfferModal = (classes: any) => {
        if(this.state.addListing && this.state.sellerViewOfferData?.attributes && this.state.isViewing && !this.state.isUpdating){
            return(
                <>
                {this.viewOfferModal(classes)}
                </>
            )
        }
    }


    viewOfferModal = (classes: any) => {
        return (
          <StyleModel>
            <form autoComplete="off" className="FormOrganisationDetails">
              <Box className={classes.addListingModal}>
                <Box className={classes.close} onClick={this.handleClose} data-test-id="close-btn">
                  <img src={close} alt="" />
                </Box>
                <h1 className={classes.title}>View Offer</h1>
                <section className={classes.organisation}>
                  <ProjectText fontweight="800" TextSize="15px" textcolor="#000"> REQUEST ID </ProjectText>
                  <ProjectText TextSize="14px" textcolor="#000">{this.state.sellerViewOfferData?.attributes?.request_uid}</ProjectText>
                  <InputLabel htmlFor="PRINCIPALORGANISATION" style={{ marginTop: "20px" }}>
                    <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                      PRINCIPAL ORGANISATION NAME
                    </ProjectText>
                  </InputLabel>
                  <div className={classes.formInputFieldContainer}>
                    <h2 className={classes.formFieldText}>
                      {this.state.sellerViewOfferData.attributes?.principal_organization_name || this.state.sellerViewOfferData.attributes?.organization_code}
                    </h2>
                  </div>
                </section>
                <section className={classes.vesselContainer}>
                  <h3 className={classes.vesselHeading}> Port & Vessel Information </h3>
                  {this.renderVasselBlock(classes)}
                </section>
                <section className={classes.vesselContainer}>
                  <h3 className={classes.vesselHeading}> Container & Pricing Details </h3>
                  <Grid container spacing={2} style={{ marginBottom: "15px" }}>
                    <Grid item xs={12} lg={6}>
                      <InputLabel htmlFor="CONTAINERTYPE">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                          CONTAINER Status
                        </ProjectText>
                      </InputLabel>
                      <div className={classes.formInputFieldContainer}>
                        <h2 className={classes.formFieldText}>
                          {String(this.state.sellerViewOfferData.attributes?.offer_container_type)?.toUpperCase()}
                        </h2>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel htmlFor="EMAILADDRESS">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                          Container Types
                        </ProjectText>
                      </InputLabel>
                      <div className={classes.containerCodesContainer}>
                        {this.state.sellerViewOfferData.attributes?.offer_container_codes?.map((item: string) => {
                          return (
                            <div className={classes.containerCodes}>
                              {item}
                            </div>
                          )
                        })}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "15px" }}>
                    <Grid item xs={12} lg={4}>
                      <InputLabel htmlFor="CONTAINERTYPE">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                          CONTAINER SIZE 
                        </ProjectText>
                      </InputLabel>
                      <div className={classes.formInputFieldContainer}>
                        <h2 className={classes.formFieldText}>
                          {String(this.state.sellerViewOfferData.attributes?.offer_container_sizes)?.toUpperCase()}
                        </h2>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <InputLabel htmlFor="CONTAINERTYPE">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                          Offered Quantity
                        </ProjectText>
                      </InputLabel>
                      <div className={classes.formInputFieldContainer}>
                        <h2 className={classes.formFieldText}>
                          {this.state.sellerViewOfferData.attributes?.offer_quantity}
                        </h2>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <InputLabel htmlFor="CONTAINERSIZE">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                          AVG. TONS/CONTAINER
                        </ProjectText>
                      </InputLabel>
                      <div className={classes.formInputFieldContainer}>
                        <h2 className={classes.formFieldText}>
                          {this.state.sellerViewOfferData.attributes?.requested_quantity[0]?.tons_per_teu_extra}
                        </h2>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "15px" }}>
                    <Grid item xs={12} lg={6}>
                      <InputLabel htmlFor="CONTAINERTYPE">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                          BUYER PRICE
                        </ProjectText>
                      </InputLabel>
                      <div className={classes.rateMainContainer}>
                        <div className={classes.rateLeftContainer}>
                          <h2 className={classes.rateTitleText}>USD</h2>
                        </div>
                        <div className={classes.rateRightContainer}>
                          <h2 className={classes.rateValueText}>
                          {this.state.sellerViewOfferData.attributes?.offer_rate_by_buyer ? 
                     `${parseInt(this.state.sellerViewOfferData.attributes?.offer_rate_by_buyer).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                            </h2>
                          <h2 className={classes.rateUnitText}> /{String(this.state.sellerViewOfferData.attributes?.offer_container_sizes)?.toUpperCase()}</h2>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}
                    >
                      <Box 
                        className={classes.leftBar}>
                        <InputLabel htmlFor="CONTAINERSIZE3">
                          <ProjectText Upercase variant="subtitle1" 
                            TextSize="14px" fontweight="900" textcolor="black">
                            Terms
                          </ProjectText>
                        </InputLabel>
                      </Box>
                      <Grid container 
                        spacing={2}>
                        <Grid item 
                           xs={12} lg={6}>
                          <div className={classes.formInputFieldContainer}>
                            <h2 className={classes.formFieldText}>{this.state.sellerViewOfferData.attributes?.offer_terms_buyers?.split("/")[0]?.trim()?.toString().toUpperCase()}</h2>
                          </div>
                          <div className={classes.positionRelative}><span className={classes.slashTerms}>/</span></div>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <div className={classes.formInputFieldContainer}>
                            <h2 className={classes.formFieldText}>{this.state.sellerViewOfferData.attributes?.offer_terms_buyers?.split("/")[1]?.trim()?.toString()?.toUpperCase()}</h2>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "15px" }}>
                    <Grid item xs={12} lg={6}>
                      <InputLabel htmlFor="CONTAINERTYPE">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                          SELLER PRICE
                        </ProjectText>
                      </InputLabel>
                      <div className={classes.rateMainContainer}>
                        <div className={classes.rateLeftContainer}>
                          <h2 className={classes.rateTitleText}>USD</h2>
                        </div>
                        <div className={classes.rateRightContainer}>
                          <h2 className={classes.rateValueText}>
                          {this.state.sellerViewOfferData.attributes?.offer_seller_rate ? 
                     `${parseInt(this.state.sellerViewOfferData.attributes?.offer_seller_rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                            </h2>
                          <h2 className={classes.rateUnitText}> /{String(this.state.sellerViewOfferData.attributes?.offer_container_sizes)?.toUpperCase()}</h2>
                        </div>
                      </div>
                    </Grid>
                    <Grid 
                        item xs={12} lg={6}>
                      <Box 
                        className={classes.leftBar}
                        >
                        <InputLabel htmlFor="CONTAINERSIZE">
                          <ProjectText 
                            Upercase 
                            variant="subtitle1" 
                            TextSize="14px" fontweight="900" 
                            textcolor="black"
                        >
                            Terms
                          </ProjectText>
                        </InputLabel>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid 
                            item xs={12} lg={6}
                        >
                          <div className={classes.formInputFieldContainer}>
                            <h2 className={classes.formFieldText}>{this.state.sellerViewOfferData.attributes?.offer_terms_sellers?.split("/")[0]?.toString()?.toUpperCase()}</h2>
                          </div>
                          <div className={classes.positionRelative}><span className={classes.slashTerms}>/</span></div>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <div className={classes.formInputFieldContainer}>
                            <h2 className={classes.formFieldText}>{this.state.sellerViewOfferData.attributes?.offer_terms_sellers?.split("/")[1]?.toString()?.toUpperCase()}</h2>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes && this.state.sellerViewOfferData.attributes?.offer_sec_container_sizes != "" && this.renderSecondViewContainer(classes)}
                </section>
                <div className={classes.dFlexJCenter}>
                  {
                    this.state.sellerViewOfferData.attributes?.status.toLowerCase() != "rejected" &&
                  <button type="submit" data-test-id="OpenUpdateForm" className={classes.btnTheme} onClick={() => this.onUpdateOfferClick()}>
                    UPDATE
                  </button>
                }
                  <button className={classes.withDrawbtn} onClick={() => this.onWithdrawOfferClick()}>
                    WITHDRAW
                  </button>
                </div>
              </Box>
            </form>
          </StyleModel>
        );
      }
      

convertToContainerSize = (str: any) =>{
    const numericPart = 
        str.match(/\d+/);
    
    if (
        numericPart !== null
        ) 
        {
        return parseInt(numericPart[0]); } else { return NaN; }

}

renderSecondContainerDetails = (item: any, classes: any) => {
    return (
      <>
        <TableCell>
        {item.attributes.offer_quantity && item.attributes.offer_quantity != "" &&
          <>
            <span className={classes.tableContent}>
              {item.attributes.offer_quantity}
              </span>
          </>
          }
          {item.attributes.offer_sec_quantity && item.attributes.offer_sec_quantity !== "" &&
            <div>
              <span className={classes.tableContent}>{item.attributes.offer_sec_quantity}</span>
            </div>
          }
        </TableCell>
        <TableCell>
        {item.attributes.offer_quantity && item.attributes.offer_quantity != "" &&
          <>
            <span className={classes.tableContent}>
              <b>
              {item.attributes.offer_rate_by_buyer ? 
                      `$${parseInt(item.attributes.offer_rate_by_buyer).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                /</b>{item.attributes.offer_container_sizes}
            </span>
          </>}
          {item.attributes.offer_sec_quantity && item.attributes.offer_sec_quantity !== "" &&
            <div>
              <span className={classes.tableContent}>
                <b>
                {item.attributes.offer_sec_rate_by_buyer ? 
                     `$${parseInt(item.attributes.offer_sec_rate_by_buyer).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                  /</b>{item.attributes.offer_sec_container_sizes}
              </span>
            </div>
          }
        </TableCell>
        <TableCell>
          {item.attributes.offer_quantity && item.attributes.offer_quantity != "" &&
          <>
          <span className={classes.tableContent}>
            <LightTooltip
            title={item.attributes.offer_rate_by_buyer < item.attributes.offer_seller_rate ? 
              "": 
              "Seller price is lower than buyer price."}
              // title="Full legal name of your organisation as registered with the local authority."
              placement="right"
              className="tooltip"
            >
              <span className={item.attributes.offer_rate_by_buyer < item.attributes.offer_seller_rate ? classes.redDot : classes.dot}></span>
            </LightTooltip>
            <b>
            {item.attributes.offer_seller_rate ? 
                     `$${parseInt(item.attributes.offer_seller_rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
            /</b>{item.attributes.offer_container_sizes}
          </span>
          </>}
          {item.attributes.offer_sec_quantity && item.attributes.offer_sec_quantity !== "" &&
            <div>
              <span className={classes.tableContentWhiteSpace}>
                <LightTooltip
                   title={item.attributes.offer_sec_rate_by_buyer < item.attributes.offer_sec_rate_by_seller ? 
                    "Full legal name of your organisation as registered with the local authority.": 
                    "Seller price is lower than buyer price."}
               
                  placement="right"
                  className="tooltip"
                >
                  <span className={item.attributes.offer_sec_rate_by_buyer < item.attributes.offer_sec_rate_by_seller ? classes.redDot : classes.dot}></span>
                </LightTooltip>
                <b>
                {item.attributes.offer_sec_rate_by_seller ? 
                     `$${parseInt(item.attributes.offer_sec_rate_by_seller).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
                  /</b>{item.attributes.offer_sec_container_sizes}
              </span>
            </div>
          }
        </TableCell>
        <TableCell>
          <span className={classes.tableContent}>
            <b>
            {item.attributes.total ? 
                     `$${parseInt(item.attributes.total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`:""}
              </b>
          </span>
        </TableCell>
      </>
    );
  }
  
  renderSlotFilter = (classes: any) => {
    const newOrgList = this.state.filter?.principalOrganisation?.filter((item) => item.isChecked === true);
    let text = "";
      let backgroundColor = newOrgList[0]?.attributes?.color_name;
    return (
      <div style={{ position: 'relative', color: 'rgba(30, 30, 30, 1)', fontFamily: 'Asap', fontSize: '14px', fontWeight: 500 }}>
        <div className="selectSlotInput" data-test-id="organizationFilter" onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })} style={useStyles.selectSlot}>
          <div style={{ fontWeight: 500, display: 'flex', overflow: 'auto' ,alignItems:"center"}}>
             {newOrgList?.length > 0 
             ? ( <>
            {newOrgList?.map((slot: any) => {
              text += `${slot.attributes?.name?.trim().split(" ").join("/")} ${slot.attributes?.code} `
              if (slot.attributes?.color_name) 
              {
                backgroundColor += ` ${slot.attributes?.color_name} `;
              };
              return (
                <></> )
            })}
            <div className="DropDownDiv" 
            title={text?.split("/").join(" ")}
            >
              {(text.length > 28 ? text.slice(0,28) + " ..." : text).split(" ").filter((txt)=> txt !== "").map((txt,index)=>
              index % 2 === 0 
              ? (
                <h3 className="DropDownDivh3" key={index}>
                  {txt?.split("/").join(" ")}</h3>
              ) : ( 
              <p className="DropDownDivP" key={index} 
              style={{ padding: '.25rem .625rem', borderRadius: '.25rem', 
              backgroundColor:backgroundColor?.split(" ").filter((txt:string)=> txt !== "")[index]  }}>
                    {txt}</p>
                    ))}
                </div>   </>
        ) : <> {!Array.isArray(this.state.principalOrgList) && this.state.principalOrgList?.type === "organization" ? 
        <>
        <span>{this.state.principalOrgList.attributes?.name}
        </span>
        <p  style={webStyle.boxtextp}>
            {this.state.principalOrgList.attributes?.code} </p>
    </>
        // `${this.state.principalOrgList?.attributes?.name}    <span>${this.state.principalOrgList?.attributes?.code} <span>`
        : "ALL"}</>
        }
          </div>
          <img src={image_dropdown} style={{width: '10px', height: '10px', objectFit: 'contain'}} alt="dropdown" />
        </div>
        {this.state.isSlotFilterOpen && (
          <ClickAwayListener onClickAway={() => this.setState({ isSlotFilterOpen: false })}>
            <div>
              {Array.isArray(this.state.principalOrgList) ?(
                 <div className="selectSlotDropdown" data-test-id="organizationFilterDropdown" style={useStyles.selectSlotDropdown}>
                  {this.state.principalOrgList?.map((val: any) => {
                const updatedValue: any = this.state.filter?.principalOrganisation?.filter((item) => item.label === val.attributes.name);
                return (
                  <div key={val.id} style={{ borderLeft: `4px solid ${val.attributes.color_name}`, ...webStyle.selectSlotItem }}>
                    <h4 style={{ textTransform: 'uppercase' }}>{val.attributes.name}</h4>
                    <Checkbox
                      color="primary"
                      checked={updatedValue[0].isChecked}
                      data-test-id="typecheck4"
                      name={updatedValue[0].label}
                      onClick={(e) => this.handleOfferChange(e, this.state.filter.principalOrganisation, "principalOrganisation")}
                    />
                  </div>
                );
              })}
                  </div>
              ):(this.state.principalOrgList?.type !== "organization" && 
            <div className="selectSlotDropdown" data-test-id="organizationFilterDropdown" style={useStyles.selectSlotDropdown}>
                <div  key={this.state.principalOrgList.id}
                    style={{ borderLeft: `.25rem solid 
                    ${this.state.principalOrgList.attributes.name}`, ...webStyle.selectSlotItem }}>
                    <h4 
                    style={{ textTransform: 'uppercase' }}> {this.state.principalOrgList.attributes.name}
                    </h4>
                    <Checkbox color="primary"
                    name={this.state.filter?.principalOrganisation?.filter((item) => item.label === this.state.principalOrgList.attributes.name)[0].label}
                    checked=
                    {this.state.filter?.principalOrganisation?.filter((item) => item.label === this.state.principalOrgList.attributes.name)[0].isChecked}
                    onChange=
                    {(e) => this.handleOfferChange(e,this.state.filter.principalOrganisation, "principalOrganisation")}/>
                  </div>
                  </div>
              )}
            </div>
          </ClickAwayListener>
        )}
      </div>
    );
  }

  

spinnLoader = () => {
    if (this.state.loading) {
      return <Spinner spinnerModal={this.state.loading} />
    }
  }

  renderThreeDots = (item: any, classes: any, open: boolean, anchorElDropdown: any, selectedOrg: any) => {
    return(
      <div className={classes.leftBar}>
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event)=>this.handleClick(event, item)}
                data-test-id="basic-button"
                role="basic-button"
            >
                <img src={ellipsis} alt="" /> 
            </Button>
            {
              open && selectedOrg == item.id && 
            <Menu
                id="basic-menu"
                anchorEl={anchorElDropdown}
                open={open}
                onClose={this.handleCloseDropdown}
            >
                <Paper className={classes.uncontrolledBox}>
                <h6 
                    className={classes.editText} 
                    onClick = {() => this.handleViewOfferCick()}>
                        VIEW OFFER
                    </h6>
                    {
                      item.attributes.status?.toLowerCase() !== "rejected" && 
                      <h6 
                    className={classes.editText} 
                    onClick={() => this.onUpdateOfferClick()}>
                        UPDATE OFFER
                    </h6>
                    }
                <h6 
                    className={classes.deleteText} 
                    onClick={() => this.onWithdrawOfferClick()}>
                        WITHDRAW OFFER
                </h6>
                </Paper>
            </Menu>
            }
            </div>
    </div>
    )
  }

  renderPrincipalOrganisation = (classes: any, item: any) => {
    return(
      <TableCell className={classes.tableLeftBorder}style={{ borderLeftColor: item.attributes.principal_color ?? "rgb(184, 213, 249)" }}>
          <span className={classes.org} style={{backgroundColor: item.attributes.principal_color ?? "rgb(184, 213, 249)"}}>
              {item.attributes.seller_principal_org_code || item.attributes.seller_organization_code || item.attributes.principal_org_code || item.attributes.organization_code}
              </span>
      </TableCell>
    )
  }

  renderTableTerms = (classes: any, item: any) => {
    return(
      <TableCell>
        <span className={classes.tableContentWhiteSpace}>
          {
            item.attributes?.offer_quantity && item.attributes?.offer_quantity != "" &&
            <b>
                {item.attributes?.offer_terms_sellers?.split(',')?.map((term: any) => term.trim()).join('/')}
            </b>
          }
          {
            item.attributes?.offer_sec_quantity && item.attributes?.offer_sec_quantity != "" &&
            <b>{ item.attributes?.offer_quantity && item.attributes?.offer_quantity != "" ? (<>&nbsp; | &nbsp;</>) :null}{item.attributes?.offer_sec_terms_sellers?.split(',')?.map((term: any) => term.trim()).join('/')}</b>
          }
        </span>
    </TableCell>
    )
  }

  renderFirstTwoFilters = ()=> {
    return(
      <>
      <FormControl style= {{ width: "100%", marginRight: "15px" }}>
              <StyledInputLable shrink >OFFER ID</StyledInputLable>
                <Box style={{ 
                    marginTop: "24px" }}>
                  <StyledBox
                    data-test-id="listingfilter1" onClick={() => this.handleOfferExpanded("listingID")}
                  >
                    <DropdownArrowBox> 
                    <img 
                    src={image_dropdown} 
                    style={this.state.offerExpanded ? {transform: "scaleY(-1)"}: {}} 
                    alt="" 
                    onClick={() => this.handleOfferExpanded("listingID")} />
                  </DropdownArrowBox>
                  </StyledBox>
                {this.state.offerExpanded && (
                        <ClickAwayListener onClickAway={() => this.setState({ offerExpanded: false })} >
                            <Collapse
                                in={this.state.offerExpanded}
                                timeout="auto"
                                unmountOnExit
                                disableStrictModeCompat={false}
                                style={{
                                position: "absolute", flexDirection: "column",
                                width: "100%", background: "#fff",
                                zIndex: 1, display: "flex",height:"130px", overflowY:"auto", overflowX:"hidden"
                                }}
                            >
                                {this.state.filter?.listingID?.map(
                                (item: { label: string; isChecked: boolean }) => {
                                    return (
                                    <CustomFormControlLabel
                                        key={item.label}
                                        control={
                                        <Checkbox
                                            color="primary" checked={item.isChecked}
                                            data-test-id="listingcheck1"
                                            onClick={(e) =>this.handleOfferChange( e, this.state.filter.listingID,  "listingID")}
                                            style={{ paddingRight: "30px" }} name={item.label}
                                        />
                                        }
                                        labelPlacement="start"
                                        label={item.label}
                                        style={{
                                        display: "flex", justifyContent: "space-between",
                                        flexDirection: "row-reverse",
                                        fontFamily: "Asap", fontSize: "14px",
                                        }}
                                    />
                                    );
                                }
                                )}
                            </Collapse>
                        </ClickAwayListener>

              )}
                </Box>
              <Box
                style={{
                  padding: "2px", width: "220px",
                  display: "flex", justifyContent: "start",
                  flexWrap: "wrap", gap: "5px",
                  marginTop: "3px", listStyle: "none",
                }}
              >
                {this.state.filter.listingID.map(
                  (option: { label: string; isChecked: boolean }) => {if (option.isChecked) {
                      return (
                        <li key={option.label} style={{ fontFamily: "Asap", fontSize: "14px" }} >
                          <StyledChip label={option.label} onDelete={() => this.handleOfferDelete( option, this.state.filter.listingID, "listingID" ) } data-test-id="listingIdChip" />
                        </li> ) }
                  }
                )}
              </Box>
            </FormControl>
            <FormControl style={{ width: "100%", 
            marginRight: "15px" }
            }>
              <StyledInputLable shrink>
                POL</StyledInputLable>{" "}
                <Box 
                style={{ marginTop: "24px" }
            }>
                  <StyledBox data-test-id="polfilter1" 
                  onClick={() => this.handleOfferExpanded("pol")}>
                    <DropdownArrowBox> 
                    <img src={image_dropdown} 
                    style={this.state.offerExpandedPol ? {transform: "scaleY(-1)"}: {}} 
                    alt="" onClick={() => this.handleOfferExpanded("pol")} />
                  </DropdownArrowBox>
                  </StyledBox>
              {this.state.offerExpandedPol && (
                <ClickAwayListener
                    onClickAway={()=>this.setState({ offerExpandedPol: false })}
                >
                    <Collapse
                        in={this.state.offerExpandedPol}
                        timeout="auto"
                        unmountOnExit
                        disableStrictModeCompat={false}
                        style={{
                        position: "absolute",width: "100%",
                        zIndex: 1,background: "#fff",
                        flexDirection: "column",display: "flex",height:"130px", overflowY:"auto", overflowX:"hidden"
                        }}
                    >
                        {this.state.filter?.pol?.map(
                        (item: { label: string; isChecked: boolean }) => {
                            return (
                            <CustomFormControlLabel
                                key={item.label}
                                control={
                                <Checkbox 
                                color="primary" data-test-id="polcheck1"
                                    checked={item.isChecked}
                                    onClick={(e) => this.handleOfferChange( e, this.state.filter.pol, "pol" ) }
                                    style={
                                        { paddingRight: "30px" }
                                    }
                                    name={item.label}
                                />
                                }
                                label={item.label}
                                labelPlacement="start"
                                style={{
                                fontFamily: "Asap",flexDirection: "row-reverse",
                                justifyContent: "space-between",fontSize: "14px",
                                display: "flex"
                                }}
                            />
                            );
                        }
                        )}
                    </Collapse>
                </ClickAwayListener>
              )}
                </Box>
              <Box
                style={{
                  marginTop: "3px", display: "flex",
                  width: "220px",flexWrap: "wrap",
                  listStyle: "none", justifyContent: "start",
                  gap: "5px", padding: "2px",
                }}
              >
                {this.state.filter.pol.map(
                  (option: { label: string; isChecked: boolean }) => {  if (option.isChecked){
                      return ( <li key={option.label} style={{ fontFamily: "Asap", fontSize: "14px" }} >
                          <StyledChip label={option.label} onDelete={() => this.handleOfferDelete( option, this.state.filter.pol, "pol" ) } />
                        </li> ); }
                  }
                )}
              </Box>
            </FormControl>
      </>
    )
  }
 
    render() { 

        const { classes }: any = this.props;

        const { 
          anchorElDropdown, 
          selectedOrg 
        } = this.state;

        const open = Boolean(anchorElDropdown);

        return ( 
            <div data-test-id="sellerpendingoffers">
                <Navbar 
                    SellerPendingScreen 
                    AppBarTransparent={false} 
                    TabDataCount={this.DynamicDataSet}
                />
                {this.spinnLoader()}
                <section 
                    className={classes.root}
                    >
                    <Snackbar
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        open={this.state.snackbarOpen}
                        autoHideDuration={5000}
                        onClose={this.handleSnackbarClose}
                    >
                        <SnackbarContent
                        style={{ 
                            backgroundColor: '#fff', 
                            color: '#f00', 
                            fontWeight: 'bolder' 
                        }}
                        message="Can not switch user as organisation role is not both"
                        />
                    </Snackbar>
                    <Container maxWidth="xl">
                        <Grid container alignItems="center" 
                            className={classes.slotsSection}
                        >
                            <Grid item lg={6} sm={12}>
                                <SectionWrapperLeft 
                                    className={classes.leftBar}
                                    >
                                    <StyledSwitch>
                                        <div
                                            className="switch"
                                            onClick={() => this.SwitchChnages()}
                                            data-test-id="switch-changes"
                                        >
                                            <input
                                                className="Inputswitch"
                                                type="checkbox"
                                                checked={this.state.switch}
                                            />
                                            <span className="slider round">
                                                <ProjectText TextSize="1rem" fontweight="700" className="text">
                                                    {"SELL"}
                                                </ProjectText>
                                            </span>
                                        </div>
                                    </StyledSwitch>
                                    <h1 style={useStyles.slotsFor}>
                                        Slots for 
                                    </h1>
                                    {this.renderSlotFilter(classes)}
                                </SectionWrapperLeft>
                            </Grid>
                            <Grid item xs={12} lg={6} md={6} style={useStyles.textEnd}>
                                <AddListing/>
                            </Grid>
                        </Grid>

                        <StyledTabs 
                            value={this.state.currentTab} 
                            onChange={this.handleTabChange} 
                            TabIndicatorProps={{ 
                                style: { 
                                    display: "none" 
                                }
                            }}
                            variant="fullWidth" 
                            aria-label="full width tabs example" 
                            data-test-id="tab" 
                        >
                           
                            < BorderedStyledTab
                                disableTouchRipple 
                                label={`BUYER REQUESTS (${this.state.DynamicCount.requst})`}
                                onClick={()=>this.props.navigation.navigate("SellerPendingRequest")}
                            />
                             < StyledTab
                                disableTouchRipple 
                                label={`LISTINGS (${this.state.DynamicCount.listing})`}
                                onClick={()=>this.props.navigation.navigate("SellerListing")} 
                            /> 
                            <StyledTab 
                                disableTouchRipple 
                                label={`OFFERS (${this.state.DynamicCount.offers})`}
                                onClick={()=>this.props.navigation.navigate("SellerPendingOffers")}
                            /> 
                            <StyledTab 
                                disableTouchRipple 
                                label={`BOOKINGS (${this.state.DynamicCount.booking})`}
                                onClick={()=>this.props.navigation.navigate("SellerBookings")}
                            />
                        </StyledTabs>

                        <CustomContentBox>
                        {this.renderFirstTwoFilters()}
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
              <StyledInputLable shrink>
                POD</StyledInputLable>{" "}
                <Box style={
                    { marginTop: "24px" }}>
                  <StyledBox
                  data-test-id="podfilter3" 
                  onClick={() => this.handleOfferExpanded("pod")}>
                    <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpandedPod ? 
                      {transform: "scaleY(-1)"}: {}} alt="" onClick={() => 
                      this.handleOfferExpanded("pod")} />
                  </DropdownArrowBox>
                  </StyledBox>
              {this.state.offerExpandedPod && (
                <ClickAwayListener
                    onClickAway={() => this.setState({ offerExpandedPod: false })}
                >
                    <Collapse
                        in={this.state.offerExpandedPod}
                        timeout="auto"
                        unmountOnExit
                        disableStrictModeCompat={false}
                        style={{
                        position: "absolute", background: "#fff",
                        display: "flex", flexDirection: "column",
                        width: "100%",zIndex: 1,height:"130px", overflowY:"auto", overflowX:"hidden"
                        }}
                    >
                        {this.state.filter?.pod?.map(
                        (item: { label: string; isChecked: boolean }) => {
                            return (
                            <CustomFormControlLabel
                                key={item.label}
                                control={
                                <Checkbox
                                    color="primary"
                                    checked={item.isChecked}
                                    data-test-id="podcheck3"
                                    onClick={(e) => this.handleOfferChange( e, this.state.filter.pod, "pod" ) }
                                    name={item.label}
                                    style={{ 
                                        paddingRight: "30px" }}
                                />
                                }
                                label={item.label}
                                labelPlacement="start"
                                style={{
                                    justifyContent: "space-between",fontFamily: "Asap",
                                    display: "flex", fontSize: "14px",
                                flexDirection: "row-reverse"
                                }}
                            />
                            );
                        }
                        )}
                    </Collapse>
                </ClickAwayListener>

              )}
                </Box>
              <Box
                style={{
                    padding: "2px", display: "flex",
                    justifyContent: "start", flexWrap: "wrap",
                    gap: "5px", listStyle: "none",
                    marginTop: "3px",
                    width: "220px"
                }}
              >
                {this.state.filter.pod.map(
                  (option: { label: string; isChecked: boolean }) => {if (option.isChecked) {
                      return ( <li key={option.label} style={{ fontSize: "14px", fontFamily: "Asap" }} >
                          <StyledChip label={option.label} onDelete={() => this.handleOfferDelete( option, this.state.filter.pod, "pod" ) } />
                        </li> ); } 
                }
                )}

              </Box>
            </FormControl>
            <FormControl style={{ 
                width: "100%", marginRight: "15px" }}>
              <StyledInputLable shrink >
                STATUS</StyledInputLable>
                {" "}
                <Box style={
                    { marginTop: "24px" }
                }>
                  <StyledBox 
                    data-test-id="typefilter4" 
                    onClick={() => this.handleOfferExpanded("type")}>
                      <DropdownArrowBox> 
                        <img src={
                          image_dropdown
                          } style={this.state.offerExpandedtype ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("type")} />
                      </DropdownArrowBox>
                    </StyledBox>
              {this.state.offerExpandedtype && (
                <ClickAwayListener
                    onClickAway={() => this.setState({ 
                        offerExpandedtype: false })}
                >
                    <Collapse
                        in={this.state.offerExpandedtype}
                        unmountOnExit
                        timeout="auto"
                        disableStrictModeCompat={false}
                        style={{
                        position: "absolute", zIndex: 1,
                        width: "100%", background: "#fff",
                        display: "flex", flexDirection: "column",height:"130px", overflowY:"auto", overflowX:"hidden"
                        }}
                    >
                        {this.state.filter?.type?.map(
                        (item: { label: string; isChecked: boolean }) => {
                            return (
                            <CustomFormControlLabel
                                key={item.label}
                                control={
                                <Checkbox
                                    color="primary"
                                    checked={item.isChecked}
                                    data-test-id="typecheck4"
                                    onClick={(e) => this.handleOfferChange( e, this.state.filter.type, "type" ) }
                                    name={item.label}
                                    style={
                                        { paddingRight: "30px" 
                                    }}
                                />
                                }
                                label={item.label}
                                labelPlacement="start"
                                style={{
                                fontSize: "14px", display: "flex",
                                flexDirection: "row-reverse", justifyContent: "space-between",
                                fontFamily: "Asap"
                                }}
                            />
                            );
                        }
                        )}
                    </Collapse>
                </ClickAwayListener>
              )}
                </Box>
              <Box
                style={{display: "flex",  flexWrap: "wrap", marginTop: "3px", width: "220px",listStyle: "none", padding: "2px", justifyContent: "start",gap: "5px" }}
              >
                {this.state.filter.type.map(
                  (option:{label:string; isChecked:boolean}) => { if (option.isChecked){
                      return (<li style={{ fontFamily: "Asap", fontSize: "14px" }} key={option.label}>
                          <StyledChip label={option.label} onDelete={() => this.handleOfferDelete( option, this.state.filter.type, "type" ) } />
                        </li>
                      );
                    }
                  }
                )}
              </Box>
            </FormControl>
            <FormControl variant="standard" style= {{ width: "100%" } }>
              <StyledInputLable
                style={ { fontWeight:700}}
                htmlFor="ETB DATE RANGE"
                shrink
              >
                ETB DATE RANGE
              </StyledInputLable>
              <BootstrapInput
                defaultValue=""
                fullWidth
                id="ETB DATE RANGE"
                data-test-id="dateopne"
                onClick={this.TogolFilter}
                value={this.getDate()}
              />
          <CalendarImgBox> <img src={DateTimeIcon} alt=""onClick={this.TogolFilter}/>
          </CalendarImgBox>
              <Box style={{ position: "absolute", top: "70px", right: "0px" }}>
            {
              this.state.open &&
              <DateContainer style={{ position: "relative" }} data-test-id="DateFilterMainDiv">
                <DatePickerStyle
                  open={this.state.open}
                  toggle={this.TogolFilter}
                  wrapperClassName="DateMainClassName"
                  className="DateMainClassName"
                  initialDateRange={{
                    startDate: this.state.DateRangeStartDate,
                    endDate: this.state.DateRangeEndDate
                  }}
                  onChange={(range: DateRange) => { this.SetDateFilterData(range);  }}
                />
                <Box className="ButtonContaienr">
                  <Button style={{ marginRight: "10px" }} data-test-id="ClearFilter" variant="text" onClick={()=> {
                    this.ClearFilterData()
                  } }>
                    <ProjectText variant="h6" TextSize="16px" textcolor="primary"> clear </ProjectText>
                  </Button>
                  <Button variant="contained" data-test-id="SelectFilter" onClick={this.FilterDateSelect} color="primary" className="SelectButton">
                    <ProjectText variant="h6" TextSize="16px" textcolor="white"> Select </ProjectText>
                  </Button>
                </Box>
              </DateContainer>
            }
          </Box>
            </FormControl>
                        </CustomContentBox>
                        <TabPanel value={this.state.currentTab} index={2} data-test-id="SellerTable">
                            {this.state.sellerListings && (
                                <TableContainer>
                                    <StyledTable aria-label="sticky table">
                                        <TableHead>
                                            <StyledTableHeaderRow>
                                                {columns.map((column: any) => (
                                                    <StyledTableCell
                                                        key={column.id} style={{ 
                                                            minWidth: column.minWidth,
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        <ul className={classes.dFlex}>
                                                            {column.label.map((
                                                                elem: any, i: number
                                                                ) => (
                                                                <li key={i}>
                                                                    {elem}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </StyledTableCell>
                                                ))}
                                            </StyledTableHeaderRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                !this.state.loading && this.state.sellerOfferData.map((item: any) => {
                                                    return (
                                                        <StyledTableRow key={item.id}>
                                                          {this.renderPrincipalOrganisation(classes, item)}
                                                            <TableCell >
                                                                <span className={classes.tableContent}>
                                                                    {item.attributes.offer_uid}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell className={classes.bodyOfferTextAlignment}>
                                                                <span className={classes.tableContent} style={{ textTransform: 'unset' }}>
                                                                    {moment.parseZone(item.attributes.etb_pol).format('DD MMM YYYY h:mm A')}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell className={classes.bodyOfferTextAlignment}>
                                                              <span className={classes.tableContent}>
                                                                  {item.attributes.vessel_code}<br />{" "}
                                                                  {item.attributes.in_voyage}/<br />{" "}{item.attributes.out_voyage}
                                                              </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span className={classes.tableContent}>
                                                                    {item.attributes.pol}/
                                                                    <br />
                                                                    {item.attributes.pol_terminal}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span className={classes.tableContent}>
                                                                {item.attributes.pod}/
                                                                <br />
                                                                {item.attributes.pod_terminal}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell className={classes.bodyOfferTextAlignment}>
                                                                <span className={classes.tableContent} style={{ textTransform: 'unset' }}>
                                                                {moment.parseZone(item.attributes.eta_pod).format('DD MMM YYYY h:mm A')}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span className={classes.tableContent}>
                                                                    {item.attributes.offer_container_type}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span className={classes.tableContent}>
                                                                    {item.attributes.offer_container_codes?.join(',')} 
                                                                </span>
                                                            </TableCell>
                                                            {this.renderSecondContainerDetails(item, classes)}
                                                            {this.renderTableTerms(classes, item)}
                                                            <TableCell>
                                                                <section className={classes.btnDiv}>
                                                                    <div>
                                                                        <div 
                                                                        className={`${
                                                                          item.attributes.status === 'pending'
                                                                            ? classes.offerStatus
                                                                            : classes.rejectClass                                                                            
                                                                        }`}
                                                                        onClick={() => this.setState({
                                                                            offerModal: true, 
                                                                            addListing: true 
                                                                            })}
                                                                        data-test-id={`OfferText`}>
                                                                            {item.attributes?.status?.toUpperCase()}!
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </TableCell>
                                                            <TableCell>{
                                                                      this.renderThreeDots(item, classes, open, anchorElDropdown, selectedOrg)
                                                                    }</TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })
                                            }

                                        </TableBody>
           
                                    </StyledTable>
                                   
                                </TableContainer>

                                
                                
                            )}
 {this.state.sellerOfferData?.length==0&& <div  style={{...webStyle.noData, flexDirection: "column"}}>
              <img
                src={resultNotFound1}
                alt="resultNotFound1"
                style={webStyle.noDataImg}
              />
              <p style={{...webStyle.head,fontWeight: "bold"}}>No Results Found!</p>
              <p  style={{...webStyle.msg}}>Change or Remove Filters and Try Again.</p>
            </div>}
                            
                        </TabPanel>
                    </Container>
                </section>

                <Dialog
                    open={this.state.offerModal}
                    onClose={this.handleClose}
                    maxWidth="md"
                    fullWidth
                >
                    {/* View offer Modal Start */}
                    {this.renderViewOfferModal(classes)}
                    {/* View offer Modal End */}

                    {/* Update offer modal Start */}
                    {this.renderUpdateOfferModal(classes)}
                    {/* Update offer modal End */}

                    {/* Listing Submitted Modal Start */}
                    {this.state.listingAdded && (
                        <Box className={classes.listingSubmittedBox}>
                            <div className={classes.textEnd}> <img src={close} alt="" /> </div>
                            <div style={{ ...webStyle.textCenter, ...webStyle.pTB50 }}>
                                <div className={classes.mb20}> <img src={image_success} alt="" /> </div>
                                <h1 className={classes.listingHeading}>Offer Submitted!</h1>
                                <p className={classes.listingIdPara}>
                                    Your offer (ID: O38750A) has been submitted successfully and shared with the buyer.
                                </p>
                                <p className={classes.listingIdPara}>
                                    You'll be notified when the buyer responds to your offer.
                                </p>
                            </div>
                        </Box>
                    )}
                    {/* Listing Submitted Modal End */}
                </Dialog >
                <ProjectModel 
                    IconButtonBgColor="black" CloseIconSize="1rem" 
                    BorderRadius="10px" BtnColor="white" 
                    maxWidth="md" ModelTitleColor="white" 
                    TitlePadding="5px" 
                    onClose={this.openWithdrawModalCloseAndOpen} 
                    DialogContentPadding={true} fullWidth 
                    modalTitle="" aria-labelledby="customized-dialog-title" 
                    OpenModel={this.state.openWithdrawModal}>
                <DialogContent>
                    <LogoutModel>
                    <Box className="LogoutModelContainer">
                        <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                            <Box className="QuetionMarkImgClass">
                            <img src={QuetionMarkImg} alt="QuetionMarkImg" />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ProjectText TextSize="28px" fontweight="700" align="center">
                            Withdraw Offer?
                            </ProjectText>
                        </Grid>
                        <Grid item xs={12}>
                            <ProjectText TextSize="1rem" fontweight="400" align="center">
                            Are you sure you want to withdraw this offer (ID: {this.state.withdrawableItem?.attributes?.offer_uid})? This action cannot be undone.
                            </ProjectText>
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end">
                            <ContainedButton onClick={() => this.withdrawFunction(this.state.withdrawableItem?.id)} HoverColor="#DB2525" MarginFalse fullWidth bgcolor="#DB2525">
                            Withdraw
                            </ContainedButton>
                        </Grid>
                        <Grid item xs={4}>
                            <ContainedButton HoverColor="white"
                            TextColor="black" onClick={this.openWithdrawModalCloseAndOpen} BorderColor="black" MarginFalse fullWidth bgcolor="white">
                            CANCEL
                            </ContainedButton>
                        </Grid>
                        </Grid>
                    </Box>
                    </LogoutModel>
                </DialogContent>
                </ProjectModel>
                <ProjectModel 
                    IconButtonBgColor="black" 
                    CloseIconSize="1rem" 
                    BorderRadius="10px" 
                    BtnColor="white" 
                    maxWidth="md" 
                    ModelTitleColor="white" 
                    TitlePadding="5px" 
                    onClose={this.openUpdateModalCloseAndOpen} 
                    DialogContentPadding={true} 
                    fullWidth 
                    modalTitle="" 
                    aria-labelledby="customized-dialog-title" 
                    OpenModel={this.state.openUpdateModal}
                    >
                <DialogContent>
                    <LogoutModel>
                    <Box 
                        className="LogoutModelContainer">
                        <Grid 
                            container 
                            justifyContent="center" 
                            spacing={2}>
                        <Grid 
                            item 
                            xs={12}>
                            <Box 
                                className="QuetionMarkImgClass">
                                <img 
                                src={image_success} 
                                alt="SuccessImg" />
                            </Box>
                        </Grid>
                        <Grid 
                            item xs={12}>
                            <ProjectText 
                                TextSize="28px" 
                                fontweight="700" 
                                align="center">
                            Offer Updated!
                            </ProjectText>
                        </Grid>
                        <Grid 
                            xs={12} item >
                            <ProjectText 
                                TextSize="1rem" 
                                fontweight="400" 
                                align="center">Your offer (ID: {this.state.withdrawableItem?.attributes?.offer_uid}) has been updated successfully and shared with the buyer. You'll be notified when the buyer responds to your offer.
                            </ProjectText>
                        </Grid>
                        </Grid>
                    </Box>
                    </LogoutModel>
                </DialogContent>
                </ProjectModel>
            </div>
            
        );
    }
}

export { SellerPendingOffers };
export default withRouter((withStyles)(useStyles)(SellerPendingOffers));

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.25)", 
        backgroundColor: theme.palette.common.white, 
        color: "black", 
        fontSize: 14,
        display: " inline-flex",
        justifyContent: "center",
        borderRadius: "8px",
        alignItems: "center",
        fontFamily: "Asap",
        padding: "8px 16px",
        whiteSpace:"nowrap"
    },
    arrow: { color: "white" },
}))(Tooltip);

const CustomContentBox=styled(Box)({
    background: "#FFF",
    margin: "0px",
    display: "flex",
    padding: "25px 20px",
    justifyContent: "space-between",
    "@media (max-width: 1280px)": {
      display: "block",
     },
  })

  const StyleModel = styled(Box)({
    "& .PhoneInputPackgeClass": {
      width: "100%",
      height: "40px",
      borderRadius: "10px",
      fontSize: "15px",
      fontFamily: "Asap, sans-serif",
      fontWeight: 500, 
      backgroundColor: "white",
      "@media (max-width: 1200px)": {
        fontSize: "12px",
        height: "30px",
      },
    },
    "& .dial-code": {
      fontFamily: "Asap, sans-serif",
      color: "black !important",
      fontWeight: "500",
    },
    "& .no-entries-message": {
      fontFamily: "Asap, sans-serif",color: "black !important",
      fontWeight: "500",
    },
    "& .country-name": {
      fontFamily: "Asap, sans-serif",
    },
    "& .ErrorPhoneClass": {
      "& .PhoneInputPackgeClass": {
        backgroundColor: "#FBE9E9",
      },
      "& .flag-dropdown": {
        backgroundColor: "#FBE9E9",
      },
      "& .selected-flag": {
        backgroundColor: "#FBE9E9",
      },
    },
    "& .flag-dropdown": {
      borderRight: "none",
      borderRadius: "10px 0px 0px 10px",
    },
    "& .selected-flag": {
      backgroundColor: "white",
      borderRadius: "10px",
    },
    "& .PhoneInput": {
      position: "relative",
      "& .PhoneError": {
        fontSize: "18px", 
        color: "red",
        margin: "6px 14px", 
        position: "absolute",
      },
    },
    "& .CodesMultiSelect": {
          height: "56px"
      },
      "& .AutoComplateTextField": {
          color: "#3CA8D6",
      },
      "& .DeleteChip": {
          margin: "0 2px", 
          backgroundColor: "#e3e6ed",
          color: "black",
          borde: "none",
          fontWeight: "700"
      },
     
      "& .MuiAutocomplete-inputRoot":{    
          fontFamily:'asap'
        },
      "& .MultiSelect": {
          "& .MuiOutlinedInput-input": {
              padding: "5px 12px",
              height: "33px"
          }
      },
      "& .ViewlessContainer": {
          "& .MuiInputBase-input .Mui-disabled": {
              color: "black"
          }
      },
      "& .SellerMultipleSelect": {
          "& .SellerReactSelects__control": {
              borderRadius: "10px",
          }
      },
      "& .ErrorTextContainer": {
          display: "flex",
          marginTop: "5px",
          alignItems: "center",
          "& .TextHidden": {
              visibility: "hidden"
          },
          "& .ErrorIconContainer": {
              marginRight: "6px",
              "& .MuiSvgIcon-root": {
                  color: "red"
              }
          },
          "& .TextError": {
              color: "red",fontSize: "16px",
              marginBottom: "0px",
              fontFamily: "Asap, sans-serif",
              transition: "width 1s ease-out ",
          },
      },
      "& .centerGrid": {
          justifyContent: "center"},
      "& .AddContainerFirstButton": {
          display: "flex",
          justifyContent: "flex-end",whiteSpace: "nowrap",
          "& .Mui-disabled":{
              backgroundColor: "lightgrey",color: "grey",
              "& .BlackPlusIcon": 
              {
                  filter: "brightness(1)" 
                },
          },
          "@media (max-width: 960px)": {
              display: "flex",justifyContent: "center" 
            }
      },
      "& .SlashClassContainer": 
      {
          position: "relative",
          "& .SlashClass":
           {
              display: "flex", justifyContent: "center",
              alignItems: "center",position: "absolute",
              transform: "translate(40%, 100%)"
          },
      },
      "& .CenterAddContainer": 
      {
          display: "flex",justifyContent: "center !important",
          "& .Mui-disabled": 
          {
              backgroundColor: "lightgrey", color: "grey"
          },
          "@media (max-width: 960px)":
           {
              display: "flex",justifyContent: "center" 
            }
      },
      "& .CurrencyBox": {
          marginRight: "5px"
      },
      "& .ViewMoreContainer": {
          position: "absolute", right: "20px",
          "@media (max-width: 600px)": {
              marginTop: "20px"
          }
      },
      "& .BlackPlusIcon": {
          filter: "brightness(0)"
      },
      "& .AddContainerSecondButton": {
          display: "flex",justifyContent: "flex-start",
          "& .Mui-disabled": {
              backgroundColor: "lightgrey",color: "grey" }
      },
      "& .AddContainerSecondButtonCenter": {
          display: "flex",
          justifyContent: "flex-start",
          "& .Mui-disabled": {
              backgroundColor: "lightgrey",color: "grey"
          },
          "@media (max-width: 960px)": {
              display: "flex",
              justifyContent: "center"
          }
      },
      "& .DivedSection": {
          "& .MuiDivider-root": {
              backgroundColor: "white",
              height: "5px"
          }
      },
  });

const webStyle = {
  boxtextp:{
    marginLeft:"4px",
    height: "fit-content",
    fontSize: "13px",
    width: "fit-content",    
    fontStyle: "normal",
    fontWeight: 900,
    fontFamily: "Asap",
    padding: "4px 16px 4px",
    background: "rgb(184, 213, 249)",
    borderRadius:"4px",    
    whiteSpace: "nowrap" as "nowrap",
  },
    textCenter: { 
      textAlign: "center" as "center",
   },
    pTB50: { padding: "50px 0", },
    selectSlotItem: {
        display: 'flex',
        width:'100%',
        height: '36px',
        justifyContent: 'space-between', 
        alignItems: 'center',paddingLeft: '16px'
      },
      noData:{
        display: "flex",
        fontFamily: "Asap",
       justifyContent: "center",alignItems: "center",
        paddingTop: "96px",
      },
       noDataImg: {
        height: "80px",
      },
       head : {
        margin: "8px",
        fontFamily: "Asap",
        fontSize: "32px",
      },
       msg: {
        margin: "0px",
        fontFamily: "Asap",
        fontSize: "16px",
      },
};

const DateContainer = styled(Box)({
    "& .ButtonContaienr": {
      display: "flex", position: "absolute",  
      justifyContent: "flex-end", bottom: "2px", 
      right: "10px",  zIndex: 9,  
      padding: ".625rem .375rem"
    },
    "& .DateMainClassName": {
      "& .MuiInputBase-input":{
        fontFamily: "Asap, sans-serif",
      },
      "& .MuiPaper-root": {
        paddingBottom: "20px",
        "& .MuiGrid-wrap-xs-nowrap":{
          "&.MuiGrid-root":{
            "& .materialui-daterange-picker-MuiList-root-59":{ 
              display:"none"  
            }
          }
        }
      },
      "& .MuiTypography-root": {  
        fontWeight: "500 !important",
      fontFamily: 'Asap !important' 
    },
      "& .materialui-daterange-picker-makeStyles-highlighted-57":{ color: "red !important" }
    },
    "& ul":{ marginBottom:"20px" },
    "& li":{ fontFamily: "Asap, sans-serif"}
  })
  
  const DatePickerStyle = styled(DateRangePicker)({
    "& .MuiInputLabel-animated": { 
      transition: "unset" 
    },
    "& .DateMainClassName": {
      "& .MuiPaper-root": { 
        paddingBottom: "60px !important", 
      backgroundColor: "grey" 
    }
    }
  })

  const CalendarImgBox = styled(Box)({
    root: { position:"relative"
    }, "& img": {
      height:"24px",
      width:"24px",
      position:"absolute",
      top:"32px",
      zIndex:1,
      right:"12px",  
      cursor:"pointer"
    }
  });
  const DropdownArrowBox = styled(Box)({
    root: {
      position: "relative"
    },
    "& img": {
      height: "10px", width: "10px", 
      position: "absolute",top: "15px", 
      zIndex: 1,  right: "20px",
      cursor: "pointer"
  
    }
  });

  const CustomFormControlLabel = styled(FormControlLabel)({
    "& span": {
      fontFamily: "Asap"
    }
  })

// Customizable Area End