import React from "react";
// Customizable Area Start
import {
  Box, 
  Button,
  Checkbox, 
  Container,
  Dialog, 
  DialogActions,
  Grid, 
  IconButton,
  Popper, 
  Typography,
  styled, 
  Accordion,
  AccordionDetails, 
  AccordionSummary,
  TextField, 
  FormControl,
  Tab, 
  Tabs,
  InputBase, 
  InputLabel,
  withStyles, 
  Snackbar,
  Chip,
  SnackbarContent, 
  ClickAwayListener,
  Collapse, 
  FormControlLabel, 
  Tooltip
} from "@material-ui/core";
import {
  AddcloseImg, 
  Matches,
  SuccessGrad, 
  arraw,
  circleAdd, 
  pdf,
  redQue, 
  downIMg,
  resultNotFound,
  upIMg, 
  deleteImg,
  dots, 
  image_dropdown,
  resultNotFoundRed,
  DateTimeIcon,
  RemoveIcon,
  orangeQue
} from "./assets";
import LandingPageTextField, {
  OptionForSelect,
  ProjectTextField,
} from "../../../components/src/ProjectTextField";
import ProjectText from "../../../components/src/ProjectText";
import BuyerMatchesController, { 
  configJSON 
} from "./BuyerMatchesController";
import CustomizedSteppers from "../../../components/src/StepFile.web";
import VeticalFile from "../../../components/src/VerticalFile.web";
import { 
  Close 
} from "@material-ui/icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { 
  Formik, 
  FormikErrors, 
  FormikTouched
} from "formik";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import Navbar from "../../../components/src/NavBar.web";
import BuyerToggelComponentWeb from "../../../components/src/BuyerToggelComponent.web";
import { 
  DateRangePicker, 
  DateRange 
} from "materialui-daterange-picker";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { 
  CustomBox, 
  SectionWrapperLeft 
} from "./SellerListing.web";
import Spinner from "../../../components/src/ProjectLoader";

// Customizable Area End



 
const StyledDialog = styled(Dialog)(
  
  {

  "& .MuiDialog-paper": { 
    width: "100%",
    height: "100%", 
    maxWidth: "800px",
    maxHeight: "720px", 
    borderRadius: "24px",
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper": { 
    width: "100%",
    height: "100%", 
    maxWidth: "800px",
    maxHeight: "720px", 
    borderRadius: "24px",
    overflowY: "auto", // Enable vertical scrolling
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar": {
    width: "8px",
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "4px",
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#888",
  },
  
  "& .titleTypography": 
  {
    textAlign: "left",
    color: "#1E1E1E",
    fontFamily: "Asap",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",
    fontSize: "14px"
  },
  "& .pending": 
  {
    lineHeight: "29px", 
    color: "#EE8F00",
    fontSize: "14px",
    fontFamily: "Asap",
    fontStyle: "normal",
    fontWeight: 700
  },
  "& .smallText": {
    marginTop: '8px',
    color: "#363636", 
     fontFamily: "Asap",
     lineHeight: "20px",
      fontStyle: "normal",
      fontWeight: 500, 
    fontSize: "14px" 
  },
  "& .dialogTitle": {
    color: "#1E1E1E",
    fontFamily: "Asap", 
    fontWeight: 700, 
    fontSize: "28px",
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center" as const
  },
  "& .CancleBtn": {
    display: "flex",
    justifyContent: "center",
    lineHeight: "16px",
    alignItems: "center", 
    background: "#DB2525",
    color: "var(--Basic-White, #FFF)",
    margin: "auto",
    borderRadius: "20px",
    padding: "6px 10px",
    fontStyle: "normal",
    fontFamily: "Asap",
    width: "auto",
    fontSize: "14px",
    height: "32px",
    fontWeight: 700,
    textTransform: "uppercase"
  },
  "& .CancleBtnDisabled": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "16px",
    background: "gray",
    color: "var(--Basic-White, #FFF)",
    margin: "auto",
    borderRadius: "20px",
    padding: "6px 10px",
    fontStyle: "normal",
    fontFamily: "Asap",
    width: "auto",
    fontSize: "14px", 
    height: "32px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  "& .icon": {
    color: "#a31c1c",
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: "4em",
    justifyContent: "center",
  },

  "& .topBox": {
    background: "#DCECFF",
    margin: "0", 
    padding: "0",
    display: "flex",
    flexDirection: "column" as const, 
    position: "relative",
    width: "100%",  
  },
  "& .stepperBox": 
  {
    padding: "8%",
  },
  "& .headerDialod": 
  {
    marginTop: "35px", 
    marginBottom:"20px",  
    position: "relative"
  },
  "& .closeIconHeader": 
  {
    position: "absolute", 
    zIndex: 1,
    background: "black", 
    right: "10px",
    top: "7px", 
    border: "1px solid black",
    borderRadius: "50%",
    padding: "0",
    margin: "0", 
    color: "white",
    height: "22px",
    display: "flex",
    justifyContent: "center", 
    width: "22px",
    alignContent: "center" as const,
    "& button": 
    {
      color: "#fff",
    }
  },
  "& .midSmallcard" : { 
    display: "flex",
    flexDirection: 'column',
    width: "129px"
  },
  "& .midSmallcards1" : { 
    paddingLeft: '15px',
    width: "100%",
    paddingTop: '10px',
    flexDirection: 'column',
  },

  "& .midCardSection": 
  {
    display: "flex",
      justifyContent:"space-around",
      width: "100%",
      gap:"10px",
     margin: "20px 0",
     padding:"0 20px",
      "@media (max-width: 768px)": {
        display:"grid", 
        gridTemplateColumns:"repeat(2,1fr)",
        justifyContent:"center", 
        gap:"16px",
        paddingLeft:"16px"
   
       },
  },

  "& .stepperBoxcancel": { 
    textAlign: "center", 
    margin: "5%",
  },
  "& .nominationCancel": {
     color: "black",
     fontStyle: "normal", 
   fontWeight: 700, 
   fontFamily: "Asap",  
    fontSize: "24px", 
  },
  "& .nominationCanceltext": 
  {
    color: "black",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
     fontFamily: "Asap",
  },
  "& .nominationCancelbuttons": {
    margin:"1%", 
    background: "#D22", 
    alignItems: "center",
    fontFamily: "Asap", 
    borderRadius: "32px",
    fontWeight: 700,
    color: " #FFF",
    width: "240px",
    lineHeight: "16px",
    height: " 32px", 
    display: " flex",
    padding: "25px 70px", 
    fontSize: "14px"
  },
  "& .nominationCancelbuttonsOutlined": {
    margin:"1%", 
    borderColor: "#D22", 
    alignItems: "center",
    fontFamily: "Asap", 
    borderRadius: "32px",
    fontWeight: 700,
    color: "#D22",
    width: "236px",
    lineHeight: "16px",
    height: "28px", 
    display: " flex",
    padding: "25px 70px", 
    fontSize: "14px",
    borderWidth: '1px'
  },

  "& .nominateCancelgrid": { 
    display: "inline-flex ",
    margin: "3% !important",
  },

}
);

const LightTooltip = withStyles((theme) => ({
  tooltip: { 
    boxShadow: "0rem .375rem .9375rem -0.1875rem rgba(0, 0, 0, 0.25)", 
  backgroundColor: theme.palette.common.white, 
  color: "black", 
  fontSize: "12px", 
  display: " inline-flex", 
  justifyContent: "center", 
  borderRadius: ".5rem", 
  alignItems: "center",
  fontFamily: "Asap, sans-serif",},
  arrow: {color: "white"}
}))(Tooltip);

export default class BuyerMatches extends BuyerMatchesController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  sucessmessage(Message: string, Text: string) {
    return (
      <Grid container>
        <Grid item className="nominationClose" lg={12} md={12} sm={12} xs={12}>
          <img
            className="nominationCloseimg"
            id="closeSucessmodal"
            data-testid="closeSucessmodal"
            onClick={() => {
              this.handleClose(Message);
            }}
            src={AddcloseImg}
            alt=""
          />
        </Grid>
        <Grid
          className="nominationMsggrid"
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <img className="nominationErrorimg" src={SuccessGrad} alt="" />
          <Typography className="nominationHeading">{Message}</Typography>
          <Typography className="nominationLinktext">{Text}</Typography>
        </Grid>
      </Grid>
    );
  }

  getInitialValue = (value: any) => value || "";

  getShipperInitialValues = () => {
    const { state } = this;
    return {
      ShipperCompanyName: this.getInitialValue(state.ShipperCompanyName),
      ShipperPersonInCharge: this.getInitialValue(state.ShipperPersonInCharge),
      Address: this.getInitialValue(state.Address),
      AddressLine: this.getInitialValue(state.AddressLine),
      City: this.getInitialValue(state.City),
      State: this.getInitialValue(state.State),
      Postal: this.getInitialValue(state.Postal),
      Country: this.getInitialValue(state.Country),
      ShipperEmail: this.getInitialValue(state.ShipperEmail),
      ShipperContact: this.getInitialValue(state.ShipperContact),
    };
  };

  getConsigneeInitialValues = () => {
    const { state } = this;
    return {
      ConsigneeCompanyName: this.getInitialValue(state.ConsigneeCompanyName),
      ConsigneePersonInCharge: this.getInitialValue(
        state.ShipperPersonInCharge
      ),
      ConsigneeAddress: this.getInitialValue(state.ConsigneeAddress),
      ConsigneeAddressLine: this.getInitialValue(state.ConsigneeAddressLine),
      ConsigneeCity: this.getInitialValue(state.ConsigneeCity),
      ConsigneeState: this.getInitialValue(state.ConsigneeState),
      ConsigneePostal: this.getInitialValue(state.ConsigneePostal),
      ConsigneeCountry: this.getInitialValue(state.ConsigneeCountry),
      ConsigneeEmail: this.getInitialValue(state.ConsigneeEmail),
      ConsigneeTaxID: this.getInitialValue(state.ConsigneeTaxID),
      ConsigneeContact: this.getInitialValue(state.ConsigneeContact),
      TaxId:this.getInitialValue(state.TaxId)
    };
  };

  BuyerShippingError = (
    BuyerErrorMessage:
      | string
      | undefined
      | string[]
      | FormikErrors<any>
      | FormikErrors<any>[],
    BuyerTextShow: boolean
  ) => {
    return (
      BuyerErrorMessage !== undefined &&
      BuyerTextShow && (
        <Box className="ErrorTextContainer">
          <ProjectText className="buyerTexterrorMsg">
            {typeof BuyerErrorMessage === "string" ? BuyerErrorMessage : ""}
          </ProjectText>
        </Box>
      )
    );
  };

  AddContainerTypeButtonIsDisableOrNot = (
    values:{
      ContainerDetails:Array<{
      ContainerNumber: string; 
      HsCode: string; 
      PackingCount: string|number; 
      CargoDescription: string;
      ContainerSize: string; 
      SealNumber: string; 
      PackingType: string; 
      GWT: string| number; 
    }>
  })=>{
    const DisableorNot = values.ContainerDetails?.map((
      data: {
        PackingType: string; 
        CargoDescription: string;
        ContainerSize: string; 
        ContainerNumber: string|number; 
      SealNumber: string; 
      HsCode: string; 
      PackingCount: string|number; 
      GWT: string|number; 
    }) => {
      if(data.ContainerNumber === "" 
      || data.ContainerSize === "" 
      || data.SealNumber === "" 
      || data.HsCode === "" 
      || data.PackingCount === "" 
      || data.PackingType === "" 
      || data.GWT === "" 
      || data.CargoDescription === "" 
      ){
        return ""
      }
    }).includes("")
    return DisableorNot
  }


  ConsigneeAccordionRender = (
    errors: FormikErrors<{ 
      ConsigneePostal: string, 
      ConsigneeCountry: string, 
      ConsigneeContact: string, 
      ConsigneeAddress: string, 
      ConsigneeEmail: string, 
      ConsigneeTaxID: string, 
      ConsigneeAddressLine: string, 
      ConsigneeCity: string, 
      ConsigneeState: string, 
      ConsigneePersonInCharge: string, 
      ConsigneeCompanyName: string, 
    }>,
    touched: FormikTouched<{ 
      ConsigneeCity: string, 
      ConsigneeCompanyName: string, 
      ConsigneeAddress: string, 
      ConsigneeAddressLine: string, 
      ConsigneeCountry: string, 
      ConsigneeState: string, 
      ConsigneeEmail: string, 
      ConsigneeTaxID: string, 
      ConsigneePersonInCharge: string, 
      ConsigneeContact: string, 
      ConsigneePostal: string, 
    }>,
    values: { 
      ConsigneeEmail: string, 
      ConsigneeTaxID: string, 
      ConsigneeCountry: string, 
      ConsigneeContact: string, 
      ConsigneePersonInCharge: string, 
      ConsigneeCompanyName: string, 
      ConsigneeAddressLine: string, 
      ConsigneeCity: string, 
      ConsigneeState: string, 
      ConsigneePostal: string|number, 
      ConsigneeAddress: string, 
    },
    setFieldValue: (
      field: string, 
      value: string, 
      shouldValidate?: boolean | undefined
      ) => void
      ) => {
    return(
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="submittingSecondgrid"
      >
        <Accordion
          className="submittingAccordiongrid"
          expanded={this.state.consigneeAccordion}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="vesselInformationtext"
              >
                {configJSON.consigneedetails}
                {this.state.consigneeAccordion ? (
                  <span
                    onClick={this.handleConsigneeaccordion}
                    className="vesselViewlesstext"
                  >
                    <img
                      className="vesselCloseimg"
                      src={upIMg}
                      alt=""
                    />
                    {configJSON.viewless}
                  </span>
                ) : (
                  <span
                    onClick={this.handleConsigneeaccordion}
                    className="vesselViewlesstext"
                  >
                    <img
                      className="vesselCloseimg"
                      src={downIMg}
                      alt=""
                    />
                    {configJSON.viewmore}
                  </span>
                )}
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.consigneename}
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  data-testid="ConsigneeCompanyName"
                  helperText={
                    errors.ConsigneeCompanyName !== undefined &&
                    touched.ConsigneeCompanyName &&
                    errors.ConsigneeCompanyName
                  }
                  value={values.ConsigneeCompanyName}
                  id="ConsigneeCompanyName"
                  name="ConsigneeCompanyName"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneeCompanyName",
                      setFieldValue
                    )
                  }
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.consigneecharge}
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  data-testid="ConsigneePersonInCharge"
                  helperText={
                    errors.ConsigneePersonInCharge !== undefined &&
                    touched.ConsigneePersonInCharge &&
                    errors.ConsigneePersonInCharge
                  }
                  value={values.ConsigneePersonInCharge}
                  id="ConsigneePersonInCharge"
                  name="ConsigneePersonInCharge"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneePersonInCharge",
                      setFieldValue
                    )
                  }
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordionDetails">
            <Grid container>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "start", marginLeft: "1%" }}
                className="vesselsubtext"
              >
                {configJSON.consigneeaddress}
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.streetaddressone}
                </Typography>

                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  data-testid="ConsigneeAddress"
                  helperText={
                    errors.ConsigneeAddress !== undefined &&
                    touched.ConsigneeAddress &&
                    errors.ConsigneeAddress
                  }
                  value={values.ConsigneeAddress}
                  id="ConsigneeAddress"
                  name="ConsigneeAddress"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneeAddress",
                      setFieldValue
                    )
                  }
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.streetaddresstwo}
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  data-testid="ConsigneeAddressLine"
                  helperText={
                    errors.ConsigneeAddressLine !== undefined &&
                    touched.ConsigneeAddressLine &&
                    errors.ConsigneeAddressLine
                  }
                  value={values.ConsigneeAddressLine}
                  id="ConsigneeAddressLine"
                  name="ConsigneeAddressLine"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneeAddressLine",
                      setFieldValue
                    )
                  }
                />
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.city}
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  data-testid="ConsigneeCity"
                  helperText={
                    errors.ConsigneeCity !== undefined &&
                    touched.ConsigneeCity &&
                    errors.ConsigneeCity
                  }
                  value={values.ConsigneeCity}
                  id="ConsigneeCity"
                  name="ConsigneeCity"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneeCity",
                      setFieldValue
                    )
                  }
                />
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.state}{" "}
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  data-testid="ConsigneeState"
                  helperText={
                    errors.ConsigneeState !== undefined &&
                    touched.ConsigneeState &&
                    errors.ConsigneeState
                  }
                  value={values.ConsigneeState}
                  id="ConsigneeState"
                  name="ConsigneeState"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneeState",
                      setFieldValue
                    )
                  }
                />
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.postal}
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  data-testid="ConsigneePostal"
                  helperText={
                    errors.ConsigneePostal !== undefined &&
                    touched.ConsigneePostal &&
                    errors.ConsigneePostal
                  }
                  value={values.ConsigneePostal}
                  id="ConsigneePostal"
                  name="ConsigneePostal"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneePostal",
                      setFieldValue
                    )
                  }
                />
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.country}
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  helperText={
                    errors.ConsigneeCountry !== undefined &&
                    touched.ConsigneeCountry &&
                    errors.ConsigneeCountry
                  }
                  value={values.ConsigneeCountry}
                  id="ConsigneeCountry"
                  name="ConsigneeCountry"
                  data-testid="ConsigneeCountry"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneeCountry",
                      setFieldValue
                    )
                  }
                />
              </Grid>

              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.consigneeemail}
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  helperText={
                    errors.ConsigneeEmail !== undefined &&
                    touched.ConsigneeEmail &&
                    errors.ConsigneeEmail
                  }
                  value={values.ConsigneeEmail}
                  id="ConsigneeEmail"
                  name="ConsigneeEmail"
                  data-testid="ConsigneeEmail"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneeEmail",
                      setFieldValue
                    )
                  }
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  {configJSON.consigneecontact}
                </Typography>
                <PhoneInput
                  country={"sg"}
                  placeholder={""}
                  value={`${values.ConsigneeContact}`}
                  onChange={(e) =>
                    this.PhoneInputOnChange(
                      e,
                      "ConsigneeContact",
                      setFieldValue
                    )
                  }
                  searchPlaceholder="Search for countries"
                  data-testid="ConsigneeContact"
                  inputProps={{
                    required: true,
                    id: "ConsigneeContact",
                    name: "ConsigneeContact",
                  }}
                  countryCodeEditable={false}
                  enableSearch={true}
                  jumpCursorToEnd={true}
                  inputClass={`PhoneInputPackgeClass`}
                />
                {this.BuyerShippingError(
                  errors.ConsigneeContact,
                  errors.ConsigneeContact !== undefined &&
                  touched.ConsigneeContact !== undefined
                )}
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                  TAX ID {" "} <span style={{fontWeight: 200, fontSize: '14px', textTransform: 'capitalize', marginLeft: '5px'}}>(optional)</span>
                <LightTooltip
                    title="Full legal name of your organisation as registered with the local authority."
                    placement="right"
                    className="tooltip"
                  >
                    <InfoOutlinedIcon className="infoIcon" style={{fontSize:"20px",paddingLeft:"5px"}} />
                  </LightTooltip>
                </Typography>
                <LandingPageTextField
                  BoderRadius="10px"
                  variant="outlined"
                  size="small"
                  type="text"
                  helperText={
                    errors.ConsigneeTaxID !== undefined &&
                    touched.ConsigneeTaxID &&
                    errors.ConsigneeTaxID
                  }
                  value={values.ConsigneeTaxID}
                  id="ConsigneeTaxID"
                  name="ConsigneeTaxID"
                  data-testid="ConsigneeTaxID"
                  fullWidth
                  onChange={(e) =>
                    this.AddBuyerListingFormTextFieldChange(
                      e,
                      "ConsigneeTaxID",
                      setFieldValue
                    )
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }

  AccordianFirstCondtion = (
    index:number,
    ArraryLength:boolean,
    setFieldValue:any,
    values:{
    ContainerDetails:Array<{
      ContainerNumber: string; 
      ContainerType: string; 
      ContainerSize: string; 
      AverageTons: string | never[]; 
      SealNumber: string; 
      HsCode: string; 
      PackingType: string; 
      PackingCount: string|number; 
      GWT: string | number; 
      CargoDescription: string;
    }>
  },
  )=>{


    return(
      <Grid
        lg={12} md={12} sm={12} xs={12}
        className="vesselInformationtext"
      >
        <Typography className="vesselInformationtext">
        {this.ContainerDetailsheadingRedner(index)}

          <span
            onClick={() => this.toggleAccordion(index)}
            className="vesselViewlesstext"
          >
            <img
              className="vesselCloseimg"
              src={upIMg}
              alt=""
            />
            {configJSON.viewless}
            </span>
            {
              ArraryLength &&
              <span
                onClick={() => {
                  this.toggleAccordion(index);this.removeContainer(index, values.ContainerDetails, setFieldValue);
                }}
                className="vesselRemovetext"
              >
                {configJSON.remove}
              </span>
            }

        </Typography>
      </Grid>
    )
  }

  ContainerDetailsheadingRedner = (index:number)=>{
    const CurrentIndex = index === 0 ? 1: index + 1
    return(
      <span>
        {index === 0 ? 
        configJSON.containerdetails : 
        `Container ${CurrentIndex}`
        }
      </span>
    )
  }

  renderDynamicForm = (
    values: any, 
    ArraryLength: any, 
    errors: any, 
    touched: any, 
    setFieldValue: any
    ) => {
    return(
      <>
        {values.ContainerDetails?.map((item: {
                    ContainerNumber: string;
                    ContainerType: string;
                    ContainerSize: string;
                    AverageTons: string | never[];
                    SealNumber: string;
                    HsCode: string;
                    PackingType: string;
                    PackingCount: string;
                    GWT: string;
                    CargoDescription: string;
                  }, index: number) => {
                    return(
                        <Accordion
                          key={`${String.fromCharCode(94 + index)} this.state.containers`}
                          className="submittingAccordiongrid"
                          expanded={this.state.AccordianOpenIndex === index}
                        >
                          <AccordionSummary
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                          >
                            {this.state.AccordianOpenIndex === index ? (
                              this.AccordianFirstCondtion(index,ArraryLength,setFieldValue,values)
                            ) : (
                              
                                <Grid container>
                                  <Grid
                                    lg={12} md={12} sm={12} xs={12}
                                    className="vesselInformationtext"
                                  >
                                    <Typography className="vesselInformationtext">
                                    {this.ContainerDetailsheadingRedner(index)}
                                    
                                      <span
                                        onClick={() =>
                                          this.toggleAccordion(index)
                                        }
                                        className="vesselViewlesstext"
                                      >
                                        <img
                                          className="vesselCloseimg"
                                          src={downIMg}
                                          alt=""
                                        />
                                        {configJSON.viewmore}
                                      {
                                        ArraryLength &&
                                        <span
                                          onClick={() => {
                                            this.removeContainer(index, values.ContainerDetails, setFieldValue);
                                          }}
                                          className="vesselRemovetext"
                                        >
                                          {configJSON.remove}
                                        </span>
                                      }
                                      </span>
                                    
                                    </Typography>
                                  </Grid>
                                  {this.state.AccordianOpenIndex === index === false  && this.handleContainerDetails(
                                    errors,
                                    touched,
                                    setFieldValue,
                                    item,
                                    index,
                                    values
                                  )}
                                </Grid>
                            )}
                          </AccordionSummary>
                          <AccordionDetails>
                            {this.handleContainerData(
                              errors,
                              touched,
                              setFieldValue,
                              values,
                              index,
                              item
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )
                  })}
      </>
    )
  }

  handleSubmittingmodal() {
    return (
        <Formik
          data-test-id="formik"
          validationSchema={this.Validation}
          initialValues={{
            POLTERMINAL: this.state.POLTERMINAL || "",
            POD: this.state.POD || "",
            PODTERMINAL: this.state.PODTERMINAL || "",
            POL: this.state.POL || "",
            ...this.getShipperInitialValues(),
            ...this.getConsigneeInitialValues(),
            ContainerDetails: [{
              ContainerNumber: this.state.ContainerNumber || "",
              ContainerType: this.state.ContainerType || "",
              ContainerSize: this.state.ContainerSize || "",
              AverageTons: this.state.AverageTons || [],
              SealNumber: this.state.SealNumber || "",
              HsCode: this.state.HsCode || "",
              PackingType: this.state.PackingType || "",
              PackingCount: this.state.PackingCount || "",
              GWT: this.state.GWT || "",
              CargoDescription: this.state.CargoDescription || "",
            }]
          }}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => { this.SubmittShippingApi(values); }}
        >
          {({ errors, touched, setFieldValue, handleSubmit, values, }) => {
          const DisableAddContainerType = this.AddContainerTypeButtonIsDisableOrNot(values)
          const ArraryLength = values.ContainerDetails?.length !== 1;
          const containerSizes = [this.state.selectedBooing?.attributes?.container_size, this.state.selectedBooing?.attributes?.sec_container_sizes];
          const newContainerSizes = containerSizes?.filter(size => size!== null)
            return (
              <form onSubmit={handleSubmit} autoComplete="off" style={{maxHeight: '70vh'}}>
                <Grid item lg={12} md={12} sm={12} xs={12} className="submittinggrid" >
                  {this.handleVesselinformation( errors, touched, setFieldValue, values)}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className="submittingSecondgrid">
                  <Accordion
                    expanded={this.state.shipperAccordion}
                    className="submittingAccordiongrid"
                  >
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header" 
                    >
                      {this.handleShippersummary( errors, touched, setFieldValue, values)}
                    </AccordionSummary>
                    <AccordionDetails  className="accordionDetails" >
                      {this.handleShipperData( errors,  touched, setFieldValue, values )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {this.ConsigneeAccordionRender(errors,touched,values,setFieldValue)}
                <Grid item lg={12} md={12} sm={12} xs={12} className="submittingSecondgrid">
                  {this.renderDynamicForm(values, ArraryLength, errors, touched, setFieldValue)}
                  {
                    
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={`vesselInformationtext ${DisableAddContainerType ? "AddContainerButtonDisable":""}`}
                    >
                      <Button
                        className="addContainerbutton"
                        disabled={DisableAddContainerType}
                        onClick={()=>this.addContainer(values.ContainerDetails,setFieldValue)}
                      >
                        <span>
                          <img
                            className="addContainerimg"
                            src={circleAdd}
                            alt=""
                          />
                        </span>
                        {configJSON.addcontainertype}
                      </Button>
                    </Grid>
                  }
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="vesselInformationtext1"
                >
                  <Button
                    className="nominationButton"
                    data-testid="sucessSubmitting"
                    type="submit"
                  >
                    {configJSON.verifybutton}
                  </Button>
                </Grid>
              </form>
            );
          }}
        </Formik>
    );
  }
  handleShipperData(
    errors: FormikErrors<{
      ConsigneeContact: any;
      POLTERMINAL: any;
      AverageTons: any;
      PODTERMINAL: any;
      ContainerSize: any;
      HsCode: any;
      POD: any;
      ShipperCompanyName: any;
      Country: any;
      POL: any;
      ShipperPersonInCharge: any;
      AddressLine: any;
      ContainerNumber: any;
      PackingType: any;
      City: any;
      State: any;
      ConsigneeState: any;
      Address: any;
      Postal: any;
      GWT: any;
      ShipperEmail: any;
      ShipperContact: any;
      ConsigneeCompanyName: any;
      ConsigneePersonInCharge: any;
      CargoDescription: any;
      ConsigneeAddress: any;
      ConsigneeAddressLine: any;
      ConsigneeCity: any;
      ConsigneePostal: any ;
      PackingCount: any;
      ConsigneeCountry: any;
      ConsigneeEmail: any;
      ConsigneeTaxID: any;
      ContainerType: any;
      SealNumber: any;
    }>,
    touched: FormikTouched<{
      POLTERMINAL: any;
      POD: any;
      PODTERMINAL: any;
      ConsigneePostal: any;
      ConsigneeEmail: any;
      ConsigneeTaxID: any;
      POL: any;
      ShipperCompanyName: any;
      HsCode: any;
      ShipperPersonInCharge: any;
      AverageTons: any;
      Address: any;
      AddressLine: any;
      PackingCount: any;
      City: any;
      State: any;
      Postal: any;
      CargoDescription: any;
      Country: any;
      ShipperEmail: any;
      ContainerSize: any;
      ShipperContact: any;
      ConsigneeCompanyName: any;
      ConsigneePersonInCharge: any;
      ConsigneeContact: any;
      ConsigneeAddress: any;
      ConsigneeAddressLine: any;
      ConsigneeCity: any;
      PackingType: any;
      ConsigneeState: any;
      ConsigneeCountry: any;
      ContainerNumber: any;
      ContainerType: any;
      GWT: any;
      SealNumber: any;
    }
    >,
    setFieldValue: 
    {
      (field: string, value: any, shouldValidate?: boolean | undefined): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
    },
    values: 
    FormikErrors<{
      POL: string;
      POD: string;
      PODTERMINAL: string;
      ShipperCompanyName: string;
      ShipperPersonInCharge: string;
      Address: string;
      ConsigneeEmail: string;
      ConsigneeTaxID: string;
      City: string;
      Postal: string|number;
      ConsigneeState: string;
      Country: string;
      ShipperEmail: string;
      ShipperContact: string;
      ConsigneeCompanyName: string;
      ConsigneePersonInCharge: string;
      ConsigneeAddress: string;
      ConsigneeAddressLine: string;
      ConsigneePostal: string|number;
      State: string;
      ConsigneeCountry: string;
      ConsigneeContact: string;
      ContainerNumber: string;
      POLTERMINAL: string;
      ContainerType: string;
      ContainerSize: string;
      AverageTons: string[];
      ConsigneeCity: string;
      AddressLine: string;
      SealNumber: string;
      HsCode: string;
      PackingType: string;
      PackingCount: string|number;
      GWT: string|number;
      CargoDescription: string;
    }>
  ) {
    return (
      <Grid container>
        <Grid
          lg={12} md={12} sm={12} xs={12}
          style={{ textAlign: "start" }}
          className="vesselsubtext"
        >
          {configJSON.shipperaddress}
        </Grid>
        <Grid
          item
          lg={12} md={12} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.streetaddressone}
          </Typography>

          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="Address"
            helperText={
              errors.Address !== undefined && touched.Address && errors.Address
            }
            value={values.Address}
            id="Address"
            name="Address"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(
                e,
                "Address",
                setFieldValue
              )
            }
          />
        </Grid>
        <Grid
          item
          lg={12} md={12} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.streetaddresstwo}
          </Typography>

          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="AddressLine"
            helperText={
              errors.AddressLine !== undefined &&
              touched.AddressLine &&
              errors.AddressLine
            }
            value={values.AddressLine}
            id="AddressLine"
            name="AddressLine"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(
                e,
                "AddressLine",
                setFieldValue
              )
            }
          />
        </Grid>
        <Grid
          item
          lg={3} md={3} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.city}
          </Typography>

          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="City"
            helperText={
              errors.City !== undefined && touched.City && errors.City
            }
            value={values.City}
            id="City"
            name="City"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(e, "City", setFieldValue)
            }
          />
        </Grid>
        <Grid
          item
          lg={3} md={3} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.state}
          </Typography>

          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="State"
            helperText={
              errors.State !== undefined && touched.State && errors.State
            }
            value={values.State}
            id="State"
            name="State"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(e, "State", setFieldValue)
            }
          />
        </Grid>
        <Grid
          item
          lg={3} md={3} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.postal}
          </Typography>

          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="Postal"
            helperText={
              errors.Postal !== undefined && touched.Postal && errors.Postal
            }
            value={values.Postal}
            id="Postal"
            name="Postal"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(
                e,
                "Postal",
                setFieldValue
              )
            }
          />
        </Grid>
        <Grid
          item
          lg={3} md={3} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.country}
          </Typography>

          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="Country"
            helperText={
              errors.Country !== undefined && touched.Country && errors.Country
            }
            value={values.Country}
            id="Country"
            name="Country"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(
                e,
                "Country",
                setFieldValue
              )
            }
          />
        </Grid>

        <Grid
          item
          lg={6} md={6} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.shipperemail}
          </Typography>

          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="ShipperEmail"
            helperText={
              errors.ShipperEmail !== undefined &&
              touched.ShipperEmail &&
              errors.ShipperEmail
            }
            value={values.ShipperEmail}
            id="ShipperEmail"
            name="ShipperEmail"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(
                e,
                "ShipperEmail",
                setFieldValue
              )
            }
          />
        </Grid>
        <Grid
          item
          lg={6} md={6} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.shippercontact}
          </Typography>
          <PhoneInput
            country={"sg"}
            placeholder={""}
            value={`${values.ShipperContact}`}
            onChange={(e) =>
              this.PhoneInputOnChange(e, "ShipperContact", setFieldValue)
            }
            searchPlaceholder="Search for countries"
            data-test-id="ShipperContact"
            inputProps={{
              required: true,
              id: "ShipperContact",
              name: "ShipperContact",
            }}
            countryCodeEditable={false}
            enableSearch={true}
            jumpCursorToEnd={true}
            inputClass={`PhoneInputPackgeClass`}
          />
          {this.BuyerShippingError(
            errors.ShipperContact,
            errors.ShipperContact !== undefined &&
              touched.ShipperContact !== undefined
          )}
        </Grid>
      </Grid>
    );
  }
  handleShippersummary(
    errors: FormikErrors<{
      POLTERMINAL: any;
      POD: any;
      AddressLine: any;
      PODTERMINAL: any;
      POL: any;
      ShipperCompanyName: any;
      ShipperPersonInCharge: any;
      Address: any;
      City: any;
      ShipperEmail: any;
      ShipperContact: any;
      GWT: any;
      ConsigneeCompanyName: any;
      ConsigneePersonInCharge: any;
      ConsigneeAddress: any;
      ConsigneeAddressLine: any;
      ConsigneeCity: any;
      PackingType: any;
      ConsigneeState: any;
      ConsigneePostal: any;
      ConsigneeCountry: any;
      ConsigneeEmail: any;
      ConsigneeTaxID: any;
      Country: any;
      ContainerNumber: any;
      State: any;
      ContainerType: any;
      ContainerSize: any;
      AverageTons: any;
      SealNumber: any;
      ConsigneeContact: any;
      HsCode: any;
      PackingCount: any;
      CargoDescription: any;
      Postal: any;
    }>
    ,
    touched: FormikTouched<{
      POD: any;
      POL: any;
      ShipperCompanyName: any;
      SealNumber: any;
      Address: any;
      AddressLine: any;
      City: any;
      State: any;
      POLTERMINAL: any;
      Postal: any;
      ShipperEmail: any;
      ShipperContact: any;
      ConsigneeCompanyName: any;
      ConsigneePersonInCharge: any;
      Country: any;
      ConsigneeAddress: any;
      ConsigneeAddressLine: any;
      ConsigneeState: any;
      ConsigneePostal: any;
      ConsigneeCountry: any;
      AverageTons: any;
      PODTERMINAL: any;
      ConsigneeEmail: any;
      ConsigneeTaxID: any;
      ShipperPersonInCharge: any;
      ContainerNumber: any;
      ConsigneeContact: any;
      ContainerType: any;
      ContainerSize: any;
      ConsigneeCity: any;
      HsCode: any;
      PackingType: any;
      PackingCount: any;
      GWT: any;
      CargoDescription: any;
    }>,
    setFieldValue: {
      (field: any, value: any, shouldValidate?: boolean | undefined): void;
      (
        field: any,
        value: any,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: any,
        value: any,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: any,
        value: any,
        shouldValidate?: boolean | undefined
      ): void;
    },
    values: FormikErrors<{
      POLTERMINAL: any;
      POL: any;
      ShipperCompanyName: any;
      ShipperPersonInCharge: any;
      Address: any;
      Postal: any;
      ContainerNumber: any;
      Country: any;
      AverageTons: any;
      ShipperEmail: any;
      ShipperContact: any;
      POD: any;
      ConsigneeCompanyName: any;
      ConsigneePersonInCharge: any;
      PODTERMINAL: any;
      HsCode: any;
      ConsigneeAddress: any;
      ConsigneeAddressLine: any;
      ConsigneeCity: any;
      CargoDescription: any;
      ConsigneeState: any;
      ConsigneePostal: any;
      City: any;
      ConsigneeCountry: any;
      ConsigneeEmail: any;
      ConsigneeTaxID: any;
      AddressLine: any;
      ConsigneeContact: any;
      ContainerType: any;
      State: any;
      ContainerSize: any;
      SealNumber: any;
      PackingType: any;
      PackingCount: any;
      GWT: any;
    }>
  ) {
    return (
      <Grid container>
        <Grid lg={12} md={12} sm={12} xs={12} className="vesselInformationtext">
          {configJSON.shipperdetails}
          {this.state.shipperAccordion ? (
            <span
              onClick={this.handleShipperaccordion}
              className="vesselViewlesstext"
            >
              <img className="vesselCloseimg" src={upIMg} alt="" />
              {configJSON.viewless}
            </span>
          ) : (
            <span
              onClick={this.handleShipperaccordion}
              className="vesselViewlesstext"
            >
              <img className="vesselCloseimg" src={downIMg} alt="" />
              {configJSON.viewmore}
            </span>
          )}
        </Grid>
        <Grid
          item
          lg={6} md={6} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.shippercompany}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="ShipperCompanyName"
            value={values.ShipperCompanyName}
            helperText={
              errors.ShipperCompanyName !== undefined &&
              touched.ShipperCompanyName &&
              errors.ShipperCompanyName
            }
            id="ShipperCompanyName"
            name="ShipperCompanyName"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(
                e,
                "ShipperCompanyName",
                setFieldValue
              )
            }
          />
        </Grid>
        <Grid
          item
          lg={6} md={6} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.shippercharge}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            data-testid="ShipperPersonInCharge"
            helperText={
              errors.ShipperPersonInCharge !== undefined &&
              touched.ShipperPersonInCharge &&
              errors.ShipperPersonInCharge
            }
            value={values.ShipperPersonInCharge}
            id="ShipperPersonInCharge"
            name="ShipperPersonInCharge"
            fullWidth
            onChange={(e) =>
              this.AddBuyerListingFormTextFieldChange(
                e,
                "ShipperPersonInCharge",
                setFieldValue
              )
            }
          />
        </Grid>
      </Grid>
    );
  }

  handleVesselinformation(
    errors: FormikErrors<{ POLTERMINAL: any; POD: any; PODTERMINAL: any; Postal: any; ShipperCompanyName: any; ShipperPersonInCharge: any; Address: any; ConsigneeCity: any; ConsigneeCompanyName: any; AddressLine: any; AverageTons: any; City: any; State: any; Country: any; ShipperEmail: any; ShipperContact: any; ConsigneePersonInCharge: any; ConsigneeAddress: any; ConsigneeAddressLine: any; HsCode: any; ConsigneeState: any; ConsigneePostal: any; ConsigneeCountry: any; ConsigneeContact: any; ContainerNumber: any; ConsigneeEmail: any; ConsigneeTaxID: any; ContainerType: any; GWT: any; ContainerSize: any; SealNumber: any; PackingType: any; PackingCount: any; CargoDescription: any; }>
    ,
    touched: FormikTouched<{ POLTERMINAL: any; PODTERMINAL: any; POL: any; ShipperCompanyName: any; ConsigneeAddress: any; ShipperPersonInCharge: any; AddressLine: any; City: any; State: any; ConsigneeContact: any; Postal: any; Country: any; HsCode: any; ShipperEmail: any; ShipperContact: any; Address: any; ConsigneeCompanyName: any; ConsigneePersonInCharge: any; ConsigneeAddressLine: any; GWT: any; CargoDescription: any; POD: any; ConsigneeCity: any; ConsigneeState: any; ContainerNumber: any; ConsigneePostal: any; ConsigneeCountry: any; PackingType: any; ConsigneeEmail: any; ConsigneeTaxID: any; ContainerType: any; ContainerSize: any; AverageTons: any; SealNumber: any; PackingCount: any;
    }>,
    setFieldValue: {
      (field: string, value: any, shouldValidate?: boolean | undefined): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
    },
    values: FormikErrors<{
      POLTERMINAL: string;
      POD: string;
      PODTERMINAL: string;
      ShipperCompanyName: string;
      ShipperPersonInCharge: string;
      Address: string;
      City: string;
      PackingType: string;
      State: string;
      Postal: string;
      Country: string;
      ShipperEmail: string;
      ShipperContact: string;
      ConsigneeCompanyName: string;
      CargoDescription: string;
      ConsigneePersonInCharge: string;
      POL: string;
      ConsigneeContact: string;
      ConsigneeCity: string;
      ConsigneeState: string;
      ConsigneeCountry: string;
      ConsigneeEmail: string;
      ConsigneeTaxID: string;
      AddressLine: string;
      ContainerNumber: string;
      GWT: string;
      ContainerType: string;
      ConsigneeAddressLine: string;
      ContainerSize: string;
      AverageTons: string[];
      ConsigneeAddress: string;
      ConsigneePostal: string;
      SealNumber: string;
      HsCode: string;
      PackingCount: string;
    }>
  ) {
    return (
      <Grid container>
        <Grid lg={12} md={12} sm={12} xs={12} className="vesselInformationtext">
          {configJSON.vesselinformation}
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.polText}
          </Typography>
          <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>{this.state.selectedBooing?.attributes.pol}</text></div>
        </Grid>
        
        <Grid item lg={3} md={3} sm={12} xs={12} className="vesselTextfieldgrid" > <Typography className="vesselTextfieldtext">
            {configJSON.podText}
          </Typography>
              <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>{this.state.selectedBooing?.attributes.pod}</text></div>
            </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} className="vesselTextfieldgrid" >
          <Typography className="vesselTextfieldtext"> {configJSON.podterminal} </Typography> 
          <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>{this.state.selectedBooing?.attributes.pod_terminal}</text></div>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext1">
            {configJSON.polterminal} </Typography>
            <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
              {this.state.selectedBooing?.attributes.offer_quantity + this.state.selectedBooing?.attributes?.offer_sec_quantity*2}
              </text></div>
          </Grid>
      </Grid>
    );
  }
  handleContainerDetails(
    errors:FormikErrors<{ ContainerDetails:Array<{
      ContainerNumber: string,
      ContainerType: string,
      ContainerSize: string,
      AverageTons: string[],
      SealNumber: string,
      HsCode: string,
      PackingType: string,
      PackingCount: string,
      GWT: string,
      CargoDescription: string,
    }> }>,
    touched: FormikTouched<{
      POD: string; POLTERMINAL: string;
      PODTERMINAL: string;
      ConsigneeState: string; ShipperContact: string;
      ShipperCompanyName: string; ShipperPersonInCharge: string;
      Address: string;  ContainerNumber: string;
      AddressLine: string; City: string;
      POL: string; State: string;
      Postal: string; HsCode: string;
      ConsigneePostal: string; PackingCount: string;
      Country: string; ShipperEmail: string;
      ConsigneeCompanyName: string; ConsigneePersonInCharge: string;
      ConsigneeAddress: string; ConsigneeCountry: string;
      ConsigneeAddressLine: string; ConsigneeCity: string;
      ConsigneeEmail: string; 
      ConsigneeTaxID: string; 
      ConsigneeContact: string;
      CargoDescription: string; ContainerType: string;
      AverageTons: string[]; SealNumber: string;
      ContainerSize: string; PackingType: string;
      GWT: string;
    }>,
    setFieldValue: {
      (field: string, value: any, shouldValidate?: boolean | undefined): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
    },
    DynamicValues:{
      ContainerNumber: string,
      ContainerType: string,
      ContainerSize: string,
      AverageTons: string[] | string,
      SealNumber: string,
      HsCode: string,
      PackingType: string,
      PackingCount: string,
      GWT: string,
      CargoDescription: string,
    },
    index:number,
    values: any,
  ) {
    return (
      <Grid container>
        <Grid
          item
          lg={3} md={6} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containernumber}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            value={DynamicValues.ContainerNumber}
            id="ContainerNumber"
            name="ContainerNumber"
            fullWidth
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "ContainerNumber",
                setFieldValue,
                index
              )
            }
          />
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors, "ContainerNumber", index,touched),
            !!this.DynamicErrorRender(errors, "ContainerNumber", index,touched))}
        </Grid>
        <Grid item
          lg={3}  md={6}
          sm={12} xs={12}
          className="vesselTextfieldgrid"  >
          <Typography 
          className="vesselTextfieldtext"
          >
            {configJSON.containerstatus} </Typography>
          <div
          style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}>
            <text style = {{fontFamily: 'Asap', color: '#000', fontWeight: 600}}>{this.state.selectedBooing?.attributes?.container_type}
            </text>
            </div>
        </Grid>
        <Grid
          item
          lg={3} md={6} sm={12} xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containersize}
          </Typography>
          <ProjectTextField
            variant="outlined"
            fullWidth
            value={DynamicValues.ContainerSize} id="ContainerSize"
            name="ContainerSize"
            onChange={(e) =>this.ContainerDetaisOnChange(e,"ContainerSize",
                setFieldValue,
                index
              )
              } >
            {
              index == 0 ?
            Object.values({ containerSize: this.state.selectedBooing?.attributes?.container_size, 
            containerSecSize: this.state.selectedBooing?.attributes?.sec_container_sizes})
            .filter(size => size !== null && size !== values?.ContainerDetails[1]?.ContainerSize)?.map((ContainerSize: any, index: any) => {
              return (
                <OptionForSelect key={index} 
                value={ContainerSize}>
                  <ProjectText TextSize="14px"
                   variant="h6" 
                  fontweight="600">
                    {ContainerSize}
                  </ProjectText>  </OptionForSelect> );
            })
            :
             Object.values({ containerSize: this.state.selectedBooing?.attributes?.container_size, 
              containerSecSize: this.state.selectedBooing?.attributes?.sec_container_sizes})
              .filter(size => size !== null && size !== values?.ContainerDetails[0]?.ContainerSize)?.map((ContainerSize: any, index: any) => {
                return (
                  <OptionForSelect key={index} 
                  value={ContainerSize}>
                    <ProjectText TextSize="14px"
                     variant="h6" 
                    fontweight="600">
                      {ContainerSize}
                    </ProjectText>  </OptionForSelect> );
              })
          }
          </ProjectTextField>
          {this.BuyerShippingError(this.DynamicErrorRender(errors, "ContainerSize", index,touched),
            !!this.DynamicErrorRender(errors, "ContainerSize", index,touched))}
        </Grid>
        <Grid item
          lg={3} md={6}
          sm={12} xs={12}
          className="vesselTextfieldgrid" >
          <Typography className="vesselTextfieldtext">
            {configJSON.containeraveragetypes}
          </Typography>
          <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}>
            {this.state.selectedBooing?.attributes?.container_code?.map((item: any) => {
                return(
                  <text style = {{fontFamily: 'Asap', color: '#000', padding: '7px 15px', backgroundColor: '#CDCDCD', borderRadius: '15px', marginRight: '10px', fontWeight: 600}}>{item}</text>
                )})}
                </div>
        </Grid>
      </Grid>
    ); }


  DynamicErrorRender = (errors:FormikErrors<{ ContainerDetails:Array<{
    CargoDescription: string,
    ContainerSize: string,
    ContainerNumber: string,
    AverageTons: string[],
    ContainerType: string,
    SealNumber: string,
    HsCode: string,
    GWT: string,
    PackingCount: string,
    PackingType: string,
  }> }>,ErrorKey: "ContainerNumber" |"ContainerType" |"ContainerSize" |"AverageTons" |"SealNumber" |"HsCode" |"PackingType" |"PackingCount" |"GWT" |"CargoDescription",
  index:number,
  touched:FormikTouched<{
    POLTERMINAL: string;
      POD: string;
      PODTERMINAL: string; ConsigneeState: string;
      ShipperContact: string; ShipperCompanyName: string;
      ShipperPersonInCharge: string; Address: string;
      ContainerNumber: string; AddressLine: string;
      City: string; POL: string;
      State: string; HsCode: string;
      ConsigneePostal: string; Postal: string;
      PackingCount: string; Country: string;
      ShipperEmail: string; ConsigneeCompanyName: string;
      ConsigneePersonInCharge: string; ConsigneeAddress: string;
      ConsigneeCountry: string; ConsigneeAddressLine: string;
      ConsigneeCity: string; 
      ConsigneeEmail: string;
      ConsigneeTaxID: string;
      ConsigneeContact: string; CargoDescription: string;
      ContainerType: string; AverageTons: string[];
      SealNumber: string; ContainerSize: string;
      PackingType: string; GWT: string;
    ContainerDetails:Array<{
      ContainerNumber: string,
      ContainerType: string,
      ContainerSize: string,
      AverageTons: string | string[],
      SealNumber: string,
      HsCode: string,
      PackingType: string,
      PackingCount: string,
      GWT: string,
      CargoDescription: string,
    }> | undefined
  }>
  ) =>{
    type ContainerDetails = {
      ContainerNumber?: string;
      ContainerType?: string;
      ContainerSize?: string;
      AverageTons?: string;
      SealNumber?: string;
      HsCode?: string;
      PackingType?: string;
      PackingCount?: string;
      GWT?: string;
      CargoDescription?: string;
    }
    if (errors.ContainerDetails && Array.isArray(touched.ContainerDetails)) {
      const ErrorData = errors.ContainerDetails[index] as ContainerDetails;
      const TouchData = touched.ContainerDetails[index];
      if (ErrorData && TouchData) {
        if (ErrorData.hasOwnProperty(ErrorKey)) {
          return ErrorData[ErrorKey];
        }
      }
    }
  }

  renderContainerType = (errors: any, touched: any, values: any, setFieldValue: any, index: number) => {
    return(
      <>
      <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            onKeyPress={(e)=>{ ((e.which < 65 && e.which !== 32) || (e.which > 122 && e.which !== 32)) && e.preventDefault()}}
            helperText={
              errors.PackingType !== undefined &&
              touched.PackingType &&
              errors.PackingType
            }
            value={values.PackingType}
            id="PackingType"
            name="PackingType"
            fullWidth
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "PackingType",
                setFieldValue,
                index
              )
            }
          />
      </>
    )
  }
  
  handleContainerData(
    errors: FormikErrors<{
      POLTERMINAL: string;
      POD: string;
      POL: string;
      SealNumber: string;
      ConsigneeAddress: string;
      ShipperCompanyName: string;
      ShipperPersonInCharge: string;
      HsCode: string;
      Address: string;
      AddressLine: string;
      City: string;
      State: string;
      Postal: string;
      ConsigneeCountry: string;
      Country: string;
      ShipperEmail: string;
      PODTERMINAL: string;
      ContainerSize: string;
      ShipperContact: string;
      PackingType: string;
      ConsigneeCompanyName: string;
      ConsigneePersonInCharge: string;
      GWT: string;
      ConsigneeAddressLine: string;
      ConsigneeCity: string;
      ConsigneeState: string;
      ConsigneePostal: string;
      ConsigneeEmail: string;
      ConsigneeTaxID: string;
      ConsigneeContact: string;
      ContainerNumber: string;
      ContainerType: string;
      AverageTons: string[];
      PackingCount: string;
      CargoDescription: string;
      ContainerDetails: [{
        ContainerNumber: string,
        ContainerType: string,
        ContainerSize: string,
        AverageTons: string[],
        SealNumber: string,
        HsCode: string,
        PackingType: string,
        PackingCount: string,
        GWT: string,
        CargoDescription: string,
      }]
    }>,
    touched: FormikTouched<{
      POLTERMINAL: string;
      POD: string;
      ContainerSize: string;
      PODTERMINAL: string;
      ConsigneeCity: string;
      ShipperCompanyName: string;
      ShipperPersonInCharge: string;
      Address: string;
      ConsigneeAddress: string;
      AverageTons: string[];
      AddressLine: string;
      State: string;
      Country: string;
      ShipperEmail: string;
      ShipperContact: string;
      PackingType: string;
      ConsigneeCompanyName: string;
      ConsigneeAddressLine: string;
      ConsigneeState: string;
      ConsigneePostal: string;
      ConsigneeCountry: string;
      ConsigneeEmail: string;
      ConsigneeTaxID: string;
      City: string;
      ConsigneeContact: string;
      ConsigneePersonInCharge: string;
      ContainerNumber: string;
      ContainerType: string;
      ContainerDetails: Array<{
        ContainerNumber: string,
        ContainerType: string,
        ContainerSize: string,
        AverageTons: string[],
        SealNumber: string,
        HsCode: string,
        PackingType: string,
        PackingCount: string,
        GWT: string,
        CargoDescription: string,
      }>,
      POL: string;
      SealNumber: string;
      HsCode: string;
      PackingCount: string;
      Postal: string;
      GWT: string;
      CargoDescription: string;
    }>,
    setFieldValue: {
      (field: string, value: any, shouldValidate?: boolean | undefined): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
      (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ): void;
    },
    values: any,
    index:number,
    DynamicValues:{
      ContainerNumber: string,
      ContainerType: string,
      ContainerSize: string,
      AverageTons: string[] | string,
      SealNumber: string,
      HsCode: string,
      PackingType: string,
      PackingCount: string,
      GWT: string,
      CargoDescription: string,
    }
  ) {
    return (
      <Grid container>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containernumber}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            helperText={
              errors.ContainerNumber !== undefined &&
              touched.ContainerNumber &&
              errors.ContainerNumber
            }
            value={DynamicValues.ContainerNumber}
            id="ContainerNumber"
            name="ContainerNumber"
            fullWidth
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "ContainerNumber",
                setFieldValue,
                index
              )
            }
          />
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors,"ContainerNumber",index,touched),
            !!this.DynamicErrorRender(errors,"ContainerNumber",index,touched))}

        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containerstatus}
          </Typography>
          <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000', fontWeight: 600}}>{this.state.selectedBooing?.attributes?.container_type}</text></div>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containersize}
          </Typography>
          <ProjectTextField
            variant="outlined"
            fullWidth
            value={values.ContainerSize}
            id="ContainerSize"
            name="ContainerSize"
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "ContainerSize",
                setFieldValue,
                index
              )
            }
          >
            {
            index == 0 ? 
            Object.values({ 
              containerSize: this.state.selectedBooing?.attributes?.container_size, containerSecSize: 
              this.state.selectedBooing?.attributes?.sec_container_sizes}).map((ContainerSize: any, index: any) => {
              return (
                <OptionForSelect  
                key={index} 
                value=
                {ContainerSize}
                >
                  <ProjectText 
                  TextSize="14px" 
                  variant="h6" fontweight="600"
                  > {ContainerSize} </ProjectText>
                </OptionForSelect>
              );
            })
            :
            Object.values({ 
              containerSize: this.state.selectedBooing?.attributes?.container_size, containerSecSize: 
              this.state.selectedBooing?.attributes?.sec_container_sizes})?.map((ContainerSize: any, index: any) => {
              return (
                <OptionForSelect  
                key={index} 
                value=
                {ContainerSize}
                >
                  <ProjectText 
                  TextSize="14px" 
                  variant="h6" fontweight="600"
                  > {ContainerSize}</ProjectText>
                </OptionForSelect>
              );
            })
          }
          </ProjectTextField>
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors, "ContainerSize", index,touched),
            !!this.DynamicErrorRender(errors, "ContainerSize", index,touched))}
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containeraveragetypes}
          </Typography>
          <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}>
            {this.state.selectedBooing?.attributes?.container_code?.map((item: any) => {
                return(
                  <text style = {{fontFamily: 'Asap', color: '#000', padding: '7px 15px', backgroundColor: '#CDCDCD', borderRadius: '15px', marginRight: '10px', fontWeight: 600}}>{item}</text>
                )})}</div>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containerseal}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
            helperText={
              errors.SealNumber !== undefined &&
              touched.SealNumber &&
              errors.SealNumber
            }
            value={values.SealNumber}
            id="SealNumber"
            name="SealNumber"
            fullWidth
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "SealNumber",
                setFieldValue,
                index
              )
            }
          />          
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors, "SealNumber", index,touched),
            !!this.DynamicErrorRender(errors, "SealNumber", index,touched))}
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containercode}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            helperText={
              errors.HsCode !== undefined && touched.HsCode && errors.HsCode
            }
            value={values.HsCode}
            id="HsCode"
            name="HsCode"
            fullWidth
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "HsCode",
                setFieldValue,
                index
              )
            }
          />
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors, "HsCode", index,touched),
            !!this.DynamicErrorRender(errors, "HsCode", index,touched))}
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.containerpackaging}
          </Typography>
          {this.renderContainerType(errors, touched, values, setFieldValue, index)}
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors, "PackingType", index,touched),
            !!this.DynamicErrorRender(errors, "PackingType", index,touched))}
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.packagecount}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
            helperText={
              errors.PackingCount !== undefined &&
              touched.PackingCount &&
              errors.PackingCount
            }
            value={values.PackingCount}
            id="PackingCount"
            name="PackingCount"
            fullWidth
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "PackingCount",
                setFieldValue,
                index
              )
            }
          />
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors, "PackingCount", index,touched),
            !!this.DynamicErrorRender(errors, "PackingCount", index,touched))}
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtextkgs">
            {configJSON.packagegwt}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            onKeyPress={(e)=>{(e.which < 48 || e.which > 57) && e.preventDefault()}}
            helperText={errors.GWT !== undefined && touched.GWT && errors.GWT}
            value={values.GWT}
            id="GWT"
            name="GWT"
            fullWidth
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "GWT",
                setFieldValue,
                index
              )
            }
          />
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors, "GWT", index,touched),
            !!this.DynamicErrorRender(errors, "GWT", index,touched))}
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vesselTextfieldgrid"
        >
          <Typography className="vesselTextfieldtext">
            {configJSON.packagedescription}
          </Typography>
          <LandingPageTextField
            BoderRadius="10px"
            variant="outlined"
            size="small"
            type="text"
            helperText={
              errors.CargoDescription !== undefined &&
              touched.CargoDescription &&
              errors.CargoDescription
            }
            value={values.CargoDescription}
            id="CargoDescription"
            name="CargoDescription"
            fullWidth
            onChange={(e) =>
              this.ContainerDetaisOnChange(
                e,
                "CargoDescription",
                setFieldValue,
                index
              )
            }
          />
          {this.BuyerShippingError(
            this.DynamicErrorRender(errors, "CargoDescription", index,touched),
            !!this.DynamicErrorRender(errors, "CargoDescription", index,touched))}
        </Grid>
      </Grid>
    );
  }

  renderPaymentConfiguration = () => {
    return (
      <>
      {this.state.paymentconfiguration && (
            <Grid>
              <StyledDialogModal open={this.state.paymentconfiguration}>
                <Grid container>
                  <Grid
                    item
                    className="nominationClose"
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <img
                      className="nominationCloseimg"
                      src={AddcloseImg}
                      data-testid="paymentOpenmodal"
                      alt=""
                      onClick={() => {
                        this.handleClose("paymentconfiguration");
                      }}
                    />
                  </Grid>
                  <Grid
                    className="nominationMsggrid"
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Typography className="nominationHeading">
                      {configJSON.paymentheading}
                    </Typography>
                    <Box className={`paymentTextBox ${this.state.DragStart ? "dragging" : ""} `}>
                      <Box>
                        <Typography className="verificationText">
                          {configJSON.paymnetnotify}
                        </Typography>
                       { this.state.UploadPaymentData.length !== 0 ?
                    <Box > <Box> {this.state.UploadPaymentData?.map((item: { name: string }, index: number) => { return (
                            <Button variant="outlined" color="default" fullWidth className="recentButton" key={String.fromCharCode(94 + index)} 
                            startIcon={<span><img src={pdf} className="pdfImg" alt="" /></span>}>
                              <text style={{width: '320px'}}>
                              {this.TextWithEllipsisDraft1({text:item?.name,maxLength: 30})} 

                              </text>
                              <span >
                              <img  className="uploadDeletebutton" data-testid="revisionOpenmodal" onClick={() => this.HandelRemoveUploadPaymentDocument(index)} src={deleteImg} alt=""  />
                              </span>
                            </Button> ) }) } </Box> </Box>
                    :
                   <>
                        <Button
                          variant="outlined"
                          color="default"
                          component="label"
                          htmlFor="UploadPayment"
                          className="uploadButton"
                          data-test-id = "draggableButton"
                          onDragEnter={(event: any) => this.HandleDragFuncation(event)}
                          onDrop={(event: any) => this.HandleFilUploadDrag(event)}
                          onDragLeave={(event: any) => this.HandleDragFuncation(event)}
                          onDragOver={(event: any) => this.HandleDragFuncation(event)}
                          startIcon={<CloudUploadIcon />}
                        >
                          <text style={{fontFamily: "Asap"}}>{configJSON.paymenttextdoc}</text>
                        </Button>
                      <input
                        name={`UploadPayment`}
                        className="Custmizedinputs"
                        id={`UploadPayment`}
                        accept=".pdf"
                        type="file"
                        data-test-id={"UploadPayment"}
                        style={{ display: "none" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          this.UploadPaymentDoc(e);
                        }}
                      />
                      </>
  }
                      </Box>
                    </Box>
                    <Button
                    className={this.state.UploadPaymentData?.length === 0 ? "disbledBtn" : "nominationButton"}
                      data-testid="sucessPaymentnmodal"
                      onClick={this.handlePaymentsucess}
                      disabled={this.state.UploadPaymentData?.length === 0 ? true : false}
                    >
                      {configJSON.verifybutton}
                    </Button>
               
                  
                  </Grid>
                </Grid>
              </StyledDialogModal>
            </Grid>
          )}
      </>
    ) }
    getPrincipalOrg(principalOrgList: any): string  | JSX.Element{
      if (Array.isArray(principalOrgList)) {return "ALL";
      } else if (principalOrgList.type === "organization") 
      {
        return(
          <><span>{principalOrgList?.attributes?.name}
           </span>
    <p  style={buyerStyle.boxtext}>
        {principalOrgList?.attributes?.code} 
        </p></> )      }
      return "ALL"
    }
     MapData = () => {
      let text = "";
      const activeSlotFilter = this.state.activeSlotFilter;
      let backgroundColor = activeSlotFilter[0]?.attributes?.color_name;
    
      // Building the text and backgroundColor strings
      activeSlotFilter.forEach((slot: any) => 
      {
        text += `${slot.attributes?.name?.trim().split(" ").join("/")} ${slot.attributes?.code} `;
        if (slot.attributes?.color_name) 
        {
          backgroundColor += ` ${slot.attributes?.color_name} `; 
        }});        
      // Handling empty state case
      if (activeSlotFilter.length === 0) {
        return this.getPrincipalOrg(this.state.buyerPrinicpalOrgList); }
    
      // Truncating text if necessary
      const truncatedText = text.length > 28 
      ? text.slice(0, 28) + " ..." : text;
    
      return ( 
      <div className="DropDownDiv" 
        title={text?.split("/").join(" ")}
        >
          {truncatedText.split(" ").filter(txt => txt !== "").map((txt, index) => {
            if (index % 2 === 0) {
              return ( <h3 
                className="DropDownDivh3" key={index}> {txt?.split("/").join(" ")}
                 </h3>
              );
            } else {
              return (
                <p className="DropDownDivP" 
                key={index}
                  style={{
                    padding: ".25rem .625rem", 
                    borderRadius: ".25rem",
                    backgroundColor: backgroundColor?.split(" ").filter((txt:any) => txt !== "")[index],
                  }
                }
                > {txt} 
                </p>
              );
            } }
            )
            } </div>   
            );
    }; 
    renderSlotForSwitchAndOrgSelectSection = () => {

     
      const MapDataElse = () => {
        return (
          <div className="DropDownDiv">
            <h3 className="DropDownDivh3">{this.state.activeSlotFilter?.attributes?.name}</h3>
            <p className="DropDownDivP" style={{ padding: '.25rem .625rem', borderRadius: '.25rem', backgroundColor: this.state.activeSlotFilter?.attributes?.color_name }}>{this.state.activeSlotFilter?.attributes?.code}</p>
          </div>   )
      }  
      return (
          <Grid item 
          lg={6} md={6}>
            <SectionWrapperLeft style={buyerStyle.leftBar}>
              <StyledSwitch>
                <div className="switch" 
                onClick={() => this.SwitchChnages()} data-test-id="switch-changes">
                  <input  className="Inputswitch"
                    type="checkbox"
                    checked={this.state.switch}  />
                  <span className="slider round">
                    <ProjectText TextSize="1rem" fontweight="700" className="text">
                      {this.state.switch ? "BUY" : ""}
                    </ProjectText>
                  </span>  </div>
              </StyledSwitch>  
              <h1 style={buyerStyle.slotsFor}>Slots for</h1>
              {!this.state.isSlotFilterOpen && ( <div 
                  style={{  color: 'rgba(30, 30, 30, 1)', position: 'relative',
                  fontSize: '.875rem',fontFamily: 'Asap',  fontWeight: 500 }}
                  >
                    <div  className="selectSlotInput" style={buyerStyle.selectSlot}
                    onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                    data-test-id="organizationFilter" >
                      <div style={{  display: 'flex',fontWeight: 500, overflow: 'auto' ,alignItems:"center"}}>
                        {Array.isArray(this.state.activeSlotFilter) ?
                          this.MapData() : 
                          <MapDataElse/>  }  </div>
                      <img 
                      style={{width: '10px', height: '10px', objectFit: 'contain'}}
                      src={image_dropdown}
                       alt="dropdown" />
                    </div>
                    </div>)}
              {this.state.isSlotFilterOpen&&<ClickAwayListener 
              onClickAway={() => this.setState({ isSlotFilterOpen: false })}>
                <div style={{ position: 'relative', fontSize: '.875rem', color: 'rgba(30, 30, 30, 1)',fontWeight: 500, fontFamily: 'Asap'}}>
                  <div   style={buyerStyle.selectSlot} className="selectSlotInput" data-test-id="organizationFilter"
                  onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                   >
                    <div style={{  display: 'flex', fontWeight: 500,overflow: 'auto',alignItems:"center" }}>
                      {Array.isArray(this.state.activeSlotFilter) ?
                        this.MapData()
                        : <MapDataElse/> 
                        }
                    </div>
                    <img src={image_dropdown} 
                    style={{width: '10px', height: '10px', objectFit: 'contain'}}
                    alt="dropdown" />
                  </div>
                  {this.state.isSlotFilterOpen && (
                    <Box>                 
                    {Array.isArray(this.state.buyerPrinicpalOrgList) ? (
                    <div data-test-id="organizationFilterDropdown" className="selectSlotDropdown"
                     style={buyerStyle.selectSlotDropdown}
                     >
                     {this.state.buyerPrinicpalOrgList?.map((val: any) => {
                      return (
                        <div key={val.id} style={{ borderLeft: `.25rem solid ${val.attributes.color_name}`, 
                        ...buyerStyle.selectSlotItem }}
                        >
                          <h4 style={{ textTransform: 'uppercase' }}
                          >{val.attributes.name}
                          </h4>
                          <Checkbox color="primary" checked={this.state.activeSlotFilter.includes(val)}
                            data-test-id="organizationFilterDropdownCheck"
                            onChange={(e) => this.handleSlotFilter(val, e.target.checked)} /> </div>
                     )}
                     )
                     }
                       </div>
                       )
                    : this.state.buyerPrinicpalOrgList.type !== "organization" && (
                      <div 
                      className="selectSlotDropdown"
                      data-test-id="organizationFilterDropdown" style={buyerStyle.selectSlotDropdown}
                     >
                    <div  key={this.state.buyerPrinicpalOrgList.id}
                          style={{ borderLeft: `.25rem solid ${this.state.buyerPrinicpalOrgList.attributes.color_name}`, 
                          ...buyerStyle.selectSlotItem }}
                          >
                          <h4 style={{ textTransform: 'uppercase' }}
                          > 
                          {this.state.buyerPrinicpalOrgList.attributes.name}  </h4>
                          <Checkbox 
                          color="primary" 
                          checked=
                          {this.state.activeSlotFilter.includes(this.state.buyerPrinicpalOrgList)}
                          onChange={(e) => this.handleSlotFilter(this.state.buyerPrinicpalOrgList, e.target.checked)}
                          />
                        </div>     </div>
                    )               
                    }</Box>
                  )}  </div>
              </ClickAwayListener>}
            </SectionWrapperLeft>
          </Grid>
      )
    }
    renderContainerInfo() {
      const selectedAttributes = this.state.selectedBooing?.attributes;
    
      if (!selectedAttributes) {
        return null;
      }
    
      const { container_size, offer_quantity, container_type, sec_container_sizes, offer_sec_quantity, offer_sec_container_type } = selectedAttributes;
    
      const renderPrimaryContainerInfo = () => {
        if (offer_quantity) {
          return `${offer_quantity} X ${container_size} ${container_type}`;
        }
        return null;
      };
    
      const renderSecondaryContainerInfo = () => {
        if (offer_sec_quantity) {
          return `${offer_sec_quantity} X ${sec_container_sizes} ${container_type}`;
        }
        return null;
      };
    
      return (
        <>
          {renderPrimaryContainerInfo() && <>{renderPrimaryContainerInfo()}<br /></>}
          {renderSecondaryContainerInfo() && <>{renderSecondaryContainerInfo()}</>}
        </>
      );
    }
  dateRangeFilter = () =>{
    return(
         <Box 
                     style={{ position: "absolute", top: "70px", right: "0px"  }}>
            {
              this.state.open 
              &&
              <DateContainer style={{ position: "relative",zIndex:99 }} 
              data-test-id="DateFilterMainDiv">
                <DatePickerStyle
                  open={this.state.open}  toggle={this.TogolFilter}
                  wrapperClassName="DateMainClassName" className="DateMainClassName"
                  onChange={(range: DateRange) => {
                    this.SetDateFilterData(range);
                  }}
                  initialDateRange={{
                    startDate: this.state.DateRangeStartDate || new Date(),
                    endDate: this.state.DateRangeEndDate || new Date()
                  }}
                />
                <Box className="ButtonContaienr">
                  <Button style={{ marginRight: "10px" }} 
                  data-test-id="ClearFilter" 
                  variant="text" onClick={()=> {
                    this.ClearFilterData()
                  } }>
                    <ProjectText variant="h6" 
                    TextSize="16px" 
                    textcolor="primary"> clear
                    </ProjectText>
                  </Button>
                  <Button variant="contained" 
                  data-test-id="SelectFilter" 
                  onClick={this.FilterDateSelect} 
                  color="primary" className="SelectButton">
                    <ProjectText variant="h6" TextSize="16px" 
                    textcolor="white"> Select
                    </ProjectText>  </Button>
                </Box>
              </DateContainer>
            }
          </Box>
    )
  }

  renderFirstTwoFilters = () => {
    return(
      <>
      <FormControl style={{ width: "100%", marginRight: "15px" }}>
        <Box style={{ 
              marginTop: "24px" 
              }}>
              <StyledBox
                onClick={() => 
                  this.handleBuyerOfferExpanded("listingID")}
                data-test-id="buyerListingfilter1"
              >
                <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpanded ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpanded("listingID")} />
                  </DropdownArrowBox>
              </StyledBox>
          <StyledInputLable shrink>BOOKING ID</StyledInputLable>
          {this.state.buyerExpanded&&<ClickAwayListener
              data-test-id="requestIdClickAway1"
            onClickAway={() => 
              this.setState({ buyerExpanded: false })}
          >         
              <Collapse
                disableStrictModeCompat={false}
                timeout="auto"
                in={this.state.buyerExpanded}
                unmountOnExit
                style={{
                  display: "flex",
                  position: "absolute",
                  flexDirection: "column",
                  zIndex: 1,
                  background: "#fff",
                  width: "100%",
                  maxHeight: "178px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                 minHeight: "30px",
                }}
              >
                {this.state.buyerFilter.listingID?.map(
                  (item: { label: string; isChecked: boolean }) => {
                    return (
                      <CustomFormControlLabel
                        key={item.label}
                        control={
                          <Checkbox
                          checked={item.isChecked}
                          color="primary"
                            data-test-id="buyerListingcheck"
                            name={item.label}
                            onClick={(e) =>
                              this.handleBuyerOfferChange(
                                e,
                                this.state.buyerFilter.listingID,
                                "listingID"
                              )
                            }
                            style={{ paddingRight: "30px" }}
                          />
                        }
                        labelPlacement="start"
                        label={item.label}
                        style={{
                          fontFamily: "Asap",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row-reverse",
                        }}
                      />
                    );
                  }
                )}
              </Collapse>
          </ClickAwayListener>}
          <Box
            style={{
              justifyContent: "start",
              marginTop: "3px",
              padding: "2px",
              gap: "5px",
              display: "flex",
              flexWrap: "wrap",
              listStyle: "none",
              width: "220px",
            }}
          >
            {this.state.buyerFilter.listingID.map(
              (option: { 
                label: string; 
                isChecked: boolean 
              }) => {
                if (option.isChecked) {
                  return (
                    <li
                      key={option.label}
                      style={{ 
                        fontSize: "14px",
                        fontFamily: "Asap", 
                       }}
                    >
                      <StyledChip
                      data-test-id="buyerDeletListingIdChip1"
                      onDelete={() => 
                        this.handleBuyerOfferDelete( option, this.state.buyerFilter.listingID, "listingID" )
                      }
                      label={option.label} 
                      />
                    </li>
                  );
                }
              } )}
          </Box>
          </Box>
        </FormControl>
        <FormControl 
        style={{ 
          width: "100%", 
          marginRight: "15px" 
          }}>
              <Box style={{ marginTop: "24px" }}>
              <StyledBox data-test-id="polfilter2" onClick={() => this.handleBuyerOfferExpanded("pol")}>
              <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedPol ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpanded("pol")} />
                  </DropdownArrowBox>
              </StyledBox>
          <StyledInputLable shrink>POL</StyledInputLable>{" "}
          {this.state.buyerExpandedPol&&<ClickAwayListener
           data-test-id="polClickAway2"
            onClickAway={() => this.setState({ buyerExpandedPol: false })}
          >
              <Collapse
                in={this.state.buyerExpandedPol}
                disableStrictModeCompat={false}
                timeout="auto"
                unmountOnExit
                style={{
                  background: "#fff", zIndex: 1,
                  width: "100%", display: "flex",
                  position: "absolute",
                  flexDirection: "column",
                  maxHeight: "178px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                 minHeight: "30px",
                }}
              >
                {this.state.buyerFilter?.pol?.map(
                  (item: { 
                    label: string; isChecked: boolean }) => {
                    return (
                      <CustomFormControlLabel
                        key={item.label}
                        control={
                          <Checkbox
                          data-test-id="buyerPolcheck"
                            color="primary"
                            checked={item.isChecked}
                            onClick={(e) =>
                              this.handleBuyerOfferChange( e, this.state.buyerFilter.pol, "pol" ) }
                            name={item.label}
                            style={{ 
                              paddingRight: "30px" }}
                          />
                        }
                        label={item.label}
                        style={{
                          justifyContent: "space-between",
                          fontFamily: "Asap",
                          display: "flex",
                          flexDirection: "row-reverse", fontSize: "14px",
                        }}
                        labelPlacement="start"
                      />
                    );
                  }
                )}
              </Collapse>
          </ClickAwayListener>}
          <Box
            style={{
              padding: "2px",
              width: "220px",
              flexWrap: "wrap", justifyContent: "start",
              marginTop: "3px", display: "flex",
              listStyle: "none",
              gap: "5px",
            }}
          >
            {this.state.buyerFilter.pol.map(
              (option: { 
                label: string; 
                isChecked: boolean }) => {
                if (option.isChecked) {
                  return (
                    <li
                    style={{ 
                      fontFamily: "Asap", 
                    fontSize: "14px" }}
                      key={option.label}
                    >
                      <StyledChip
                      data-test-id="buyerDeletPolIdChip2"
                        label={option.label}
                        onDelete={() =>
                          this.handleBuyerOfferDelete(
                            option, this.state.buyerFilter.pol, "pol" ) }
                      />
                    </li>
                  );
                }
              }
            )}
          </Box>
          </Box>
        </FormControl>
      </>
    )
  }

  renderFilterSection = () => {
    return (
      <CustomBox>
        {this.renderFirstTwoFilters()}
        <FormControl style={{ 
          width: "100%", 
          marginRight: "15px" }}>
             <Box style={{ marginTop: "24px" }}>
              <StyledBox data-test-id="podfilter3" onClick={() => this.handleBuyerOfferExpanded("pod")}>
              <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedPod ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpanded("pod")} />
                  </DropdownArrowBox>
                </StyledBox>        
          <StyledInputLable shrink>
            POD</StyledInputLable>{" "}
          {this.state.buyerExpandedPod&&<ClickAwayListener
           data-test-id="podClickAway3"
            onClickAway={() => 
              this.setState({ buyerExpandedPod: false })}
          >
               <Collapse
                disableStrictModeCompat={false}
                in={this.state.buyerExpandedPod}
                unmountOnExit
                timeout="auto"
                style={{
                  background: "#fff",
                  position: "absolute",
                  flexDirection: "column",
                  display: "flex",
                  zIndex: 1000,
                  width: "100%",
                  maxHeight: "178px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                 minHeight: "30px",
                }}
              >
                {this.state.buyerFilter?.pod?.map(
                  (item: { 
                    label: string; 
                    isChecked: boolean }) => {
                    return (
                      <CustomFormControlLabel
                        key={item.label}
                        control={
                          <Checkbox
                          checked={item.isChecked}
                          data-test-id="buyerPodCheck"
                            color="primary"
                            onClick={(e) =>
                              this.handleBuyerOfferChange(
                                e,
                                this.state.buyerFilter.pod,
                                "pod"
                              )
                            }
                            style={{ paddingRight: "30px" }}
                            name={item.label}
                          />
                        }
                        labelPlacement="start"
                        label={item.label}
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          fontFamily: "Asap",
                        }}
                      />
                    );
                  }
                )}
              </Collapse>
           
          </ClickAwayListener>}
          <Box
            style={{
              padding: "2px",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "start",
              width: "220px", gap: "5px",
              listStyle: "none",
              marginTop: "3px",
            }}
          >
            {this.state.buyerFilter.pod.map(
              (option: { 
                label: string; 
                isChecked: boolean }) => {
                if (option.isChecked) {
                  return (
                    <li
                      key={option.label}
                      style={{ 
                        fontSize: "14px" ,
                        fontFamily: "Asap", 
                      }}
                    >
                      <StyledChip
                      data-test-id="buyerDeletPodIdChip3"
                        label={option.label}
                        onDelete={() =>
                          this.handleBuyerOfferDelete( option, this.state.buyerFilter.pod, "pod" ) }
                      />
                    </li>
                  );
                }
              }
            )}
          </Box>
          </Box>
        </FormControl>
        <FormControl style={{ 
          width: "100%", 
          marginRight: "15px" }}>
            <Box style={{ 
              marginTop: "24px" 
              }}>
              <StyledBox data-test-id="typefilter4" 
                onClick={() => this.handleBuyerOfferExpanded("type")}>
                  <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedType ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpanded("type")} />
                  </DropdownArrowBox>
                </StyledBox>
          <StyledInputLable shrink>
            STATUS
          </StyledInputLable>{" "}
          {this.state.buyerExpandedType&&<ClickAwayListener
           data-test-id="typeClickAway4"
            onClickAway={() => 
              this.setState({ buyerExpandedType: false })}
          >
            
              <Collapse
                disableStrictModeCompat={false}
                in={this.state.buyerExpandedType}
                unmountOnExit
                timeout="auto"
                style={{
                  zIndex: 1,
                  display: "flex",
                  position: "absolute",
                  width: "100%",
                  flexDirection: "column",
                  background: "#fff",
                  maxHeight: "178px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                 minHeight: "30px",
                }}
              >
                {this.state.buyerFilter?.type?.map(
                  (item: { 
                    label: string; 
                    isChecked: boolean }) => {
                    return (
                      <CustomFormControlLabel
                        key={item.label}
                        control={
                          <Checkbox
                          checked={item.isChecked}
                          data-test-id="buyerTypeCheck"
                            color="primary"
                            onClick={(e) =>
                              this.handleBuyerOfferChange( e, this.state.buyerFilter.type, "type" ) }
                              style={{ paddingRight: "30px" }}
                            name={item.label}
                          />
                        }
                        label={item.label}
                        labelPlacement="start"
                        style={{
                          flexDirection: "row-reverse",
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "14px",
                          fontFamily: "Asap",
                        }}
                      />
                    );
                  }
                )}
              </Collapse>
           
          </ClickAwayListener>}
          <Box
            style={{
              gap: "5px",
              listStyle: "none",
              padding: "2px",
              display: "flex",
              width: "220px",
              justifyContent: "start",
              marginTop: "3px",
              flexWrap: "wrap",
            }}
          >
            {this.state.buyerFilter.type.map(
              (option: { 
                label: string; 
                isChecked: boolean }) => {
                if (option.isChecked) {
                  return (
                    <li
                      key={option.label}
                      style={{ 
                        fontFamily: "Asap", 
                        fontSize: "14px" }}
                    >
                      <StyledChip
                      data-test-id="buyerDeletTypeIdChip4"
                        onDelete={() =>
                          this.handleBuyerOfferDelete( option, this.state.buyerFilter.type, "type" ) }
                        label={option.label}
                      />
                    </li>
                  );
                }
              }
            )}
          </Box>
          </Box>
        </FormControl>
        <FormControl variant="standard" style={{ width: "100%" }}>
          <StyledInputLable  shrink
            htmlFor="ETB DATE RANGE"
            style=
            {{ 
              fontWeight: 700 
            }}
          >   LOADING DATE RANGE
          </StyledInputLable>
          <BootstrapInput
               defaultValue="" fullWidth
               id="ETB DATE RANGE"
               data-test-id="dateopne"  onClick={this.TogolFilter}
               value={this.state.StartDate !== "" 
               && this.state.EndDate !== "" ? 
               `${this.state.RenderStart} - ${this.state.RenderEndDate}` : "" }
                    /><CalendarImgBox><img src={DateTimeIcon} alt="" onClick={this.TogolFilter} /></CalendarImgBox>
                    
          {this.dateRangeFilter()}
        </FormControl>
      </CustomBox>
    )
  }

  renderButtonClassname = (booking: any) => {
    return booking.activeButtonvalue === "Booking confirmed!"
    ? "mainButtonconfirmed"
    : booking.isDisabled && "mainButtonDisabled" || "mainButton1"
  }

  onDisableBooking = (booking: any, index: number) => {
    if (!booking.isDisabled) {
      this.setState({ selectedBooing: booking }, () => {
        this.handleOpentrackingModal(booking.activeButtonvalue, booking,index);
      });
    }
  }

  renderPopupModals = () => {
    return(
      <>
      {/* Nomination Modal Start */}
      {this.state.nominationModal && (
        <StyledDialogModal
         open={this.state.nominationModal}>
          <Grid container>
            <Grid  item  className="nominationClose"
              lg={12}    md={12}
              sm={12}    xs={12} >
              <img className="nominationCloseimg"
                data-testid="closeNominatemodal"
                src={AddcloseImg} alt=""     onClick={() => {
                  this.handleClose("nominationModal");
                }}
              />
            </Grid>
            <Grid className="nominationMsggrid"
              item lg={12}
              md={12} sm={12}
              xs={12} >
              <img className="nominationErrorimg"
                src={resultNotFoundRed} alt="" />
              <Typography 
              className="nominationHeading">
                {configJSON.nominationheading}
              </Typography>
              <Typography 
              className="nominationLink">
                <div style={{cursor: 'pointer'}} onClick={() => {window.open(this.state.portnetUrlData, '_blank')}}>
                  {this.state.portnetUrlData}
                </div>
              </Typography>
              <Typography className="nominationLinktext">
                <span>
                  <Checkbox  data-testid="nominationCheckbox"
                    className={this.state.nominationCheckbox
                        ? "nominateChecked" : "nominateCheckbox"  }
                    checked={this.state.nominationCheckbox}
                    onChange={this.handleCheckbox} />
                </span>
                {configJSON.nominationcheck}
              </Typography>
              <Button className={
                  this.state.nominationCheckbox ? "nominationButton"
                    : "nominationButtondisable" }
                disabled={!this.state.nominationCheckbox} data-testid="sucessOpenmodal"
                onClick={this.handleNominationsucess}  >
                {configJSON.nominationbutton}
              </Button>
            </Grid>
          </Grid>
        </StyledDialogModal>
      )}

      {this.state.submittingState && (
        <StyledDialogModal open={this.state.submittingState}>
          <Grid
            item
            className="nominationClose"
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <img
              className="nominationCloseimg"
              src={AddcloseImg}
              alt=""
              data-testid="closeSubmittingmodal"
              onClick={() => {
                this.handleClose("submittingState");
              }}
            />
          </Grid>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="submittingFirstgrid"
            >
              {configJSON.submitheading}
            </Grid>
            {this.handleSubmittingmodal()}
            {/* <Grid item lg={12} md={12} sm={12} xs={12} className="submittingFirstgrid">
                            <Button className="nominationButton" data-testid="sucessSubmitting" onClick={this.handleSubmitsucess}>{configJSON.verifybutton}</Button>
                              </Grid> */}
          </Grid>
        </StyledDialogModal>
      )}

      {/*B/L verified Modal Start */}
      {this.state.DraftBLstate && (
        <Grid>
          <StyledDialogModal open={this.state.DraftBLstate}>
            <Grid container>
              <Grid
                item
                className="nominationClose"
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <img
                  className="nominationCloseimg"
                  data-testid="draftOpenmodal"
                  src={AddcloseImg}
                  alt=""
                  onClick={() => {
                    this.handleClose("DraftBLstate");
                  }}
                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Typography className="nominationHeading">
                  {configJSON.verifyheading}
                </Typography>
                <Box className="verifyTextBox">
                  <Box>
                    <Typography className="verificationText">
                      {configJSON.verifyurl}
                    </Typography>
                    <Grid item xs={6} spacing={0}>
                        <Button  variant="outlined"
                      color="default"
                      style={{ textAlign: 'left', minWidth: '400px !important' }}
                      className="uploadButtonshow"
                      >
                          <Grid xs={11}>
                            <Box className="textandPdfIcon">
                            <img alt="upload" style={{ marginLeft: '8px',height: "1.375rem" }} className="SmallIcon" src={pdf} />
                            <ProjectText
                                  variant="subtitle1"
                                  fontweight="600"
                                  TextSize=".875rem"
                                  noWrap
                                  style={{ marginLeft: '12px', overflow: 'revert' }}
                                  onClick={() => window.open(this.state.selectedBooing.attributes.draft_bl && this.state.selectedBooing.attributes.draft_bl[
                                    this.state.selectedBooing.attributes.draft_bl.length - 1
                                  ], "_blank")}
                              >
                                <text style={{width: "230px !important"}}>
                                  {this.TextWithEllipsisDraft({
                                      text: (
                                          this.state.selectedBooing?.attributes?.draft_bl_file_name &&
                                          this.state.selectedBooing.attributes.draft_bl_file_name.length > 0
                                      )
                                      ? this.state.selectedBooing.attributes.draft_bl_file_name[
                                          this.state.selectedBooing.attributes.draft_bl_file_name.length - 1
                                        ]
                                      : '', maxLength: 25
                                  })}
                                </text>
                              </ProjectText>
                            </Box>
                          </Grid>
                          <Grid xs={1}>
                              <img onClick={this.handleRevisionrequest} className="SmallIcon" src={RemoveIcon} alt="upload" data-test-id="DeletePdfBtn" />
                          </Grid>
                        </Button>
                      </Grid>
                  </Box>
                </Box>
                <Button
                  className="nominationButton1"
                  data-testid="sucessDraftmodal"
                  onClick={this.handleVerifysucess}
                >
                  CONFIRM
                </Button>
                <Button
                  className="nominationButtonOutlined"
                  data-testid="sucessDraftmodal"
                  onClick={this.handleRevisionrequest}
                >
                  REJECT
                </Button>
              </Grid>
            </Grid>
          </StyledDialogModal>
        </Grid>
      )}
      {/*revision request Modal Start */}
      {this.state.revisionrequest && (
        <Grid>
          <StyledDialogModal open={this.state.revisionrequest}>
            <Grid container>
              <Grid
                item
                className="nominationClose"
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <img
                  className="nominationCloseimg"
                  src={AddcloseImg}
                  alt=""
                  data-testid="revisionClosemodal"
                  onClick={() => {
                    this.handleClose("revisionRequest");
                  }}
                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <img className="nominationErrorimg" src={orangeQue} alt="" />
                <Typography className="nominationHeading">
                  {configJSON.reasonheading}
                </Typography>
                <Typography className="revisionText">
                  {configJSON.reasonnotify}
                </Typography>
                <Typography className="revisionText">
                  {configJSON.reasonnotifytwo}
                </Typography>
                <Box className="reasonBox">
                  <Typography className="revisionText">
                    {configJSON.reasontext}
                  </Typography>
                  <TextField onChange={(e) => this.handleReasonFieldChange(e)} variant="outlined" 
                    required
                    InputProps={{className: "reasonTextfield"}}                          
                    style={{color:'#000',fontWeight:'bold',width:'100%'}}                  
                  />
                </Box>
                <Button
                  className="nominationButton1"
                  data-testid="sucessRevisonnmodal"
                  onClick={this.handleRevisionsucess}
                >
                  {configJSON.verifybutton}
                </Button>
              </Grid>
            </Grid>
          </StyledDialogModal>
        </Grid>
      )}
      {/*payment confirmation Modal Start */}
      {this.renderPaymentConfiguration()}

      {/*Successful messages Modal Start */}
      {this.state.sucessOpenmodal && (
        <StyledDialogModal open={this.state.sucessOpenmodal}>
          {this.sucessmessage(
            "Nomination Successful!",
            "We have notified the seller! You will be notified once the seller authorisie to nominate."
          )}
        </StyledDialogModal>
      )}
      {this.state.submittingSucess && (
        <StyledDialogModal open={this.state.submittingSucess}>
          {this.sucessmessage(
            "Submission Successful!",
            "We have notified the seller! You will be notified once the seller uploads the Draft B/L."
          )}
        </StyledDialogModal>
      )}
      {this.state.verifySucess && (
        <StyledDialogModal open={this.state.verifySucess}>
          {this.sucessmessage(
            "Draft B/L Verified!",
            "We have notified the seller! You will be notified once the seller uploads the invoice."
          )}
        </StyledDialogModal>
      )}
      {this.state.revisionSucess && (
        <StyledDialogModal open={this.state.revisionSucess}>
          {this.sucessmessage(
            "Request Revision Successful",
            "We have notified the seller! You will be notified once the seller confirms request revision."
          )}
        </StyledDialogModal>
      )}
      {this.state.paymentSucess && (
        <StyledDialogModal open={this.state.paymentSucess}>
          {this.sucessmessage(
            "Payment Confirmation Sent!",
            "We have notified the seller! You will be notified  once the seller confirms the payment."
          )}
        </StyledDialogModal>
      )}
      </>
    )
  }

  TextWithEllipsis = ({ text, maxLength }: any) => {
    if (!text || text.length <= maxLength) {
      return <span style={{display:'block' , width:"135px"}}>{text}</span>;
    }
  
    const truncatedText = text.slice(0, maxLength) + '...';
  
    return (
      <span title={text} style={{display:'block' , width:"135px"}}>
        {truncatedText}
      </span>
    );
  };
  TextWithEllipsisDraft = ({ text, maxLength }: any) => {
    if (!text || text.length <= maxLength) {
      return <span style={{display:'block' , width:"230px"}}>{text}</span>;
    }
  
    const truncatedText = text.slice(0, maxLength) + '...';
  
    return (
      <span title={text} style={{display:'block' , width:"230px"}}>
        {truncatedText}
      </span>
    );
  };
  TextWithEllipsisDraft1 = ({ text, maxLength }: any) => {
    if (!text || text.length <= maxLength) {
      return <span>{text}</span>;
    }
  
    const truncatedText = text.slice(0, maxLength) + '...';
  
    return (
      <span title={text} style={{display:'block' , width:"320px !important"}}>
        {truncatedText}
      </span>
    );
  };

   formatRate(rate:any) {
    return `$${parseInt(rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}/`;
  }
  
   renderTypography(selectedBooing:any) {
    const attributes = selectedBooing?.attributes;
    const sellerOfferRate = attributes?.seller_offer_rate;
    const containerSize = attributes?.container_size;
    const sellerSecOfferRate = attributes?.seller_sec_offer_rate;
    const secContainerSizes = attributes?.sec_container_sizes?.trim();
    const firstQuantity = attributes?.offer_quantity;
    const secondQuantity = attributes?.offer_sec_quantity;

    return (
      <Typography className="smallText">
        {firstQuantity && (
          <>
          <span style={{ fontWeight: 700 }}>
            {this.formatRate(sellerOfferRate)}
          </span>
          {containerSize}<br />
          </>
        )}
        {secondQuantity && (
          <>
          <span style={{ fontWeight: 700 }}>
            {this.formatRate(sellerSecOfferRate)}
          </span>
          {secContainerSizes}<br />
          </>
        )}
      </Typography>
    );
  }

  darkenColor(hex: any, percent: number) {
    hex = hex.replace('#', '')
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16)
    
    let b = parseInt(hex.substring(4, 6), 16);
    r = 
    Math.floor(r * (100 - percent) / 100);
    g = 
    Math.floor(g * (100 - percent) / 100);
    b = 
    Math.floor(b * (100 - percent) / 100);
    r = 
    Math.min(255, Math.max(0, r));
    g = 
    Math.min(255, Math.max(0, g));
    b = 
    Math.min(255, Math.max(0, b));
    return '#' + (r * 65536 + g * 256 + b).toString(16).padStart(6, '0');}

  renderOffersTable = () => {
    return(
      <>
      {this.state.bookingData?.map((booking:any, index:number) => {
        const principalColorCode = booking.attributes?.request_color_name;
        const darkenedBorderColor = this.darkenColor(principalColorCode ?? '',15);
        const darkenedBorderColors = this.darkenColor(principalColorCode ?? '',50);
        const cancelButtonTitle = booking.attributes?.booking_confirmation_status == "Booking Cancelled" ? "Cancelled By Seller" : "Booking Cancelled";
          return (
            <Grid container className="trackingDatagridsecond" key={booking.id}>
              <Grid
                className="trackingDatagrid"
                item
                lg={6}
              >
                <Grid className={"boxPart1"}
                style={{borderLeftColor: darkenedBorderColor,backgroundColor:booking.attributes?.request_color_name}}
                >
                  <Grid
                    item
                    className="box"
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Typography className="boxText"
                    style={{border: `1px solid ${darkenedBorderColors}`,color:darkenedBorderColors,backgroundColor:principalColorCode}}
                    >
                      {booking.attributes?.request_principal_org_code || booking.attributes?.request_org_code} 
                    </Typography>
                    <Typography className="boxTextid">
                      ID : {booking.attributes.booking_uid} 
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{width:"100%"}}>
                    <Grid container className={"firstSecondPart"}>
                      <Box
                        className={"mbottom"}
                      >
                        <Typography className={"first"}>
                          {moment(
                          booking.attributes.etb
                        ).format("D MMM") } 
                        {` (${moment(
                          booking.attributes.etb
                        ).format("ddd")})`}
                        </Typography>
                        <Box style={{ width: "fit-content" }}>
                          <Typography className={"third"}>
                            {booking.attributes.pol}
                          </Typography>
                          <Typography variant="h3" className={"seconds"}>{booking.attributes.pol_terminal} </Typography>
                        </Box> 
                      </Box>
                      <Box
                        className={"middlepartoffirstbox"}
                      >
                        <Box className={"trainPart"}>
                          <img
                            src={Matches}
                            alt=""
                            className="trainImage"
                          />
                          <Typography
                            variant="h6"
                            className={"trainPart"}
                          >
                            {
                              this.TextWithEllipsis({text: booking.attributes.vessel_voyage && booking.attributes.in_voyage 
                                && `${booking.attributes.vessel_voyage} / In- ${booking.attributes.in_voyage} 
                                Out- ${booking.attributes.out_voyage}`,
                                                      maxLength: 28})
                            }
                          </Typography>
                        </Box>
                        <img
                          style={{ width: "95%" }}
                          src={arraw}
                          alt=""
                        />
                        <Typography>
                        {booking.attributes.offer_quantity 
                            &&
                            <>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontFamily: "Asap",
                            }}
                          >{booking.attributes.offer_quantity}
                          </span>
                          <span  style={{fontWeight: 700 }}>
                                x</span>
                          <span className={"containerSizeBorder"}>
                            {booking.attributes.container_size}
                             </span>  
                             {booking.attributes.offer_sec_quantity && <span  style={{fontWeight: "bold"  }}>,
                              </span>}
                               </>}                        
                            {booking.attributes.offer_sec_quantity 
                            &&
                            <>
                            <span
                              style={{
                                fontFamily: "Asap", fontWeight: "bold",
                              }}
                            >
                              {booking.attributes.offer_sec_quantity}
                            </span>
                             <span> x 
                              <span className={"containerSizeBorder"}>
                                {booking.attributes.sec_container_sizes}
                                </span></span>
                             </>
                             }{" "}
                            <span style={{fontFamily:"Asap",fontWeight:600}}>{booking.attributes.container_type}</span>
                        </Typography>
                      </Box>
                      <Box
                        className={"mbottom"}
                      >
                        <Typography className={"first"}>
                        {moment(
                          booking.attributes.eta
                        ).format("D MMM") } 
                        {` (${moment(
                          booking.attributes.eta
                        ).format("ddd")})`}
                        </Typography>
                        <Typography className={"third"}>
                          {booking.attributes.pod}
                        </Typography>
                        <Typography variant="h3" className={"second"}>{booking.attributes.pod_terminal}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
                <Grid className="trackingDatagrid"
                  item
                  lg={6}>
                  <Box className={"secondBoxMain"}>
                    <CustomizedSteppers
                      newStepperState={booking.currentStep}
                    />
                        <Box className="nominates_icon">
                  {
                    booking.attributes.booking_status == "rejected" ? 
                    <Button style={{textTransform: "uppercase"}} variant="contained" data-testid="openTrakingmodal" className="mainButtonDisabled" disabled={true}  >
                      {cancelButtonTitle}
                    </Button>
                  :
              
                    <Button
                      variant="contained"
                      data-testid="openTrakingmodal"
                      onClick={() => this.onDisableBooking(booking, index)}
                      className={this.renderButtonClassname(booking)}
                      disabled={booking.isDisabled} 
                    >
                      <text style={{textTransform: "uppercase"}}>
                        {booking.activeButtonvalue}
                        </text>
                    </Button>

                  }
                  <Box
                  >
                    <Button
                      data-testid="openPopover"
                      onClick={(e)=>this.openPopover(e, booking, index)}
                    >
                      <img src={dots} alt="" />
                    </Button>
                    <Popper
                      open={this.state.openPop}
                      anchorEl={this.state.anchorEl}
                      placement="left"
                      className="popperStyle"
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          color: "#1E1E1E",
                          fontFamily: "Asap",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                          textTransform: "uppercase",
                          backgroundColor: "white",
                          borderRadius: "4px",
                          padding: "10px 10px",
                          width: "fit-content",
                          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                          cursor: 'pointer'
                        }}
                        className="iconcontent"
                        onClick={()=>this.handleViewDetails(booking)}
                      >
                        {configJSON.ViewDetails}
                      </Typography>
                    </Popper>
                  </Box>
                  </Box>
                  </Box>
                </Grid>
              </Grid>
          );
        })}
      </>
    )
  }

  spinnerLoader = () => {
    if (this.state.loading) {
      return <Spinner 
        spinnerModal={this.state.loading} />
    }
  }


  render() {
    // Customizable Area Start
    
    const colorMap: any = {
      pending: "#EE8F00",
      accepted: "#0f0",
      rejected: 'red'
    };
    
    const bookingStatus = this.state.selectedBooing?.attributes?.booking_status?.toLowerCase();
    
    const color: any = colorMap[bookingStatus];

    const cancelButtonTitle = this.state.selectedBooing?.attributes?.booking_confirmation_status == "Booking Cancelled" ? "Cancelled By Seller" : "Booking Cancelled";

    
    // Customizable Area End
    return (
      // Customizable Area Start
      <StyleContainer>
        <Navbar SellerPendingScreen={true} isBuyerScreen={true} AppBarTransparent = {false} TabDataCount={this.DynamicDataSet} />
        {this.spinnerLoader()}
        <section style={buyerStyle.root}>
          <Snackbar
              anchorOrigin={{ vertical: 'top',
              horizontal: 'right', }}
              open={this.state.buyerRequestSnackbarOpen}
              onClose={this.handleSnackbarClose}
              autoHideDuration={5000}
          >
              <SnackbarContent style={{ backgroundColor: '#fff', color: '#f00', fontWeight: 'bolder' }}
              message="Can not switch user as organisation role is not both" />
          </Snackbar>
          <Container maxWidth="xl" data-test-id="BuyerListing1">
          <Grid container alignItems="center" 
          style={buyerStyle.slotsSection}>
                            {this.renderSlotForSwitchAndOrgSelectSection()}
                            <BuyerToggelComponentWeb />
                            </Grid>

            <StyledTabs
                data-test-id="tab"
                value={this.state.currentTab}
                aria-label="full width tabs example"
                onChange={this.handleTabChange}
                variant="fullWidth"
                TabIndicatorProps={{
                    style: { display: "none" },
                }}
            >
                <BorderedStyledTab disableTouchRipple label={`REQUESTS (${this.state.DynamicCount.requst})`} onClick={() => this.goToBuyerMatchesPage("BuyersRequest")} data-testId="BuyersRequest"/>
                <StyledTab disableTouchRipple label={`SELLER OFFERS (${this.state.DynamicCount.offers})`} onClick={() => this.props.navigation.navigate("BuyersOffer")} />
                <StyledTab disableTouchRipple label={`BOOKINGS (${this.state.DynamicCount.booking})`} />
            </StyledTabs>
            {
              this.renderFilterSection()
            }
            {Array.isArray(this.state.bookingData) &&
                        this.state.bookingData?.length !== 0 ? (
                        <BuyerOffestyledrScreen>
                            <MainDiv>
                              <Box className="trackingDatabox">
                                {this.renderOffersTable()}
                              </Box>
                          </MainDiv>
                        <Container maxWidth="xl" className="MainContainer">

                          <StyledDialog open={this.state.trackingModal }>
                            <Box className="topBox">
                            <Box className="closeIconHeader">
                                  <IconButton size="small"
                                    data-testid="closeTrackingmodal"
                                    onClick={() => {
                                      this.handleClose("trackingModal");
                                    }}
                                  >
                                    <Close fontSize="small" />
                                  </IconButton>
                                </Box>
                              <Box className="headerDialod">
                                <Typography variant="h5" className="dialogTitle">
                                  {configJSON.bookingDetails}
                                </Typography>
                                
                              </Box>

                             <Grid container className="midCardSection">
                                <Grid item lg={2} md={2} sm={2} xs={2} >
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.slotxpert}
                                  </Typography>
                                  <Typography className="smallText">
                                    {this.state.selectedBooing?.attributes?.booking_uid}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2}>
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.vessel}
                                  </Typography>
                                  <Typography className="smallText">
                                    {
                                      this.TextWithEllipsis({text: this.state.selectedBooing?.attributes?.vessel_voyage && this.state.selectedBooing?.attributes?.in_voyage && `${this.state.selectedBooing?.attributes?.vessel_voyage} / In- ${this.state.selectedBooing?.attributes?.in_voyage} Out- ${this.state.selectedBooing?.attributes?.out_voyage}`, maxLength:28})
                                    }
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2}>
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.pol}
                                  </Typography>
                                  <Typography className="smallText">
                                  {this.state.selectedBooing?.attributes?.pol}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} >
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.pod}
                                  </Typography>
                                  <Typography className="smallText">
                                    {" "}
                                    {this.state.selectedBooing?.attributes?.pod}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} >
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.sellerbooking}
                                  </Typography>
                                  <Typography className="Pending">
                                    {/* <text style = {{fontFamily: 'Asap',  color: color}}>{this.state.selectedBooing?.attributes?.booking_status}</text> */}
                                    {this.state.selectedBooing?.attributes?.booking_reference_number  ?
                <text style={{ fontFamily: 'Asap', color: "#EE8F00" }}>{this.state.selectedBooing?.attributes?.booking_reference_number}</text>:
                <text style={{ fontFamily: 'Asap', color: color,textTransform: "uppercase" }}>{this.state.selectedBooing?.attributes?.booking_status}</text>}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} >
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.containers}
                                  </Typography>
                                  <Typography className="smallText">
                                    {this.renderContainerInfo()}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} >
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.rates}
                                  </Typography>
                                  {this.renderTypography(this.state.selectedBooing)}
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} >
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.etb}
                                  </Typography>
                                  <Typography className="smallText">
                                  {moment(
                                                this.state.selectedBooing?.attributes?.etb
                                              ).format("D MMM YYYY") } 
                                              
                                    <br />
                                    {moment(
                                                this.state.selectedBooing?.attributes?.etb
                                              ).format("H:mm A")}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} >
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.eta}
                                  </Typography>
                                  <Typography className="smallText">
                                  {moment(this.state.selectedBooing?.attributes?.eta).format("D MMM YYYY") } 
                                    <br />
                                    {moment(this.state.selectedBooing?.attributes?.eta).format("H:mm A")}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2}
                                  className="midSmallcard"
                                  style={{ visibility: "hidden" }}
                                >
                                  <Typography variant="body2" className="titleTypography">
                                    {configJSON.eta}
                                  </Typography>
                                  <Typography className="smallText">
                                    {moment(this.state.selectedBooing?.attributes?.eta).format("D MMM") }<br />
                                    {moment(this.state.selectedBooing?.attributes?.eta).format("ddd")}
                                  </Typography>
                                </Grid>
                                {
                                  this.state.selectedBooing?.attributes?.seller_booking_cancel_reason && <Grid item lg={12} md={12} sm={12} xs={12} className="midSmallcards1"><Typography variant="body2" className="titleTypography">Reason for Cancellation</Typography> <Typography className="smallText">{this.state.selectedBooing?.attributes?.seller_booking_cancel_reason}</Typography></Grid>
                                }
                              </Grid>

                            </Box>

                            {/* Cancel Booking Modal Start */}
                            {this.state.cancelBooking ? (
                              <Box className="stepperBoxcancel">
                                <img className="nominationErrorimg" src={redQue} alt="" />
                                <Typography className="nominationCancel">Cancel Booking</Typography>
                                <Typography className="nominationCanceltext">
                                  Are you sure you want to cancel your booking # {this.state.selectedBooing?.attributes?.booking_uid}
                                </Typography>
                                <Box className="nominateCancelgrid">
                                  <Button
                                    onClick={this.Cancelbooking}
                                    variant="contained"
                                    className="nominationCancelbuttons"
                                  >
                                    CANCEL
                                  </Button>
                                  <Button
                                    onClick={this.handleNocancel}
                                    variant="outlined"
                                    className="nominationCancelbuttonsOutlined"
                                  >
                                    No
                                  </Button>
                                </Box>
                              </Box>
                            ) : (
                              <Box className="stepperBox">
                                <VeticalFile
                                  data-testid="nominateModal"
                                  newState={this.state.currBookingIndex !== -1 ? this.state.bookingData[this.state.currBookingIndex!]?.currentStep: this.state.curr_booking?.currentStep}
                                  handleNominationModal={this.handleNominationmodal}
                                  booking={this.state.selectedBooing}
                                  selectValue={""}
                                  bookingStatus = {this.state.selectedBooing?.attributes?.booking_confirmation_status == "Booking Confirmed"}
                                />

                                <DialogActions>
                                  {
                                    this.state.selectedBooing?.attributes?.booking_status == "rejected" ?
                                    <Button
                                    data-testid="cancelBookingbutton"
                                    variant="contained"
                                    className="CancleBtnDisabled"
                                    disabled
                                  >
                                    {cancelButtonTitle}
                                    </Button>
                                    :
                                    <Button
                                    onClick={this.handleCancelbooking}
                                    data-testid="cancelBookingbutton"
                                    variant="contained"
                                    className="CancleBtn"
                                  >
                                    {configJSON.cancelButton}
                                    </Button>
                                  }
                                </DialogActions>
                              </Box>
                            )}
                          </StyledDialog>
                        </Container>

                        {this.renderPopupModals()}
                      </BuyerOffestyledrScreen>

                        ): (
                          <div style={{...buyerStyle.noDataShow, 
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                          }}>
                          <img
                              src={resultNotFoundRed}
                              alt="resultNotFound"
                              style={buyerStyle.noDataImgShow}
                          />
                          <p style={{...buyerStyle.noDtataContent, fontWeight: "bold"}}>No Results Found!</p>
                          <p style={buyerStyle.dataMessage}>Change or Remove Filters and Try Again.</p>
                          </div>
                    )}
          </Container>
        </section>
      </StyleContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const CalendarImgBox = styled(Box)({
  root: {
    position: "relative"},
  "& img": {
    cursor: "pointer",
  height: "24px", width: "24px",
  position: "absolute",top: "32px", 
  zIndex: 1,right: "12px"
}
});
const DropdownArrowBox = styled(Box)({
  root: {
    position: "relative"
  },
  "& img": {
    height: "10px",width: "10px", 
    position: "absolute",top: "15px", 
    zIndex: 1, right: "20px",
    cursor: "pointer"

  }
});

const DatePickerStyle = styled(DateRangePicker)({
  "& .MuiInputLabel-animated": 
  {
    transition: 
    "unset"
  },
  "& .DateMainClassName": {
    "& .MuiPaper-root": 
    {
      paddingBottom: 
      "60px !important",
      backgroundColor:
       "grey",
    }
  }
})

const DateContainer = styled(Box)({
  "& .ButtonContaienr":
   {
    position: 
    "absolute",
    display: "flex", 
    justifyContent: "flex-end",
    bottom: "2px", 
    right: "10px",
    zIndex: 9,
    padding: ".625rem .375rem"
  },
  "& .DateMainClassName": 
  {
    "& .MuiInputBase-input":{
      fontFamily: "Asap, sans-serif",
    },
    "& .MuiPaper-root": 
    {
      paddingBottom:
       "20px",
      "& .MuiGrid-wrap-xs-nowrap":
      {
        "&.MuiGrid-root":
        {
          "& .materialui-daterange-picker-MuiList-root-59":{
            display:"none"
          }
        }
      }
    },
    "& .MuiTypography-root":
     {
      fontWeight: "500 !important",
      fontFamily: 'Asap !important'
    },
    "& .materialui-daterange-picker-makeStyles-highlighted-57":
    {
      color:
       "red !important",
    }
  },"& ul":{ 
    marginBottom: "20px"},
  "& li":{ 
    fontFamily: "Asap, sans-serif"}
})
const BuyerOffestyledrScreen = styled(Box)({
  root: {
    background: "#eee",padding: "30px 0",
    height: "100vh",
  },
  "& .MainContainer": {
    paddingTop: "30px",
  },
  "& .containerStyle": {
    padding: "0% 0%",
  },

  "& .slotsFor": {
    margin: 0,
    fontFamily: `"Asap", "sans-serif"`,
    fontWeight: 800,
    fontSize: "28px",
    color: "#1E1E1E",
  },
  "& .selectSlot": {
    width: "400px",
    marginLeft: "15px",
  },

  "& .slotsSection": {
    marginBottom: "50px",
    "& .leftBar": {
      display: "flex",
      alignItems: "center",
    },
  },

  "& .btnDiv": {
    display: "flex",
    alignItems: "center",
  },
  "& .addRequest": {
    display: "flex", 
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Asap",
    border: "none",
    fontSize: "14px",
    textTransform: "uppercase" as "uppercase",
    borderRadius: "32px",
    padding: "9px 18px",
    color: "#FFFFFF",
    marginRight: "30px",
    width: "256px",
    fontWeight: "bold",
    background: "#FF5C00",
    marginLeft: "73%",
    height: "48px",
    cursor: "pointer",
  },
  "& .btnImgDiv": {
    marginRight: "8px", 
    width: "10%",
  },
});

const StyledDialogModal = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: "800px",
    borderRadius: "24px",
    padding: "5% 0.5%",
    paddingTop: "0.5% !important",
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper": { 
    width: "100%",
    maxWidth: "800px",
    maxHeight: "720px", 
    borderRadius: "24px",
    overflowY: "auto", // Enable vertical scrolling
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar": {
    width: "8px",
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "4px",
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#888",
  },

  
  "& .MuiFormHelperText-contained":{
    marginLeft:"0px"
      },
  "& .buyerTexterrorMsg": {
    color: "red",fontSize: "16px",
  },
  "& .nominateCheckbox": {
    color: "#1E1E1E",
    "& .MuiSvgIcon-root": 
    { 
      fontSize: 28 
    },
  },
  "& .nominateChecked": {
    color: "#FF5C00",
    "& .MuiSvgIcon-root": 
    { 
      fontSize: 28
     },
  },
  "& .nominationClose": {
    textAlign: "right",
  },
  "& .nominationMsggrid": {
    textAlign: "center",
  },
  "& .nominationCloseimg": {
    height: "24px",
    cursor:"pointer"
  },
  "& .nominationErrorimg": {
    height: "100px",
  },
  "& .nominationHeading": {
    fontWeight: 700,
    fontSize: "28px",
    color: "#1E1E1E",
    fontFamily: "Asap",
  },
  "& .revisionText": {
    fontWeight: 400,
    fontSize: "18px",
    color: "#1E1E1E",
    fontFamily: "Asap",
  },
  "& .reasonBox": {
    margin: "5%",
    textAlign: "start",
    display: 'flex',
    flexDirection: 'column',
  },
  "& .reasonTextfield": {
    fontWeight:800,fontSize: "18px",
    color: "#1E1E1E",fontFamily: "Asap",
    width: "100%",
  },

  "& .nominationLink": {
    fontWeight: 500,fontSize: "14px",
    color: "#1653DD",fontFamily: "Asap",
  },
  "& .nominationLinktext": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E1E1E",
    fontFamily: "Asap",
  },
  "& .nominationButton": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "10px 70px",
    backgroundColor: "#FF5C00",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
    textTransform: "uppercase",
  },
  "& .nominationButton1": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "10px 70px",
    backgroundColor: "#FF5C00",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
    textTransform: "uppercase",
  },
  "& .disbledBtn": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "250px",
    backgroundColor: "gray",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
  },
  "& .nominationButtonOutlined": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "8px 70px",
    backgroundColor: "#fff",
    color: "#FF5C00",
    borderRadius: "25px",
    marginTop: "2%",
    textTransform: "capitalize",
    border: '2px solid #FF5C00',
    marginLeft: '10px'
  },
  "& .addContainerbutton": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "10px 50px",
    backgroundColor: "#FF5C00",
    color: "#fff",
    borderRadius: "25px",
    margin: "2% 0%",
    textTransform: "uppercase",
  },
  "& .AddContainerButtonDisable":{
    "& .Mui-disabled":{
      backgroundColor: "lightgrey"
    }
  },
  "&. addContainerimg": {
    height: "20px",
  },
  "& .nominationButtondisable": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "10px 70px",
    backgroundColor: "#FF5C00",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
    opacity: "0.3",
    textTransform: "capitalize",
  },
  "& .uploadButton": {
    width: "fit-content",
    padding: "10px 22px",
    textAlign: "start",
    textTransform: "capitalize",
  },
  "& .uploadButtonshow": {
    width: "max-content",
    padding: "10px 10px",
    textAlign: "start",
    paddingLeft: "5px",
    position: "relative",
    margin: "1%",
    textTransform: "capitalize",
  },
  "& .textandPdfIcon": {
    display: "flex"
  },
  "& .SmallIcon": {
    cursor: "pointer",
    height: "1.375rem",
  },
  "& .verifyTextBox": {
    marginTop: "2%",
    padding: "1% 32%",
    textAlign: "start",
     display: "flex",
     justifyContent: "center"
  },
  "& .paymentTextBox": {
    marginTop: "2%",
    padding: "1% 25%",
    textAlign: "start",
  },
  "& .dragging":{
    "& .uploadButton":{
      backgroundColor: "lightgrey"
    }
  },
  "& .recentButton": {
    width: "fit-content",
    padding: "10px 10px",
    textAlign: "start",
    position:"relative",
    margin:"1%",
    fontFamily:'asap',
    textTransform:"uppercase",
    "& .MuiButton-label":{
      justifyContent:"flex-start"
    },
    "& .MuiButton-startIcon":{
      marginLeft: "0px"
    }
  },
  "& .uploadDeletebutton": {
    position:"absolute",
    right:"1%",
        top:"30%",
        height:"20px"
  },
  "& .pdfImg":{
   height:"20px"
  },
  "& .verificationText": {
    fontWeight: 700,
    fontSize: "16px",
    color: "#363636",
    fontFamily: "Asap",
  },
  "& .submittingAccordiongrid": {
    backgroundColor: "#E3E6ED",
  },
  "& .submittingSecondgrid": {
    marginTop: "2%",
    backgroundColor: "#E3E6ED",
    "& .MuiPaper-elevation1":{
      boxShadow: "none"
    }
  },
  "& .submittinggrid": {
    marginTop: "2%",
    backgroundColor: "#E3E6ED",
    padding: "1% 2%",
  },
  "& .submittingFirstgrid": {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "24px",
    color: "#1E1E1E",
    fontFamily: "Asap"
  },
  "& .vesselTextfieldgrid": {
    padding: "1% 1%",
    fontFamily: "Asap",
  },
  "& .vesselInformationtext": {
    textAlign: "center",
    fontWeight: 600,
    fontSize: "20px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    position: "relative",
  },

  "& .vesselInformationtext1": {
    textAlign: "center",
    fontWeight: 600,
    fontSize: "20px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    position: "relative",
    paddingBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .HeadingTextHidden":{
    visibility:"hidden"
  },
  "& .vesselsubtext": {
    textAlign: "center",
    fontWeight: 600,
    fontSize: "18px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    position: "relative",
  },
  "& .accordionDetails": {

    "& .MuiAccordionDetails-root": {
      padding: "0px 16px 16px !important",
    },
    
  },
  "& .vesselViewlesstext": {
    position: "absolute",
    right: "1%",
    fontSize: "12px",
    cursor:"pointer"
  },
  "& .vesselCloseimg": {
    height: "18px",
    position: "absolute",
    right: "100%",
  },
  "& .vesselRemovetext": {
    position: "absolute",
    right: "1%",
    fontSize: "12px",
    top: "90%",
    color: "red",
  },
  "& .vesselTextfieldtext": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    textTransform: 'uppercase',
    alignItem: 'center',
    justifyContent: 'flex-start',
    display:'flex',
  },
  "& .vesselTextfieldgrid .country-list": {
    fontFamily: "Asap",
    color: '#1b1b1b',
  },
  "& .vesselTextfieldgrid .country-list .dial-code": {
    fontFamily: "Asap",
    color: '#1b1b1b',
  },
  "& .vesselTextfieldtextkgs": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    alignItem: 'center',
    justifyContent: 'flex-start',
    display:'flex',
  },
  "& .vesselTextfieldtext1": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    alignItem: 'center',
    justifyContent: 'flex-start',
    display:'flex',
  },
  "& .PhoneInputPackgeClass": {
    height: "40px",
    borderRadius: "10px",
    fontSize: "15px",
    fontFamily: "Asap, sans-serif",
    width: "100%",
    fontWeight: 600,
    backgroundColor: "white",
    "@media (max-width: 1200px)": {
      fontSize: "12px",
      height: "30px",
    },
  },
  "& .textfieldStyle": {
    color: "black",
  },
});

const MainDiv = styled(Box)({
  "@media (max-width: 1279px)": {
    overflow: "scroll"
   },
   "& .trackingDatabox": {
     margin: "1%",
   },
  "& .trackingDatacontainer": {
    textAlign: "center",
    margin: "10px 0px",
    overFlow: "scroll",
  },
  "& .trackingDatagridsecond": {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center", 
    margin: "10px 0px",
    borderRadius:"8px",
    "@media (max-width: 1279px)": {
      display: "grid"
     },
  },
  "& .trackingMaingrid": {
    display: "flex",
    alignItems: "center",
  },
  "& .trackingDatagriditem": {},
  "& .trackingDatagriditem1": {
    paddingTop: "5%",
  },
  "& .mainContainer": {
    display: "flex",
    fontSize: "16px",
    marginTop: "10px"
  },
  "& .boxPart1": {
    backgroundColor: "#C2E7FF",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "8px 0px 0px 8px",
    borderLeft: "6px solid #6AA2C6",
  },
  "& .box": {
    display: "flex",
    margin: "2%"
  },
  "& .boxText": {
    height: "fit-content", 
    width: "fit-content",
    border: "1px solid #6AA2C6",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 900,
    fontFamily: "Asap",
    padding: "4px 20px 4px",
    background: "#C2E7FF",
    color: "black",
    borderRadius:"4px"
  },
  "& .boxTextid": {
    color: "#1653DD",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    fontFamily: "Asap",
    lineHeight: "14px",
    textTransform: "uppercase",
    margin: "6%",
    whiteSpace:"nowrap"
  },

  "& .firstSecondPart": {
    display: "flex",
    justifyContent:"center",
    padding: "20px 0px",
    alignItems: "center",
    gap: "16px",
    width: "95%",
    margin: "3%",
    "& .secondPart1": {
      width: "15%",
    }
  },
  "& .first": {
    color: "#797979",
    fontSize: "12px",
    fontStyle: "italic",
    fontFamily: "Asap",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  "& .mbottom" :{
    marginBottom:"30px",
    display:"flex",
    flexDirection:"column",
    gap:"5px",
    alignItems:"flex-start"
},
  "& .second": {
    color: "#1E1E1E",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "16px",
    fontFamily: "Asap",
    gap: "16px",
    marginTop: "7px",
    "@media (max-width: 768px)": {
      fontSize: '16px'
    },
  },
  "& .seconds": {
    color: "#1E1E1E",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "16px",
    fontFamily: "Asap",
    gap: "16px",
    marginTop: "11px",
    "@media (max-width: 768px)": {
      fontSize: '16px'
    },
  },
  "& .third": {
    color: "#1E1E1E", 
    fontSize: "20px",
    fontFamily: "Asap",
    fontWeight: 700,
    lineHeight: "16px",
    gap: "16px",
    marginTop: "7px",
    whiteSpace:"nowrap",
    "@media (max-width: 768px)": {
      fontSize: '16px'
    },
  },
  "& .middlepartoffirstbox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  "& .trainImage": {
    height: "24px",
    width: "24px",
  },

  "& .trainPart": {
    display: "flex",
    gap: "7px",
    color: " #1E1E1E",
    fontFamily: "Asap",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    whiteSpace:"nowrap",
    width:"80%",
    "@media (max-width: 768px)": {
      fontSize: '15px'
    },
  },

  "& .containerSizeBorder": {
    border: "1px solid #000",
    padding: "1px 5px",
background: "#F7F7F7",
boderRadius: "4px",
margin: "0px 4px",
pontSize: "14px",
fontFamily: "Asap",
  },
  "& .arrowImage": {
    color: "red",
    height: "20px",
  },
  "& .secondBoxMain": {
    backgroundColor: "#FFF",
    display: "flex",
    alignItems: "center",
    gap: "25px",
    width: "100%", 
    borderRadius: "0px 8px 8px 0px",
  },
  "& .popperStyle": {
     backgroundColor: "white",
     padding: "1%",
  },

  "& .nominates_icon": {
    display: "flex",
    alignItems: "center",
    gap: "18px",
  },

  "& .AllImg": {
    width: "58px", 
    height: "58px",
    borderRadius: "31px",
    background: "#D9D9D9",
  },

  "& .secondBoxPart1": {
    backgroundColor: "#D9D9D9",
    height: "58px",
    width: "58px", 
    borderRadius: "31px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  "& .para": {
    color: "#797979",
    alignItems: "center",
    fontSize: "10px",
    fontWeight: "600",
  },
  "& .mainButton1": {
    minWidth: '180px',
    backgroundColor: "#FF5C00",
    borderRadius: "62px",
    display: "flex",
    width: "100%",
    height: "44px", 
    padding: "6px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    color: "var(--Basic-White, #FFF)",
    textAlign: "center",
    fontFamily: "Asap",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "capitalize",
  },
  "& .mainButtonDisabled":{
    minWidth: '180px',
    backgroundColor: "gray",
    borderRadius: "62px",
    display: "flex", 
    width: "100%",
    height: "44px",
    padding: "6px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    color: "var(--Basic-White, #FFF)",
    textAlign: "center",
    fontFamily: "Asap",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  "& .mainButtonconfirmed": {
    minWidth: '180px',
    backgroundColor: "#A1EA88",
    border: "1px solid #168B1B",
    borderRadius: "62px",
    display: "flex",
    width: "100%",
    height: "44px",
    padding: "6px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    color: "#168B1B",
    textAlign: "center",
    fontFamily: "Asap",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "capitalize",
  },
  "& .ByerMatchesSubmittedBoxes": {
    padding: "10px 20px",
    "& .pTB50": {
      padding: "50px 0",
    },
    "& .mb20": {
      marginBottom: "20px",
    },
    "& .BuyerHeading": {
      color: "#1E1E1E",
      fontSize: "28px",
      fontFamily: "Asap, sans-serif",
      fontWeight: 700,
    },
    "& .BuyerPara": {
      color: "#363636",
      fontSize: "16px",
     fontFamily: "Asap, sans-serif",
     margin: "0 0 5px 0",
    },
    "& .textEnd": {
      display: "flex",
      justifyContent: "end",
    },
    "& .successBtn": {
      width: "30px",
      height: "30px",
    },
    "& .RevisionModal": {
      color: "#363636",
      fontFamily: "Asap",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
    },
    "& .dFlexJCenter": {
      display: "flex",
      alignItems: "center",
    },
    "& .submitBtn": {
      display: "flex", 
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Asap",
      fontSize: "16px",
      background: "#FF5C00",
      border: "none",
      borderRadius: "32px",
      padding: "9px 18px",
      color: "#FFFFFF",
      fontWeight: 600,
      height: "56",
      width: "264",
       marginLeft: "28%",
      textTransform: "capitalize",
      cursor: "pointer",
    },
  },
});

// ############################# New Styles Start ###########################################


const StyleContainer = styled(Box)({
  "& .noData": {
    flexDirection: "column",
    alignItems: "center",
    display: "flex", 
    paddingTop: "6rem",
    justifyContent: "center",
    fontFamily: "Asap",
  },
  "& .noDataImg": {
    height: "5rem"
  },
  "& .head": {
    margin: "0.5rem",
    fontWeight: "bold",
    fontFamily: "Asap", fontSize: "2rem",
  },
  "& .msg": {
    margin: "0rem", 
    fontSize: "1rem",
    fontFamily: "Asap",
  },
  "& .DropDownDiv": {
    display: 'flex', 
    flexShrink: 0, 
    textTransform: 'uppercase', 
    gap: '.625rem', 
    alignItems: 'center', 
    justifyContent: 'center',
     paddingRight: '.625rem', 
     paddingLeft: '.625rem',
    "& .DropDownDivh3": {
      fontSize: '1rem',
      margin:"0px",
      "@media (max-width: 600px)": {
        fontSize: '10px',
      },
    },
    "& .DropDownDivP": {
      fontSize: '1rem',
      "@media (max-width: 600px)": {
        fontSize: '10px',
      },
    },
    "@media (max-width: 600px)": {
      paddingLeft: '0px',
    },
  },
});
const buyerStyle = {
  root: {
      background: "#eee", padding: "30px 0",
  },
  slotsSection: {
      marginBottom: "50px"
  },
  boxtext:{
    marginLeft:"4px",width: "fit-content",
    height: "fit-content",
    fontWeight: 900,fontSize: "13px",
    fontStyle: "normal", fontFamily: "Asap",
    background: "#C2E7FF",
    padding: "4px 16px 4px",borderRadius:"4px",
    bordeRadius:"4px",
    whiteSpace: "nowrap" as "nowrap",border: "1px solid #6AA2C6",
  },
  leftBar: {
      alignItems: "center",
      display: "flex",
  },
  slotsFor: {
    margin: 0,
    fontFamily: `"Asap", "sans-serif"`,
    fontWeight: 800,
    fontSize: "24px",
    color: "#1E1E1E",
  },
  selectSlot: {
    width: "24.3125rem",
    height: "3rem",
    border: ".0625rem solid rgba(205, 205, 205, 1)",
    background: "rgba(255, 255, 255, 1)",
    display: 'flex',
    marginLeft: ".9375rem",
    padding: '1rem .75rem',
    justifyContent: 'space-between',
    borderRadius: ".5rem",
    cursor: 'pointer',
    alignItems: 'center',
  },
  selectSlotDropdown: {
    position: 'absolute' as 'absolute',
    borderRadius: '.5rem',
    width: '24rem',
    boxShadow: "rgba(100, 100, 111, 0.2) 0rem .4375rem 1.8125rem 0rem",
    background: 'white',
    padding: '.25rem .375rem',
    flexDirection: "column" as "column",
    display: 'flex',
    gap: '.125rem',
    marginTop: '.25rem',
    left: '0.9rem',
    zIndex: 2,
  },
  selectSlotItem: {
    display: 'flex',
    width: '100%',
    height: '2.25rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1rem',
  },
  
  addListing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ".875rem",
    background: "#1653DD",
    border: "none",
    borderRadius: "2rem",
    padding: ".5625rem 1.125rem",
    color: "#FFFFFF",
    marginRight: "1.875rem",
    fontWeight: 600,
    height: "3rem",
    width: "16rem",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
  },
  btnDiv: {
    alignItems: "center",
      display: "flex",
  },
  deleteText: {
      fontFamily: "Asap",
      margin: 0,
      fontSize: "14px",
      cursor: 'pointer',
      color: "#DC2626",
  },
  editText: {
      fontFamily: "Asap",
      cursor: 'pointer',
      fontSize: "14px",
      margin: "0 0 15px 0",
      color: "#1E1E1E",
  },
  uncontrolledBox: {
      display: "inline-block",
      textAlign: "end" as "end",
      minWidth: "150px",
      padding: "15px",
  },
  addRequest: {
    alignItems: "center",
      display: "flex",
      border: "none",
      justifyContent: "center",
      fontSize: "14px",
      borderRadius: "32px",
      marginRight: "30px",
      width: "256px",
      fontWeight: 600,
      background: "#FF5C00",
      height: "48px",
      fontFamily: 'Asap',
      textTransform: "uppercase" as "uppercase",
      padding: "9px 18px",
      color: "#FFFFFF",
      cursor: "pointer"
  },
  addRequestContainer: {
      display: "flex",
      border: "1px solid #000",
      alignItems: "center",
      borderRadius: "32px",
      padding: "9px 18px",
      width: "256px",
      fontWeight: 600,
      fontSize: "14px",
      fontFamily: 'Asap',
      justifyContent: "center", textTransform: "uppercase" as "uppercase",
      color: "black",
      marginRight: "30px",
      height: "48px",
  },
  btnImgDiv: {
    width: "10%",
      marginRight: "8px",
  },
  textEnd: {
    display: "flex",
      justifyContent: "end",
  },
  org: {
      color: "#1E1E1E",
      padding: "6px 12px",
      background: "#B8D5F9",
      fontWeight: 500,
      fontSize: "15px",
      fontFamily: 'Asap',
      borderRadius: "4px",
  },
  tableContent: {
    fontWeight: 500,
      color: "#363636",
      fontFamily: 'Asap',
      fontSize: "15px",
  },
  highlightedTableContent: {
    padding: '8px 0px',
    width: '100%',
    textAlign: 'center' as 'center',
  },
  totalRate: {
      color: "#363636",
      fontWeight: 800,
      fontSize: "15px",
      fontFamily: 'Asap',
  },
  typeDiv: {
    fontWeight: 500,
    background: "#B8D5F9",
    justifyContent: "space-between",
      display: "flex",
      color: "#1E1E1E",
      fontFamily: "Asap",
      fontSize: "15px",
      borderRadius: "4px", marginBottom: "3px",
      padding: "6px 12px",
      border: "1px solid #6495D2"
  },
  pipe: {
    left: "78px",
      position: "absolute" as "absolute",
      top: 0,
      background: "#6495D2",
      height: "32px",
      width: "1px",
  },
  quantityDiv: {
    borderRadius: "8px",
    display: "flex",
      alignItems: "center",
      border: "1px solid #DADADA",
      padding: "10px 20px",
      marginRight: "15px",
  },
  available: {
    fontWeight: 500,
      fontSize: "15px",
      fontFamily: "Asap",
      color: "#3C7C09",
  },
  availableQuantity: {
    margin: 0,
      fontSize: "18px",
      fontWeight: 500,
      color: "#3C7C09",
      fontFamily: "Asap"
  },
  total: {
    padding: "5px 10px",
      color: "#1E1E1E",
      borderRadius: "4px",
      fontFamily: "Asap",
      background: "#EAEAEA",
      fontWeight: 500,
      fontSize: "15px",
  },
  bookedAmount: {
    marginTop: "10px",
      fontSize: "15px",
      fontFamily: "Asap",
      fontWeight: 500,
      textAlign: "center" as "center",
      color: "#DB2525",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  },
  
  tableLeftBorder: {
    textAlign: "center" as "center",
      borderRadius: "6px",
      borderLeft: "3px solid #B8D5F9",
  },
  mr15: {
    marginRight: "15px"
},
  tableRightBorder: {
      borderTopRightRadius: "6px",
      borderRight: "3px solid #ffffff", borderBottomRightRadius: "6px",
      textAlign: "center" as "center",
  },
  dFlex: {
    justifyContent: "center",
      listStyle: "none",
      alignItems: "center",
      display: "flex",
  },
  textCenter: {
      textAlign: "center" as "center"
  },
  MainDiv: {
    marginTop: "6%",flexDirection: "column" as const,
      height: "192px",display: "inline-flex",
      marginLeft: "33%",width: "304px",
      alignItems: "center",
  },
  InnerDiv: {
    marginLeft: "40%",
    width: "180%",
      height: "100%"
  },
  headerImagess: {
      width: "100px",
      marginLeft: "30%",
      height: "100px",
  },
  headingNotFound: {
    fontStyle: "normal",
    fontWeight: 700,
      color: "#1E1E1E",
      lineHeight: "normal",
      fontFamily: "Asap",
      fontSize: "24px",
  },
  headingChanges: {
    fontSize: "16px",
    fontStyle: "normal",
      color: "#363636",
      fontFamily: "Asap",
      marginTop: "auto",
      width: "284px",
      height: "18px",
      fontWeight: 500,
      lineHeight: "normal",
  },
  tableYellowBorder: {
      borderRadius: "6px",
      textAlign: "center" as "center",
      borderLeft: "3px solid #D0B655",
  },
  orgYellow: {
      fontSize: "15px",
      background: "#FFECA5",
      padding: "6px 12px",
      color: "#1E1E1E",
      borderRadius: "4px",
      fontWeight: 500,
      fontFamily: "Asap",
  },
  typeDivYellow: {
    marginBottom: "3px",
      display: "flex",
      fontWeight: 500,
      borderRadius: "4px",
      justifyContent: "space-between",
      color: "#1E1E1E",
      background: "#FFECA5",
      fontFamily: "Asap",
      border: "1px solid #FFECA5",
      fontSize: "15px",
      padding: "6px 12px",
  },
  tableGreenBorder: {
      borderLeft: "3px solid #7DBD43",
      borderRadius: "6px",
      textAlign: "center" as "center",
  },
  orgGreen: {
      background: "#CFF0B0",
      borderRadius: "4px",
      color: "#1E1E1E",
      fontWeight: 500,
      fontFamily: "Asap",
      padding: "6px 12px",
      fontSize: "15px",
  },
  typeDivGreen: {
    fontSize: "15px",
    color: "#1E1E1E",
      justifyContent: "space-between",
      borderRadius: "4px",
      fontWeight: 500,
      display: "flex",
      marginBottom: "3px",
      background: "#CFF0B0",
      border: "1px solid #7DBD43",
      fontFamily: "Asap",
      padding: "6px 12px",
  },
  tableDarkBlueBorder: {
      borderRadius: "6px",
      borderLeft: "3px solid #6AA2C6",
      textAlign: "center" as "center",
  },
  orgDarkBlue: {
    padding: "6px 12px",
    borderRadius: "4px",
      background: "#C2E7FF",
      color: "#1E1E1E",
      fontWeight: 500,
      fontFamily: "Asap",
      fontSize: "15px",
  },
  typeDivDarkBlue: {
    fontSize: "15px",
    fontWeight: 500,
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #6AA2C6",
      marginBottom: "3px",
      color: "#1E1E1E",
      borderRadius: "4px",
      fontFamily: "Asap",
      background: "#C2E7FF",
      padding: "6px 12px",
  },
  tablePinkBorder: {
      textAlign: "center" as "center",
      borderRadius: "6px",
      borderLeft: "3px solid #FF8276",
  },
  orgPink: {
      fontWeight: 500,color: "#1E1E1E",
      padding: "6px 12px",
      background: "#F5B7B1",
      borderRadius: "4px",
      fontFamily: "Asap",
      fontSize: "15px",
  },
  typeDivPink: {
      background: "#F5B7B1",padding: "6px 12px",
      fontWeight: 500,fontSize: "15px",
      fontFamily: "Asap",display: "flex",
      border: "1px solid #FF8276",marginBottom: "3px",
      color: "#1E1E1E",justifyContent: "space-between",
      borderRadius: "4px",
  },
  tablePurpleBorder: {
      textAlign: "center" as "center",borderLeft: "3px solid #D87DFF",
      borderRadius: "6px",
  },
  orgPurple: {
      background: "#D7BDE2",borderRadius: "4px",
      fontWeight: 500,fontSize: "15px",
      fontFamily: "Asap",color: "#1E1E1E",
      padding: "6px 12px",
  },
  typeDivPurple: {
    color: "#1E1E1E",background: "#D7BDE2",
      display: "flex",justifyContent: "space-between",
      border: "1px solid #D87DFF",borderRadius: "4px",
      fontSize: "15px",padding: "6px 12px",
      fontFamily: "Asap",fontWeight: 500,
      marginBottom: "3px"
  },
  tableMustardBorder: {
      textAlign: "center" as "center",
      borderRadius: "6px",
      borderLeft: "3px solid #FFAA62",
  },
  orgMustard: {
    borderRadius: "4px",fontSize: "15px",
      fontFamily: "Asap",fontWeight: 500,
      padding: "6px 12px",color: "#1E1E1E",
      background: "#F5CBA7",
  },
  typeDivMustard: {
    background: "#F5CBA7",fontSize: "15px",
      display: "flex",justifyContent: "space-between",
       color: "#1E1E1E",fontFamily: "Asap",
      fontWeight: 500,border: "1px solid #FFAA62",
      padding: "6px 12px",borderRadius: "4px",
      marginBottom: "3px"
  },

  addListingModal: {
    borderRadius: "24px",
      padding: "20px 10px",
  },
  p_10_b_30: {
    padding: "10px 30px"
},
  ellipsis: {
    cursor: "pointer"
},
  
  close: {
      cursor: "pointer", textAlign: "end" as "end",
  },
  title: {
    color: "#1E1E1E",fontSize: "28px",
      textAlign: "center" as "center",margin: "0 0 15px 0",
      fontFamily: "Asap, sans-serif",
  },
  
  principalLabel: {
      fontWeight: 600,fontSize: "14px",
      color: "#363636", fontFamily: "Asap, sans-serif",
  },
  organisation: {
    margin: "0 20px 20px 20px"
},
  principalLabelAverageBox: {
    fontFamily: "Asap, sans-serif",fontSize: "14px",
      color: "#363636",justifyContent: "space-between",
      fontWeight: 600, display: "flex",
  },
  Removes: {
      display: "flex",fontFamily: "Asap",
      fontSize: "14px",alignItems: "center",
      gap: "8px", lineHeight: "24px",
      color: "#DB2525",fontStyle: "normal",
      fontWeight: 600,
  },
  icons: {
      width: "5%",
      marginLeft: "52%",
      height: "2%",
  },
  vesselContainer: {
    borderRadius: "18px",background: "#E3E6ED",
      marginBottom: "15px",padding: "25px 10px",
  },
  vesselHeading: {
    margin: "0 0 25px 0",color: "#1E1E1E",
      textAlign: "center" as "center",
      fontSize: "18px",fontFamily: "asap, sans-serif",
  },
  inputText: {
    width: "100%",padding: "0 8px",
      border: "1px solid #CBD5E1", height: "44px",
      borderRadius: "5px",
  },


  bBottom: {
    border: "1px solid #CBD5E1",
      margin: "0 2px 20px 2px",
  },
  rateBox: {
    background: "#fff",alignItems: "center",
      borderRadius: "8px",border: "1px solid #CBD5E1",
      display: "flex",
  },
  borderRight: {
    borderRight: "1px solid #CDCDCD",
      height: "41px",
  },
 
  usd: {
    margin: "0 10px",
},
  rateDiv: {
      fontSize: "14px",
      fontFamily: "Asap, sans-serif",
      color: "#1E1E1E",
  },
  
  inputRateText: {
      height: "44px",width: "40px",
      borderBottomLeftRadius: "5px",border: "none",
      borderTopLeftRadius: "5px",padding: "0 8px",
  },
  slash: {
      position: "relative" as "relative",
      left: "12px",
      color: "gray"
  },
  ml5: {
    marginRight: '85%',width: "4%",
  },
  dFlexJCenter: {
    justifyContent: "center",display: "flex",
      alignItems: "center"
  },
  submitBtn: {
    borderRadius: "32px", padding: "9px 18px",
      display: "flex", alignItems: "center",
      height: "45px", textTransform: "uppercase" as "uppercase",
      width: "256px",justifyContent: "center",
      cursor: "pointer",fontFamily: 'Asap',
      fontSize: "16px",background: "#AA4649",
      border: "none",color: "#FFFFFF",
      fontWeight: 600,
  },
  mb20: {
    marginBottom: "20px"
  },
  BuyerSubmittedBox: {
      padding: "10px 20px"
  },
  BuyerHeading: {
      color: "#1E1E1E", fontFamily: "Asap, sans-serif",
      fontSize: "28px",
      fontWeight: 700,
  },
  
  pTB50: {
    padding: "50px 0"
  },
  successBtn: {
      width: "10%"
  },
  noDataShow : {
    paddingTop: "6rem",
      fontFamily: "Asap",
  },
  noDtataContent : {
    fontFamily: "Asap",
    fontSize: "2rem",
    margin: "0.5rem",
  },
  noDataImgShow : {
      height: "5rem",
  },
  dataMessage: {
    margin: "0rem",
    fontSize: "1rem",
      fontFamily: "Asap",
  },
}



const StyledSwitch = styled("div")({
  position: 'relative',
  marginRight: "15px",
  display: 'inline-block',
  width: '75px',
  height: '2.125rem',
  '& input': {
    opacity: 0,
    width: 0,
    height: 0,
  },
  '& .slider': {
    position: 'absolute',
    cursor: 'pointer',
    backgroundColor: '#ccc',
      top: 0,
      left: 0,right: 0,
      bottom: 0,transition: 'background-color 0.4s',
      "& .text": {
          position: "absolute",color: "#FFF",
          top: "5px",right: "9px",
          fontSize: "1rem",fontWeight: "bolder",
          fontFamily: 'Asap',
      },
      '&:before': {
          position: 'absolute',content: '""',
          width: '1.625rem',height: '1.625rem',
          right: '0.56rem',bottom: '.25rem',
          transition: 'transform 0.4s',
          backgroundColor: '#FFFFFF',
      },
  },

  '& input:checked + .slider': {
    backgroundColor: '#FF5C00',},

  '& input:focus + .slider': {
    boxShadow: '0 0 .0625rem #2196F3',},

  '& input:checked + .slider:before': {
    transform: 'translateX(-37px)',},

  '& .slider.round': {
      borderRadius: '2.125rem',

      '&:before': {borderRadius: '50%',
},
  },
});

const projectTextFieldProps: any = {
  anchorOrigin: {
    horizontal: "left",
    vertical: "bottom"
  },
  transformOrigin: {
    horizontal: "left",
    vertical: "top"
  },
  getContentAnchorEl: null
}


const StyledTabs = withStyles((theme) => ({
  root: {
      background: "#DADADA",borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",color: " #363636",
      fontSize: "20px",fontFamily: 'Asap',
      fontStyle: "normal",fontWeight: 500,
      lineHeight: "normal"
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",overflow: "visible",
      fontWeight: 700,color: "#1E1E1E",
      fontSize: "14px",fontFamily: 'Asap',
      fontStyle: "normal",lineHeight: "normal",
      textTransform: "uppercase",
      width: "451.5px",height: "72px"
  },
  selected: {
    background: "#ffffff",},

}))(Tab);

const BorderedStyledTab = withStyles((theme) => ({
  root: { borderTopRightRadius: "5px", borderTopLeftRadius: "5px",overflow: "visible", fontWeight: 700,color: "#1E1E1E", fontSize: "14px",fontFamily: 'Asap', fontStyle: "normal",lineHeight: "normal", textTransform: "uppercase", width: "451.5px",height: "72px"},
  selected: { background: "#ffffff", },
  wrapper: { borderRight: '1px solid rgba(172, 172, 172, 1)', padding: '14px 0px' }
}))(Tab);

const StyledInputLable = withStyles((theme) => ({
  root: {
      fontSize: "18px",fontWeight: 700,
      color: "#363636",textTransform: "uppercase",
      fontFamily: 'Asap',
  }
}))(InputLabel);


const BootstrapInput = withStyles((theme) => ({
  root: {
      "label + &": {marginTop: theme.spacing(3),},
  },
  input: {
      borderRadius: 5,
      position: "relative",backgroundColor: theme.palette.common.white,border: "1px solid #ced4da",
      fontSize: "16px",fontFamily: 'Asap',
      padding: "10px 12px",transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#363636",
      "&:focus": {borderColor: "##DCDCD",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
      },
  },
}))(InputBase);


const StyledBox = withStyles((theme) => ({
root: {
  position: "relative",
  border: "1px solid #ced4da",
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  fontSize: 16,
  backgroundColor: theme.palette.common.white,
  padding: "20px 110px",
  cursor: "pointer",
  borderRadius: 8,
  "&:focus": { borderColor: "#000",
  },
},
}))(Box);

const StyledChip = withStyles((theme) => ({
root: {
  background: "#FF5C00",
  fontFamily: "Asap, sans-serif", fontSize: "14px",
  color: "white",
  "& .MuiChip-deleteIcon": {
    color: "rgba(255, 255, 255, 1)",
  },
},
}))(Chip);
const CustomFormControlLabel = styled(FormControlLabel)({
  "& span": {
    fontFamily: "Asap"
  }
})



// ############################# New Styles End ###########################################

// Customizable Area End
